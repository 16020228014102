
import RemoteSelect from '@/components/FormRender/RemoteSelect/RemoteSelect.vue';
import RemoteModal from '@/components/FormRender/RemoteModal/RemoteModal.vue';
import RemoteTreeSelect from '@/components/FormRender/RemoteTreeSelect/RemoteTreeSelect.vue';
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'FieldRender',
  props: {
    field: {
      type: Object,
      required: true,
    },
    value: {
      required: true,
    },
    options: {
      type: Array,
      default: () => [],
    },
    validateInfos: {
      type: Object,
      default: () => {},
    },
    formModel: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    const { t } = useI18n();
    return {
      t,
    };
  },
  data() {
    return {
      model: this.value,
    };
  },
  watch: {
    model: {
      handler(newValue) {
        if ((this.field.type === 'number' || this.field.type === 'money') &&  (newValue == ''||newValue == null)) {
          this.model = 0;
          this.$emit('update:value', 0);
        } else if(this.field.type === 'input' && newValue){
          this.$emit('update:value', newValue.replace(/(^\s*)|(\s*$)/g, ""));
        } else{
          this.$emit('update:value', newValue);
        }
      },
    },
    value: {
      handler(newValue) {
        if ((this.field.type === 'number' || this.field.type === 'money') && (newValue == ''||newValue == null)) {
          this.model = 0;
        } else if(this.field.type === 'input' && newValue){
          this.model = (newValue.toString()).replace(/(^\s*)|(\s*$)/g, "");
        } else {
          this.model = newValue;
        }
      },
    },
  },
  created() {},
  components: {
    RemoteModal,
    RemoteSelect,
    RemoteTreeSelect,
  },
});
