<template>
  <pro-provider :i18n="t" :content-width="contentWidth">
    <a-layout class="ant-pro-basicLayout">
      <template v-if="isMobile">
        <a-drawer
          :bodyStyle="{ padding: 0, height: '100%' }"
          placement="left"
          :width="sideWidth"
          :closable="false"
          :visible="!collapsed"
          @update:visible="updateCollapsed"
        >
          <sider-menu
            style="height: 100%"
            :theme="theme"
            :layout="layout"
            :fixed="fixedSidebar"
            :menus="menus"
            :sider-width="sideWidth"
            :split-menus="false"
            :collapsed-button="false"
            :collapsed="false"
            v-model:open-keys="openKeys"
            v-model:selected-keys="selectedKeys"
          />
        </a-drawer>
      </template>
      <sider-menu
        v-else-if="!hasTopMenu"
        :theme="theme"
        :layout="layout"
        :fixed="fixedSidebar"
        :menus="menus"
        :sider-width="sideWidth"
        :split-menus="splitMenus"
        :collapsed-button="!splitMenus"
        v-model:collapsed="collapsed"
        v-model:open-keys="openKeys"
        v-model:selected-keys="selectedKeys"
      />
      <a-layout>
        <header-view
          :theme="theme"
          :layout="layout"
          :menus="menus"
          :sider-width="sideWidth"
          :has-sider-menu="hasSideMenu"
          :fixed-header="fixedHeader"
          :split-menus="splitMenus"
          v-model:collapsed="collapsed"
          v-model:open-keys="openKeys"
          v-model:selected-keys="selectedKeys"
        >
          <div style="margin-bottom: -15px">
            <a-input-group compact>
              <a-tag v-if="mqtt_state == -1" color="red" style="margin-top: 5px; margin-right: 8px">
                {{ t('离线') }}
              </a-tag>
              <a-tag
                v-if="mqtt_state == 1"
                color="green"
                style="margin-top: 5px; margin-right: 8px"
              >
                {{ t('在线') }}
              </a-tag>
              <a-tag v-if="mqtt_state == 0" color="blue" style="margin-top: 5px; margin-right: 8px">
                {{ t('无推送') }}
              </a-tag>
              <a-tooltip>
                <template #title>
                  <span>{{ t('客服未接入消息') }}</span>
                </template>
                <a-badge :count="no_receive_num" :offset="[-25, 5]">
                  <bell-outlined
                    style="margin-top: 8px; font-size: 16px; margin-right: 30px"
                    @click="to_kefu"
                  />
                </a-badge>
              </a-tooltip>
              <a-tooltip>
                <template #title>
                  <span>{{ t('未读消息') }}</span>
                </template>
                <a-badge :count="no_read_number" :offset="[-25, 5]">
                  <message-outlined
                    style="margin-top: 8px; font-size: 16px; margin-right: 30px"
                    @click="to_kefu"
                  />
                </a-badge>
              </a-tooltip>
              <a-select
                v-model:value="select_company"
                :dropdownMatchSelectWidth="false"
                style="width: 200px; margin-left: 10px"
                @change="selectCompany"
              >
                <a-select-option v-for="(obj, index) in company_list" :key="index" :value="obj.id">
                  {{ (t && t(`${obj.name}`)) || obj.name }}
                </a-select-option>
              </a-select>
              <a-button
                style="margin-left: 3px; margin-right: 3px"
                type="primary"
                @click="btn_obj.cb(t)"
                ghost
              >
                <plus-outlined />
                {{ t('新增顾问') }}
              </a-button>
              <a-select
                v-model:value="select_jump"
                :dropdownMatchSelectWidth="false"
                style="width: 120px"
              >
                <a-select-option value="1">{{ t('顾问') }}</a-select-option>
                <a-select-option value="2">{{ t('合同') }}</a-select-option>
              </a-select>
              <a-input-search
                :placeholder="t('模糊搜索')"
                style="width: 250px"
                v-model:value="search_value"
                allowClear="true"
                @search="openSearch"
              />
            </a-input-group>
          </div>
          <div style="margin-right: 10px; margin-left: 20px">
            <avatar-dropdown :current-user="currentUser" />
          </div>
          <div>
            <org-name-now :org_list="org_list" :current_org="current_org" />
            <select-lang />
            <!--            <mqtt-state />-->
            <component
              :is="modal.component ? modal.component : 'form-modal'"
              v-for="modal in modal_list"
              :key="modal.id"
              v-bind="modal"
              @update="
                data => {
                  modal_update(data, modal);
                }
              "
              @ok="
                data => {
                  modal_ok(data, modal);
                }
              "
              @cancel="
                data => {
                  modal_cancel(data, modal);
                }
              "
            ></component>
          </div>
        </header-view>
        <multi-tab v-if="multiTab" :fixed="multiTabFixed" :sider-width="sideWidth" />

        <router-view v-slot="{ Component }">
          <wrap-content>
            <component :is="Component"></component>
          </wrap-content>
        </router-view>
      </a-layout>
    </a-layout>
  </pro-provider>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  getCurrentInstance,
  inject,
  onUnmounted,
  reactive,
  ref,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { genMenuInfo } from '@/utils/menu-util';
import { default as WrapContent } from '@/components/base-layouts/wrap-content/index.vue';
import { default as GlobalFooter } from '@/components/base-layouts/global-footer/index.vue';
import { default as SiderMenu } from '@/components/base-layouts/sider-menu/index.vue';
import { default as HeaderView } from '@/components/base-layouts/header/index.vue';
import { default as SelectLang } from '@/components/select-lang/index.vue';
import { default as OrgNameNow } from '@/components/org-name-now.vue';
import { default as AvatarDropdown } from '@/components/avatar-dropdown.vue';
import { default as SettingDrawer } from '@/components/setting-drawer/index.vue';
import { MultiTab } from '@/components/multi-tab';
import { BellOutlined, MessageOutlined } from '@ant-design/icons-vue';
import { useStore } from 'vuex';
import { injectMenuState } from './use-menu-state';
import isGotoOldRouter from '@/router/return-old-router';
import { default as mqttState } from '@/components/mqtt-state.vue';
import FormModal from '@/components/form-modal/form-modal.vue';
import { btn_obj } from '@/layouts/create-consultant.ts';
import { useRoute, useRouter } from 'vue-router';
import { query_person_to_company_list } from '@/api/baseinfo-model';
import {
  GET_COMPANY,
  GET_NO_READ_NUMBER,
  GET_NO_RECEIVE_NUM,
  LOGOUT,
} from '@/store/modules/user/actions';
import bus from '@/utils/bus';
import { notification } from 'ant-design-vue';
import { debounce } from 'lodash';

export default defineComponent({
  name: 'BasicLayout',
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const { t } = useI18n();
    const menuState = injectMenuState();
    const select_jump: any = ref('1');
    const select_company: any = ref(null);
    const company_list: any = ref([]);
    const search_value: any = ref('');
    const isMobile = inject('isMobile', ref(false));
    const currentUser = computed(() => store.getters['user/currentUser']);
    const current_org = computed(() => store.getters['user/current_org']);
    const no_read_number = computed(() => store.getters['user/no_read_number']);
    const no_receive_num = computed(() => store.getters['user/no_receive_num']);
    store.dispatch(`user/${GET_NO_READ_NUMBER}`, { org_id: Number(current_org.value?.id) });
    store.dispatch(`user/${GET_NO_RECEIVE_NUM}`, {
      org_id: Number(current_org.value?.id),
      is_no_service: true,
      is_auto_reply: false,
      is_closed: false,
    });
    const timer: any = setInterval(() => {
      store.dispatch(`user/${GET_NO_READ_NUMBER}`, { org_id: Number(current_org.value?.id) });
      store.dispatch(`user/${GET_NO_RECEIVE_NUM}`, {
        org_id: Number(current_org.value?.id),
        is_no_service: true,
        is_auto_reply: false,
        is_closed: false,
      });
    }, 60000); // 刷新未读数 1分钟
    const org_list = computed(() => store.getters['user/org_list']);
    const info = computed(() => store.getters['user/info']);
    const current_company = computed(() => store.getters['user/current_company']);
    select_company.value = current_company.value?.id ? current_company.value?.id : null;
    const hasSideMenu = computed(
      () => menuState.layout.value === 'side' || menuState.layout.value === 'left',
    );
    const hasTopMenu = computed(() => menuState.layout.value === 'top');

    onUnmounted(() => {
      clearInterval(timer);
    });
    const mqtt_state = ref(0);
    const modal_list: any = reactive([]);
    let $bus: {
      emit: (arg0: string, arg1: any) => void;
      off: (arg0: string) => void;
      on: (arg0: string, arg1: any) => void;
    } | null = null;
    $bus = getCurrentInstance()?.appContext.config.globalProperties.$bus;

    $bus?.on('modal.add', (modal: any) => {
      if (!modal.id) {
        modal.id = Date.now();
      }

      modal_list.push(modal);
    });
    $bus?.on('mqtt_state', (mqttstate: any) => {
      mqtt_state.value = mqttstate;
    });
    query_person_to_company_list({
      org_id: current_org.value?.id,
      user_id: info.value.id,
    }).then((res: any) => {
      company_list.value.length = 0;
      let ids: any = [];
      let num = 0;
      if (res && res.data && res.data.length > 0) {
        if (res.data[0]?.cc_companys && res.data[0]?.cc_companys.length > 0) {
          res.data[0]?.cc_companys.forEach((item: any) => {
            company_list.value.push({
              name: item.name,
              id: item.id,
            });
            ids += item.id + ',';
            num += 1;
          });
        }
      }
      if (num > 0) {
        company_list.value.reverse();
        company_list.value.push({
          name: '全部公司',
          id: ids,
        });
        company_list.value.reverse();
        store.dispatch(`user/${GET_COMPANY}`, { id: ids, name: '全部公司' });
        select_company.value = ids;
      } else {
        store.dispatch(`user/${GET_COMPANY}`, { id: -1, name: '全部公司' });
        select_company.value = null;
      }
    });
    const modal_update = (data: any, modal: any) => {
      if (modal.update) {
        const result = modal.update(data, modal);
        if (result instanceof Promise) {
          result.then(function () {
            modal_list.splice(modal_list.indexOf(modal, 0), 1);
          });
        } else if (result === true || result === null || result === undefined) {
          modal_list.splice(modal_list.indexOf(modal, 0), 1);
        }
      } else {
        modal_list.splice(modal_list.indexOf(modal, 0), 1);
      }
    };
    const modal_ok = (data: any, modal: any) => {
      if (modal.ok) {
        const result = modal.ok(data, modal);
        if (result instanceof Promise) {
          result.then(function () {
            modal_list.splice(modal_list.indexOf(modal, 0), 1);
          });
        } else if (result === true || result === null || result === undefined) {
          modal_list.splice(modal_list.indexOf(modal, 0), 1);
        }
      } else {
        modal_list.splice(modal_list.indexOf(modal, 0), 1);
      }
    };

    const modal_cancel = (data: any, modal: any) => {
      if (modal.cancel) {
        modal.cancel(data, modal);
      }
      modal_list.splice(modal_list.indexOf(modal, 0), 1);
    };

    const openSearch = (searchValue: string) => {
      if (searchValue && select_jump.value == '1') {
        router.push({ path: '/adviser-manage/cerp-adviser', query: { q: searchValue } });
      } else if (searchValue && select_jump.value == '2') {
        router.push({ path: '/adviser-manage/cerp-contract', query: { q: searchValue } });
      }
      search_value.value = '';
    };
    const to_kefu = () => {
      router.push({
        path: '/kefu/kefu-session',
      });
    };

    // gen menus
    const allowRouters = computed(() => store.getters[`user/allowRouters`]); // genMenuInfo(filterMenu(routes)).menus;
    const menus = computed(() => genMenuInfo(allowRouters.value).menus);
    // 返回旧版本
    const returnOldRouter = (selectedKeys: any) => {
      isGotoOldRouter(selectedKeys[selectedKeys.length - 1]);
    };
    const selectCompany = (value: any) => {
      const path_arr = route.path.split('/');
      let list = company_list.value.filter((item: any) => item.id == value);
      if (list && list?.length > 0) {
        store.dispatch(`user/${GET_COMPANY}`, { id: list[0].id, name: list[0].name });
        if (path_arr[path_arr.length - 1] == 'cerp-contract') {
          router.push({
            path: '/adviser-manage/cerp-contract',
            query: {
              cc_company_name: list[0].name == '全部公司' ? '' : list[0].name,
              cc_company_id: list[0].id,
            },
          });
        } else if (path_arr[path_arr.length - 1] == 'cerp-extra_field') {
          router.push({
            path: '/adviser-manage/cerp-extra_field',
            query: {
              cc_company_name: list[0].name == '全部公司' ? '' : list[0].name,
              cc_company_id: list[0].id,
            },
          });
        } else if (path_arr[path_arr.length - 1] == 'receipt-contract') {
          router.push({
            path: '/receipt/receipt-contract',
            query: {
              cc_company_name: list[0].name == '全部公司' ? '' : list[0].name,
              cc_company_id: list[0].id,
            },
          });
        } else if (path_arr[path_arr.length - 1] == 'receipt-receipt') {
          router.push({
            path: '/receipt/receipt-receipt',
            query: {
              cc_company_name: list[0].name == '全部公司' ? '' : list[0].name,
              cc_company_id: list[0].id,
            },
          });
        } else if (path_arr[path_arr.length - 1] == 'receipt-payment') {
          router.push({
            path: '/receipt/receipt-payment',
            query: {
              cc_company_name: list[0].name == '全部公司' ? '' : list[0].name,
              cc_company_id: list[0].id,
            },
          });
        } else if (path_arr[path_arr.length - 1] == 'baseinfo-time_calendar') {
          router.push({
            path: '/static-info-manage/baseinfo-time_calendar',
            query: {
              cc_company_name: list[0].name == '全部公司' ? '' : list[0].name,
              cc_company_id: list[0].id,
            },
          });
        } else if (path_arr[path_arr.length - 1] == 'baseinfo-cccompany_contract') {
          router.push({
            path: '/static-info-manage/baseinfo-cccompany_contract',
            query: {
              cc_company_name: list[0].name == '全部公司' ? '' : list[0].name,
              cc_company_id: list[0].id,
            },
          });
        }
        bus.emit('selectCompany');
      }
    };
    const logout = debounce(() => {
      notification.error({
        message: t('登录过期'),
        description: t('请重新登录'),
      });
      store.dispatch(`user/${LOGOUT}`).then(() => {
        location.href = '/';
      });
    }, 4000);
    bus.on('LOGOUT', () => {
      logout();
    });
    return {
      t,
      currentUser,
      org_list,
      select_jump,
      company_list,
      select_company,
      search_value,
      current_org,
      menus,
      ...menuState,
      hasSideMenu,
      hasTopMenu,
      mqtt_state,
      isMobile,
      no_read_number,
      no_receive_num,
      to_kefu,
      returnOldRouter,
      isGotoOldRouter,
      openSearch,
      modal_list,
      modal_ok,
      modal_update,
      modal_cancel,
      btn_obj,
      selectCompany,
    };
  },
  components: {
    FormModal,
    MultiTab,
    BellOutlined,
    MessageOutlined,
    WrapContent,
    SiderMenu,
    GlobalFooter,
    HeaderView,
    SelectLang,
    OrgNameNow,
    AvatarDropdown,
    SettingDrawer,
    mqttState,
  },
});
</script>

<style lang="less">
@import '../components/base-layouts/basic-layout.less';
</style>
