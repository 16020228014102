<template>
<template v-if="text && text.length > 0"
          v-for="(obj, obj_index) in text"
          :key="obj.id==undefined?obj_index:obj.id"
>
  <a-tag
    :color="column.color ? column.color(record) : 'green'"
    size="small"
    style="margin-bottom: 1px"
    @click="column.cb ? column.cb(record, obj) : ''"
  >
    {{ obj.person_name ? obj.dept_name + '-' + obj.person_name : obj.dept_name }}
  </a-tag>
</template>
<template v-if="text && text.length == 0">
  {{}}
</template>
</template>
<script lang="ts">
import {defineComponent} from 'vue';
export default defineComponent({
  name: 'Cell_list1',
  props: {
    column: {
      type: Object,
      required: true,
      default: () => {},
    },
    index: {
      type: Number,
      required: true,
    },
    text: {
      type: [String , Number , Array],
      required: true,
    },
    record: {
      type: Object,
      required: true,
      default: () => {},
    },
    pageSize: {
      type: Number,
      required: true,
      default: () => 20,
    },
    current: {
      type: Number,
      required: true,
      default: () => 1,
    },

    titleList: {
      type: Object,
      required: true,
      default: () => {},
    },
    column_dropdown_btn_dict: {
      type: Object,
      required: false,
      default: () => {},
    },
    column_normal_btn_list: {
      type: Array,
      required: true,
      default: () => [],
    },
    handleOpenEdit: {
      type: Function,
      required: true,
    },
    handleDelete: {
      type: Function,
      required: true,
    },
    handleRecover: {
      type: Function,
      required: true,
    },
    requestParams: {
      type: Object,
      required: false,
    },
  },
});
</script>
