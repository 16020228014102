<template>
  <a-modal
    :title="title"
    width="720px"
    :visible="true"
    :maskClosable="false"
    :confirmLoading="is_loading || loading"
    :cancelText="t('取消')"
    :okText="t('确定上传')"
    centered
    @ok="handleSubmit"
    @cancel="
      () => {
        $emit('cancel');
      }
    "
  >
    <a-upload
      :customRequest="customRequest"
      :multiple="multiple"
      v-model:file-list="fileList"
      :list-type="type"
      :remove="handleRemove"
      :previewFile="addcard"
      @preview="handlePreview"
    >
      <template v-if="type === 'picture-card'">
        <plus-outlined />
        <div class="ant-upload-text">{{ t('上传') }}</div>
      </template>
      <a-button v-if="type === 'picture'">
        <upload-outlined></upload-outlined>
        {{ t('上传') }}
      </a-button>
    </a-upload>
  </a-modal>
  <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
    <img alt="example" style="width: 100%" :src="previewImage" />
  </a-modal>
</template>

<script lang="ts">
import { PlusOutlined, UploadOutlined } from '@ant-design/icons-vue';
import { defineComponent, ref } from 'vue';
import { get_upload_files_url, upload_file } from '@/api/nsbcs-modal';
import { message, notification } from 'ant-design-vue';
import icon_fun from './type';
import { useI18n } from 'vue-i18n';

const permissionActions = ['add', 'delete', 'update', 'query', 'import', 'export'];
function getBase64(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

interface FileItem {
  uid: string;
  fid?: number;
  name?: string;
  status?: string;
  response?: Response;
  percent?: number;
  url: string;
  preview?: string;
  originFileObj?: any;
}

export default defineComponent({
  name: 'UploadImageModal',
  components: { UploadOutlined, PlusOutlined },
  props: {
    title: {
      type: String,
      required: true,
    },
    group: {
      type: String,
      required: true,
      default: () => 'sys',
    },
    access_type: {
      type: String,
      required: true,
      default: () => 'public',
    },
    loading: {
      type: Boolean,
    },
    type: {
      type: String,
      required: true,
      default: () => 'picture',
    },
    filetype: {
      type: Array,
      required: false,
      default: () => [],
    },
    multiple: {
      type: Boolean,
      default: () => false,
    },
  },
  emits: ['ok', 'cancel', 'update'],
  setup(props, { emit }) {
    const { t } = useI18n();
    const previewVisible = ref<boolean>(false);
    const previewImage = ref<string | undefined>('');
    const is_loading = ref<boolean>(false);
    const handleCancel = () => {
      previewVisible.value = false;
    };
    const handlePreview = async (file: FileItem) => {
      if (!file.url && !file.preview) {
        file.preview = (await getBase64(file.originFileObj)) as string;
      }
      previewImage.value = file.url || file.preview;
      previewVisible.value = true;
    };
    const fileList = ref<FileItem[]>([]);
    const extname = (filename: any) => {
      if (!filename || typeof filename != 'string') {
        return false;
      }
      var a = filename.split('').reverse().join('');
      var b = a.substring(0, a.search(/\./)).split('').reverse().join('');
      return b;
    };
    const file_ids: number[] = [];
    const file_urls: any = [];
    let num1 = 0;
    let num2 = 0;
    const customRequest = (fileobj: any) => {
      num1 = num1 + 1;
      let type = extname(fileobj.file.name);
      let is_return: any = true;
      if(props.filetype && props.filetype.length > 0){
        let list: any = '';
        props.filetype.map((item: any) => {
          list = list + ' ' + item;
          if(item == type){
            is_return = false;
          }
        })
        if(is_return){
          notification.error({
            message: t('温馨提示'),
            description:
              '上传文件类型错误，应为' + list + ' 请删除后重新上传',
          });
          return;
        }
      }
      is_loading.value = true;

      console.log('fileList', fileList);
      get_upload_files_url({
        access_type: props.access_type,
        filetype: type,
        filename: fileobj.file.name,
        group: props.group,
        author: 0,
      }).then((res: any) => {
        file_urls.push(res.params.url);
        let formData = new FormData();
        formData.append('file', fileobj.file);
        formData.append('file_id', res.params.file_id);
        formData.append('key', res.params.key);
        upload_file(formData, {
          onUploadProgress: (progressEvent: any) => {
            let complete = ((progressEvent.loaded / progressEvent.total) * 100) | 0;
            fileobj.onProgress({ percent: complete });
          },
        }).then((res1: any) => {
          num2 = num2 + 1;
          console.log('res1', res1);
          file_ids.push(res.params.file_id);
          fileobj.file.fid = res.params.file_id;
          fileobj.file.url = res.params.url;
          fileobj.onSuccess();
          if (num2 === num1) {
            is_loading.value = false;
          } else {
            is_loading.value = true;
          }
          message.success('创建成功，可以【确认上传】');
        });
      });
    };

    const handleSubmit = (e: Event) => {
      e.preventDefault();
      if (file_ids.length == 0) {
        message.success('请选择需要上传的附件');
        return;
      }
      emit('ok', file_ids);
      emit('update', file_urls);
    };

    const handleRemove = (file: any) => {
      file_ids.splice(file_ids.indexOf(file.originFileObj.fid), 1);
      num1 = 0;
      num2 = 0;
    };

    const addcard = (file: any) => {
      console.log('file11', file);
      const type = extname(file.name);
      if (/(png|jpg|gif|jpeg|webp)$/.test(type as string)) {
        return getBase64(file);
      }
      return new Promise(resolve => {
        resolve(icon_fun(extname(file.name) as string));
      });
    };
    return {
      props,
      t,
      handleRemove,
      addcard,
      handleSubmit,
      customRequest,
      is_loading,
      previewVisible,
      previewImage,
      handleCancel,
      handlePreview,
      fileList,
      permissionActions,
    };
  },
});
</script>
