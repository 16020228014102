<template>
  <a-form-item
    v-if="field.type !== 'divider'"
    :label="field.label_i18n == undefined ? field.label : t(field.label_i18n)"
    :name="field.name"
    :extra="field.extra"
    v-bind="validateInfos ? validateInfos[field.name] : null"
  >
    <div style="height: 62px; width: 100%" v-if="field.type === 'kong'"></div>
    <!--    表单占位组件-->
    <!--    默认 【表单空位 高54px; 名称空位 高30px;】 如遇个别特殊表单，根据实际情况自行调节 高度-->
    <!--    height 属性必填-->
    <div :style="'height: ' + field.height + 'px'" v-if="field.type === 'kong_title'"></div>
    <div v-if="field.type === 'title'"
         :style="'height: ' +( (field?.height && field?.height != 30) ? field?.height : 30) + 'px; position: relative'">
      <span
        style="
          position: absolute;
          bottom: 0;
          padding: 0;
          margin: 0;
          font-weight: bolder;
          font-size: 17px;
        "
      >
        {{ field.name }}
      </span>
    </div>
    <a-input
      v-if="field.type === 'input'"
      size="small"
      v-model:value="model"
      :placeholder="
        field.placeholder_i18n == undefined ? field.placeholder : t(field.placeholder_i18n)
      "
      :autocomplete="field.autocomplete == undefined ? 'off' : field.autocomplete"
      :disabled="field.disabled"
      :default-value="field.defaultValue"
      :allow-clear="field.allowClear"
      :type="field.type ? field.type : 'input'"
      :maxlength="field.maxlength"
    />

    <a-textarea
      v-if="field.type === 'textarea'"
      v-model:value="model"
      :autocomplete="field.autocomplete == undefined ? 'off' : field.autocomplete"
      :placeholder="
        field.placeholder_i18n == undefined ? field.placeholder : t(field.placeholder_i18n)
      "
      :disabled="field.disabled"
      :allow-clear="field.allowClear"
      :autoSize="{
        minRows: field.minRows ? field.minRows : 3,
        maxRows: field.maxRows ? field.maxRows : 3,
      }"
    />

    <a-input-number
      v-if="field.type === 'number'"
      size="small"
      v-model:value="model"
      :autocomplete="field.autocomplete == undefined ? 'off' : field.autocomplete"
      :disabled="field.disabled"
      :default-value="field.defaultValue"
      :min="field.min"
      :max="field.max"
      :formatter="field.formatter"
      :precision="field.precision"
      :step="field.step"
      :placeholder="
        field.placeholder_i18n == undefined ? field.placeholder : t(field.placeholder_i18n)
      "
      style="width: 100%"
    />
    <a-input-number
      v-if="field.type === 'money'"
      size="small"
      v-model:value="model"
      :autocomplete="field.autocomplete == undefined ? 'off' : field.autocomplete"
      :disabled="field.disabled"
      :default-value="field.defaultValue"
      :min="field.min"
      :max="field.max"
      :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
      :precision="field.precision"
      :step="field.step"
      :placeholder="
        field.placeholder_i18n == undefined ? field.placeholder : t(field.placeholder_i18n)
      "
      style="width: 100%"
    />
    <a-input-password
      v-if="field.type === 'password'"
      size="small"
      v-model:value="model"
      :autocomplete="field.autocomplete == undefined ? 'off' : field.autocomplete"
      :disabled="field.disabled"
      :default-value="field.defaultValue"
      :min="field.min"
      :max="field.max"
      :formatter="field.formatter"
      :precision="field.precision"
      :step="field.step"
      :placeholder="
        field.placeholder_i18n == undefined ? field.placeholder : t(field.placeholder_i18n)
      "
      style="width: 100%"
    />
    <template v-if="field.type === 'select'">
      <a-select
        size="small"
        v-if="field.mode === 'default'"
        v-model:value="model"
        style="min-width: 100px"
        :autocomplete="field.autocomplete == undefined ? 'off' : field.autocomplete"
        :show-search="!!field.show_search"
        :disabled="field.disabled"
        :placeholder="
          field.placeholder_i18n == undefined ? field.placeholder : t(field.placeholder_i18n)
        "
        :default-value="field.defaultValue"
        :allow-clear="field.allowClear"
        :maxTagCount="field.maxTagCount"
      >
        <a-select-option
          v-for="option in options"
          :key="option[field.valueKey ? field.valueKey : 'value']"
          :value="option[field.valueKey ? field.valueKey : 'value']"
        >
          {{ option[field.labelKey ? field.labelKey : 'label'] }}
        </a-select-option>
      </a-select>
      <a-select
        v-else
        size="small"
        v-model:value="model"
        style="min-width: 100px"
        :show-search="!!field.show_search"
        :disabled="field.disabled"
        :placeholder="
          field.placeholder_i18n == undefined ? field.placeholder : t(field.placeholder_i18n)
        "
        :default-value="field.defaultValue"
        :allow-clear="field.allowClear"
        :mode="field.mode"
        :maxTagCount="field.maxTagCount"
      >
        <a-select-option
          v-for="(option, index) in options"
          :key="index"
          :value="option[field.valueKey ? field.valueKey : 'value']"
        >
          {{ option[field.labelKey ? field.labelKey : 'label'] }}
        </a-select-option>
      </a-select>
    </template>

    <AttributeTable
      v-if="field.type === 'attributetable'"
      v-model:value="model"
      :field="field"
      :formModel="formModel"
      :init_model="field.init_model"
      size="small"
    />
    <a-cascader
      v-if="field.type === 'cascader'"
      v-model:value="model"
      size="small"
      :disabled="field.disabled"
      :options="options"
      :placeholder="
        field.placeholder_i18n == undefined ? field.placeholder : t(field.placeholder_i18n)
      "
      :default-value="field.defaultValue"
      :allow-clear="field.allowClear"
    />

    <a-month-picker
      v-if="field.type === 'monthpicker'"
      size="small"
      v-model:value="model"
      :autocomplete="field.autocomplete == undefined ? 'off' : field.autocomplete"
      :disabled="field.disabled"
      :allow-clear="field.allowClear"
      :default-value="field.defaultValue"
      :format="field.format"
      :value-format="field.valueFormat"
      :disabled-date="field.disabledTime"
      :show-time="field.showTime"
      :placeholder="
        field.placeholder_i18n == undefined ? field.placeholder : t(field.placeholder_i18n)
      "
      style="width: 100%"
    />
    <a-date-picker
      v-if="field.type === 'datepicker'"
      size="small"
      v-model:value="model"
      :autocomplete="field.autocomplete == undefined ? 'off' : field.autocomplete"
      :disabled="field.disabled"
      :allow-clear="field.allowClear"
      :default-value="field.defaultValue"
      :format="field.format"
      :value-format="field.valueFormat"
      :show-time="field.showTime"
      :disabled-date="field.disabledTime"
      :placeholder="
        field.placeholder_i18n == undefined ? field.placeholder : t(field.placeholder_i18n)
      "
      style="width: 100%"
    />

    <a-range-picker
      size="small"
      v-if="field.type === 'rangepicker'"
      v-model:value="model"
      :default-value="field.defaultValue"
      :disabled="field.disabled"
      :allow-clear="field.allowClear"
      :format="field.format"
      :value-format="field.valueFormat"
      :show-time="field.showTime"
      :disabled-date="field.disabledTime"
      :placeholder="
        field.placeholder_i18n == undefined ? field.placeholder : t(field.placeholder_i18n)
      "
      style="width: 100%"
    />

    <a-time-picker
      size="small"
      v-if="field.type === 'timepicker'"
      v-model:value="model"
      :default-value="field.defaultValue"
      :disabled="field.disabled"
      :allow-clear="field.allowClear"
      :format="field.format"
      :value-format="field.valueFormat"
      :placeholder="
        field.placeholder_i18n == undefined ? field.placeholder : t(field.placeholder_i18n)
      "
      style="width: 100%"
    />

    <a-radio-group
      size="small"
      v-if="field.type === 'radio'"
      v-model:value="model"
      :disabled="field.disabled"
      :default-value="field.defaultValue"
    >
      <a-radio
        v-for="option in options"
        :key="option[field.valueKey ? field.valueKey : 'value']"
        :value="option[field.valueKey ? field.valueKey : 'value']"
        :disabled="option.disabled"
      >
        {{ option[field.labelKey ? field.labelKey : 'label'] }}
      </a-radio>
    </a-radio-group>

    <a-checkbox-group
      size="small"
      v-if="field.type === 'checkbox'"
      v-model:value="model"
      :disabled="field.disabled"
      :default-value="field.defaultValue"
    >
      <a-checkbox
        v-for="option in options"
        :key="option[field.valueKey ? field.valueKey : 'value']"
        :value="option[field.valueKey ? field.valueKey : 'value']"
        :disabled="option.disabled"
      >
        {{ option[field.labelKey ? field.labelKey : 'label'] }}
      </a-checkbox>
    </a-checkbox-group>

    <a-tree-select
      size="small"
      v-if="field.type === 'treeselect'"
      v-model:value="model"
      :placeholder="
        field.placeholder_i18n == undefined ? field.placeholder : t(field.placeholder_i18n)
      "
      :disabled="field.disabled"
      :default-value="field.defaultValue"
      :allow-clear="field.allowClear"
      :tree-data="options"
      :tree-checkable="field.multiple"
      :tree-node-filter-prop="field.valueKey"
      :tree-label-prop="field.labelKey"
    />
    <remote-select
      v-if="field.type === 'remoteselect'"
      v-model:value="model"
      :field="field"
      :formModel="formModel"
      :get-data="field.getData"
      :label-key="field.labelKey"
      :value-key="field.valueKey"
      :maxTagCount="field.maxTagCount"
      :maxTagTextLength="field.maxTagTextLength"
      :label-key-list="field.labelKeyList"
      :label-space="field.labelSpace"
      :init_model="field.init_model"
      :search_config="field.search_config"
    />
    <remote-tree-select
      v-if="field.type === 'remotetreeselect'"
      v-model:value="model"
      :field="field"
      :get-data="field.getData"
      :label-key="field.labelKey"
      :value-key="field.valueKey"
      :search_config="field.search_config"
      :disabled="field.disabled"
      :default-value="field.defaultValue"
      :allow-clear="field.allowClear"
    />
    <remote-modal
      v-if="field.type === 'remotemodal'"
      v-model:value="model"
      :field="field"
      :columns="field.columns"
      :init_model="field.init_model"
      :field_status="field.field_status"
      :watch="field.watch"
      :get-data="field.getData"
      :modalType="field.modalType"
      :search_config="field.search_config"
    />
  </a-form-item>

  <a-divider v-if="field.type === 'divider'" :orientation="field.orientation">
    {{ field.label }}
  </a-divider>
</template>
<script lang="ts">
import RemoteSelect from '@/components/FormRender/RemoteSelect/RemoteSelect.vue';
import RemoteModal from '@/components/FormRender/RemoteModal/RemoteModal.vue';
import RemoteTreeSelect from '@/components/FormRender/RemoteTreeSelect/RemoteTreeSelect.vue';
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'FieldRender',
  props: {
    field: {
      type: Object,
      required: true,
    },
    value: {
      required: true,
    },
    options: {
      type: Array,
      default: () => [],
    },
    validateInfos: {
      type: Object,
      default: () => {},
    },
    formModel: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    const { t } = useI18n();
    return {
      t,
    };
  },
  data() {
    return {
      model: this.value,
    };
  },
  watch: {
    model: {
      handler(newValue) {
        if ((this.field.type === 'number' || this.field.type === 'money') &&  (newValue == ''||newValue == null)) {
          this.model = 0;
          this.$emit('update:value', 0);
        } else if(this.field.type === 'input' && newValue){
          this.$emit('update:value', newValue.replace(/(^\s*)|(\s*$)/g, ""));
        } else{
          this.$emit('update:value', newValue);
        }
      },
    },
    value: {
      handler(newValue) {
        if ((this.field.type === 'number' || this.field.type === 'money') && (newValue == ''||newValue == null)) {
          this.model = 0;
        } else if(this.field.type === 'input' && newValue){
          this.model = (newValue.toString()).replace(/(^\s*)|(\s*$)/g, "");
        } else {
          this.model = newValue;
        }
      },
    },
  },
  created() {},
  components: {
    RemoteModal,
    RemoteSelect,
    RemoteTreeSelect,
  },
});
</script>

<style scoped>
.ant-calendar-picker {
  width: 100% !important;
}
</style>
