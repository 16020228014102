<template>
  <template v-for="file in text" :key="file.file_id">
    <a-tooltip>
      <template #title>
        <span>{{ file.name }}</span>
      </template>
    <a-button
      v-if="file.file_id"
      style="padding: 1px"
      type="link"
      @click="column.cb(file)"
      :title="file.name"
    >
        <span style="
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: inline-block;
        width: 150px;
        ">
        {{ file.name }}
        </span>
    </a-button>
    </a-tooltip>
    <delete-outlined @click="column.del(record, text, file)" style="color: #ff0000"/>
  </template>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {
  ColumnHeightOutlined,
  DeleteOutlined,
  EditOutlined, ForwardFilled,
  FullscreenExitOutlined,
  FullscreenOutlined,
  PlusOutlined,
  RedoOutlined,
  ReloadOutlined,
  SettingOutlined,
} from '@ant-design/icons-vue';
import FormModal from "@/components/form-modal/form-modal.vue";
import SearchRender from "@/components/FormRender/SearchRender.vue";

export default defineComponent({
  name: 'Cell_clicklist',
  props: {
    column: {
      type: Object,
      required: true,
      default: () => {
      },
    },
    index: {
      type: Number,
      required: true,
    },
    text: {
      type: [String, Number],
      required: true,
    },
    record: {
      type: Object,
      required: true,
      default: () => {
      },
    },
    pageSize: {
      type: Number,
      required: true,
      default: () => 20,
    },
    current: {
      type: Number,
      required: true,
      default: () => 1,
    },
  },
  components: {
    DeleteOutlined,
  },
});
</script>
