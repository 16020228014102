<template>
  <a-dropdown
    class="ant-pro-dropdown ant-pro-dropdown-action"
    placement="bottomRight"
    overlayClassName="pro-components-header-dropdown-index-container"
  >
    <span>
      <span class="ant-pro-header-account-name anticon" @click="select_mqtt_state()">
        <a-tag v-if="mqtt_state.connected" color="green">在线</a-tag>
        <a-tag v-if="!mqtt_state.connected" color="red">离线</a-tag>
      </span>
    </span>
  </a-dropdown>
  <form-modal
    v-bind="editModal"
    v-if="editModal.visible"
    @cancel="
      () => {
        editModal.visible = false;
      }
    "
    @ok="handlerOk"
  />
</template>

<script lang="ts">
import { defineComponent, reactive, getCurrentInstance, onUnmounted } from 'vue';
import mqtt from '@/utils/service/mqtt';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'MqttState',
  props: {},
  setup() {
    const store = useStore();
    const current_org = store.getters['user/current_org'];
    const internalInstance = getCurrentInstance();
    let $bus: {
      emit: (arg0: string, arg1: any) => void;
      off: (arg0: string) => void;
      on: (arg0: string, arg1: any) => void;
    } | null = null;
    if (internalInstance != null) {
      $bus = internalInstance.appContext.config.globalProperties.$bus;
    }
    const mqtt_state = reactive({
      connected: false,
    });
    const editModal = reactive({
      visible: false,
    });
    const select_mqtt_state = () => {
      editModal.visible = true;
    };
    const handlerOk = () => {
      editModal.visible = false;
    };
    // mqtt().init();
    mqtt().channel_subscribe_event(current_org.id, 'foo');
    $bus?.on('mqtt_state', (data: any) => {
      if (data == 1) {
        mqtt_state.connected = true;
      } else {
        mqtt_state.connected = false;
      }
    });
    $bus?.on('foo', (data: any) => {
      if (data == 1) {
        mqtt_state.connected = true;
      } else {
        mqtt_state.connected = false;
      }
    });
    onUnmounted(() => {
      mqtt().channel_unsubscribe_event(current_org.id, 'foo');
      $bus?.off(current_org.id + 'foo');
    });
    return {
      select_mqtt_state,
      mqtt_state,
      editModal,
      handlerOk,
    };
  },
  components: {},
});
</script>

<style lang="less">
@import './header-dropdown.less';

.ant-pro-header-account-name {
  vertical-align: unset;
}

.ant-pro-header-account-avatar {
  margin: 12px 8px 12px 0;
  color: @primary-color;
  vertical-align: top;
  background: hsla(0, 0%, 100%, 0.85);
}

.prefixIcon {
  color: @primary-color;
  font-size: @font-size-base;
}
</style>
