import mitt from 'mitt';
import { btnTd } from '@/api/typing';
import defaultSlotList from '@/components/page-model/slotList';
import modalBox from '@/components/form-modal/modal-tools';
import { reactive } from 'vue';
import { create_c_c_bank_number_curreny } from '@/api/baseinfo-model';
import { message } from 'ant-design-vue';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';

export const event_obj = mitt();
export const btn_list = [
  {
    name: '币种关联',
    clazz: 'primary',
    type: 'dropdown',
    cb: (record: any, dataSource: any, index: any, requestParams: any, t: any) => {
      const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
      const editModal = reactive({
        visible: true,
        title: t('币种关联'),
        loading: false,
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'vertical',
            col: [],
          },
          fields: [
            {
              type: 'input',
              name: 'account_number',
              label: '账号',
              label_i18n: t('账号'),
              disabled: false,
              placeholder: '请填输入账号',
              placeholder_i18n: t('请填输入账号'),
              datasourceType: 'remote',
              allowClear: false,
            },
            {
              type: 'remoteselect',
              name: 'currency_ids',
              labelKeyList: ['code', 'currency'],
              label: '币种',
              label_i18n: t('币种'),
              disabled: false,
              placeholder: '请选择币种',
              placeholder_i18n: t('请选择币种'),
              datasourceType: 'remote',
              mode: 'multiple',
              allowClear: true,
              modalType: 'baseinfo-country',
            },
          ],
          rules: {
            account_number: [{ required: true, message: t('请填输入账号') }],
            currency_ids: [{ required: true, message: t('请选择币种') }],
          },
          model: reactive({
            org_id: current_org?.id,
            account_number: null,
            cc_bank_account_id: record.id,
          }),
          watch: {},
        },
        options: {},
        ok: (payload: any) => {
          return new Promise(resolve => {
            editModal.loading = true;
            create_c_c_bank_number_curreny(payload)
              .then(() => {
                message.success(t('操作成功'));
                event_obj.emit('refresh', 'scgyfanganlist');
                resolve(null);
              })
              .finally(() => {
                editModal.loading = false;
              });
          });
        },
      });
      modalBox(editModal);
    },
  },
  {
    name: '备注',
    type: 'dropdown',
    clazz: 'primary',
    cb: (record: any) => {
      const modifyModal: any = reactive({
        visible: false,
        component: 'NoteModel',
        type: 'update',
        params: {
          object_id: record.id,
          note_type_flag: 'baseinfo.ccbankaccountscc',
        },
      });
      modifyModal.visible = true;
      modifyModal.ok = () => {
        event_obj.emit('refresh');
      };
      modifyModal.cancel = () => {};
      modalBox(modifyModal);
    },
  },
];
export const slotList = defaultSlotList;
export const config = {
  creatCol: [],
  updateCol: [],
};

export const head_btn_list: btnTd[] = [];
