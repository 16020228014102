
import { defineComponent, ref } from 'vue';
import { message } from 'ant-design-vue';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import { CloseCircleFilled, PlusOutlined } from '@ant-design/icons-vue';
import numerals from '@/utils/numeral';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'PreviewModal',
  props: {
    visible: {
      type: Boolean,
      required: true,
      default: () => false,
    },
    obj: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  emits: ['ok', 'cancel'],
  setup(props, { emit }) {
    const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
    const { t } = useI18n();
    const is_show = ref(true);
    const close = () => {
      is_show.value = false;
    };
    const getDate = (time: any) => {
      if (time) {
        time = time.substring(0, 10);
      }
      return time;
    };

    const money = (money: any) => {
      return numerals.money(money);
    };
    return {
      money,
      getDate,
      close,
      t,
      is_show,
      message,
    };
  },
  components: {
    PlusOutlined,
    CloseCircleFilled,
  },
});
