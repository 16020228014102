<template>
  <a-tag :color="column.color ? column.color(record) : '#ceba00'" @click="column.cb ? column.cb(record) : ''">
                    <span>
                      今日
                    </span>
  </a-tag>
  <a-tag :color="column.color1 ? column.color1(record) : '#ceba00'" @click="column.cb1 ? column.cb1(record) : ''">
                    <span>
                      昨日
                    </span>
  </a-tag>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
export default defineComponent({
  name: 'Cell_report_tag',
  props: {
    column: {
      type: Object,
      required: true,
      default: () => {},
    },
    index: {
      type: Number,
      required: true,
    },
    text: {
      type: [String, Number],
      required: false,
      default: () => '',
    },
    record: {
      type: Object,
      required: true,
      default: () => {},
    },
    pageSize: {
      type: Number,
      required: true,
      default: () => 20,
    },
    current: {
      type: Number,
      required: true,
      default: () => 1,
    },

    titleList: {
      type: Object,
      required: true,
    },
    column_normal_btn_list: {
      type: Array,
      required: false,
      default: () => [],
    },
    column_dropdown_btn_dict: {
      type: Object,
      required: false,
    },
    handleOpenEdit: {
      type: Function,
      required: true,
    },
    handleDelete: {
      type: Function,
      required: true,
    },
    handleRecover: {
      type: Function,
      required: true,
    },
    requestParams: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
});
</script>
<style lang="less">
.needCopyRow {
  td {
    position: relative;
  }
}
.large-font {
  font-size: 17px;
  font-weight: 500;
}
.large-number-font {
  font-size: 24px;
  font-weight: 500;
}
</style>