import request from '@/utils/request';

export async function queryApiurl(params?: { [key: string]: any }): Promise<RoleResponse> {
  return request.post('/permission/query_apiurl_list', params);
}

export async function removeUrl(params: Record<string, any>) {
  return request.post('/permission/delete_apiurl', params);
}

export async function recoverUrl(params: Record<string, any>) {
  return request.post('/permission/recover_apiurl', params);
}

export async function addURL(params: Record<string, any>) {
  return request.post('/permission/create_apiurl', params);
}

export async function updateUrl(params: Record<string, any>) {
  return request.post('/permission/update_apiurl', params);
}

export async function import_apiurl(params: Record<string, any>) {
  return request.post('/permission/import_apiurl', params);
}

export async function queryUrlNotBind(params?: { [key: string]: any }): Promise<RoleResponse> {
  return request.post('/permission/query_url_not_bind_menu_list', params);
}

export async function change_apiurl_auth(params?: { [key: string]: any }): Promise<RoleResponse> {
  return request.post('/permission/change_apiurl_auth', params);
}

interface RuleItem {
  key: string | number;
  id: number;
  callNo: number;
  avatar: string;
  desc: string;
  disabled: false;
  href: string;
  name: string;
  owner: string;
  progress: number;
  status: number;
  updatedAt: string;
  createdAt: string;
}

interface RoleResponse {
  current: number;
  data: RuleItem[];
  pageSize: string;
  success: boolean;
  total: number;
}
