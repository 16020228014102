<template>
  <div v-show="!formShow.visible" >
    <a-button type="link" @click="()=>{
      formShow.visible = true;
      $emit('resize');
    }">{{ t('展开') }}
    </a-button>
    <a-tag v-for="field in form.fields" v-show="model[field.name]!=null&&model[field.name]!=''" color="green">
      {{ field.label_i18n == undefined ? field.label : t(field.label_i18n) }}:{{ showLabel(field, field.name) }}
    </a-tag>
  </div>
  <a-form
    v-show="formShow.visible"
    ref="form"
    autocomplete="off"
    :model="model"
    :rules="form.rules"
    :layout="form.settings.layout"
    :label-col="form.settings.labelCol"
    :wrapper-col="form.settings.wrapperCol"
    :labelAlign="form.settings.labelAlign"
    scrollToFirstError
  >
    <a-form-item v-if="showSearch">
      <a-button type="link" @click="()=>{
      formShow.visible = false;
      $emit('resize');
    }">{{ t('隐藏') }}
      </a-button>
    </a-form-item>
    <field-render
      v-for="field in form.fields"
      v-model:value="model[field.name]"
      v-model:label="labelModel[field.name]"
      :field="field"
      :formModel="model"
      :key="field.name"
      :validateInfos="validateInfos"
      :options="options[field.datasource ? field.datasource : field.name]"
    />
    <a-form-item v-if="showSearch">
      <a-button size="small" type="primary" @click="search">{{ t('查询') }}</a-button>
    </a-form-item>
    <a-form-item v-if="showReset">
      <a-button size="small" @click="reset">{{ t('重置') }}</a-button>
    </a-form-item>
    <a-form-item v-if="showReport">
      <a-button size="small" type="info" @click="editModal.visible = true">{{ t('订阅') }}</a-button>
    </a-form-item>
  </a-form>
  <a-modal
    v-if="editModal.visible"
    :visible="editModal.visible"
    @cancel="
      () => {
        editModal.visible = false;
      }
    "
  />
</template>
<script language="ts">
import { watch } from 'vue';
import { useI18n } from 'vue-i18n';

export default {
  name: 'SearchRender',
  components: {},
  emits: ['search', 'resize'],
  props: {
    model: {
      type: Object,
      required: true,
    },
    form: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
    validateInfos: {
      type: Object,
      default: () => {},
    },
    resetFields: {
      type: Function,
      default: () => {},
    },
    showReport: {
      type: Boolean,
      default: () => false,
    },
    showSearch: {
      type: Boolean,
      default: () => true,
    },
    showReset: {
      type: Boolean,
      default: () => true,
    },
  },
  data() {
    const { t } = useI18n();
    return {
      editModal: {
        visible: false,
      },
      formShow: {
        visible: false,
      },
      labelModel: {},
      t: t,
    };
  },
  methods: {
    search() {
      this.$refs.form.validate().then(
        () => {
          this.$emit('search');
          this.formShow.visible = false;
        },
        () => {
          // this.$emit('search');
        },
      );
    },
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      this.resetFields(this.form.reset_model);
    },
    disable(name) {
      this.form.fields[name].disabled = true;
    },
    disableAll() {
      this.form.fields.forEach(item => {
        item.disabled = true;
      });
    },
    showLabel(field, name){
      const options = this.options[field.datasource ? field.datasource : field.name];
      if(this.labelModel[name]!=undefined){
        return this.labelModel[name];
      }else if(options){
        if(typeof this.model[name] == 'object' && this.model[name] != undefined && this.model[name].length>=0){
          const selectItems = options.filter(item=>this.model[name].includes(item.value)).map(item=>item.name);
          if(selectItems.length>0){
            return selectItems;
          }

          return '';
        }else{
          const selectItem = options.find(item=>item.value==this.model[name]);
          if(selectItem){
            return selectItem.name;
          }
          return '';
        }
      }else{
        return this.model[name];
      }
    },
  },
  created() {
    if (this.form.watch) {
      for (let k in this.form.watch) {
        watch(
          () => this.model[k],
          () => {
            this.form.watch[k](this.model, this.form);
          },
        );
      }
    }
  },
};
</script>
