<template>
              <span v-for="obj in text" :key="obj.id">
                <a-tag color="#87d068" style="margin-bottom: 1px" v-if="obj.parent_name">
                  {{ obj.parent_name }}&nbsp;>&nbsp;{{ obj.name }}
                </a-tag>
                <a-tag color="#87d068" style="margin-bottom: 1px" v-if="!obj.parent_name">
                  {{ obj.name }}
                </a-tag>
              </span>
</template>
<script lang="ts">
import {defineComponent} from 'vue';
export default defineComponent({
  name: 'Cell_menulist',
  props: {
    column: {
      type: Object,
      required: true,
      default: () => {},
    },
    index: {
      type: Number,
      required: true,
    },
    text: {
      type: [String , Number, Array],
      required: false,
      default:()=>'',
    },
    record: {
      type: Object,
      required: true,
      default: () => {},
    },
    pageSize: {
      type: Number,
      required: true,
      default: () => 20,
    },
    current: {
      type: Number,
      required: true,
      default: () => 1,
    },
    dataSource: {
      type: Array,
      required: false,
      default: () => [],
    },
    titleList: {
      type: Object,
      required: true,
    },
    column_normal_btn_list: {
      type: Array,
      required: false,
      default: () => [],
    },
    column_dropdown_btn_dict: {
      type: Object,
      required: false,
    },
    handleOpenEdit: {
      type: Function,
      required: true,
    },
    handleDelete: {
      type: Function,
      required: true,
    },
    handleRecover: {
      type: Function,
      required: true,
    },
    requestParams: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
});
</script>
