
import { computed, createVNode, defineComponent, onMounted, reactive, ref } from 'vue';
import { ExclamationCircleOutlined, ReloadOutlined, StarFilled } from '@ant-design/icons-vue';
import { debounce } from 'lodash-es';
import PageContainer3 from '@/components/base-layouts/page-container3/index.vue';
import { STable } from '@surely-vue/table';
import SearchRender from '@/components/FormRender/SearchRender.vue';
import ExportExcelModal from '@/components/info-modal/export-excel-modal/index.vue';
import moment from 'moment/moment';
import { getFullDate } from '@/utils/function';
import { useStore } from 'vuex';
import {
  get_work_hour_template_calendar,
  update_work_hour_template_month_data,
  update_work_hour_template_month_holiday,
} from '@/api/baseinfo-model';
import modalBox from '@/components/form-modal/modal-tools';
import { message, Modal } from 'ant-design-vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'TimeCalendarModal',
  props: {
    record: {
      type: Object,
      default: () => {
      },
    },
  },
  components: {
    ExportExcelModal,
    SearchRender,
    STable,
    PageContainer3,
    ReloadOutlined,
    StarFilled,
  },
  setup(props) {
    const store = useStore();
    const current_org = store.getters['user/current_org'];
    const { t } = useI18n();
    const filename: any = ref('工时年历模板');
    const multiHeader: any = ref([[props.record.country__name, props.record.cc_company__name, props.record.work_year]]);
    const month = ref(null);
    const loading: any = ref(false);
    const tableHeight = reactive({ y: window.innerHeight - 130 });
    const onresize: any = debounce(() => {
      tableHeight.y = window.innerHeight - 130;
    }, 200);
    onMounted(() => {
      window.onresize = onresize;
      onresize();
    });
    const month_list = ref([
      { value: null, label: t('全部') },
      { value: props.record.work_year + '-' + 1, label: '1月' },
      { value: props.record.work_year + '-' + 2, label: '2月' },
      { value: props.record.work_year + '-' + 3, label: '3月' },
      { value: props.record.work_year + '-' + 4, label: '4月' },
      { value: props.record.work_year + '-' + 5, label: '5月' },
      { value: props.record.work_year + '-' + 6, label: '6月' },
      { value: props.record.work_year + '-' + 7, label: '7月' },
      { value: props.record.work_year + '-' + 8, label: '8月' },
      { value: props.record.work_year + '-' + 9, label: '9月' },
      { value: props.record.work_year + '-' + 10, label: '10月' },
      { value: props.record.work_year + '-' + 11, label: '11月' },
      { value: props.record.work_year + '-' + 12, label: '12月' },
    ]);
    const dataSource: any = ref([]);
    const refresh = () => {
      loading.value = true;
      get_work_hour_template_calendar({
        org_id: current_org?.id,
        work_hour_template_id: props.record.id,
      }).then((res: any) => {
        dataSource.value.length = 0;
        if (res && res.data && res.data.length > 0) {
          res.data.forEach((item: any) => {
            if (item && item.length > 0) {
              item.forEach((item1: any) => {
                item1.index = item1.month + item1.name;
              });
              Object.keys(item[1]).map((key: string) => {
                if (item[1][key] && key != 'name' && key != 'index' && key != 'month') {
                  item[2][key] = { value: item[2][key], name: item[1][key] };
                }
              });
              if (month.value) {
                if (month.value == item[0].month) {
                  dataSource.value.push({ ...item[0], style: 'week' }, item[2], item[3], item[4]);
                }
              } else {
                dataSource.value.push({ ...item[0], style: 'week' }, item[2], item[3], item[4]);
              }
            }
          });
        }
        dataSource.value = [].concat(dataSource.value);
        loading.value = false;
      });
    };
    refresh();
    const search = () => {
      refresh();
    };
    const baseColumns = ref([
      {
        title: 'Name',
        dataIndex: 'name',
        minWidth: 100,
        autoHeight: true,
        fixed: 'left',
      },
      {
        title: '1',
        dataIndex: '1',
        width: 50,
        autoHeight: true,
        fixed: 'left',
      },
      {
        title: '2',
        dataIndex: '2',
        width: 50,
        autoHeight: true,
        fixed: 'left',
      },
      {
        title: '3',
        dataIndex: '3',
        width: 50,
        autoHeight: true,
        fixed: 'left',
      },
      {
        title: '4',
        dataIndex: '4',
        width: 50,
        autoHeight: true,
        fixed: 'left',
      },
      {
        title: '5',
        dataIndex: '5',
        width: 50,
        autoHeight: true,
        fixed: 'left',
      },
      {
        title: '6',
        dataIndex: '6',
        width: 50,
        autoHeight: true,
        fixed: 'left',
      },
      {
        title: '7',
        dataIndex: '7',
        width: 50,
        autoHeight: true,
        fixed: 'left',
      },
      {
        title: '8',
        dataIndex: '8',
        width: 50,
        autoHeight: true,
        fixed: 'left',
      },
      {
        title: '9',
        dataIndex: '9',
        width: 50,
        autoHeight: true,
        fixed: 'left',
      },
      {
        title: '10',
        dataIndex: '10',
        width: 50,
        autoHeight: true,
        fixed: 'left',
      },
      {
        title: '11',
        dataIndex: '11',
        width: 50,
        autoHeight: true,
        fixed: 'left',
      },
      {
        title: '12',
        dataIndex: '12',
        width: 50,
        autoHeight: true,
        fixed: 'left',
      },
      {
        title: '13',
        dataIndex: '13',
        width: 50,
        autoHeight: true,
        fixed: 'left',
      },
      {
        title: '14',
        dataIndex: '14',
        width: 50,
        autoHeight: true,
        fixed: 'left',
      },
      {
        title: '15',
        dataIndex: '15',
        width: 50,
        autoHeight: true,
        fixed: 'left',
      },
      {
        title: '16',
        dataIndex: '16',
        width: 50,
        autoHeight: true,
        fixed: 'left',
      },
      {
        title: '17',
        dataIndex: '17',
        width: 50,
        autoHeight: true,
        fixed: 'left',
      },
      {
        title: '18',
        dataIndex: '18',
        width: 50,
        autoHeight: true,
        fixed: 'left',
      },
      {
        title: '19',
        dataIndex: '19',
        width: 50,
        autoHeight: true,
        fixed: 'left',
      },
      {
        title: '20',
        dataIndex: '20',
        width: 50,
        autoHeight: true,
        fixed: 'left',
      },
      {
        title: '21',
        dataIndex: '21',
        width: 50,
        autoHeight: true,
        fixed: 'left',
      },
      {
        title: '22',
        dataIndex: '22',
        width: 50,
        autoHeight: true,
        fixed: 'left',
      },
      {
        title: '23',
        dataIndex: '23',
        width: 50,
        autoHeight: true,
        fixed: 'left',
      },
      {
        title: '24',
        dataIndex: '24',
        width: 50,
        autoHeight: true,
        fixed: 'left',
      },
      {
        title: '25',
        dataIndex: '25',
        width: 50,
        autoHeight: true,
        fixed: 'left',
      },
      {
        title: '26',
        dataIndex: '26',
        width: 50,
        autoHeight: true,
        fixed: 'left',
      },
      {
        title: '27',
        dataIndex: '27',
        width: 50,
        autoHeight: true,
        fixed: 'left',
      },
      {
        title: '28',
        dataIndex: '28',
        width: 50,
        autoHeight: true,
        fixed: 'left',
      },
      {
        title: '29',
        dataIndex: '29',
        width: 50,
        autoHeight: true,
        fixed: 'left',
      },
      {
        title: '30',
        dataIndex: '30',
        width: 50,
        autoHeight: true,
        fixed: 'left',
      },
      {
        title: '31',
        dataIndex: '31',
        width: 50,
        autoHeight: true,
        fixed: 'left',
      },
      {
        title: 'Total',
        dataIndex: 'total',
        width: 50,
        autoHeight: true,
        fixed: 'right',
      },
    ]);
    const dynamicColumnItems = computed(() => {
      let list: any = [];
      baseColumns.value.forEach((item: any) => {
        list.push(
          {
            checked: true,
            fixed: item.fixed ? item.fixed : false,
            key: item.dataIndex,
            label: item.title,
            width: item.width,
          },
        );
      });
      return list;
    });
    const customCell = (obj: any) => {
      if (obj.record?.style == 'week') {
        return { style: { background: '#40556C' } };
      }
      if (obj.record.name == 'GGK') {
        return { style: { background: '#FFB000' } };
      }
      if (obj.record.name == 'Total Hours') {
        return { style: { background: '#D9D9D9' } };
      }
    };
    const AddCalendar = () => {
      const editModal = reactive({
        visible: true,
        loading: false,
        title: t('设定法定节假日'),
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'vertical',
            col: [],
          },
          fields: [
            {
              type: 'rangepicker',
              name: 'start_end_date',
              label: '开始-结束时间',
              label_i18n: t('开始-结束时间'),
              disabled: false,
              allowClear: true,
              inputType: 'number',
              defaultValue: '',
              showTime: false,
              valueFormat: 'YYYY-MM-DD',
              placeholder: '',
              disabledTime: (date: any) => {
                return date.format('YYYY') != props.record.work_year;
              },
            },
            {
              type: 'input',
              name: 'holiday_name',
              label: '节日名字',
              label_i18n: t('节日名字'),
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写节日名字',
              placeholder_i18n: t('请填写节日名字'),
            },
          ],
          rules: reactive({
            start_end_date: [{ required: true, message: t('请填写开始-结束时间') }],
            holiday_name: [{ required: true, message: t('请填写节日名字') }],
          }),
          model: reactive({
            org_id: current_org?.id,
            holiday_name: null,
            start_end_date: null,
          }),
          watch: {},
        },
        options: {},
        ok: (data: any) => {
          return new Promise(function (resolve) {
            editModal.loading = true;
            update_work_hour_template_month_holiday({
              org_id: current_org?.id,
              work_hour_template_id: props.record.id,
              holiday_name: data.holiday_name,
              start_date: data.start_end_date[0],
              end_date: data.start_end_date[1],
            })
              .then((res: any) => {
                message.success(t('添加成功'));
                refresh();
                resolve(null);
              })
              .finally(() => {
                editModal.loading = false;
              });
          });
        },
      });
      modalBox(editModal);
    };
    const UpdateCalendar = (record: any, text: any) => {
      let date = moment(record.month + '-' + (text?.value > 9 ? text?.value : ('0' + text?.value))).format('YYYY-MM-DD');
      const editModal = reactive({
        visible: true,
        loading: false,
        title: t('修改法定节假日'),
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'vertical',
            col: [],
          },
          fields: [
            {
              type: 'datepicker',
              name: 'date',
              label: '日期',
              label_i18n: t('日期'),
              disabled: true,
              allowClear: true,
              inputType: 'number',
              defaultValue: '',
              showTime: true,
              valueFormat: 'YYYY-MM-DD',
              format: 'YYYY-MM-DD',
              placeholder: '请填写日期',
              placeholder_i18n: t('请填写日期'),
            },
            {
              type: 'input',
              name: 'holiday_name',
              label: '节日名字',
              label_i18n: t('节日名字'),
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写节日名字',
              placeholder_i18n: t('请填写节日名字'),
            },
          ],
          rules: reactive({
            holiday_name: [{ required: true, message: t('请填写节日名字') }],
          }),
          model: reactive({
            org_id: current_org?.id,
            date: date,
            holiday_name: text?.name ? text?.name : '',
          }),
          watch: {},
        },
        options: {},
        ok: (data: any) => {
          return new Promise(function (resolve) {
            editModal.loading = true;
            update_work_hour_template_month_holiday({
              org_id: current_org?.id,
              work_hour_template_id: props.record.id,
              holiday_name: data.holiday_name,
              start_date: date,
              end_date: date,
            })
              .then((res: any) => {
                message.success(t('修改成功'));
                refresh();
                resolve(null);
              })
              .finally(() => {
                editModal.loading = false;
              });
          });
        },
      });
      modalBox(editModal);
    };
    const DelCalendar = (record: any, text: any) => {
      Modal.confirm({
        title: () => t('是否删除该日法定节假日'),
        icon: () => createVNode(ExclamationCircleOutlined),
        onOk() {
          return new Promise(resolve => {
            let date = moment(record.month + '-' + (text?.value > 9 ? text?.value : ('0' + text?.value))).format('YYYY-MM-DD');
            update_work_hour_template_month_holiday({
              org_id: current_org?.id,
              work_hour_template_id: props.record.id,
              start_date: date,
              end_date: date,
              holiday_name: null,
            })
              .then((res: any) => {
                message.success(t('删除成功'));
                refresh();
              })
              .finally(() => {
                resolve(null);
              });
          }).catch(() => console.log('Oops errors!'));
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onCancel() {
        },
      });
    };
    const UpdateNum = (record: any, column: any, num: any) => {
      let date = moment(record.month + '-' + (column.dataIndex > 9 ? column.dataIndex : ('0' + column.dataIndex))).format('YYYY-MM-DD');
      update_work_hour_template_month_data({
        org_id: current_org?.id,
        work_hour_template_id: props.record.id,
        date: date,
        value: num,
      }).then(() => {
        refresh();
      });
    };
    const UpdateNumModal = (record: any, column: any) => {
      let date = moment(record.month + '-' + (column.dataIndex > 9 ? column.dataIndex : ('0' + column.dataIndex))).format('YYYY-MM-DD');
      const editModal = reactive({
        visible: true,
        loading: false,
        title: t('工时录入'),
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'vertical',
            col: [],
          },
          fields: [
            {
              type: 'datepicker',
              name: 'date',
              label: '日期',
              label_i18n: t('日期'),
              disabled: true,
              allowClear: true,
              inputType: 'number',
              defaultValue: '',
              showTime: true,
              valueFormat: 'YYYY-MM-DD',
              format: 'YYYY-MM-DD',
              placeholder: '请填写日期',
              placeholder_i18n: t('请填写日期'),
            },
            {
              type: 'input',
              name: 'value',
              label: '工时',
              label_i18n: t('工时'),
              disabled: false,
              allowClear: true,
              inputType: 'number',
              defaultValue: '',
              placeholder: '请填写工时',
              placeholder_i18n: t('请填写工时'),
            },
          ],
          rules: reactive({
            value: [{ required: true, message: t('请填写工时') }],
          }),
          model: reactive({
            org_id: current_org?.id,
            date: date,
            value: null,
          }),
          watch: {},
        },
        options: {},
        ok: (data: any) => {
          return new Promise(function (resolve) {
            editModal.loading = true;
            update_work_hour_template_month_data({
              org_id: current_org?.id,
              work_hour_template_id: props.record.id,
              value: data.value,
              date: date,
            })
              .then((res: any) => {
                message.success(t('操作成功'));
                refresh();
                resolve(null);
              })
              .finally(() => {
                editModal.loading = false;
              });
          });
        },
      });
      modalBox(editModal);
    };
    const SelectMonth = () => {
      refresh();
    };
    return {
      t,
      month,
      baseColumns,
      dataSource,
      tableHeight,
      loading,
      dynamicColumnItems,
      filename,
      month_list,
      multiHeader,
      SelectMonth,
      UpdateNum,
      UpdateNumModal,
      DelCalendar,
      search,
      getFullDate,
      customCell,
      AddCalendar,
      UpdateCalendar,
    };
  },
});
