
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'JsonViewModal',
  props: {
    title: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
    },
    mode: {
      type: String,
      required: true,
      default: () => 'javascript',
    },
    params: {
      type: String,
      required: true,
    },
    hold: {
      type: Function,
      required: true,
    },
    okText: {
      type: String,
      default: () => '',
    },
  },
  setup(props, { emit }) {
    const code = ref<string>('');

    code.value = props.params ? JSON.parse(props.params) : '';
    const sendMessage = () => {
      emit('send-message');
    };
    return {
      code,
      sendMessage,
    };
  },
});
