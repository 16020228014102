
import { defineComponent, computed } from 'vue';
import { useStore } from 'vuex';
import { AppState } from '@/store/modules/app/state';
import { locales } from '@/locales';
import { GlobalOutlined } from '@ant-design/icons-vue';

const languageLabels = {
  'zh-CN': '简体中文',
  'zh-TW': '繁体中文',
  'en-US': 'English',
  'pt-BR': 'Português',
};
const languageIcons = {
  'zh-CN': '🇨🇳',
  'zh-TW': '🇭🇰',
  'en-US': '🇺🇸',
  'pt-BR': '🇧🇷',
};
const languageSupports = {
  'zh-CN': true,
  'zh-TW': false,
  'en-US': true,
  'pt-BR': false,
};

interface MenuClick {
  item: Record<string, unknown>;
  key: string;
  keyPath: string;
}

export default defineComponent({
  setup() {
    const store = useStore<AppState>();
    const currentLang = computed(() => store.getters['app/lang']);

    const handleMenuClick = ({ key }: MenuClick): void => {
      store.dispatch('app/SET_LANG', key);
    };

    return {
      currentLang,

      locales,
      languageLabels,
      languageIcons,
      languageSupports,
      handleMenuClick,
    };
  },
  components: {
    GlobalOutlined,
  },
});
