const True = true;
const False = false;
export const settings_todoevent = {
  labelAlign: 'right',
  layout: 'vertical',
  col: [],
};
export const fields_todoevent = [
  {
    type: 'input',
    name: 'title',
    label: '标题',
    label_i18n: '标题',
    placeholder_i18n: '请填写标题',
    disabled: false,
    allowClear: true,
    inputType: 'text',
    defaultValue: '',
    placeholder: '请填写标题',
  },
  {
    type: 'input',
    name: 'content',
    label: '内容',
    label_i18n: '内容',
    placeholder_i18n: '请填写内容',
    disabled: false,
    allowClear: true,
    inputType: 'text',
    defaultValue: '',
    placeholder: '请填写内容',
  },
  {
    type: 'radio',
    name: 'is_finished',
    label: '是否完成',
    label_i18n: '是否完成',
    disabled: false,
    labelKey: 'name',
    valueKey: 'value',
    defaultValue: '',
  },
  {
    type: 'remotemodal',
    name: 'adviser_id',
    label: '顾问',
    label_i18n: '顾问',
    placeholder_i18n: '请选择隶属顾问',
    disabled: false,
    defaultValue: '',
    labelKey: 'full_name',
    valueKey: 'id',
    placeholder: '隶属顾问',
    show_search: false,
    maxTagCount: 5,
    mode: 'default',
    title: '选择顾问',
    modalType: 'cerp-adviser',
  },
  {
    type: 'select',
    name: 'status',
    label: '紧迫状态',
    mode: 'default',
    disabled: false,
    defaultValue: '',
    labelKey: 'name',
    valueKey: 'value',
    placeholder: '请选择紧迫状态',
    datasource: 'status',
  },
];
export const options_todoevent = {
  is_finished: [
    { value: True, name: '完成' },
    { value: False, name: '未完成' },
  ],
  status: [
    { value: 0, name: '普通' },
    { value: 1, name: '紧急' },
  ],
};
