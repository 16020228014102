import { queryApiurl } from '@/api/permission/apiurl-manage';
import { useI18n } from 'vue-i18n';

interface SearchConfig {
  rules: any;
  fields: any;
  settings: any;
  model: any;
}

export default () => {
  const { t } = useI18n();

  const apiurl_config: SearchConfig = {
    settings: {
      name: '表单名称',
      labelAlign: 'right',
      layout: 'inline',
    },
    fields: [
      {
        type: 'input',
        name: 'name',
        label: '接口名称',
        label_i18n: '接口名称',
        placeholder_i18n: '请填写接口名称',
        disabled: false,
        allowClear: true,
        inputType: 'text',
        maxlength: 100,
        defaultValue: '',
        placeholder: '请填写接口名称',
      },
      {
        type: 'input',
        name: 'url',
        label: '绝对路径url',
        label_i18n: '绝对路径url',
        placeholder_i18n: '绝对路径url',
        disabled: false,
        allowClear: true,
        inputType: 'text',
        maxlength: 100,
        defaultValue: '',
        placeholder: '绝对路径url',
      },
    ],
    rules: {
      name: [],
    },
    model: {},
};
const apiurl_columns = [
  {
    title: t('接口名称'),
    dataIndex: 'name',
  },
  {
    title: 'url',
    dataIndex: 'url',
  },
];
const apiurl_ConfigOptions = {
  is_active: [
    { name: t('全部'), value: '' },
    { name: t('正常'), value: true },
    { name: t('禁用'), value: false },
  ],
};
const slotList = [
  {
    slotName: 'index', // 槽名
  },
];
  return {
    searchConfig: apiurl_config,
    searchConfigOptions: apiurl_ConfigOptions,
    getData: queryApiurl,
    columns: apiurl_columns,
    slotList: slotList,
  };
};
