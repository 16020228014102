<template>
  <a-modal
    :title="title"
    width="800px"
    :visible="visible"
    :maskClosable="false"
    centered
    @ok="handleCancel"
    @cancel="handleCancel"
  >
    <template #footer>
      <a-button type="primary" @click="handleDownload">{{ t('导出') }}</a-button>
      <a-button type="primary" @click="handleCancel">{{ t('关闭') }}</a-button>
    </template>
    <a-tag
      :color="'green'"
      v-for="(obj, obj_index) in data_list"
      :key="obj.id == undefined ? obj_index : obj.id"
      style="margin-bottom: 1px"
    >
      {{ obj.adviser__employee_number + '#' + obj.adviser__full_name }}
    </a-tag>
  </a-modal>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { query_notice_record_list } from '@/api/cerp-model';
import { DownloadOutlined } from '@ant-design/icons-vue';
import { useStore } from 'vuex';
import { exportJsonToExcel } from '@/utils/excel';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'tagModal',
  props: {
    record: {
      type: Object,
      default: () => {},
    },
    is_read: {
      type: Boolean,
      default: () => true,
    },
    title: {
      type: String,
      default: () => '',
    },
  },
  components: {
    DownloadOutlined,
  },
  emits: ['ok', 'cancel'],
  setup(props, { emit }) {
    const { t } = useI18n();
    const visible = ref<boolean>(false);
    const store = useStore();
    const current_org = store.getters['user/current_org'];
    const data_list: any = ref([]);
    query_notice_record_list({
      org_id: current_org.id,
      notice_id: props.record.id,
      is_read: props.is_read,
      is_active: true,
    }).then((res: any) => {
      if (res && res.data && res.data.length > 0) {
        res.data.map((item: any) => {
          data_list.value.push(item);
        });
      }
    });
    const handleOpen = () => {
      visible.value = true;
    };
    const handleCancel = () => {
      visible.value = false;
      emit('cancel');
    };
    const handleDownload = () => {
      let data: any = [];
      data_list.value.forEach((item: any) => {
        data.push([item.adviser__full_name, item.adviser__employee_number]);
      });
      exportJsonToExcel({
        data: data,
        autoWidth: false,
        bookType: 'xlsx',
        filename: props.record.title + '-' + props.title,
        header: [t('顾问'), t('顾问工号')],
        merges: [],
        multiHeader: [],
      });
    };

    return {
      t,
      props,
      data_list,
      visible,
      handleOpen,
      handleCancel,
      handleDownload,
    };
  },
});
</script>
