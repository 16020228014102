
import { defineComponent, reactive, getCurrentInstance, onUnmounted } from 'vue';
import mqtt from '@/utils/service/mqtt';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'MqttState',
  props: {},
  setup() {
    const store = useStore();
    const current_org = store.getters['user/current_org'];
    const internalInstance = getCurrentInstance();
    let $bus: {
      emit: (arg0: string, arg1: any) => void;
      off: (arg0: string) => void;
      on: (arg0: string, arg1: any) => void;
    } | null = null;
    if (internalInstance != null) {
      $bus = internalInstance.appContext.config.globalProperties.$bus;
    }
    const mqtt_state = reactive({
      connected: false,
    });
    const editModal = reactive({
      visible: false,
    });
    const select_mqtt_state = () => {
      editModal.visible = true;
    };
    const handlerOk = () => {
      editModal.visible = false;
    };
    // mqtt().init();
    mqtt().channel_subscribe_event(current_org.id, 'foo');
    $bus?.on('mqtt_state', (data: any) => {
      if (data == 1) {
        mqtt_state.connected = true;
      } else {
        mqtt_state.connected = false;
      }
    });
    $bus?.on('foo', (data: any) => {
      if (data == 1) {
        mqtt_state.connected = true;
      } else {
        mqtt_state.connected = false;
      }
    });
    onUnmounted(() => {
      mqtt().channel_unsubscribe_event(current_org.id, 'foo');
      $bus?.off(current_org.id + 'foo');
    });
    return {
      select_mqtt_state,
      mqtt_state,
      editModal,
      handlerOk,
    };
  },
  components: {},
});
