<template>
  <a-tag
    :color="column.color ? column.color(record, text) : 'green'"
    v-if="column.content ? column.content(record, text) : text"
    @click="column.cb ? column.cb(record, text) : ''"
  >
    <span v-if="column.content">
      {{ column.content(record, text) }}
    </span>
    <span v-else>
                    {{ text }}
                  </span>
  </a-tag>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
export default defineComponent({
  name: 'Cell_tag',
  props: {
    column: {
      type: Object,
      required: true,
      default: () => {},
    },
    index: {
      type: Number,
      required: true,
    },
    text: {
      type: [Number , String , Boolean],
      required: false,
    },
    record: {
      type: Object,
      required: true,
      default: () => {},
    },
    pageSize: {
      type: Number,
      required: true,
      default: () => 20,
    },
    current: {
      type: Number,
      required: true,
      default: () => 1,
    },
  },
});
</script>
