
import { CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { defineComponent, reactive, ref } from 'vue';
import { useStore } from 'vuex';
import { useForm } from 'ant-design-vue/es/form';
import { createBeneficiary, get_form, options } from '@/components/show-insurance/show-insurance.ts';
import { create_insurance } from '@/api/cerp-model';
import { message, notification } from 'ant-design-vue';
import bus from '@/utils/bus';

import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'ShowInsurance',
  components: { ExclamationCircleOutlined, CheckCircleOutlined },
  props: {
    title: {
      type: String,
      required: true,
    },
    insuranceObj: {
      type: Object,
      required: true,
    },
    insuranceCreate: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['ok', 'cancel'],
  setup(props, { emit }) {
    const store = useStore();
    const { t } = useI18n();
    const event_obj = bus;
    const loading = ref(false);
    const is_from = ref(true);
    const get_form_obj = reactive({
      ...props.insuranceObj,
    });
    get_form_obj.beneficiary_list.forEach((item: any) => {
      item.is_create = false;
      item.loading = false;
    });
    let form = reactive({ ...get_form(get_form_obj) });
    const modelRef = reactive({
      ...form.model,
      policy_no: props.insuranceObj.policy_no,
      insurance_fee: props.insuranceObj.insurance_fee,
      month_insurance_fee: props.insuranceObj.month_insurance_fee,
      start_end_date: props.insuranceObj.start_end_date,
      beneficiary_ids: [],
    });
    const rulesRef = reactive({ ...form.rules });
    const { validateInfos: validateInfos } = useForm(modelRef, rulesRef);

    const craete_insurance_fun = () => {
      loading.value = true;
      create_insurance({
        ...modelRef,
        effective_date_time: modelRef.start_end_date[0],
        expiry_date_time: modelRef.start_end_date[1],
      })
        .then(() => {
          loading.value = false;
          message.success(t('添加成功'));
          emit('ok', true);
        })
        .catch(() => {
          loading.value = false;
        });
    };
    const craete_beneficiary = (item: any) => {
      if (modelRef.adviser_id) {
        item.loading = true;
        createBeneficiary({
          adviser_id: modelRef.adviser_id,
          ...item,
        })
          .then((id: any) => {
            item.loading = false;
            item.is_create = true;
            (modelRef.beneficiary_ids as Array<any>).push(id);
            event_obj.emit('remoteselect');
          })
          .catch(() => {
            item.loading = false;
          })
          .finally(() => {
            item.loading = false;
          });
      } else {
        notification.error({
          message: '提示',
          description: '请先选择确认顾问！',
        });
      }
    };
    const getDateTime = (time: any) => {
      if (time) {
        time = time.substring(0, 16);
      }
      return time;
    };
    const getMonth = (time: any) => {
      if (time) {
        time = time.substring(0, 7);
      }
      return time;
    };
    const getDate = (time: any) => {
      if (time) {
        time = time.substring(0, 10);
      }
      return time;
    };

    return {
      craete_insurance_fun,
      craete_beneficiary,
      getDateTime,
      getMonth,
      getDate,
      createBeneficiary,
      get_form_obj,
      form,
      is_from,
      loading,
      options,
      modelRef,
      validateInfos,
    };
  },
});
