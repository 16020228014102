import request from '@/utils/request';

// 搜索栏 组织数据
export async function queryOrgList(params?: { [key: string]: any }): Promise<RoleResponse> {
  return request.post('/org/query_org_for_login_list', params);
}
// 搜索栏 组织数据
export async function simpleLoginMaintain(params?: { [key: string]: any }): Promise<RoleResponse> {
  return request.post('/user/simple_login', params);
}
export async function querySwitchUserList(params?: { [key: string]: any }): Promise<RoleResponse> {
  return request.post('/sys/query_switch_user_list', params);
}
export async function checkIsSuperUser(params?: { [key: string]: any }): Promise<RoleResponse> {
  return request.post('/sys/check_is_super_user', params);
}
export async function query_todoevent_unfinish_list(params?: { [key: string]: any }): Promise<RoleResponse> {
  return request.post('/cerp/query_todoevent_unfinish_list', params);
}
export async function switchUser(params?: { [key: string]: any }): Promise<any> {
  return request.post('/sys/switch_user', params);
}
export async function change_password(params?: { [key: string]: any }): Promise<any> {
  return request.post('/sys/change_password', params);
}

// 修改角色人员模态框  人员信息
export async function queryGroupList(params?: { [key: string]: any }): Promise<any> {
  return new Promise(resolve => {
    request.post('/org/query_group_and_no_group_member', params).then((res: any) => {
      const group_list: Array<any> = [];
      let num = 0;
      res.group_list.forEach((item: any) => {
        const children: Array<any> = [];
        const obj = {
          key: item.name,
          name: item.name,
          user_id: 0,
          person_id: 0,
          children: children,
        };
        num = num + 1;
        if (item && item.person_list && item.person_list.length > 0) {
          item.person_list.forEach((item1: any) => {
            if (item1?.id) {
              const obj1: any = {
                key: item1.id,
                name: item1.user_id + '#' + item1.realname,
                user__username: item1.user__username,
                user_id: item1.user_id,
                person_id: item1.id,
              };
              num = num + 1;
              obj.children.push(obj1);
            }
          });
        }
        group_list.push(obj);
      });
      group_list.forEach((item: any) => {
        if (item.children && item.children.length > 0) {
          item.name = item.name + '(' + item.children.length + '人)';
        } else {
          item.name = item.name + '(0人)';
        }
      });
      res.person_list.forEach((item: any) => {
        const obj = {
          key: item.id,
          name: item.user_id + '#' + item.realname,
          user__username: item.user__username,
          user_id: item.user_id,
          person_id: item.id,
        };
        num = num + 1;
        group_list.push(obj);
      });
      res.data = group_list;
      resolve(res);
    });
  });
}

interface RuleItem {
  key: string | number;
  callNo: number;
  avatar: string;
  desc: string;
  disabled: false;
  href: string;
  name: string;
  owner: string;
  progress: number;
  status: number;
  updatedAt: string;
  createdAt: string;
}

interface RoleResponse {
  current: number;
  data: RuleItem[];
  pageSize: string;
  success: boolean;
  total: number;
}
