import { query_question_group_list } from '@/api/baseinfo-model';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';


const org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
const search_config: any = {
  model: {
    org_id: org?.id,
    is_active: true,
  },
  watch: [],
};


export default () => {
  return {
    getData: query_question_group_list,
    searchConfig: search_config,
  };
};
