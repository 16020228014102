import { reactive } from 'vue';
import { create_address } from '@/api/cerp-model';
import { message } from 'ant-design-vue';
import modalBox from '@/components/form-modal/modal-tools';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import { getDate, getDateTime } from '@/utils/function';

export default (t: any, obj: any) => {
  const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org

  const settings_adviser = {
    labelAlign: 'right',
    layout: 'vertical',
    col: [9, 9, 7],
  };
  const fields_adviser = [
    {
      type: 'input',
      name: 'employee_number',
      label: '顾问工号',
      label_i18n: 'page.cerp.adviser.form.label.employee_number',
      disabled: true,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写顾问工号',
      placeholder_i18n: 'page.cerp.adviser.form.placeholder.employee_number',
    },
    {
      type: 'select',
      name: 'title',
      label: '称谓',
      label_i18n: 'page.cerp.adviser.form.label.title',
      mode: 'default',
      disabled: false,
      defaultValue: '',
      labelKey: 'name',
      valueKey: 'value',
      placeholder: '请选择称谓',
      placeholder_i18n: 'page.cerp.adviser.form.placeholder.title',
      datasource: 'title',
    },
    {
      type: 'input',
      name: 'first_name',
      label: '名字',
      label_i18n: 'page.cerp.adviser.form.label.first_name',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写名字',
      placeholder_i18n: 'page.cerp.adviser.form.placeholder.first_name',
    },
    {
      type: 'input',
      name: 'last_name',
      label: '姓',
      label_i18n: 'page.cerp.adviser.form.label.last_name',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写姓',
      placeholder_i18n: 'page.cerp.adviser.form.placeholder.last_name',
    },
    {
      type: 'input',
      name: 'other_name',
      label: '其他名字',
      label_i18n: 'page.cerp.adviser.form.label.other_name',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写其他名字',
      placeholder_i18n: 'page.cerp.adviser.form.placeholder.other_name',
    },
    {
      type: 'datepicker',
      name: 'birth_date',
      label: '出生日期',
      label_i18n: 'page.cerp.adviser.editModal.column.title.birth_date',
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      showTime: false,
      disabledTime: (date: any) => {
        return date.format('YYYY') >= 2015;
      },
      valueFormat: 'YYYY-MM-DD',
      placeholder: '请填写出生日期',
      placeholder_i18n: 'page.cerp.beneficiary.form.placeholder.date_of_birth',
    },
    {
      type: 'remoteselect',
      name: 'nationality',
      label: '国籍',
      label_i18n: 'page.baseinfo.country.form.label.nationality',
      disabled: false,
      placeholder: '请选择国籍',
      placeholder_i18n: 'page.cerp.adviser.form.placeholder.nationality_id',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-country',
    },
    {
      type: 'remoteselect',
      name: 'pay_currency_id',
      labelKeyList: ['code', 'currency'],
      label: '付款币种',
      label_i18n: 'page.cerp.contract.colums.column.title.pay_currency__currency',
      disabled: false,
      placeholder: '请选择付款币种',
      placeholder_i18n: 'page.cerp.adviser.form.placeholder.pay_currency_id',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-country',
    },
    {
      type: 'remoteselect',
      name: 'user_email_template_id',
      label: '用户邮箱模板',
      label_i18n: 'page.cerp.adviser.form.label.user_email_template_id',
      disabled: false,
      placeholder: '请选择用户邮箱模板',
      placeholder_i18n: 'page.cerp.adviser.form.placeholder.user_email_template_id',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-emailtemplate',
    },
    {
      type: 'remoteselect',
      name: 'person_type_id',
      label: '个人类别',
      label_i18n: 'page.cerp.adviser.form.label.person_type_id',
      disabled: false,
      placeholder: '请选择个人类别',
      placeholder_i18n: 'page.cerp.adviser.form.placeholder.person_type_id',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-persontype',
    },
    {
      type: 'input',
      name: 'mobile',
      label: '手机',
      label_i18n: 'page.cerp.adviser.form.label.mobile',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写手机',
      placeholder_i18n: 'page.cerp.adviser.form.placeholder.mobile',
    },
    {
      type: 'input',
      name: 'phone',
      label: '电话',
      label_i18n: 'page.cerp.adviser.form.label.phone',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写电话',
      placeholder_i18n: 'page.cerp.adviser.form.placeholder.phone',
    },
    {
      type: 'input',
      name: 'personal_email',
      label: '个人邮箱',
      label_i18n: 'page.cerp.adviser.form.label.personal_email',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写个人邮箱',
      placeholder_i18n: 'page.cerp.adviser.form.placeholder.personal_email',
    },
    {
      type: 'input',
      name: 'work_email',
      label: '工作邮箱',
      label_i18n: 'page.cerp.adviser.form.label.work_email',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写工作邮箱',
      placeholder_i18n: 'page.cerp.adviser.form.placeholder.work_email',
    },
    {
      type: 'remoteselect',
      name: 'work_address_id',
      label: '工作地址',
      label_i18n: 'page.cerp.adviser.form.label.work_address_id',
      disabled: false,
      placeholder: '请选择工作地址',
      placeholder_i18n: 'page.cerp.adviser.form.placeholder.work_address_id',
      datasourceType: 'remote',
      mode: 'default',
      init_model: { is_related_adviser: true, address_type: 1 },
      allowClear: true,
      labelKey: 'display_name',
      valueKey: 'id',
      modalType: 'cerp-address',
      cb: () => {
        return new Promise(function (resolve) {
          const editModal1 = reactive({
            visible: true,
            title: t('新建工作地址'),
            loading: false,
            form: {
              settings: {
                labelAlign: 'right',
                layout: 'vertical',
                col: [],
              },
              fields: [
                {
                  type: 'input',
                  name: 'street',
                  label: '街道',
                  label_i18n: 'page.cerp.address.form.label.street',
                  disabled: false,
                  allowClear: true,
                  inputType: 'text',
                  defaultValue: '',
                  placeholder: '请填写街道',
                  placeholder_i18n: 'page.cerp.address.form.placeholder.street',
                },
                {
                  type: 'input',
                  name: 'city',
                  label: '城市',
                  label_i18n: 'page.cerp.address.form.label.city',
                  disabled: false,
                  allowClear: true,
                  inputType: 'text',
                  defaultValue: '',
                  placeholder: '请填写城市',
                  placeholder_i18n: 'page.cerp.address.form.placeholder.city',
                },
                {
                  type: 'input',
                  name: 'state',
                  label: '州/省',
                  label_i18n: 'page.cerp.address.form.label.state',
                  disabled: false,
                  allowClear: true,
                  inputType: 'text',
                  defaultValue: '',
                  placeholder: '请填写州/省',
                  placeholder_i18n: 'page.cerp.address.form.placeholder.state',
                },
                {
                  type: 'remoteselect',
                  name: 'country_id',
                  label: '国家',
                  label_i18n: 'page.cerp.address.form.label.country_id',
                  disabled: false,
                  placeholder: '请选择国家',
                  placeholder_i18n: 'page.cerp.address.form.placeholder.country_id',
                  datasourceType: 'remote',
                  mode: 'default',
                  allowClear: true,
                  modalType: 'baseinfo-country',
                },
                {
                  type: 'input',
                  name: 'postcode',
                  label: '邮编',
                  label_i18n: 'page.cerp.address.form.label.postcode',
                  disabled: false,
                  allowClear: true,
                  inputType: 'text',
                  defaultValue: '',
                  placeholder: '请填写邮编',
                  placeholder_i18n: 'page.cerp.address.form.placeholder.postcode',
                },
                {
                  type: 'radio',
                  name: 'address_type',
                  label: '地址类型',
                  label_i18n: 'page.cerp.address.form.label.address_type',
                  disabled: true,
                  labelKey: 'name',
                  valueKey: 'value',
                  defaultValue: '',
                  placeholder_i18n: 'page.cerp.address.form.placeholder.address_type',
                },
              ],
              rules: {},
              model: reactive({
                org_id: current_org?.id,
                is_active: true,
                adviser_id: null,
                adviser_name: null,
                street: null,
                city: null,
                state: null,
                country_id: null,
                postcode: null,
                address_type: 1,
              }),
            },
            options: {
              address_type: [
                { value: 0, name: t('page.cerp.adviser.form.label.home_address_id') },
                { value: 1, name: t('page.cerp.adviser.form.label.work_address_id') },
              ],
            },
            ok: (payload: any) => {
              editModal1.loading = true;
              return new Promise(resolve1 => {
                create_address(payload)
                  .then((res: any) => {
                    message.success(t('创建成功'));
                    resolve1(null);
                    resolve(res.id as number);
                  })
                  .finally(() => {
                    editModal1.loading = false;
                  });
              });
            },
          });
          modalBox(editModal1);
        });
      },
    },
    {
      type: 'remoteselect',
      name: 'cc_bank_account_id',
      label: 'CC银行账户',
      label_i18n: 'page.cerp.adviser.form.label.cc_bank_account_id',
      disabled: false,
      placeholder: '请选择CC银行账户',
      placeholder_i18n: 'page.cerp.adviser.form.placeholder.cc_bank_account_id',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      labelKey: 'account_flag',
      valueKey: 'id',
      modalType: 'baseinfo-ccbankaccountscc',
    },
    {
      type: 'datepicker',
      name: 'end_date',
      label: '顾问结束日期',
      label_i18n: 'page.cerp.adviser.form.label.end_date',
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      showTime: true,
      valueFormat: 'YYYY-MM-DD',
      format: 'YYYY-MM-DD',
      placeholder: '顾问结束日期',
      placeholder_i18n: 'page.cerp.adviser.form.placeholder.end_date',
    },
    {
      type: 'remoteselect',
      name: 'tag_ids',
      label: '标签',
      label_i18n: 'page.cerp.adviser.form.label.tag_id',
      disabled: false,
      placeholder: '请选择标签',
      placeholder_i18n: 'page.cerp.adviser.form.placeholder.tag_id',
      datasourceType: 'remote',
      mode: 'multiple',
      allowClear: true,
      labelKey: 'name',
      valueKey: 'id',
      modalType: 'baseinfo-tag',
    },
    {
      type: 'select',
      name: 'status',
      label: '个人状态',
      label_i18n: 'page.cerp.adviser.form.label.status',
      mode: 'default',
      disabled: false,
      defaultValue: '',
      labelKey: 'name',
      valueKey: 'value',
      placeholder: '请选择个人状态',
      placeholder_i18n: 'page.cerp.adviser.form.placeholder.status',
      datasource: 'status',
    },
    {
      type: 'input',
      name: 'wechat',
      label: '微信账号',
      label_i18n: 'page.cerp.adviser.form.label.wechat',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写微信账号',
      placeholder_i18n: 'page.cerp.adviser.form.placeholder.wechat',
    },
    {
      type: 'input',
      name: 'qq',
      label: 'QQ账号',
      label_i18n: 'page.cerp.adviser.form.label.qq',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写QQ账号',
      placeholder_i18n: 'page.cerp.adviser.form.placeholder.qq',
    },
    {
      type: 'input',
      name: 'skypelid',
      label: 'Skype ID',
      label_i18n: 'page.cerp.adviser.form.label.skypelid',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写Skype ID',
      placeholder_i18n: 'page.cerp.adviser.form.placeholder.skypelid',
    },
    {
      type: 'input',
      name: 'whats_app',
      label: 'WhatsApp账号',
      label_i18n: 'page.cerp.adviser.form.label.whats_app',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写WhatsApp账号',
      placeholder_i18n: 'page.cerp.adviser.form.placeholder.whats_app',
    },
    {
      type: 'remoteselect',
      name: 'home_address_id',
      label: '家庭地址',
      label_i18n: 'page.cerp.adviser.form.label.home_address_id',
      disabled: false,
      placeholder: '请选择家庭地址',
      placeholder_i18n: 'page.cerp.adviser.form.placeholder.home_address_id',
      datasourceType: 'remote',
      mode: 'default',
      init_model: { is_related_adviser: true, address_type: 0 },
      allowClear: true,
      labelKey: 'display_name',
      valueKey: 'id',
      modalType: 'cerp-address',
      cb: () => {
        return new Promise(function (resolve) {
          const editModal1 = reactive({
            visible: true,
            title: t('新建家庭地址'),
            loading: false,
            form: {
              settings: {
                labelAlign: 'right',
                layout: 'vertical',
                col: [],
              },
              fields: [
                {
                  type: 'input',
                  name: 'street',
                  label: '街道',
                  label_i18n: 'page.cerp.address.form.label.street',
                  disabled: false,
                  allowClear: true,
                  inputType: 'text',
                  defaultValue: '',
                  placeholder: '请填写街道',
                  placeholder_i18n: 'page.cerp.address.form.placeholder.street',
                },
                {
                  type: 'input',
                  name: 'city',
                  label: '城市',
                  label_i18n: 'page.cerp.address.form.label.city',
                  disabled: false,
                  allowClear: true,
                  inputType: 'text',
                  defaultValue: '',
                  placeholder: '请填写城市',
                  placeholder_i18n: 'page.cerp.address.form.placeholder.city',
                },
                {
                  type: 'input',
                  name: 'state',
                  label: '州/省',
                  label_i18n: 'page.cerp.address.form.label.state',
                  disabled: false,
                  allowClear: true,
                  inputType: 'text',
                  defaultValue: '',
                  placeholder: '请填写州/省',
                  placeholder_i18n: 'page.cerp.address.form.placeholder.state',
                },
                {
                  type: 'remoteselect',
                  name: 'country_id',
                  label: '国家',
                  label_i18n: 'page.cerp.address.form.label.country_id',
                  disabled: false,
                  placeholder: '请选择国家',
                  placeholder_i18n: 'page.cerp.address.form.placeholder.country_id',
                  datasourceType: 'remote',
                  mode: 'default',
                  allowClear: true,
                  modalType: 'baseinfo-country',
                },
                {
                  type: 'input',
                  name: 'postcode',
                  label: '邮编',
                  label_i18n: 'page.cerp.address.form.label.postcode',
                  disabled: false,
                  allowClear: true,
                  inputType: 'text',
                  defaultValue: '',
                  placeholder: '请填写邮编',
                  placeholder_i18n: 'page.cerp.address.form.placeholder.postcode',
                },
                {
                  type: 'radio',
                  name: 'address_type',
                  label: '地址类型',
                  label_i18n: 'page.cerp.address.form.label.address_type',
                  disabled: true,
                  labelKey: 'name',
                  valueKey: 'value',
                  defaultValue: '',
                  placeholder_i18n: 'page.cerp.address.form.placeholder.address_type',
                },
              ],
              rules: {},
              model: reactive({
                org_id: current_org?.id,
                is_active: true,
                adviser_id: null,
                adviser_name: null,
                street: null,
                city: null,
                state: null,
                country_id: null,
                postcode: null,
                address_type: 0,
              }),
            },
            options: {
              address_type: [
                { value: 0, name: t('page.cerp.adviser.form.label.home_address_id') },
                { value: 1, name: t('page.cerp.adviser.form.label.work_address_id') },
              ],
            },
            ok: (payload: any) => {
              editModal1.loading = true;
              return new Promise(resolve1 => {
                create_address(payload)
                  .then((res: any) => {
                    message.success(t('创建成功'));
                    resolve1(null);
                    resolve(res.id as number);
                  })
                  .finally(() => {
                    editModal1.loading = false;
                  });
              });
            },
          });
          modalBox(editModal1);
        });
      },
    },
    {
      type: 'textarea',
      name: 'person_note',
      label: '备注',
      label_i18n: 'page.cerp.bankaccount.column.title.note',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      maxlength: 200,
      defaultValue: '',
      placeholder_i18n: 'page.cerp.banktransaction.form.placeholder.description',
      placeholder: '请填写备注',
    },
  ];
  const fields_adviser_update = [
    {
      type: 'input',
      name: 'employee_number',
      label: '顾问工号',
      label_i18n: 'page.cerp.adviser.form.label.employee_number',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写顾问工号',
      placeholder_i18n: 'page.cerp.adviser.form.placeholder.employee_number',
    },
    {
      type: 'select',
      name: 'title',
      label: '称谓',
      label_i18n: 'page.cerp.adviser.form.label.title',
      mode: 'default',
      disabled: false,
      defaultValue: '',
      labelKey: 'name',
      valueKey: 'value',
      placeholder: '请选择称谓',
      placeholder_i18n: 'page.cerp.adviser.form.placeholder.title',
      datasource: 'title',
    },
    {
      type: 'input',
      name: 'first_name',
      label: '名字',
      label_i18n: 'page.cerp.adviser.form.label.first_name',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写名字',
      placeholder_i18n: 'page.cerp.adviser.form.placeholder.first_name',
    },
    {
      type: 'input',
      name: 'last_name',
      label: '姓',
      label_i18n: 'page.cerp.adviser.form.label.last_name',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写姓',
      placeholder_i18n: 'page.cerp.adviser.form.placeholder.last_name',
    },
    {
      type: 'input',
      name: 'other_name',
      label: '其他名字',
      label_i18n: 'page.cerp.adviser.form.label.other_name',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写其他名字',
      placeholder_i18n: 'page.cerp.adviser.form.placeholder.other_name',
    },
    {
      type: 'datepicker',
      name: 'birth_date',
      label: '出生日期',
      label_i18n: 'page.cerp.adviser.editModal.column.title.birth_date',
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      showTime: false,
      disabledTime: (date: any) => {
        return date.format('YYYY') >= 2015;
      },
      valueFormat: 'YYYY-MM-DD',
      placeholder: '请填写出生日期',
      placeholder_i18n: 'page.cerp.beneficiary.form.placeholder.date_of_birth',
    },
    {
      type: 'remoteselect',
      name: 'nationality',
      label: '国籍',
      label_i18n: 'page.baseinfo.country.form.label.nationality',
      disabled: false,
      placeholder: '请选择国籍',
      placeholder_i18n: 'page.cerp.adviser.form.placeholder.nationality_id',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-country',
    },
    {
      type: 'remoteselect',
      name: 'pay_currency_id',
      labelKeyList: ['code', 'currency'],
      label: '付款币种',
      label_i18n: 'page.cerp.contract.colums.column.title.pay_currency__currency',
      disabled: false,
      placeholder: '请选择付款币种',
      placeholder_i18n: 'page.cerp.adviser.form.placeholder.pay_currency_id',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-country',
    },
    {
      type: 'remoteselect',
      name: 'user_email_template_id',
      label: '用户邮箱模板',
      label_i18n: 'page.cerp.adviser.form.label.user_email_template_id',
      disabled: false,
      placeholder: '请选择用户邮箱模板',
      placeholder_i18n: 'page.cerp.adviser.form.placeholder.user_email_template_id',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-emailtemplate',
    },
    {
      type: 'remoteselect',
      name: 'person_type_id',
      label: '个人类别',
      label_i18n: 'page.cerp.adviser.form.label.person_type_id',
      disabled: false,
      placeholder: '请选择个人类别',
      placeholder_i18n: 'page.cerp.adviser.form.placeholder.person_type_id',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-persontype',
    },
    {
      type: 'input',
      name: 'mobile',
      label: '手机',
      label_i18n: 'page.cerp.adviser.form.label.mobile',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写手机',
      placeholder_i18n: 'page.cerp.adviser.form.placeholder.mobile',
    },
    {
      type: 'input',
      name: 'phone',
      label: '电话',
      label_i18n: 'page.cerp.adviser.form.label.phone',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写电话',
      placeholder_i18n: 'page.cerp.adviser.form.placeholder.phone',
    },
    {
      type: 'input',
      name: 'personal_email',
      label: '个人邮箱',
      label_i18n: 'page.cerp.adviser.form.label.personal_email',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写个人邮箱',
      placeholder_i18n: 'page.cerp.adviser.form.placeholder.personal_email',
    },
    {
      type: 'input',
      name: 'work_email',
      label: '工作邮箱',
      label_i18n: 'page.cerp.adviser.form.label.work_email',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写工作邮箱',
      placeholder_i18n: 'page.cerp.adviser.form.placeholder.work_email',
    },
    {
      type: 'remoteselect',
      name: 'work_address_id',
      label: '工作地址',
      label_i18n: 'page.cerp.adviser.form.label.work_address_id',
      disabled: false,
      placeholder: '请选择工作地址',
      placeholder_i18n: 'page.cerp.adviser.form.placeholder.work_address_id',
      datasourceType: 'remote',
      init_model: { adviser_id: obj?.id, address_type: 1 },
      mode: 'default',
      allowClear: true,
      labelKey: 'display_name',
      valueKey: 'id',
      modalType: 'cerp-address',
      cb: () => {
        return new Promise(function (resolve, reject) {
          const editModal1: any = reactive({
            visible: false,
            title: t('工作地址'),
            loading: false,
            obj: { adviser_id: obj?.id, ...obj, address_type: 1 },
            modalType: 'cerp-address',
            component: 'TableModal',
          });
          editModal1.visible = true;
          editModal1.ok = (id: number) => {
            resolve(id as number);
          };
          editModal1.cancel = () => {};
          modalBox(editModal1);
        });
      },
    },
    {
      type: 'remoteselect',
      name: 'cc_bank_account_id',
      label: 'CC银行账户',
      label_i18n: 'page.cerp.adviser.form.label.cc_bank_account_id',
      disabled: false,
      placeholder: '请选择CC银行账户',
      placeholder_i18n: 'page.cerp.adviser.form.placeholder.cc_bank_account_id',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      labelKey: 'account_flag',
      valueKey: 'id',
      modalType: 'baseinfo-ccbankaccountscc',
    },
    {
      type: 'datepicker',
      name: 'end_date',
      label: '顾问结束日期',
      label_i18n: 'page.cerp.adviser.form.label.end_date',
      disabled: false,
      allowClear: false,
      inputType: 'number',
      defaultValue: '',
      showTime: true,
      valueFormat: 'YYYY-MM-DD',
      format: 'YYYY-MM-DD',
      placeholder: '顾问结束日期',
      placeholder_i18n: 'page.cerp.adviser.form.placeholder.end_date',
    },
    {
      type: 'remoteselect',
      name: 'tag_ids',
      label: '标签',
      label_i18n: 'page.cerp.adviser.form.label.tag_id',
      disabled: false,
      placeholder: '请选择标签',
      placeholder_i18n: 'page.cerp.adviser.form.placeholder.tag_id',
      datasourceType: 'remote',
      mode: 'multiple',
      allowClear: true,
      labelKey: 'name',
      valueKey: 'id',
      modalType: 'baseinfo-tag',
    },
    {
      type: 'select',
      name: 'status',
      label: '个人状态',
      label_i18n: 'page.cerp.adviser.form.label.status',
      mode: 'default',
      disabled: false,
      defaultValue: '',
      labelKey: 'name',
      valueKey: 'value',
      placeholder: '请选择个人状态',
      placeholder_i18n: 'page.cerp.adviser.form.placeholder.status',
      datasource: 'status',
    },
    {
      type: 'input',
      name: 'wechat',
      label: '微信账号',
      label_i18n: 'page.cerp.adviser.form.label.wechat',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写微信账号',
      placeholder_i18n: 'page.cerp.adviser.form.placeholder.wechat',
    },
    {
      type: 'input',
      name: 'qq',
      label: 'QQ账号',
      label_i18n: 'page.cerp.adviser.form.label.qq',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写QQ账号',
      placeholder_i18n: 'page.cerp.adviser.form.placeholder.qq',
    },
    {
      type: 'input',
      name: 'skypelid',
      label: 'Skype ID',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写Skype ID',
      placeholder_i18n: 'page.cerp.adviser.form.placeholder.skypelid',
    },
    {
      type: 'input',
      name: 'whats_app',
      label: 'WhatsApp账号',
      label_i18n: 'page.cerp.adviser.form.label.whats_app',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写WhatsApp账号',
      placeholder_i18n: 'page.cerp.adviser.form.placeholder.whats_app',
    },
    {
      type: 'remoteselect',
      name: 'home_address_id',
      label: '家庭地址',
      label_i18n: 'page.cerp.adviser.form.label.home_address_id',
      disabled: false,
      placeholder: '请选择家庭地址',
      placeholder_i18n: 'page.cerp.adviser.form.placeholder.home_address_id',
      datasourceType: 'remote',
      init_model: { adviser_id: obj?.id, address_type: 0 },
      mode: 'default',
      allowClear: true,
      labelKey: 'display_name',
      valueKey: 'id',
      modalType: 'cerp-address',
      cb: () => {
        return new Promise(function (resolve, reject) {
          const editModal1: any = reactive({
            visible: false,
            title: t('家庭地址'),
            loading: false,
            obj: { adviser_id: obj?.id, ...obj, address_type: 0 },
            modalType: 'cerp-address',
            component: 'TableModal',
          });
          editModal1.visible = true;
          editModal1.ok = (id: number) => {
            resolve(id as number);
          };
          editModal1.cancel = () => {};
          modalBox(editModal1);
        });
      },
    },
    {
      type: 'textarea',
      name: 'person_note',
      label: '备注',
      label_i18n: 'page.cerp.bankaccount.column.title.note',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      maxlength: 200,
      defaultValue: '',
      placeholder: '请填写备注',
      placeholder_i18n: 'page.cerp.banktransaction.form.placeholder.description',
    },
  ];
  const options_adviser = {
    title: [
      { value: '', name: '-None-' },
      { value: 'Mr.', name: 'Mr.' },
      { value: 'Mrs.', name: 'Mrs.' },
      { value: 'Ms.', name: 'Ms.' },
      { value: 'Dr.', name: 'Dr.' },
      { value: 'Prof.', name: 'Prof.' },
    ],
    status: [
      { value: 1, name: t('-无-') },
      { value: 2, name: t('工作中') },
      { value: 3, name: t('结束') },
      { value: 4, name: t('申请签证中') },
      { value: 5, name: t('尚未开始') },
      { value: 6, name: t('潜在雇员') },
      { value: 7, name: t('离职') },
      { value: 8, name: t('待定') },
    ],
  };
  const baseColumns = [
    {
      title: t('page.cerp.adviser.editModal.column.title.index'),
      dataIndex: 'index',
      width: 50,
      fixed: 'left',
    },
    {
      title: t('page.cerp.adviser.editModal.column.title.employee_number'),
      width: 80,
      dataIndex: 'employee_number',
      checked: true,
      sorter: {
      multiple: 1,
    },
      fixed: 'left',
    },
    {
      title: t('page.cerp.adviser.editModal.column.title.full_name'),
      width: 120,
      dataIndex: 'full_name',
      checked: true,
      sorter: {
      multiple: 1,
    },
      fixed: 'left',
    },
    {
      title: t('page.cerp.adviser.editModal.column.title.other_name'),
      width: 120,
      dataIndex: 'other_name',
      checked: true,
    },
    {
      title: t('page.cerp.adviser.editModal.column.title.id'),
      width: 80,
      dataIndex: 'id',
      checked: false,
    },
    {
      title: t('page.cerp.adviser.editModal.column.title.title'),
      width: 120,
      dataIndex: 'title',
      checked: true,
    },
    {
      title: t('page.cerp.adviser.editModal.column.title.birth_date'),
      width: 130,
      dataIndex: 'birth_date',
      checked: true,
      content: (record: any) => {
        return getDate(record.birth_date);
      },
    },
    {
      title: t('page.cerp.adviser.editModal.column.title.nationality__name'),
      width: 130,
      dataIndex: 'nationality__name',
      checked: true,
      sorter: {
      multiple: 1,
    },
    },
    {
      title: t('page.cerp.adviser.editModal.column.title.phone'),
      width: 120,
      dataIndex: 'phone',
      checked: true,
    },
    {
      title: t('page.cerp.adviser.editModal.column.title.mobile'),
      width: 120,
      dataIndex: 'mobile',
      checked: true,
    },
    {
      title: t('page.cerp.adviser.editModal.column.title.personal_email'),
      width: 200,
      dataIndex: 'personal_email',
      checked: true,
    },
    {
      title: t('page.cerp.adviser.editModal.column.title.work_email'),
      width: 180,
      dataIndex: 'work_email',
      checked: true,
    },
    {
      title: t('page.cerp.adviser.editModal.column.title.wechat'),
      width: 180,
      dataIndex: 'wechat',
      checked: true,
    },
    {
      title: t('page.cerp.adviser.editModal.column.title.whats_app'),
      width: 150,
      dataIndex: 'whats_app',
      checked: true,
    },
    {
      title: t('page.cerp.adviser.editModal.column.title.qq'),
      width: 150,
      dataIndex: 'qq',
      checked: true,
    },
    {
      title: t('page.cerp.adviser.editModal.column.title.skypelid'),
      width: 150,
      dataIndex: 'skypelid',
      checked: true,
    },
    {
      title: t('page.cerp.adviser.editModal.column.title.person_type__name'),
      width: 150,
      dataIndex: 'person_type__name',
      checked: true,
    },
    {
      title: t('page.cerp.adviser.editModal.column.title.status__name'),
      width: 150,
      dataIndex: 'status',
      checked: true,
      sorter: {
      multiple: 1,
    },
      content: (record: any) => {
        let color = '';
        switch (record.status) {
          case 1:
            color = t('-无-');
            break;
          case 2:
            color = t('工作中');
            break;
          case 3:
            color = t('结束');
            break;
          case 4:
            color = t('申请签证中');
            break;
          case 5:
            color = t('尚未开始');
            break;
          case 6:
            color = t('尚未开始');
            break;
          case 7:
            color = t('尚未开始');
            break;
          case 8:
            color = t('待定');
            break;
          default:
            color = '';
            break;
        }
        return color;
      },
    },
    {
      title: t('page.cerp.adviser.editModal.column.title.creater'),
      width: 150,
      dataIndex: 'creater',
      checked: true,
    },
    {
      title: t('是否银行账户信息齐全'),
      width: 200,
      dataIndex: 'is_bank_account_intact',
      checked: true,
      cellComponent: 'boolean',
      name1: t('是'),
      name2: t('否'),
      filterMultiple: false,
      filters: [
        { text: t('全部'), value: '' },
        { text: t('是'), value: true },
        { text: t('否'), value: false },
      ],
    },
    {
      title: t('page.cerp.adviser.editModal.column.title.home_address__display_name'),
      width: 150,
      dataIndex: 'home_address__display_name',
      checked: true,
    },
    {
      title: t('page.cerp.adviser.editModal.column.title.work_address__display_name'),
      width: 150,
      dataIndex: 'work_address__display_name',
      checked: true,
    },
    {
      title: t('page.cerp.adviser.editModal.column.title.pay_currency__currency'),
      width: 100,
      dataIndex: 'pay_currency__currency',
      checked: true,
      sorter: {
      multiple: 1,
    },
    },
    {
      title: t('page.cerp.adviser.editModal.column.title.tag__name'),
      width: 150,
      dataIndex: 'tags',
      checked: true,
      cellComponent: 'list',
    },
    {
      title: t('page.cerp.adviser.editModal.column.title.person_note'),
      width: 350,
      dataIndex: 'person_note',
      checked: true,
    },
    {
      title: t('page.cerp.adviser.editModal.column.title.create_time'),
      width: 150,
      dataIndex: 'create_time',
      checked: true,
      content: (record: any) => {
        return getDateTime(record.create_time);
      },
    },
    {
      title: t('page.cerp.adviser.editModal.column.title.update_time'),
      width: 150,
      dataIndex: 'update_time',
      checked: true,
      content: (record: any) => {
        return getDateTime(record.update_time);
      },
    },
    {
      title: t('page.cerp.adviser.editModal.column.title.is_active'),
      width: 110,
      dataIndex: 'is_active',
      checked: true,
      cellComponent: 'boolean',
      name1: t('可用'),
      name2: t('禁用'),
      filterMultiple: false,
      filters: [
        { text: t('全部'), value: '' },
        { text: t('可用'), value: true },
        { text: t('禁用'), value: false },
      ],
    },
    {
      title: t('page.cerp.adviser.editModal.column.title.action'),
      width: 150,
      dataIndex: 'action',
      checked: false,
      cellComponent: 'action',
      fixed: 'right',
    },
  ];

  return {
    settings_adviser,
    fields_adviser,
    fields_adviser_update,
    options_adviser,
    baseColumns,
  };
};
