<template>
  <a-modal
    :title="title"
    width="1200px"
    :visible="visible"
    transitionName=""
    maskTransitionName=""
    :destroyOnClose="true"
    :confirmLoading="loading"
    centered
    @ok="handleSubmit"
    @cancel="
      () => {
        $emit('cancel');
      }
    "
  >
    <a-layout id="components-layout-demo-basic">
      <a-layout-sider style="overflow-y: scroll" width="650px">
        <a-tag
          closable
          color="#87d068"
          v-for="obj in state.selected"
          :key="obj.key"
          @close="closeTag(obj.key)"
          style="float: left; margin-bottom: 1px; margin-right: 2px"
        >
          {{ obj.name }}
        </a-tag>
      </a-layout-sider>
      <a-layout-content>
        <!--        <search-render-->
        <!--          ref="config_render"-->
        <!--          :form="searchConfig"-->
        <!--          :model="searchConfig.model"-->
        <!--          :options="searchConfigOptions"-->
        <!--          :resetFields="resetFields"-->
        <!--          :validateInfos="validateInfos"-->
        <!--          @search="search_list"-->
        <!--        />-->
        <a-table
          bordered
          v-if="person__list.length"
          style="height: 600px;
    overflow: scroll;"
          :defaultExpandedRowKeys="state.expandedRowKeys"
          :size="'small'"
          :columns="columns"
          :loading="state.loading"
          :data-source="person__list"
          :row-selection="{
            selectedRowKeys: state.selectedRowKeys,
            checkStrictly: false,
            onSelect: onSelect,
            onChange: onChange,
            onSelectAll: onSelectAll,
          }"
          :pagination="false"
        />
        <div v-if="!person__list.length" style="height: 300px">
          <a-spin :spinning="true" :tip="t('加载中') + '...'"></a-spin>
        </div>
      </a-layout-content>
    </a-layout>
  </a-modal>
</template>

<script lang="ts">
import { defineComponent, reactive, ref } from 'vue';
import { queryGroupList } from '@/api/sys';
import SearchRender from '@/components/FormRender/SearchRender.vue';
import { SearchConfig } from '@/components/page-model/typing';
import { useForm } from 'ant-design-vue/es/form';
import { useI18n } from 'vue-i18n';

interface DataItem {
  key: number;
  name: string;
  person_id: number;
  children?: DataItem[];
}

// const rowSelection = {
//   onChange: (selectedRowKeys: (string | number)[], selectedRows: DataItem[]) => {
//     console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
//   },
//   onSelect: (record: DataItem, selected: boolean, selectedRows: DataItem[]) => {
//     console.log('record',record, 'selected', selected, 'selectedRows', selectedRows);
//   },
//   onSelectAll: (selected: boolean, selectedRows: DataItem[], changeRows: DataItem[]) => {
//     console.log('selected', selected, 'selectedRows', selectedRows, 'changeRows', changeRows);
//   },
// };
export default defineComponent({
  name: 'PersonModal',
  components: { SearchRender },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    org_id: {
      type: Number,
      required: true,
    },
    role_id: {
      type: Number,
      required: true,
    },
    person_list: {
      type: Array,
      required: true,
    },
  },
  emits: ['ok', 'cancel'],
  setup(props, { emit }) {
    const { t } = useI18n();
    const loading = ref(false);
    const columns = [
      {
        title: t('姓名'),
        dataIndex: 'name',
        key: 'name',
      },
    ];
    const searchConfig: SearchConfig = {
      settings: {
        labelAlign: 'right',
        layout: 'inline',
      },
      fields: [
        {
          type: 'input',
          name: 'name',
          label: '姓名',
          label_i18n: '姓名',
          placeholder_i18n: '请填写姓名',
          disabled: false,
          allowClear: true,
          inputType: 'text',
          defaultValue: '',
          placeholder: '',
        },
      ],
      rules: {},
      model: reactive({
        name: null,
      }),
    };

    const searchConfigOptions = {};
    const { resetFields, validateInfos } = useForm(searchConfig.model, searchConfig.rules);
    const state = reactive<{
      selectedRowKeys: Array<any>; // 选中行数组
      selected: Array<any>;
      expandedRowKeys: string;
      loading: boolean;
    }>({
      selectedRowKeys: [],
      expandedRowKeys: '',
      selected: [],
      loading: true,
    });
    let num: any = 0;
    // 人员数据接口请求
    const person__list: any = ref([]); // table数据(正规格式)
    const search_list = () => {
      person__list.value.length = 0;
      queryGroupList({
        org_id: props.org_id,
        name: searchConfig.model.name,
      }).then(res => {
        state.loading = false;
        if (num == 0) {
          state.selectedRowKeys.length = 0;
          state.selected.length = 0;
        }
        res.data.forEach((org: any) => {
          person__list.value.push(org);
        });
        let list: any[] = [];
        let expandedRowKeys: any[] = [];
        if (props.person_list && props.person_list.length > 0 && num == 0) {
          props.person_list.forEach((item: any) => {
            person__list.value.forEach((item1: any) => {
              if (item.id == item1.person_id) {
                if (list.indexOf(item.id) === -1 && num == 0) {
                  list.push(item.id);
                  state.selected.push(item1);
                }
                state.selectedRowKeys.push(item1.key);
              }
              if (item1.children && item1.children.length > 0) {
                item1.children.forEach((item2: any) => {
                  if (item.id == item2.person_id) {
                    if (list.indexOf(item.id) === -1 && num == 0) {
                      list.push(item.id);
                      state.selected.push(item2);
                    }
                    state.selectedRowKeys.push(item2.key);
                    if (expandedRowKeys.indexOf(item1.key) === -1) {
                      expandedRowKeys.push(item1.key);
                    }
                  }
                });
              }
            });
          });
        } else {
          state.selected.forEach((item: any) => {
            person__list.value.forEach((item1: any) => {
              if (item.person_id == item1.person_id) {
                state.selectedRowKeys.push(item1.key);
              }
              if (item1.children && item1.children.length > 0) {
                item1.children.forEach((item2: any) => {
                  if (item.person_id == item2.person_id) {
                    state.selectedRowKeys.push(item2.key);
                    if (expandedRowKeys.indexOf(item1.key) === -1) {
                      expandedRowKeys.push(item1.key);
                    }
                  }
                });
              }
            });
          });
        }
        num += 1;
        console.log('person__list.value', person__list.value);
        state.expandedRowKeys = expandedRowKeys.join(',');
      });
    };
    search_list();
    const onChange = (selectedRowKeys: []) => {
      console.log('selectedRowKeys changed: ', selectedRowKeys);
      state.selectedRowKeys = selectedRowKeys;
    };
    const onSelectAll = (selected: boolean, selectedRows: DataItem[], changeRows: DataItem[]) => {
      console.log('selected', selected, 'selectedRows', selectedRows, 'changeRows', changeRows);
      let list: any[] = [];
      state.selected.length = 0;
      state.selectedRowKeys.length = 0;
      if (selected) {
        selectedRows.forEach((obj: any) => {
          if (obj.person_id != 0 && list.indexOf(obj.person_id) === -1) {
            list.push(obj.person_id);
            state.selected.push(obj);
          }
          state.selectedRowKeys.push(obj.key);
          if (obj.children && obj.children.length > 0) {
            obj.children.forEach((item2: any) => {
              if (list.indexOf(item2.person_id) === -1) {
                list.push(item2.person_id);
                state.selected.push(item2);
              }
              state.selectedRowKeys.push(item2.key);
            });
          }
        });
      }
    };
    const onSelect = (record: DataItem, selected: boolean, selectedRows: DataItem[]) => {
      console.log('record', record, 'selected', selected, 'selectedRows', selectedRows);
      state.selectedRowKeys.length = 0;
      // state.selected.length = 0;
      let list: any[] = [];
      let selected_keys: any = [];
      state.selected.forEach((item: any) => {
        selected_keys.push(item.key);
      });
      selectedRows.forEach((item: any) => {
        state.selectedRowKeys.push(item.key);
        if (list.indexOf(item.person_id) === -1) {
          list.push(item.person_id);
          // state.selected.push(item);
        }
      });
      if (record.person_id == 0 && record.children && record.children.length > 0 && selected) {
        record.children.forEach((obj: any) => {
          if (state.selectedRowKeys.indexOf(obj.key) === -1) {
            state.selectedRowKeys.push(obj.key);
          }
          if (selected_keys.indexOf(obj.key) === -1) {
            state.selected.push(obj);
          }
          person__list.value.forEach((item1: any) => {
            if (obj.person_id == item1.person_id) {
              if (state.selectedRowKeys.indexOf(item1.key) === -1) {
                state.selectedRowKeys.push(item1.key);
              }
            }
            if (item1.children && item1.children.length > 0) {
              item1.children.forEach((item2: any) => {
                if (obj.person_id == item2.person_id) {
                  if (state.selectedRowKeys.indexOf(item2.key) === -1) {
                    state.selectedRowKeys.push(item2.key);
                  }
                }
              });
            }
          });
        });
      } else if (
        record.person_id == 0 &&
        record.children &&
        record.children.length > 0 &&
        !selected
      ) {
        record.children.forEach((obj: any) => {
          state.selected = state.selected.filter((item: any) => {
            return obj.key != item.key;
          });
          person__list.value.forEach((item1: any) => {
            if (obj.person_id == item1.person_id) {
              state.selectedRowKeys = state.selectedRowKeys.filter((item: any) => {
                return item1.key != item;
              });
              state.selected = state.selected.filter((item2: any) => {
                return item1.key != item2.key;
              });
            }
            if (item1.children && item1.children.length > 0) {
              item1.children.forEach((item2: any) => {
                if (obj.person_id == item2.person_id) {
                  state.selectedRowKeys = state.selectedRowKeys.filter((item: any) => {
                    return item2.key != item;
                  });
                }
              });
            }
          });
        });
      } else if (record.person_id != 0 && !selected) {
        state.selected = state.selected.filter((item: any) => {
          return record.key != item.key;
        });
        person__list.value.forEach((item1: any) => {
          if (record.person_id == item1.person_id) {
            state.selectedRowKeys = state.selectedRowKeys.filter((item: any) => {
              return item1.key != item;
            });
            state.selected = state.selected.filter((item2: any) => {
              return item1.key != item2.key;
            });
          }
          if (item1.children && item1.children.length > 0) {
            item1.children.forEach((item2: any) => {
              if (record.person_id == item2.person_id) {
                state.selectedRowKeys = state.selectedRowKeys.filter((item: any) => {
                  return item2.key != item;
                });
              }
            });
          }
        });
      } else if (record.person_id != 0 && selected) {
        if (selected_keys.indexOf(record.key) === -1) {
          state.selected.push(record);
        }
        person__list.value.forEach((item1: any) => {
          if (record.person_id == item1.person_id) {
            state.selectedRowKeys.push(item1.key);
          }
          if (item1.children && item1.children.length > 0) {
            item1.children.forEach((item2: any) => {
              if (record.person_id == item2.person_id) {
                state.selectedRowKeys.push(item2.key);
              }
            });
          }
        });
      }
      let list1 = state.selected;
      list1.forEach((item: any, index: number) => {
        if (item.person_id == 0) {
          state.selected.splice(index, 1);
        }
      });
    };
    const closeTag = (key: number) => {
      let person_id: number;
      state.selected.forEach((item: any) => {
        if (item.key == key) {
          person_id = item.person_id;
        }
      });
      person__list.value.forEach((item1: any) => {
        if (person_id == item1.person_id) {
          state.selectedRowKeys = state.selectedRowKeys.filter((item: any) => {
            return item1.key != item;
          });
        }
        if (item1.children && item1.children.length > 0) {
          item1.children.forEach((item2: any) => {
            if (person_id == item2.person_id) {
              state.selectedRowKeys = state.selectedRowKeys.filter((item: any) => {
                return item2.key != item;
              });
              state.selectedRowKeys = state.selectedRowKeys.filter((item: any) => {
                return item1.key != item;
              });
            }
          });
        }
      });
      state.selected = state.selected.filter((item: any) => {
        return key != item.key;
      });
    };
    const handleSubmit = (e: Event) => {
      e.preventDefault();
      loading.value = true;
      let list: any[] = [];
      state.selected.forEach((item: any) => {
        if (list.indexOf(item.person_id) === -1) {
          list.push(item.person_id);
        }
      });
      emit('ok', { person_ids: list, role_id: props.role_id, aim_org_id: props.org_id });
      loading.value = false;
    };

    return {
      t,
      props,
      state,
      loading,
      validateInfos,
      resetFields,
      searchConfigOptions,
      person__list,
      handleSubmit,
      search_list,
      searchConfig,
      onChange,
      onSelectAll,
      onSelect,
      closeTag,
      columns,
    };
  },
});
</script>
<style lang="less" scoped>
#components-layout-demo-basic .code-box-demo {
  text-align: center;
}

/*#components-layout-demo-basic .ant-layout-header,*/
/*#components-layout-demo-basic .ant-layout-footer {*/
/*  color: #fff;*/
/*  background: #7dbcea;*/
/*}*/
/*[data-theme='dark'] #components-layout-demo-basic .ant-layout-header {*/
/*  background: #6aa0c7;*/
/*}*/
/*[data-theme='dark'] #components-layout-demo-basic .ant-layout-footer {*/
/*  background: #6aa0c7;*/
/*}*/
/*#components-layout-demo-basic .ant-layout-footer {*/
/*  line-height: 1.5;*/
/*}*/
#components-layout-demo-basic .ant-layout-sider {
  height: 600px;
  color: #fff;
  line-height: 120px;
  background: #fff;
}

[data-theme='dark'] #components-layout-demo-basic .ant-layout-sider {
  background: #fff;
}

#components-layout-demo-basic .ant-layout-content {
  height: 600px;
  color: #fff;
  background: #fff;
}

[data-theme='dark'] #components-layout-demo-basic .ant-layout-content {
  background: #fff;
}
</style>
