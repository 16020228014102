<template>
  <a-popover  title="快速跳转" trigger="click">
    <template #content>
      <a-space :size="3" direction="vertical">
        <a-button type="link" v-for="item in column.linkList" @click="gotoUrl(item.route(record, text))" >
          {{ item.title }}
        </a-button>
      </a-space>

    </template>
    <a-button type="link" >
      {{ column.content?column.content(record, text):text }}
    </a-button>
  </a-popover>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {useRouter} from "vue-router";
export default defineComponent({
  name: 'Cell_link',
  props: {
    column: {
      type: Object,
      required: true,
      default: () => {},
    },
    index: {
      type: Number,
      required: true,
    },
    text: {
      type: [Boolean, String],
      required: false,
      default:()=>'',
    },
    record: {
      type: Object,
      required: true,
      default: () => {},
    },
    pageSize: {
      type: Number,
      required: true,
      default: () => 20,
    },
    current: {
      type: Number,
      required: true,
      default: () => 1,
    },
    dataSource: {
      type: Array,
      required: false,
      default: () => [],
    },
    titleList: {
      type: Object,
      required: true,
    },
    column_normal_btn_list: {
      type: Array,
      required: false,
      default: () => [],
    },
    column_dropdown_btn_dict: {
      type: Object,
      required: false,
    },
    handleOpenEdit: {
      type: Function,
      required: true,
    },
    handleDelete: {
      type: Function,
      required: true,
    },
    handleRecover: {
      type: Function,
      required: true,
    },
    requestParams: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  setup() {
    const router = useRouter();
    const gotoUrl = (route:any) => {
      router.push(route);

    };
    return {
      gotoUrl,
    };
  },
});
</script>
