<template>
  <a-modal
    :maskClosable="false"
    width="1250px"
    :visible="visible"
    @cancel="handleCancel"
    @ok="handleOk"
    :cancelText="t('取消')"
    :okText="t('保存')"
    wrapClassName="custom-form-modal"
    :confirmLoading="confirmLoading"
    :title="modifyModal.title"
  >
    <a-row :gutter="24">
      <a-col :span="15">
        <from-render
          ref="render"
          :form="modifyModal.form"
          :model="modifyModal.form.model"
          :options="modifyModal.options"
          :validateInfos="validateInfos"
        />
      </a-col>
      <a-col :span="9">
        <a-card :body-style="{ padding: 0 }">
          <label :title="t('添加额外项')">
            <a-button type="primary" size="small" @click="add_custom_field">
              <plus-outlined />
              {{ t('添加额外项') }}
            </a-button>
          </label>
          <a-table
            bordered="true"
            :scroll="{}"
            :columns="state.card.columns"
            row-key="id"
            :data-source="state.card.dataSource"
            :pagination="false"
          >
            <template v-for="col in ['index']" #[col]="{ index }" :key="col">
              <span>
                {{ index + 1 }}
              </span>
            </template>
            <template v-for="col in ['custom_field_name']" #[col]="{ record }" :key="col">
              <div>
                <a-input v-model:value="record[col]" style="margin: -5px 0" />
              </div>
            </template>
            <template v-for="col in ['custom_field_value']" #[col]="{ record }" :key="col">
              <div>
                <a-input v-model:value="record[col]" style="margin: -5px 0" />
              </div>
            </template>
            <template v-for="col in ['del']" #[col]="{ record, index }" :key="col">
              <div>
                <close-circle-filled
                  style="font-size: 20px; margin-bottom: -5px; color: #ff0000"
                  @click="del_custom_field(index)"
                />
              </div>
            </template>
          </a-table>
        </a-card>
      </a-col>
    </a-row>
  </a-modal>
</template>

<script lang="ts">
import { defineComponent, reactive, ref, watch } from 'vue';
import { message, notification } from 'ant-design-vue';
import {
  create_payslip,
  delete_extra_field,
  query_contract_list,
  query_extra_field_list,
  query_invoice_list,
  query_paslip_custom_field_list,
  update_payslip,
} from '@/api/cerp-model';
import { get_exchange_rate } from '@/api/baseinfo-model';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import { CloseCircleFilled, PlusOutlined } from '@ant-design/icons-vue';
import { event_obj } from '@/components/page-model2/cerp/cerp-invoice-config.ts';
import { useForm } from 'ant-design-vue/es/form';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'PayslipModal',
  props: {
    visible: {
      type: Boolean,
      required: true,
      default: () => false,
    },
    obj: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  emits: ['ok', 'cancel'],
  setup(props, { emit }) {
    const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
    const { t } = useI18n();
    const cardDataSource: Array<any> = reactive([]);
    if (props.type == 'update' && props.obj.customfield_list.length > 0) {
      cardDataSource.push(...props.obj.customfield_list);
    }
    const is_bank = ref(false);
    const extra_ids: any = ref([]);
    const local_account: any = ref(null);
    const local_deduct_fee: any = ref(null);
    const is_insurance_payment = ref(false);
    const confirmLoading = ref<boolean>(false);
    const modal: any = reactive({
      org_id: current_org?.id,
      is_active: true,
      adviser_id: props.type == 'update' ? props.obj.adviser_id : props.obj.adviser_id,
      pay_together_status: props.type == 'update' ? props.obj.pay_together_status : 0,
      adviser__full_name:
        props.type == 'update' ? props.obj.adviser__full_name : props.obj.adviser__full_name,
      invoice_no: props.type == 'update' ? props.obj.invoice__invoice_no : props.obj.invoice_no,
      pay_currency_id:
        props.type == 'update' ? props.obj.pay_currency_id : props.obj.adviser__pay_currency_id,
      exchange_rate: props.type == 'update' ? props.obj.exchange_rate : null,
      invoice_id: props.type == 'update' ? props.obj.invoice_id : props.obj.id,
      bank_charge_cost:
        props.type == 'update' ? props.obj.bank_charge_cost : props.obj.bank_charge_cost,
      transaction_id: props.type == 'update' ? props.obj.transaction_id : props.obj.transaction_id,
      cc_bank_account_id:
        props.type == 'update'
          ? props.obj.cc_bank_account_id
          : props.obj.adviser__cc_bank_account_id,
      contract_id: props.type == 'update' ? props.obj.invoice__contract_id : props.obj.contract_id,
      referral_fees: props.type == 'update' ? props.obj.referral_fees : props.obj.referral_fees,
      insurance_fee: props.type == 'update' ? props.obj.insurance_fee : props.obj.insurance_fee,
      is_auto_send: props.type == 'update' ? props.obj.is_auto_send : true,
      send_date: props.type == 'update' ? props.obj.send_date : 2,
      bank_account_id:
        props.type == 'update' ? props.obj.bank_account_id : props.obj.bank_account_id,
      payment_term: props.type == 'update' ? props.obj.payment_term : props.obj.payment_term,
      overtime: props.type == 'update' ? props.obj.invoice__overtime : props.obj.overtime,
      overtime_amount:
        props.type == 'update' ? props.obj.invoice__overtime_amount : props.obj.overtime_amount,
      accommodation_amount:
        props.type == 'update'
          ? props.obj.invoice__accommodation_amount
          : props.obj.accommodation_amount,
      accommodation_unit:
        props.type == 'update'
          ? props.obj.invoice__accommodation_unit
          : props.obj.accommodation_unit,
      work_unit: props.type == 'update' ? props.obj.invoice__work_unit : props.obj.work_unit,
      basic_amount:
        props.type == 'update' ? props.obj.invoice__basic_amount : props.obj.basic_amount,
      invoice_start_date:
        props.type == 'update' ? props.obj.invoice_start_date : props.obj.invoice_start_date,
      service_month:
        props.type == 'update' ? props.obj.invoice__service_month : props.obj.service_month,
      invoice_type: props.type == 'update' ? props.obj.invoice_type : props.obj.invoice_type,
      payslip_id: props.type == 'update' ? props.obj.id : null,
      remark: props.type == 'update' ? props.obj.remark : null,
    });

    const modifyModal = reactive({
      title: t('工资单'),
      form: {
        settings: {
          labelAlign: 'right',
          layout: 'vertical',
          col: [17, 17, 17],
        },
        fields: [
          {
            type: 'input',
            name: 'adviser__full_name',
            label: '顾问',
            label_i18n: '顾问',
            placeholder_i18n: '请选择隶属顾问',
            disabled: true,
            placeholder: '请选择隶属顾问',
            datasourceType: 'remote',
            allowClear: false,
          },
          {
            type: 'title',
            name: 'Invoice Information',
          },
          {
            type: 'datepicker',
            name: 'invoice_start_date',
            label: '请款起始日期',
            label_i18n: '请款起始日期',
            placeholder_i18n: '请款起始日期',
            disabled: true,
            allowClear: true,
            inputType: 'number',
            defaultValue: '',
            showTime: true,
            valueFormat: 'YYYY-MM-DD',
            format: 'YYYY-MM-DD',
            placeholder: '请款起始日期',
          },
          {
            type: 'money',
            name: 'basic_rate',
            label: '基本单位薪资',
            label_i18n: '基本单位薪资',
            placeholder_i18n: '基本单位薪资',
            disabled: true,
            datasourceType: 'remote',
            allowClear: false,
          },
          {
            type: 'input',
            name: 'cc_management_fee_rate',
            label: 'cc管理费率（%）',
            label_i18n: 'cc管理费率（%）',
            placeholder_i18n: 'cc管理费率（%）',
            disabled: true,
            datasourceType: 'remote',
            allowClear: false,
          },
          {
            type: 'input',
            name: 'accommodation_rate',
            label: '住宿费率',
            label_i18n: '住宿费率',
            placeholder_i18n: '住宿费率',
            disabled: true,
            datasourceType: 'remote',
            allowClear: false,
          },
          {
            type: 'select',
            name: 'management_fee_payment_flag',
            label: '管理费支付方式',
            label_i18n: '管理费支付方式',
            placeholder_i18n: '管理费支付方式',
            mode: 'default',
            disabled: true,
            defaultValue: '',
            labelKey: 'name',
            valueKey: 'value',
            datasource: 'management_fee_payment_flag',
          },
          {
            type: 'number',
            name: 'work_unit',
            label: '普通工时单位',
            label_i18n: '普通工时单位',
            placeholder_i18n: '请填写普通工时单位',
            disabled: true,
            allowClear: true,
            inputType: 'number',
            defaultValue: 0,
            placeholder: '请填写普通工时单位',
          },
          {
            type: 'money',
            name: 'basic_amount',
            label: '基本工资总额',
            label_i18n: '基本工资总额',
            placeholder_i18n: '请填写基本工资总额',
            disabled: true,
            allowClear: true,
            defaultValue: 0,
            placeholder: '请填写基本工资总额',
          },
          {
            type: 'money',
            name: 'bank_charge_cost',
            label: '银行转账费',
            label_i18n: '银行转账费',
            placeholder_i18n: '请填写银行转账费',
            disabled: false,
            allowClear: true,
            inputType: 'number',
            defaultValue: '',
            placeholder: '请填写银行转账费',
          },
          {
            type: 'title',
            name: 'Local Salary',
          },
          {
            type: 'money',
            name: 'local_management_fee_fixed',
            label: '本地发薪工资',
            label_i18n: '本地发薪工资',
            disabled: true,
            datasourceType: 'remote',
            allowClear: false,
          },
          {
            type: 'money',
            name: 'local_deduct_fee',
            label: '本地扣减费',
            label_i18n: '本地扣减费',
            disabled: true,
            datasourceType: 'remote',
            allowClear: false,
          },
          {
            type: 'title',
            name: 'Additional Information',
          },
          {
            type: 'money',
            name: 'referral_fees',
            label: '推荐费',
            label_i18n: '推荐费',
            placeholder_i18n: '请填写推荐费',
            disabled: false,
            allowClear: true,
            inputType: 'number',
            defaultValue: '',
            placeholder: '请填写推荐费',
          },
          {
            type: 'remoteselect',
            name: 'cc_bank_account_id',
            label: 'CC付款账户',
            label_i18n: 'CC付款账户',
            placeholder_i18n: '请选择CC付款账户',
            disabled: false,
            placeholder: '请选择CC付款账户',
            datasourceType: 'remote',
            mode: 'default',
            allowClear: true,
            labelKey: 'account_flag',
            valueKey: 'id',
            modalType: 'baseinfo-ccbankaccountscc',
          },
          {
            type: 'textarea',
            name: 'remark',
            label: '备注',
            label_i18n: '备注',
            placeholder_i18n: '请填写备注',
            disabled: false,
            allowClear: true,
            inputType: 'text',
            maxlength: 200,
            defaultValue: '',
            placeholder: '请填写备注',
          },
          {
            type: 'remoteselect',
            name: 'contract_id',
            label: '合同',
            label_i18n: '合同',
            placeholder_i18n: '请选择合同',
            disabled: true,
            placeholder: '请选择合同',
            datasourceType: 'remote',
            mode: 'default',
            allowClear: true,
            init_model: { adviser_id: props.obj.adviser_id },
            labelKeyList: ['start_date', 'end_date'],
            valueKey: 'id',
            modalType: 'cerp-contract',
          },
          {
            type: 'kong_title',
            height: 30,
          },
          {
            type: 'monthpicker',
            name: 'service_month',
            label: '服务月份',
            label_i18n: '服务月份',
            placeholder_i18n: '请填写服务月份',
            disabled: true,
            allowClear: true,
            inputType: 'number',
            defaultValue: '',
            showTime: false,
            valueFormat: 'YYYY-MM-DD',
            format: 'YYYY-MM',
            placeholder: '请填写服务月份',
          },
          {
            type: 'remoteselect',
            name: 'currency_id',
            labelKeyList: ['code', 'currency'],
            label: '合同薪资币种',
            label_i18n: '合同薪资币种',
            placeholder_i18n: '请选择合同薪资币种',
            disabled: true,
            placeholder: '请选择合同薪资币种',
            datasourceType: 'remote',
            mode: 'default',
            allowClear: true,
            modalType: 'baseinfo-country',
          },
          {
            type: 'money',
            name: 'cc_management_fee',
            label: 'cc管理费',
            label_i18n: 'cc管理费',
            disabled: true,
            datasourceType: 'remote',
            allowClear: false,
          },
          {
            type: 'input',
            name: 'overtime_rate',
            label: '加班费率',
            label_i18n: '加班费率',
            disabled: true,
            datasourceType: 'remote',
            allowClear: false,
          },
          {
            type: 'select',
            name: 'is_bank_charge_payment',
            label: '银行转账费支付方式',
            label_i18n: '银行转账费支付方式',
            mode: 'default',
            disabled: true,
            defaultValue: '',
            labelKey: 'name',
            valueKey: 'value',
            datasource: 'is_bank_charge_payment',
          },
          {
            type: 'number',
            name: 'overtime',
            label: '加班单位',
            label_i18n: '加班单位',
            placeholder_i18n: '请填写加班单位',
            disabled: true,
            allowClear: true,
            inputType: 'number',
            defaultValue: 0,
            placeholder: '请填写加班单位',
          },
          {
            type: 'number',
            name: 'overtime_amount',
            label: '加班总额',
            label_i18n: '加班总额',
            placeholder_i18n: '请填写加班总额',
            disabled: true,
            allowClear: true,
            inputType: 'number',
            defaultValue: 0,
            placeholder: '请填写加班总额',
          },
          {
            type: 'kong',
          },
          {
            type: 'kong_title',
            height: 30,
          },
          {
            type: 'money',
            name: 'local_management_fee',
            label: '本地管理费',
            label_i18n: '本地管理费',
            disabled: true,
            datasourceType: 'remote',
            allowClear: false,
          },
          {
            type: 'input',
            name: 'local_management_fee_rate',
            label: '本地管理费率（%）',
            label_i18n: '本地管理费率（%）',
            disabled: true,
            datasourceType: 'remote',
            allowClear: false,
          },
          {
            type: 'kong_title',
            height: 32,
          },
          {
            type: 'radio',
            name: 'is_auto_send',
            label: '邮件是否自动发送',
            label_i18n: '邮件是否自动发送',
            disabled: false,
            labelKey: 'name',
            valueKey: 'value',
            defaultValue: '',
          },
          {
            type: 'number',
            name: 'send_date',
            label: '邮件发送时间（n天后）',
            label_i18n: '邮件发送时间（n天后）',
            placeholder_i18n: '请填写邮件发送时间',
            disabled: false,
            allowClear: true,
            inputType: 'number',
            defaultValue: '',
            placeholder: '请填写邮件发送时间',
          },
          {
            type: 'select',
            name: 'pay_together_status',
            label: '合并付款状态',
            label_i18n: '合并付款状态',
            mode: 'default',
            disabled: props.type == 'update',
            defaultValue: '',
            labelKey: 'name',
            valueKey: 'value',
            datasource: 'pay_together_status',
          },
          {
            type: 'input',
            name: 'invoice_no',
            label: '请款单',
            label_i18n: '请款单',
            placeholder_i18n: '请款单',
            disabled: true,
            placeholder: '请选择请款单',
            datasourceType: 'remote',
            allowClear: false,
          },
          {
            type: 'kong_title',
            height: 30,
          },
          {
            type: 'select',
            name: 'invoice_type',
            label: '请款类型',
            label_i18n: '请款类型',
            placeholder_i18n: '请选择请款类型',
            mode: 'default',
            disabled: true,
            defaultValue: '',
            labelKey: 'name',
            valueKey: 'value',
            placeholder: '请选择请款类型',
            datasource: 'invoice_type',
          },
          {
            type: 'select',
            name: 'rate_type',
            label: '薪资种类',
            label_i18n: '薪资种类',
            mode: 'default',
            disabled: true,
            defaultValue: '',
            labelKey: 'name',
            valueKey: 'value',
            datasource: 'rate_type',
          },
          {
            type: 'input',
            name: 'management_fee_formula__name',
            label: '管理费计算方式',
            label_i18n: '管理费计算方式',
            disabled: true,
            datasourceType: 'remote',
            allowClear: false,
          },
          {
            type: 'money',
            name: 'insurance_fee',
            label: '保险费',
            label_i18n: '保险费',
            placeholder_i18n: '请填写保险费',
            disabled: false,
            allowClear: true,
            inputType: 'number',
            defaultValue: '',
            placeholder: '请填写保险费',
          },
          {
            type: 'select',
            name: 'insurance_payment_flag',
            label: '保险费支付方式',
            label_i18n: '保险费支付方式',
            mode: 'default',
            disabled: true,
            defaultValue: '',
            labelKey: 'name',
            valueKey: 'value',
            datasource: 'insurance_payment_flag',
          },
          {
            type: 'number',
            name: 'accommodation_unit',
            label: '宿款单位',
            label_i18n: '宿款单位',
            placeholder_i18n: '请填写宿款单位',
            disabled: true,
            allowClear: true,
            inputType: 'number',
            defaultValue: 0,
            placeholder: '请填写宿款单位',
          },
          {
            type: 'money',
            name: 'accommodation_amount',
            label: '住宿总额',
            label_i18n: '住宿总额',
            placeholder_i18n: '请填写住宿总额',
            disabled: true,
            allowClear: true,
            inputType: 'number',
            defaultValue: 0,
            placeholder: '请填写住宿总额',
          },
          {
            type: 'kong',
          },
          {
            type: 'kong_title',
            height: 30,
          },
          {
            type: 'number',
            name: 'exchange_rate',
            label: '本地管理费对应汇率（本地:合同）',
            label_i18n: '本地管理费对应汇率（本地:合同）',
            disabled: false,
            datasourceType: 'remote',
            allowClear: false,
          },
          {
            type: 'kong',
          },
          {
            type: 'kong_title',
            height: 30,
          },
          {
            type: 'remoteselect',
            name: 'pay_currency_id',
            labelKeyList: ['code', 'currency'],
            label: '付款币种',
            label_i18n: '付款币种',
            placeholder_i18n: '请选择付款币种',
            disabled: false,
            placeholder: '请选择付款币种',
            datasourceType: 'remote',
            mode: 'default',
            allowClear: true,
            modalType: 'baseinfo-country',
          },
        ],
        rules: {},
        model: modal,
        watch: {
          is_auto_send: (data: any) => {
            if (data['is_auto_send']) {
              data['send_date'] = 2;
            } else {
              data['send_date'] = null;
            }
          },
        },
      },
      options: {
        pay_together_status: [
          { value: 0, name: t('无') },
          { value: 1, name: t('同下次薪资合并') },
          { value: 2, name: t('暂且不付') },
          { value: 3, name: t('合并所有历史付款') },
          { value: 4, name: t('分开支付') },
        ],
        rate_type: [
          { value: 0, name: t('日薪') },
          { value: 1, name: t('月薪') },
          { value: 2, name: t('年薪') },
          { value: 3, name: t('时薪') },
          { value: 4, name: t('周薪') },
        ],
        is_bank_charge_payment: [
          { value: true, name: t('顾问支付') },
          { value: false, name: t('顾问公司支付') },
        ],
        insurance_payment_flag: [
          { value: -1, name: t('无') },
          { value: 0, name: t('顾问支付') },
          { value: 1, name: t('顾问公司支付') },
        ],
        management_fee_payment_flag: [
          { value: 0, name: t('顾问支付') },
          { value: 1, name: t('顾问公司支付') },
        ],
        invoice_type: [
          { value: 1, name: t('工资') },
          { value: 2, name: t('报销') },
          { value: 3, name: 'credit note' },
          { value: 4, name: t('加班') },
          { value: 5, name: t('CC报销') },
        ],
        is_auto_send: [
          { value: true, name: t('是') },
          { value: false, name: t('否') },
        ],
      },
    });
    const { resetFields, validate, validateInfos } = useForm(modal, modifyModal.form.rules);
    if (props.type != 'update' ? props.obj.contract_id : props.obj.invoice__contract_id) {
      query_contract_list({
        org_id: current_org.id,
        ids:
          props.type != 'update'
            ? [props.obj.contract_id].join(',')
            : [props.obj.invoice__contract_id].join(','),
      }).then((res: any) => {
        if (res.data[0].is_bank_charge_payment) {
          is_bank.value = true;
        } else {
          is_bank.value = false;
        }
        if (res.data[0].insurance_payment_flag == -1 || res.data[0].insurance_payment_flag == 0) {
          is_insurance_payment.value = true;
        } else {
          is_insurance_payment.value = false;
        }
        if (res && res.data && res.data.length > 0) {
          modal.remark1 = modal.remark;
          modal.bank_charge_cost = res.data[0].consultant_bank_charge;
          Object.assign(modal, res.data[0]);
          modal.remark = modal.remark1;
          if (props.type != 'update') {
            if (
              res.data[0].local_pay_currency__currency &&
              res.data[0].currency__currency &&
              res.data[0].local_pay_currency__currency == res.data[0].currency__currency
            ) {
              modal.exchange_rate = 1;
            }
            if (res.data[0].insurance_payment_flag != -1 && modal.invoice_type == 1) {
              modal.insurance_fee = res.data[0].insurance_fee;
            } else {
              modal.insurance_fee = 0;
            }
            if (modal.invoice_type != 1) {
              modal.bank_charge_cost = 0;
            }
            if (res.data[0].currency_id && modal.service_month && modal.local_pay_currency_id) {
              get_exchange_rate({
                org_id: current_org.id,
                service_month: modal.service_month,
                local_currency_id: modal.local_pay_currency_id,
                currency_id: modal.currency_id,
              }).then((res1: any) => {
                if (res1) {
                  if (modal.invoice_type == 1) {
                    modal.exchange_rate = res1.rate;
                  } else {
                    modal.exchange_rate1 = res1.rate;
                  }
                }
              });
            }
          }
        }
        if (modal.invoice_type == 2 || modal.invoice_type == 3) {
          is_bank.value = false;
          is_insurance_payment.value = false;
          if (props.type != 'update') {
            modal['bank_charge_cost'] = 0;
            modal['insurance_fee'] = 0;
          }
        }
      });
    } else {
      notification.error({
        message: t('错误'),
        description: t('无对应合同，请创建!'),
      });
    }
    query_invoice_list({
      org_id: current_org.id,
      ids: props.obj.invoice_id ? [props.obj.invoice_id] : [props.obj.id],
    }).then((res: any) => {
      if (res && res.data && res.data.length > 0) {
        modal.basic_amount = res.data[0].basic_amount;
        modal.work_unit = res.data[0].work_unit;
        modal.accommodation_unit = res.data[0].accommodation_unit;
        modal.accommodation_amount = res.data[0].accommodation_amount;
        modal.overtime = res.data[0].overtime;
        modal.invoice_type = res.data[0].invoice_type;
        if (res.data[0].invoice_type >= 2) {
          modal.insurance_fee = 0;
        }
        if (res.data[0].customfield_list.length > 0 && props.type != 'update') {
          res.data[0].customfield_list.forEach((item: any) => {
            if (item.is_show) {
              cardDataSource.push(item);
            }
          });
          if (cardDataSource.length == 0) {
            cardDataSource.push({
              custom_field_name: null,
              custom_field_value: null,
              extra_id: null,
            });
          }
        } else {
          cardDataSource.push({
            custom_field_name: null,
            custom_field_value: null,
            extra_id: null,
          });
        }
        if (props.type != 'update') {
          cardDataSource.push({
            // 创建抓取 合同的 个税和社保代缴
            custom_field_name: 'Income Tax',
            custom_field_value: `-${res.data[0].contract__income_tax}`,
            extra_id: null,
          });
          cardDataSource.push({
            custom_field_name: 'Social Insurance',
            custom_field_value: `-${res.data[0].contract__social_insurance}`,
            extra_id: null,
          });
          console.log('invoice_type:', res.data[0].invoice_type);
          if (res.data[0].invoice_type == 1) {
            query_extra_field_list({
              org_id: current_org.id,
              adviser_id: props.obj.adviser_id,
              month: props.obj.service_month,
              type: 1,
              is_finished: false,
              is_active: true,
            }).then((res: any) => {
              if (res.data.length > 0) {
                let list: any = [];
                list = cardDataSource.filter((item: any) => !item.extra_id);
                cardDataSource.length = 0;
                if (list.length > 0) {
                  list.map((item: any) => {
                    cardDataSource.push(item);
                  });
                }
                res.data.map((item: any) => {
                  cardDataSource.push({
                    custom_field_name: item.custom_field_name,
                    custom_field_value: item.custom_field_value,
                    extra_id: item.id,
                  });
                });
              }
            });
          }
        }
      }
    });
    watch(
      () => modal.exchange_rate,
      () => {
        if (modal.local_management_fee_fixed != 0) {
          local_account.value = -(modal.local_management_fee_fixed / modal.exchange_rate).toFixed(
            2,
          );
        }
        if (modal.local_deduct_fee != 0) {
          local_deduct_fee.value = -(modal.local_deduct_fee / modal.exchange_rate).toFixed(2);
        }
        get_cardDataSource();
      },
      { deep: true },
    );
    const get_cardDataSource = () => {
      cardDataSource.map((item: any, i: any) => {
        if (item.custom_field_name && item.custom_field_name.split('(')[0] == 'Local Account') {
          cardDataSource.splice(i, 1);
        } else if (!item.custom_field_name) {
          cardDataSource.splice(i, 1);
        }
      });
      cardDataSource.map((item: any, i: any) => {
        if (item.custom_field_name && item.custom_field_name == 'Local Deduction') {
          cardDataSource.splice(i, 1);
        } else if (!item.custom_field_name) {
          cardDataSource.splice(i, 1);
        }
      });
      if (local_account.value) {
        cardDataSource.push({
          custom_field_name: 'Local Account(' + modal.local_management_fee_fixed + ')',
          custom_field_value: local_account.value,
          extra_id: null,
        });
      }
      if (local_deduct_fee.value) {
        cardDataSource.push({
          custom_field_name: 'Local Deduction',
          custom_field_value: local_deduct_fee.value,
          extra_id: null,
        });
      }
    };
    if (props.type != 'update') {
      query_paslip_custom_field_list({
        org_id: current_org.id,
        invoice_id: props.obj.id,
      }).then((res: any) => {
        if (res && res.data && res.data.length > 0) {
          res.data.forEach((item: any) => {
            cardDataSource.push({
              custom_field_name: item.custom_field_name,
              custom_field_value: item.custom_field_value,
              extra_id: item.extra_id,
            });
          });
        }
      });
    }
    const state = {
      card: {
        columns: [
          {
            title: t('额外项描述'),
            dataIndex: 'custom_field_name',
            key: 'custom_field_name',
            width: '160px',
            slots: { customRender: 'custom_field_name' },
          },
          {
            title: t('额外项值'),
            dataIndex: 'custom_field_value',
            key: 'custom_field_value',
            width: '90px',
            slots: { customRender: 'custom_field_value' },
          },
          {
            title: '',
            dataIndex: 'del',
            key: 'del',
            width: '5px',
            slots: { customRender: 'del' },
          },
        ],
        dataSource: cardDataSource,
      },
    };
    const handleCancel = () => {
      emit('cancel');
    };
    const handleOk = () => {
      validate().then(() => {
        if (props.type == 'update') {
          confirmLoading.value = true;
          return new Promise(function (resolve) {
            const list: any = [...cardDataSource];
            let i = 0;
            list.map((item: any, index: any) => {
              if (!item.custom_field_name) {
                cardDataSource.splice(index - i, 1);
                i++;
              }
            });
            if (
              cardDataSource &&
              cardDataSource.length > 0 &&
              cardDataSource[0].custom_field_name
            ) {
              update_payslip({
                ...modifyModal.form.model,
                customfield_json: JSON.stringify(cardDataSource),
              })
                .then((res: any) => {
                  if (res.message) {
                    message.error(res.message);
                  } else {
                    message.success(t('修改成功'));
                  }
                  emit('ok');
                  if (extra_ids.value.length > 0) {
                    extra_ids.value.map((item: any) => {
                      delete_extra_field({
                        org_id: current_org.id,
                        extra_field_id: item,
                      }).then(() => {});
                    });
                  }
                  event_obj.emit('refresh');
                  resolve(null);
                })
                .finally(() => {
                  confirmLoading.value = false;
                });
            } else {
              update_payslip({
                ...modifyModal.form.model,
              })
                .then((res: any) => {
                  if (res.message) {
                    message.error(res.message);
                  } else {
                    message.success(t('修改成功'));
                  }
                  emit('ok');
                  if (extra_ids.value.length > 0) {
                    extra_ids.value.map((item: any) => {
                      delete_extra_field({
                        org_id: current_org.id,
                        extra_field_id: item,
                      }).then(() => {});
                    });
                  }
                  event_obj.emit('refresh');
                  resolve(null);
                })
                .finally(() => {
                  confirmLoading.value = false;
                });
            }
          });
        } else {
          confirmLoading.value = true;
          return new Promise(function (resolve) {
            const list: any = [...cardDataSource];
            let i = 0;
            list.map((item: any, index: any) => {
              if (!item.custom_field_name) {
                cardDataSource.splice(index - i, 1);
                i++;
              }
            });
            if (
              cardDataSource &&
              cardDataSource.length > 0 &&
              cardDataSource[0].custom_field_name
            ) {
              create_payslip({
                ...modifyModal.form.model,
                customfield_json: JSON.stringify(cardDataSource),
              })
                .then((res: any) => {
                  if (res.message) {
                    message.error(res.message);
                  } else {
                    if (res.tip) {
                      message.success(res.tip);
                    }
                    message.success(t('创建成功'));
                  }
                  emit('ok', res.id);
                  if (extra_ids.value.length > 0) {
                    extra_ids.value.map((item: any) => {
                      delete_extra_field({
                        org_id: current_org.id,
                        extra_field_id: item,
                      }).then(() => {});
                    });
                  }
                  event_obj.emit('refresh');
                  resolve(null);
                })
                .finally(() => {
                  confirmLoading.value = false;
                });
            } else {
              create_payslip({
                ...modifyModal.form.model,
              })
                .then((res: any) => {
                  if (res.message) {
                    message.error(res.message);
                  } else {
                    if (res.tip) {
                      message.success(res.tip);
                    }
                    message.success(t('创建成功'));
                  }
                  emit('ok', res.id);
                  if (extra_ids.value.length > 0) {
                    extra_ids.value.map((item: any) => {
                      delete_extra_field({
                        org_id: current_org.id,
                        extra_field_id: item,
                      }).then(() => {});
                    });
                  }
                  event_obj.emit('refresh');
                  resolve(null);
                })
                .finally(() => {
                  confirmLoading.value = false;
                });
            }
          });
        }
      });
    };
    const add_custom_field = () => {
      if (cardDataSource.length >= 9) {
        notification.error({
          message: t('提示'),
          description: t('工资单自定义项最多9个'),
        });
      } else {
        cardDataSource.push({
          custom_field_name: null,
          custom_field_value: null,
          extra_id: null,
        });
      }
    };
    const del_custom_field = (index: any) => {
      if (cardDataSource[index].extra_id) {
        extra_ids.value.push(cardDataSource[index].extra_id);
      }
      cardDataSource.splice(index, 1);
    };
    return {
      message,
      t,
      state,
      modifyModal,
      confirmLoading,
      validateInfos,
      add_custom_field,
      del_custom_field,
      handleCancel,
      handleOk,
    };
  },
  components: {
    PlusOutlined,
    CloseCircleFilled,
  },
});
</script>
