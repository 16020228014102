<template>
  <div>
    <a-tag color="green" v-if="text === 0">
      <span class="large-font">定尺</span>
    </a-tag>
    <a-tag color="red" v-if="text === 1">
      <span class="large-font">非定尺</span>
    </a-tag>
    <a-tag color="red" v-if="text === 2">
      <span class="large-font">短尺</span>
    </a-tag>
    <a-tag color="red" v-if="text === 3">
      <span class="large-font">收集定尺</span>
    </a-tag>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
export default defineComponent({
  name: 'Cell_collect_status',
  props: {
    column: {
      type: Object,
      required: true,
      default: () => {},
    },
    index: {
      type: Number,
      required: true,
    },
    text: {
      type: [String, Number],
      required: true,
    },
    record: {
      type: Object,
      required: true,
      default: () => {},
    },
    pageSize: {
      type: Number,
      required: true,
      default: () => 20,
    },
    current: {
      type: Number,
      required: true,
      default: () => 1,
    },
  },
});
</script>
<style lang="less">
.needCopyRow {
  td {
    position: relative;
  }
}
.large-font {
  font-size: 17px;
  font-weight: 500;
}
.large-number-font {
  font-size: 24px;
  font-weight: 500;
}
</style>