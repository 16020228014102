import { create_payslip, delete_payslip, query_payslip_list, recover_payslip, update_payslip } from '@/api/cerp-model';
import { TableColumn } from '@/typing';
import { Record, SearchConfig } from '@/components/page-model/typing';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import {
  btn_list,
  config,
  event_obj,
  head_btn_list,
  slotList,
} from '@/components/page-model2/cerp/cerp-payslip-config';

import { reactive } from 'vue';
import { notification } from 'ant-design-vue';
import router from '@/router';
import { getDate, getDateTime, getMonth } from '@/utils/function';
import { useI18n } from 'vue-i18n';

const None = null;
const True = true;
const False = false;

const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
const { t } = useI18n();
const search_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'inline',
  },
  fields: [
    {
      type: 'remotemodal',
      name: 'adviser_id',
      label: '隶属顾问',
      label_i18n: '隶属顾问',
      placeholder_i18n: '请选择隶属顾问',
      disabled: false,
      defaultValue: '',
      labelKey: 'full_name',
      valueKey: 'id',
      placeholder: '隶属顾问',
      show_search: false,
      maxTagCount: 5,
      mode: 'default',
      title: t('选择隶属顾问'),
      modalType: 'cerp-adviser',
    },
    {
      type: 'remoteselect',
      name: 'invoice_id',
      label: '请款单',
      label_i18n: '请款单',
      placeholder_i18n: '请款单',
      disabled: false,
      placeholder: '请选择请款单',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      labelKey: 'invoice_no',
      valueKey: 'id',
      modalType: 'cerp-invoice',
    },
    {
      type: 'select',
      name: 'is_active',
      label: '状态',
      label_i18n: '状态',
      mode: 'default',
      disabled: false,
      defaultValue: '',
      labelKey: 'name',
      valueKey: 'value',
      placeholder: '请选择状态',
      datasource: 'is_active',
    },
  ],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    invoice_id: null,
    payment_method_id: null,
    adviser_id: null,
  }),
};
const search_options = {
  is_active: [
    { value: None, name: '全部' },
    { value: True, name: '可用' },
    { value: False, name: '禁用' },
  ],
};

const edit_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [
    {
      type: 'datepicker',
      name: 'date',
      label: '收到日期',
      label_i18n: '收到日期',
      placeholder_i18n: '请填写收到日期',
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      showTime: false,
      valueFormat: 'YYYY-MM-DD',
      placeholder: '请填写收到日期',
    },
    {
      type: 'remoteselect',
      name: 'adviser_id',
      label: '隶属顾问',
      label_i18n: '隶属顾问',
      placeholder_i18n: '请选择隶属顾问',
      disabled: false,
      placeholder: '请选择隶属顾问',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      labelKey: 'full_name',
      valueKey: 'id',
      modalType: 'cerp-adviser',
    },
    {
      type: 'input',
      name: 'custom_4',
      label: '自定义4',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写自定义4',
    },
    {
      type: 'money',
      name: 'referral_fees',
      label: '推荐费',
      label_i18n: '推荐费',
      placeholder_i18n: '请填写推荐费',
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      placeholder: '请填写推荐费',
    },
    {
      type: 'input',
      name: 'remark',
      label: '备注',
      label_i18n: '备注',
      placeholder_i18n: '请填写备注',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写备注',
    },
    {
      type: 'remoteselect',
      name: 'cc_bank_account_id',
      label: 'CC银行账户',
      label_i18n: 'CC银行账户',
      placeholder_i18n: '请选择CC银行账户',
      disabled: false,
      placeholder: '请选择CC银行账户',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      labelKey: 'account_flag',
      valueKey: 'id',
      modalType: 'baseinfo-ccbankaccountscc',
    },
    {
      type: 'money',
      name: 'bank_charge_cost',
      label: '银行转账费',
      label_i18n: '银行转账费',
      placeholder_i18n: '请填写银行转账费',
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      placeholder: '请填写银行转账费',
    },
    {
      type: 'remoteselect',
      name: 'transaction_id',
      label: '银行流水',
      label_i18n: '银行流水',
      placeholder_i18n: '请填写银行流水',
      disabled: false,
      placeholder: '请选择银行流水',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'cerp-banktransaction',
    },
  ],
  rules: {
    custom_4: [{ required: true, message: '请填输入自定义4' }],
    remark: [{ required: true, message: '请填输入备注' }],
  },
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    date: null,
    invoice_id: null,
    custom_4: null,
    referral_fees: null,
    remark: null,
    cc_bank_account_id: null,
    bank_charge_cost: null,
    transaction_id: null,
  }),
};
const edit_model_config_options = {};

const update_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [],
  rules: {},
  model: reactive({}),
};
const update_model_config_options = {};

const titleList = {
  title: '工资单管理',
  addModalTitle: '新建工资单',
  updateTitle: '修改工资单',
  is_create: false, // 是否有【新建】按钮
  is_update: false, // 是否有【修改】按钮
  is_remove: true, // 是否有【删除】按钮
  is_recover: true, // 是否有【恢复】按钮
};

const api_fields: Record = {
  update: {
    payslip_id: 'id',
  },
  delete: {
    payslip_id: 'id',
  },
  recover: {
    payslip_id: 'id',
  },
  updateData: {
    org_id: 'org_id',
    payslip_id: 'id',
  },
};

const baseColumns: TableColumn[] = [
  {
    title: t('序号'),
    dataIndex: 'index',
    width: 50,
    fixed: 'left',
  },
  {
    title: 'ID',
    width: 150,
    dataIndex: 'id',
    checked: false,
  },
  {
    title: t('顾问'),
    width: 150,
    dataIndex: 'adviser__full_name',
    checked: true,
    sorter: {
      multiple: 1,
    },
    fixed: 'left',
  },
  {
    title: t('请款单'),
    width: 200,
    dataIndex: 'invoice__invoice_no',
    checked: true,
    fixed: 'left',
    cellComponent: 'click',
    content: (record: any) => {
      return record.invoice__invoice_no;
    },
    cb: (record: any) => {
      router.push({
        path: '/adviser-manage/cerp-invoice',
        query: {id: record.invoice_id, t: (new Date()).getTime()}
      });
    },
  },
  {
    title: t('工号'),
    width: 80,
    dataIndex: 'adviser__employee_number',
    checked: true,
  },
  {
    title: t('是否可用'),
    width: 150,
    dataIndex: 'is_active',
    checked: true,
    cellComponent: 'boolean',
    name1: t('可用'),
    name2: t('禁用'),
    filterMultiple: false,
    filters: [
      { text: t('全部'), value: '' },
      { text: t('可用'), value: true },
      { text: t('禁用'), value: false },
    ],
  },
  {
    title: t('状态'),
    width: 100,
    dataIndex: 'status',
    checked: true,
    content: (record: any) => {
      let color = '';
      switch (record.status) {
        case 1:
          color = t('新创建');
          break;
        case 2:
          color = t('已发送');
          break;
        case 3:
          color = t('已确认');
          break;
        case 4:
          color = t('生成PDF');
          break;
        default:
          color = '';
          break;
      }
      return color;
    },
    filters: [
      { text: t('新创建'), value: 1 },
      { text: t('已发送'), value: 2 },
      { text: t('已确认'), value: 3 },
      { text: t('生成PDF'), value: 4 },
    ],
  },
  {
    title: t('pdf生成状态'),
    width: 100,
    dataIndex: 'pdf_status',
    checked: true,
    content: (record: any) => {
      let color = '';
      switch (record.pdf_status) {
        case 1:
          color = t('正在生成');
          break;
        case 2:
          color = t('失败');
          break;
        case 3:
          color = t('成功');
          break;
        case 0:
          color = t('未开始');
          break;
        default:
          color = '';
          break;
      }
      return color;
    },
  },
  {
    title: t('pdf生成日志'),
    width: 100,
    dataIndex: 'pdf_log',
    checked: true,
  },
  {
    title: t('付款状态'),
    width: 120,
    dataIndex: 'payment_status',
    checked: true,
    content: (record: any) => {
      let color = '';
      switch (record.payment_status) {
        case 1:
          color = t('收到款项');
          break;
        case 2:
          color = t('已付款');
          break;
        case 3:
          color = t('垫付');
          break;
        case 0:
          color = t('未收到款项');
          break;
        default:
          color = '';
          break;
      }
      return color;
    },
    filterMultiple: false,
    filters: [
      { text: t('全部'), value: '' },
      { value: 0, text: t('未收到款项') },
      { value: 1, text: t('收到款项') },
      { value: 2, text: t('已付款') },
      { value: 3, text: t('垫付') },
    ],
  },
  {
    title: t('应付款金额'),
    width: 100,
    dataIndex: 'payslip_total_amount',
    checked: true,
  },
  {
    title: t('付款银行流水'),
    width: 120,
    dataIndex: 'transaction_name',
    checked: true,
  },
  {
    title: t('付款月份'),
    width: 130,
    dataIndex: 'pay_month',
    checked: true,
    content: (record: any, text: any) => {
      return getMonth(text);
    },
  },
  {
    title: t('CC公司'),
    width: 200,
    dataIndex: 'invoice__contract__cc_company__name',
    checked: true,
  },
  {
    title: t('邮件发送状态'),
    width: 120,
    dataIndex: 'email_record__status',
    checked: true,
    color: (record: any) => {
      let color = '';
      switch (record.email_record__status) {
        case 1:
          color = 'font-weight: bolder';
          break;
        case 2:
          color = 'color: #FF0000;font-weight: bolder';
          break;
        case 0:
          color = 'font-weight: bolder';
          break;
        default:
          color = '';
          break;
      }
      return color;
    },
    content: (record: any) => {
      let color = '';
      switch (record.email_record__status) {
        case 1:
          color = t('发送成功');
          break;
        case 2:
          color = t('发送失败');
          break;
        case 0:
          color = t('已发送');
          break;
        default:
          color = '';
          break;
      }
      return color;
    },
  },
  {
    title: t('邮件发送日志'),
    width: 120,
    dataIndex: 'email_record__log',
    checked: true,
  },
  {
    title: t('邮件发送时间'),
    width: 150,
    dataIndex: 'email_record__create_time',
    checked: true,
    content: (record: any) => {
      return getDateTime(record.email_record__create_time);
    },
  },
  {
    title: t('所属remittance'),
    width: 200,
    dataIndex: 'invoice__remittance__remittance_set__remittance_name',
    checked: true,
    cellComponent: 'click',
    content: (record: any) => {
      if (record.invoice__remittance__remittance_set__remittance_name) {
        return record.invoice__remittance__remittance_set__remittance_name;
      } else {
        return '';
      }
    },
    cb: (record: any) => {
      router.push({path: '/adviser-manage/cerp-remittanceset', query: {ids: [parseInt(record.invoice__remittance__remittance_set_id)], t: (new Date()).getTime()}});
    },
  },
  {
    title: t('核验'),
    width: 120,
    dataIndex: 'check_value',
    checked: true,
  },
  {
    title: 'PDF',
    width: 200,
    dataIndex: 'files_json',
    checked: true,
    cellComponent: 'click',
    content: (record: any) => {
      if (record.files_json) {
        return record.files_json?.name;
      } else {
        return '';
      }
    },
    cb: (record: any) => {
      if (record.files_json && record.files_json.file_url) {
        const url: string =
          'https://erp-cc.oss-cn-hongkong.aliyuncs.com/' + encodeURIComponent(record.files_json.file_url);
        window.open(url, '_blank');
        // axios.get('/' + record.files_json.file_url,{
        //   responseType: 'blob' }).then((res: any) => {
        //   var blob = res.data;
        //   // FileReader主要用于将文件内容读入内存
        //   var reader = new FileReader();
        //   reader.readAsDataURL(blob);
        //   // onload当读取操作成功完成时调用
        //   reader.onload = (e: any) => {
        //     var a = document.createElement('a');
        //     // 获取文件名fileName
        //     var fileName = res.config["url"].split("/");
        //     fileName = fileName[fileName.length - 1];
        //     // fileName = fileName.replace(/"/g, "");
        //     a.download = fileName;
        //     a.href = e.target.result;
        //     document.body.appendChild(a);
        //     a.click();
        //     document.body.removeChild(a);
        //   }
        // })
      } else {
        notification.error({
          message: t('提示'),
          description: t('无对应附件'),
        });
      }
    },
  },
  {
    title: 'Excel',
    width: 200,
    dataIndex: 'excel_files_json',
    checked: true,
    cellComponent: 'click',
    content: (record: any) => {
      if (record.excel_files_json) {
        return record.excel_files_json?.name;
      } else {
        return '';
      }
    },
    cb: (record: any) => {
      if (record.excel_files_json && record.excel_files_json.file_url) {
        const url: string =
          'https://erp-cc.oss-cn-hongkong.aliyuncs.com/' + encodeURIComponent(record.excel_files_json.file_url);
        window.open(url, '_blank');
        // axios.get('/' + record.excel_files_json.file_url,{
        //   responseType: 'blob' }).then((res: any) => {
        //   var blob = res.data;
        //   // FileReader主要用于将文件内容读入内存
        //   var reader = new FileReader();
        //   reader.readAsDataURL(blob);
        //   // onload当读取操作成功完成时调用
        //   reader.onload = (e: any) => {
        //     var a = document.createElement('a');
        //     // 获取文件名fileName
        //     var fileName = res.config["url"].split("/");
        //     fileName = fileName[fileName.length - 1];
        //     // fileName = fileName.replace(/"/g, "");
        //     a.download = fileName;
        //     a.href = e.target.result;
        //     document.body.appendChild(a);
        //     a.click();
        //     document.body.removeChild(a);
        //   }
        // })
      } else {
        notification.error({
          message: t('提示'),
          description: t('无对应附件'),
        });
      }
    },
  },
  {
    title: '请款类型',
    width: 100,
    dataIndex: 'invoice__invoice_type',
    checked: true,
    content: (record: any) => {
      let color = '';
      switch (record.invoice__invoice_type) {
        case 1:
          color = t('工资');
          break;
        case 2:
          color = t('报销');
          break;
        case 3:
          color = 'credit note';
          break;
        case 4:
          color = t('加班');
          break;
        case 5:
          color = t('CC报销');
          break;
        default:
          color = '';
          break;
      }
      return color;
    },
    filterMultiple: false,
    filters: [
      { text: t('全部'), value: '' },
      { text: t('工资'), value: 1 },
      { text: t('报销'), value: 2 },
      { text: 'credit note', value: 3 },
      { text: t('加班'), value: 4 },
      { text: t('CC报销'), value: 5 },
    ],
  },
  {
    title: t('收到日期'),
    width: 150,
    dataIndex: 'date',
    checked: true,
    content: (record: any) => {
      return getDate(record.date);
    },
  },
  {
    title: t('备注'),
    width: 150,
    dataIndex: 'remark',
    checked: true,
    cellComponent: 'remark',
  },
  {
    title: t('CC付款账户'),
    width: 150,
    dataIndex: 'cc_bank_account__account_flag',
    checked: true,
  },
  {
    title: t('银行流水'),
    width: 200,
    dataIndex: 'transaction__name',
    checked: true,
    cellComponent: 'click',
    content: (record: any) => {
      if (record.transaction__name) {
        return record.transaction__name;
      } else {
        return '';
      }
    },
    cb: (record: any) => {
      router.push({ path: '/adviser-manage/cerp-banktransaction', query: { id: record.transaction_id, t: (new Date()).getTime() } });
    },
  },
  {
    title: t('推荐费'),
    width: 150,
    dataIndex: 'referral_fees',
    checked: true,
    cellComponent: 'money',
  },
  {
    title: t('管理费'),
    width: 150,
    dataIndex: 'management_fee',
    checked: true,
    cellComponent: 'money',
  },
  {
    title: t('银行转账费'),
    width: 150,
    dataIndex: 'bank_charge_cost',
    checked: true,
    cellComponent: 'money',
  },
  {
    title: t('保险费'),
    width: 150,
    dataIndex: 'insurance_fee',
    checked: true,
    cellComponent: 'money',
  },
  {
    title: t('总金额'),
    width: 150,
    dataIndex: 'total_amount',
    checked: true,
    cellComponent: 'money',
  },
  {
    title: t('合同薪资币种'),
    width: 150,
    dataIndex: 'transaction__currency__currency',
    checked: true,
  },
  {
    title: t('付款币种'),
    width: 150,
    dataIndex: 'pay_currency__currency',
    checked: true,
  },

  {
    title: t('本地币种'),
    width: 150,
    dataIndex: 'invoice__contract__local_pay_currency__currency',
    checked: true,
  },
  {
    title: t('创建人'),
    width: 150,
    dataIndex: 'founder',
    checked: true,
  },
  {
    title: t('修改人'),
    width: 150,
    dataIndex: 'reviser',
    checked: true,
  },
  {
    title: t('待办未完成'),
    width: 200,
    dataIndex: 'to_do_num',
    checked: true,
    cellComponent: 'click',
    cb: (record: any) => {
      router.push({
        path: '/adviser-manage/cerp-todoevent',
        query: { adviser_id: record.adviser_id, t: (new Date()).getTime() },
      });
    },
  },
  {
    title: t('合并付款状态'),
    width: 150,
    dataIndex: 'pay_together_status',
    checked: true,
    content: (record: any) => {
      let color = '';
      switch (record.pay_together_status) {
        case 0:
          color = t('无');
          break;
        case 1:
          color = t('同下次薪资合并');
          break;
        case 2:
          color = t('暂且不付');
          break;
        case 3:
          color = t('合并所有历史付款');
          break;
        case 4:
          color = t('分开支付');
          break;
        default:
          color = '';
          break;
      }
      return color;
    },
    filterMultiple: false,
    filters: [
      { text: t('无'), value: 0 },
      { text: t('同下次薪资合并'), value: 1 },
      { text: t('暂且不付'), value: 2 },
      { text: t('合并所有历史付款'), value: 3 },
      { text: t('分开支付'), value: 4 },
    ],
  },
  {
    title: t('创建时间'),
    width: 150,
    dataIndex: 'create_time',
    checked: true,
    sorter: {
      multiple: 1,
    },
    content: (record: any) => {
      return getDateTime(record.create_time);
    },
  },
  {
    title: t('更新时间'),
    width: 150,
    dataIndex: 'update_time',
    checked: true,
    content: (record: any) => {
      return getDateTime(record.update_time);
    },
  },
  {
    title: t('是否有效'),
    width: 150,
    dataIndex: 'is_valid',
    checked: true,
    cellComponent: 'boolean',
    name1: t('有效'),
    name2: t('无效'),
    filterMultiple: false,
    filters: [
      { text: t('有效'), value: true },
      { text: t('无效'), value: false },
    ],
  },
  {
    title: t('操作'),
    width: 100,
    dataIndex: 'action',
    checked: false,
    cellComponent: 'action',
    fixed: 'right',
  },
];
const newSlotList: any = [];
Object.assign(newSlotList, slotList);
newSlotList.push();

export default () => {
  return {
    searchConfig: search_config,
    searchConfigOptions: search_options,
    formModalConfig: edit_model_config,
    formModalConfigOptions: edit_model_config_options,
    updateFormModalConfig: update_model_config,
    updateFormModalConfigOptions: update_model_config_options,
    queryData: query_payslip_list,
    addData: create_payslip,
    updateData: update_payslip,
    removeData: delete_payslip,
    recoverData: recover_payslip,
    baseColumns: baseColumns,
    slotList: newSlotList,
    titleList: titleList,
    api_fields: api_fields,
    btn_list: btn_list,
    head_btn_list: head_btn_list,
    event_obj: event_obj,
  };
};
