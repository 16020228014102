<template>
  <span
    class="anticon ant-pro-table-column-drag-icon"
    role="img"
    aria-label="info-darg-icon"
    :style="{
      paddingRight: 6,
      cursor: 'move',
    }"
  >
    <svg width="1em" height="1em" viewBox="0 0 8 16" fill="currentColor">
      <g id="surface5">
        <path
          :style="{
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
          }"
          d="M 5 4.003906 C 5 3.449219 5.449219 3 6.003906 3 C 6.558594 3 7.007812 3.449219 7.007812 4.003906 C 7.007812 4.558594 6.558594 5.007812 6.003906 5.007812 C 5.449219 5.007812 5 4.558594 5 4.003906 Z M 5 8.25 C 5 7.695312 5.449219 7.246094 6.003906 7.246094 C 6.558594 7.246094 7.007812 7.695312 7.007812 8.25 C 7.007812 8.804688 6.558594 9.253906 6.003906 9.253906 C 5.449219 9.253906 5 8.804688 5 8.25 Z M 5 12.496094 C 5 11.941406 5.449219 11.492188 6.003906 11.492188 C 6.558594 11.492188 7.007812 11.941406 7.007812 12.496094 C 7.007812 13.050781 6.558594 13.5 6.003906 13.5 C 5.449219 13.5 5 13.050781 5 12.496094 Z M 9.554688 4.003906 C 9.554688 3.449219 10.003906 3 10.558594 3 C 11.113281 3 11.5625 3.449219 11.5625 4.003906 C 11.5625 4.558594 11.113281 5.007812 10.558594 5.007812 C 10.003906 5.007812 9.554688 4.558594 9.554688 4.003906 Z M 9.554688 8.25 C 9.554688 7.695312 10.003906 7.246094 10.558594 7.246094 C 11.113281 7.246094 11.5625 7.695312 11.5625 8.25 C 11.5625 8.804688 11.113281 9.253906 10.558594 9.253906 C 10.003906 9.253906 9.554688 8.804688 9.554688 8.25 Z M 9.554688 12.496094 C 9.554688 11.941406 10.003906 11.492188 10.558594 11.492188 C 11.113281 11.492188 11.5625 11.941406 11.5625 12.496094 C 11.5625 13.050781 11.113281 13.5 10.558594 13.5 C 10.003906 13.5 9.554688 13.050781 9.554688 12.496094 Z M 9.554688 12.496094 "
        />
      </g>
    </svg>
  </span>
</template>

<script lang="ts">
export default {
  name: 'DragIcon',
};
</script>
