import { Record } from '@/components/page-model/typing';
import { isNumber } from 'lodash';

export const getTime = (time: any) => {
  if (time) {
    time = time.substring(0, 5);
  }
  return time;
};
export const getTime2 = (time: any) => {
  if (time) {
    time = time.substring(11, 16);
  }
  return time;
};
export const getTime3 = (time: any) => {
  if (time) {
    time = time.substring(11, 19);
  }
  return time;
};
export const getMonth = (time: any) => {
  if (time && time.substring(0, 4) < 2000) {
    return '';
  }
  if (time) {
    time = time.substring(0, 7);
  }
  return time;
};
export const getMonth_no_year = (time: any) => {
  if (time && time.substring(0, 4) < 2000) {
    return '';
  }
  if (time) {
    time = time.substring(5, 7);
  }
  return time;
};
export const getDateMonth = (time: any) => {
  if (time) {
    time = time.substring(0, 4) + '年' + time.substring(5, 7) + '月';
  }
  return time;
};
export const getDate = (time: any) => {
  if (time && new Date().getFullYear().toString() == time.substring(0, 4)) {
    time = time.substring(0, 10);
  } else if (time) {
    time = time.substring(0, 10);
  }
  return time;
};
export const getDay_no_year = (time: any) => {
  if (time && time.substring(0, 4) < 2000) {
    return '';
  }
  if (time && new Date().getFullYear().toString() == time.substring(0, 4)) {
    time = time.substring(8, 10);
  } else if (time) {
    time = time.substring(8, 10);
  }
  return time;
};
export const getFullDate = (time: any) => {
  if (time && time.substring(0, 4) < 2000) {
    return '';
  }
  if (time) {
    time = time.substring(0, 10);
  }
  return time;
};
export const getDateTime = (time: any) => {
  if (time && time.substring(0, 4) < 2000) {
    return '';
  }
  if (time && new Date().getFullYear().toString() == time.substring(0, 4)) {
    time = time.substring(0, 16);
  } else if (time) {
    time = time.substring(0, 16);
  }
  return time;
};
export const toFixed2 = (num: any) => {
  if (isNumber(num)) {
    if (Number(num.toFixed(2)) == 0) {
      return 0;
    } else {
      return num.toFixed(2);
    }
  } else {
    return '';
  }
};
export const toFixed0 = (num: any) => {
  if (isNumber(num)) {
    if (Number(num.toFixed(0)) == 0) {
      return 0;
    } else {
      return num.toFixed(0);
    }
  } else {
    return '';
  }
};
export const toFixed6 = (num: any) => {
  if (isNumber(num)) {
    if (Number(num.toFixed(6)) == 0) {
      return 0;
    } else {
      return num.toFixed(6);
    }
  } else {
    return '';
  }
};
// 转换时分秒
export const format_datetime_hh_mm = (second_time: any) => {
  let time: any = second_time;
  if (second_time > 60) {
    let min = second_time / 60;
    time = Math.floor(min) + '分';
    if (min > 60) {
      min = (second_time / 60) % 60;
      let hour = second_time / 60 / 60;
      time = Math.floor(hour) + '小时' + Math.floor(min) + '分';
      if (hour > 24) {
        hour = (second_time / 60 / 60) % 24;
        const day = second_time / 60 / 60 / 24;
        time = Math.floor(day) + '天' + Math.floor(hour) + '小时' + Math.floor(min) + '分';
      }
    }
  } else {
    time = second_time + '秒';
  }
  return time;
};
// 字段对应错误————>转化正确
export const getObjList = (api_fields: Record, record: Record) => {
  const obj: any = {};
  for (const key in api_fields) {
    if (record[api_fields[key] as string]) {
      obj[key] = record[api_fields[key] as string];
    }
  }
  return obj;
};
// 时间区间格式转化 数组-->字符串
export const getRangePicker = (api_fields: Record, record: Record) => {
  const obj: any = {};
  for (const key in api_fields) {
    if (record[api_fields[key] as string]) {
      obj[key] = record[api_fields[key] as string].join(',');
    }
  }
  return obj;
};

// 小数转整数--> 过钢量是小数，单位为吨。转化为整数。
export const getTon = (kgnum: number) => {
  return Math.floor(kgnum);
};

export const get_tag_color_by_slots = (item:any, text:any)=>{
  const color_list = ['green', 'orange', 'red', 'pink', 'cyan', 'blue', 'purple', '#f50', '#2db7f5', '#87d068', '#108ee9'];
  const value_list = Object.keys(item).filter((key:string)=>key.indexOf('field_')==0).map((key:string)=>key.replace('field_', '')).sort();
  return color_list[value_list.indexOf(text.toString())];
};