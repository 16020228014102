import { SearchConfig } from '@/components/page-model/typing';
import { reactive } from 'vue';
import { config } from '@/components/page-model4/baseinfo/baseinfo-questiongroup-config';

import { useI18n } from 'vue-i18n';

export default (current_org: any) => {
  const { t } = useI18n();
  const None = null;
  const True = true;
  const False = false;

  const edit_model_config: SearchConfig = {
    settings: {
      labelAlign: 'right',
      layout: 'vertical',
      col: config.creatCol,
    },
    fields: [
      {
        type: 'input',
        name: 'name',
        label: '名称',
        label_i18n: '名称',
        disabled: false,
        allowClear: true,
        inputType: 'text',
        defaultValue: '',
        placeholder: '请填写名称',
        placeholder_i18n: '请填写名称',
      },
    ],
    rules: { name: [{ required: true, message: t('请填写名称') }] },
    model: reactive({
      org_id: current_org?.id,
      is_active: true,
      name: null,
    }),
  };
  const edit_model_config_options = {};

  return {
    edit_model_config: edit_model_config,
    edit_model_config_options: edit_model_config_options,
  };
};
