import mitt from 'mitt';
import defaultSlotList from '@/components/page-model/slotList';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import { reactive } from 'vue';
import { create_todoevent } from '@/api/cerp-model';
import { message } from 'ant-design-vue';
import modalBox from '@/components/form-modal/modal-tools';

export const event_obj = mitt();
export const btn_list = [
  {
    name: '备注',
    type: 'dropdown',
    clazz: 'primary',
    cb: (record: any) => {
      const modifyModal: any = reactive({
        visible: false,
        component: 'NoteModel',
        params: {
          object_id: record.id,
          note_type_flag: 'cerp.remittance',
        },
      });
      modifyModal.visible = true;
      modifyModal.ok = () => {
        event_obj.emit('refresh');
      };
      modifyModal.cancel = () => {};
      modalBox(modifyModal);
    },
  },
];
export const slotList = defaultSlotList;
export const config = {
  creatCol: [],
  updateCol: [],
};

export const head_btn_list = [
  {
    name: '新建待办事项',
    clazz: 'primary',
    cb: (data: any, dataSource: any, t: any) => {
      const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
      const editModal = reactive({
        visible: true,
        title: t('新建待办事项'),
        loading: false,
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'vertical',
            col: [],
          },
          fields: [
            {
              type: 'input',
              name: 'adviser__full_name',
              label: '顾问',
              label_i18n: '顾问',
              placeholder_i18n: '请选择隶属顾问',
              disabled: true,
              placeholder: '请选择隶属顾问',
              datasourceType: 'remote',
              allowClear: false,
            },
            {
              type: 'input',
              name: 'title',
              label: '标题',
              label_i18n: '标题',
              placeholder_i18n: '请填写标题',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写标题',
            },
            {
              type: 'input',
              name: 'content',
              label: '内容',
              label_i18n: '内容',
              placeholder_i18n: '请填写内容',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写内容',
            },
            {
              type: 'radio',
              name: 'is_finished',
              label: '是否完成',
              label_i18n: '是否完成',
              disabled: false,
              labelKey: 'name',
              valueKey: 'value',
              defaultValue: '',
            },
            {
              type: 'datepicker',
              name: 'alarm_time',
              label: '提醒时间',
              label_i18n: '提醒时间',
              placeholder_i18n: '请填写提醒时间',
              disabled: false,
              allowClear: true,
              inputType: 'number',
              defaultValue: '',
              showTime: true,
              valueFormat: 'YYYY-MM-DD HH:mm:00',
              placeholder: '请填写提醒时间',
            },
            {
              type: 'number',
              name: 'alarm_number',
              label: '提醒间隔天数',
              label_i18n: '提醒间隔天数',
              placeholder_i18n: '请填写提醒间隔天数',
              disabled: false,
              allowClear: true,
              inputType: 'number',
              defaultValue: 0,
              placeholder: '请填写提醒间隔天数',
            },
            {
              type: 'remoteselect',
              name: 'todo_priority_id',
              label: '优先等级',
              label_i18n: '优先等级',
              placeholder_i18n: '请选择优先等级',
              disabled: false,
              placeholder: '请选择优先等级',
              datasourceType: 'remote',
              mode: 'default',
              allowClear: true,
              labelKey: 'name',
              valueKey: 'id',
              modalType: 'baseinfo.todoevent',
            },
          ],
          rules: {
            title: [{ required: true, message: t('请填输入标题') }],
            content: [{ required: true, message: t('请填输入内容') }],
          },
          model: reactive({
            org_id: current_org?.id,
            is_active: true,
            adviser_id: Number(data.id),
            adviser__full_name: data.name,
            title: null,
            content: null,
            is_finished: null,
            status: null,
          }),
        },
        options: {
          is_finished: [
            { value: true, name: t('完成') },
            { value: false, name: t('未完成') },
          ],
          status: [
            { value: 0, name: t('普通') },
            { value: 1, name: t('紧急') },
          ],
        },
        ok: (payload: any) => {
          return new Promise(resolve => {
            editModal.loading = true;
            create_todoevent({
              ...payload,
            })
              .then(() => {
                message.success(t('添加成功'));
                event_obj.emit('refresh');
                resolve(null);
              })
              .finally(() => {
                editModal.loading = false;
              });
          });
        },
      });
      modalBox(editModal);
    },
  },
];
