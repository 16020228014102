<template>
  <a-modal
    :title="title"
    width="720px"
    :visible="true"
    :maskClosable="false"
    :confirmLoading="loading"
    wrapClassName="custom-form-modal"

    :okText="okText ? okText : '保存'"
    centered
    @ok="hold(code)"
    @cancel="
      () => {
        $emit('cancel');
      }
    "
  >
    <a-row type="flex" style="height: 100%; overflow-y: hidden">
      <!--    <a-col flex="400px">100px</a-col>-->
      <a-col flex="1 1 500px" style="height: 100%; overflow-y: hidden">
        <code-editor
          v-model:editorValue="code"
          :mode="mode"
          @debounce-update="sendMessage"
        ></code-editor>
      </a-col>
      <!--    <a-col flex="0 0 400px">100px</a-col>-->
    </a-row>
  </a-modal>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import CodeEditor from '@/components/code/CodeEditor.vue';

export default defineComponent({
  name: 'CodeEditingModal',
  props: {
    title: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
    },
    mode: {
      type: String,
      required: true,
      default: () => 'javascript',
    },
    params: {
      type: String,
      required: true,
    },
    hold: {
      type: Function,
      required: true,
    },
    okText: {
      type: String,
      default: () => '',
    },
  },
  components: {
    CodeEditor,
  },
  setup(props, { emit }) {
    const code = ref<string>('');

    code.value = props.params ? props.params : '';
    const sendMessage = () => {
      emit('send-message');
    };
    return {
      code,
      sendMessage,
    };
  },
});
</script>
<style>
.custom-form-modal .ant-modal-body {
  height: 600px;
  padding: 24px;
  overflow: auto;
}

</style>
