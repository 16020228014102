import { message } from 'ant-design-vue';
import { create_beneficiary } from '@/api/cerp-model';
import { reactive } from 'vue';
import modalBox from '@/components/form-modal/modal-tools';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';

const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org

export const get_form = (obj: any) => {
  const form = {
    settings: {
      labelAlign: 'right',
      layout: 'horizontal',
      col: [],
      labelCol: {
        span: 8,
      },
      wrapperCol: {
        span: 16,
      },
    },
    fields: [
      {
        type: 'remotemodal',
        name: 'adviser_id',
        label: '隶属顾问',
        label_i18n: '隶属顾问',
        placeholder_i18n: '请选择隶属顾问',
        disabled: false,
        defaultValue: '',
        labelKey: 'full_name',
        valueKey: 'id',
        placeholder: '隶属顾问',
        // init_model: { search_text: obj.adviser__full_name },
        show_search: false,
        maxTagCount: 5,
        mode: 'default',
        title: '选择隶属顾问',
        modalType: 'cerp-adviser',
      },
      {
        type: 'remoteselect',
        name: 'insurance_company_id',
        label: '保险公司',
        label_i18n: '保险公司',
        placeholder_i18n: '请选择保险公司',
        disabled: false,
        placeholder: '请选择保险公司',
        datasourceType: 'remote',
        mode: 'default',
        allowClear: true,
        // init_model: { company_name: obj.insurance_company_name },
        labelKey: 'company_name',
        valueKey: 'id',
        modalType: 'baseinfo-insurancecompany',
      },
      {
        type: 'input',
        name: 'policy_no',
        label: '保险单号',
        label_i18n: '保险单号',
        placeholder_i18n: '请填写保险单号',
        disabled: false,
        allowClear: true,
        inputType: 'text',
        defaultValue: '',
        placeholder: '请填写保险单号',
      },
      {
        type: 'rangepicker',
        name: 'start_end_date',
        label: '起保-停保日期时间',
        label_i18n: '起保-停保日期时间',
        placeholder_i18n: '请填写起保日期时间',
        disabled: false,
        allowClear: true,
        inputType: 'number',
        defaultValue: '',
        showTime: false,
        valueFormat: 'YYYY-MM-DD',
        placeholder: '请填写起保日期时间',
      },
      {
        type: 'radio',
        name: 'is_current',
        label: '当前保险',
        label_i18n: '当前保险',
        placeholder_i18n: '请填写当前保险',
        disabled: false,
        labelKey: 'name',
        valueKey: 'value',
        defaultValue: '',
      },
      {
        type: 'input',
        name: 'insurance_fee',
        label: '保费',
        disabled: false,
        allowClear: true,
        inputType: 'text',
        defaultValue: '',
        placeholder: '请填写保费',
      },
      {
        type: 'input',
        name: 'month_insurance_fee',
        label: '每月保费',
        label_i18n: '每月保费',
        placeholder_i18n: '请填写每月保费',
        disabled: false,
        allowClear: true,
        inputType: 'text',
        defaultValue: '',
        placeholder: '请填写每月保费',
      },
      {
        type: 'remoteselect',
        name: 'beneficiary_ids',
        label: '受益人',
        label_i18n: '受益人',
        placeholder_i18n: '请选择受益人',
        disabled: false,
        placeholder: '请选择受益人',
        datasourceType: 'remote',
        mode: 'multiple',
        allowClear: true,
        labelKey: 'name',
        valueKey: 'id',
        modalType: 'cerp-beneficiary',
        cb: () => {
          const p = new Promise(function (resolve, reject) {
            const editModal1 = reactive({
              visible: true,
              title: '新建受益人',
              loading: false,
              form: {
                settings: {
                  labelAlign: 'right',
                  layout: 'vertical',
                  col: [],
                },
                fields: [
                  {
                    type: 'remotemodal',
                    name: 'adviser_id',
                    label: '隶属顾问',
                    label_i18n: '隶属顾问',
                    placeholder_i18n: '请选择隶属顾问',
                    disabled: false,
                    defaultValue: '',
                    labelKey: 'full_name',
                    valueKey: 'id',
                    placeholder: '隶属顾问',
                    show_search: false,
                    maxTagCount: 5,
                    mode: 'default',
                    title: '选择隶属顾问',
                    modalType: 'cerp-adviser',
                  },
                  {
                    type: 'input',
                    name: 'name',
                    label: '姓名',
                    label_i18n: '姓名',
                    placeholder_i18n: '请填写姓名',
                    disabled: false,
                    allowClear: true,
                    inputType: 'text',
                    defaultValue: '',
                    placeholder: '请填写姓名',
                  },
                  {
                    type: 'radio',
                    name: 'gender',
                    label: '性别',
                    label_i18n: '性别',
                    placeholder_i18n: '性别',
                    disabled: false,
                    labelKey: 'name',
                    valueKey: 'value',
                    defaultValue: '',
                  },
                  {
                    type: 'datepicker',
                    name: 'date_of_birth',
                    label: '出生日期',
                    label_i18n: '出生日期',
                    placeholder_i18n: '请填写出生日期',
                    disabled: false,
                    allowClear: true,
                    inputType: 'number',
                    defaultValue: '',
                    showTime: false,
                    valueFormat: 'YYYY-MM-DD',
                    placeholder: '请填写出生日期',
                  },
                  {
                    type: 'remoteselect',
                    name: 'id_type_id',
                    label: '证件类别',
                    label_i18n: '证件类别',
                    placeholder_i18n: '请选择证件类别',
                    disabled: false,
                    placeholder: '请选择证件类别',
                    datasourceType: 'remote',
                    mode: 'default',
                    allowClear: true,
                    modalType: 'baseinfo-idtype',
                  },
                  {
                    type: 'input',
                    name: 'id_number',
                    label: '证件号',
                    label_i18n: '证件号',
                    placeholder_i18n: '请填写证件号',
                    disabled: false,
                    allowClear: true,
                    inputType: 'text',
                    defaultValue: '',
                    placeholder: '请填写证件号',
                  },
                  {
                    type: 'datepicker',
                    name: 'id_expiry_date',
                    label: '证件有效期',
                    label_i18n: '证件有效期',
                    placeholder_i18n: '请填写证件有效期',
                    disabled: false,
                    allowClear: true,
                    inputType: 'number',
                    defaultValue: '',
                    showTime: false,
                    valueFormat: 'YYYY-MM-DD',
                    placeholder: '请填写证件有效期',
                  },
                ],
                rules: {
                  name: [{ required: true, message: '请填输入姓名' }],
                  gender: [{ required: true, message: '请填输入性别' }],
                  date_of_birth: [{ required: true, message: '请填输入出生日期' }],
                  id_number: [{ required: true, message: '请填输入证件号' }],
                  // id_expiry_date: [{ required: true, message: '请填输入证件有效期' }],
                },
                model: reactive({
                  org_id: current_org?.id,
                  is_active: true,
                  adviser_id: obj.adviser_id,
                  insurance_id: null,
                  name: null,
                  gender: null,
                  date_of_birth: null,
                  id_type_id: null,
                  id_number: null,
                  id_expiry_date: null,
                }),
              },
              options: {
                gender: [
                  { value: 0, name: '男' },
                  { value: 1, name: '女' },
                ],
              },
              ok: (payload: any) => {
                return new Promise(resolve1 => {
                  editModal1.loading = true;
                  create_beneficiary(payload)
                    .then((res: any) => {
                      message.success('创建成功');
                      resolve1(null);
                      resolve(res.id as number);
                    })
                    .finally(() => {
                      editModal1.loading = false;
                    });
                });
              },
            });
            modalBox(editModal1);
          });
          return p;
        },
      },
    ],
    rules: {
      start_end_date: [{ required: true, message: '请填输入起保-停保日期时间' }],
      is_current: [{ required: true, message: '请填输入当前保险' }],
      insurance_fee: [{ required: true, message: '请填输入保费' }],
    },
    model: reactive({
      org_id: current_org?.id,
      is_active: true,
      adviser_id: null,
      adviser__full_name: null,
      start_end_date: null,
      insurance_company_id: null,
      policy_no: null,
      effective_date_time: null,
      expiry_date_time: null,
      is_current: null,
      insurance_fee: null,
      beneficiary_ids: null,
      month_insurance_fee: null,
      nsfile_id: null,
    }),
  };
  return form;
};
export const options = {
  is_current: [
    { value: true, name: '是' },
    { value: false, name: '否' },
  ],
};
export const createBeneficiary = (obj: any) => {
  const p = new Promise(function (resolve, reject) {
    const editModal = reactive({
      visible: true,
      title: '新建受益人',
      loading: false,
      form: {
        settings: {
          labelAlign: 'right',
          layout: 'vertical',
          col: [],
        },
        fields: [
          {
            type: 'input',
            name: 'name',
            label: '姓名',
            label_i18n: '姓名',
            placeholder_i18n: '请填写姓名',
            disabled: false,
            allowClear: true,
            inputType: 'text',
            defaultValue: '',
            placeholder: '请填写姓名',
          },
          {
            type: 'radio',
            name: 'gender',
            label: '性别',
            label_i18n: '性别',
            placeholder_i18n: '性别',
            disabled: false,
            labelKey: 'name',
            valueKey: 'value',
            defaultValue: '',
          },
          {
            type: 'datepicker',
            name: 'date_of_birth',
            label: '出生日期',
            label_i18n: '出生日期',
            placeholder_i18n: '请填写出生日期',
            disabled: false,
            allowClear: true,
            inputType: 'number',
            defaultValue: '',
            showTime: false,
            valueFormat: 'YYYY-MM-DD',
            placeholder: '请填写出生日期',
          },
          {
            type: 'remoteselect',
            name: 'id_type_id',
            label: '证件类别',
            label_i18n: '证件类别',
            placeholder_i18n: '请选择证件类别',
            disabled: false,
            placeholder: '请选择证件类别',
            datasourceType: 'remote',
            mode: 'default',
            allowClear: true,
            modalType: 'baseinfo-idtype',
          },
          {
            type: 'input',
            name: 'id_number',
            label: '证件号',
            label_i18n: '证件号',
            placeholder_i18n: '请填写证件号',
            disabled: false,
            allowClear: true,
            inputType: 'text',
            defaultValue: '',
            placeholder: '请填写证件号',
          },
          {
            type: 'datepicker',
            name: 'id_expiry_date',
            label: '证件有效期',
            label_i18n: '证件有效期',
            placeholder_i18n: '请填写证件有效期',
            disabled: false,
            allowClear: true,
            inputType: 'number',
            defaultValue: '',
            showTime: false,
            valueFormat: 'YYYY-MM-DD',
            placeholder: '请填写证件有效期',
          },
        ],
        rules: {
          name: [{ required: true, message: '请填输入姓名' }],
          gender: [{ required: true, message: '请填输入性别' }],
          date_of_birth: [{ required: true, message: '请填输入出生日期' }],
          id_number: [{ required: true, message: '请填输入证件号' }],
          // id_expiry_date: [{ required: true, message: '请填输入证件有效期' }],
        },
        model: reactive({
          org_id: current_org?.id,
          is_active: true,
          adviser_id: null,
          insurance_id: null,
          name: obj.name,
          gender: null,
          date_of_birth: null,
          id_type_id: null,
          id_number: obj.id_number,
          id_expiry_date: null,
        }),
      },
      options: {
        gender: [
          { value: 0, name: '男' },
          { value: 1, name: '女' },
        ],
      },
      ok: (payload: any) => {
        return new Promise(resolve1 => {
          editModal.loading = true;
          create_beneficiary(payload)
            .then((res: any) => {
              message.success('创建成功');
              resolve1(null);
              resolve(res.id as number);
            })
            .finally(() => {
              editModal.loading = false;
            });
        });
      },
    });
    modalBox(editModal);
  });
  return p;
};
