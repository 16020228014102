import { query_user_for_service_list } from '@/api/sys-model';
import { reactive } from 'vue';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import defaultSlotList from '@/components/page-model/slotList';
import { TableColumn } from '@/typing';
import { useI18n } from 'vue-i18n';

export default () => {
  const { t } = useI18n();
  const None = null;
  const True = true;
  const False = false;

  const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org

  const config = {
    settings: {
      name: '表单名称',
      labelAlign: 'right',
    layout: 'inline',
  },
  fields: [
    {
      type: 'input',
      name: 'name',
      label: '名称',
      label_i18n: '名称',
      placeholder_i18n: '请填写名称',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写名称',
    },
  ],
  rules: reactive({}),
  model: reactive({
    org_id: current_org?.id,
    is_active: 'true',
    name: null,
  }),
};
const search_options = {
  is_active: [
    { value: None, name: t('全部') },
    { value: 'true', name: t('可用') },
    { value: 'false', name: t('禁用') },
  ],
};

const columns: TableColumn[] = [
  {
    title: t('序号'),
    dataIndex: 'index',
    width: 50,
    fixed: 'left',
    slots: { customRender: 'index' },
  },
  {
    title: t('项目主键'),
    width: 150,
    dataIndex: 'id',
    fixed: false,
    checked: false,
  },
  {
    title: t('真实姓名'),
    width: 150,
    dataIndex: 'realname',
    fixed: false,
    checked: true,
  },
  {
    title: t('电子邮箱'),
    width: 150,
    dataIndex: 'email',
    fixed: false,
    checked: true,
  },
];

  return {
    searchConfig: config,
    searchConfigOptions: search_options,
    getData: query_user_for_service_list,
    columns: columns,
    slotList: defaultSlotList,
  };
};
