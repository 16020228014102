import {
  create_c_c_bank_accounts_c_c,
  delete_c_c_bank_accounts_c_c,
  query_c_c_bank_accounts_c_c_list,
  recover_c_c_bank_accounts_c_c,
  update_c_c_bank_accounts_c_c,
} from '@/api/baseinfo-model';
import { TableColumn } from '@/typing';
import { Record, SearchConfig } from '@/components/page-model/typing';
import { useI18n } from 'vue-i18n';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import {
  btn_list,
  config,
  event_obj,
  head_btn_list,
  slotList,
} from '@/components/page-model4/baseinfo/baseinfo-ccbankaccountscc-config';

import { reactive } from 'vue';
import { getDateTime } from '@/utils/function';

const None = null;
const True = true;
const False = false;

const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org

const { t } = useI18n();
const search_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'inline',
  },
  fields: [
    {
      type: 'input',
      name: 'account_name',
      label: '账户名',
      label_i18n: t('page.baseinfo.ccbankaccountscc.form.label.account_name'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写账户名',
      placeholder_i18n: t('page.baseinfo.ccbankaccountscc.form.placeholder.account_name'),
    },
  ],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    account_name: null,
  }),
};
const search_options = {
  is_active: [
    { value: None, name: '全部' },
    { value: True, name: '可用' },
    { value: False, name: '禁用' },
  ],
};

const edit_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [
    {
      type: 'input',
      name: 'account_name',
      label: '账户名',
      label_i18n: t('page.baseinfo.ccbankaccountscc.form.label.account_name'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写账户名',
      placeholder_i18n: t('page.baseinfo.ccbankaccountscc.form.placeholder.account_name'),
    },
    {
      type: 'input',
      name: 'bank_name',
      label: '银行名称',
      label_i18n: t('银行名称'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写银行名称',
      placeholder_i18n: t('请填写银行名称'),
    },
    {
      type: 'input',
      name: 'bank_address',
      label: '银行地址',
      label_i18n: t('银行地址'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写银行地址',
      placeholder_i18n: t('请填写银行地址'),
    },
    {
      type: 'input',
      name: 'swift_code',
      label: 'Swift Code',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: 'Swift Code',
    },
    {
      type: 'input',
      name: 'account_flag',
      label: '账户代号',
      label_i18n: t('账户代号'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写账户代号',
      placeholder_i18n: t('请填写账户代号'),
    },
    {
      type: 'input',
      name: 'sort_code',
      label: 'Sort Code',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: 'Sort Code',
    },
    {
      type: 'remoteselect',
      name: 'cc_company_id',
      label: 'CC公司',
      label_i18n: t('CC公司'),
      disabled: false,
      placeholder: '请选择CC公司',
      placeholder_i18n: t('请选择CC公司'),
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-cccompany',
    },
  ],
  rules: {
    account_name: [{ required: true, message: t('请填输入账户名') }],
    bank_name: [{ required: true, message: t('请填写银行名称') }],
    bank_address: [{ required: true, message: t('请填写银行地址') }],
    swift_code: [{ required: true, message: 'Swift Code' }],
    account_flag: [{ required: true, message: t('请填写账户代号') }],
  },
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    account_name: null,
    account_number: null,
    account_flag: null,
    sort_code: null,
    cc_company_id: null,
  }),
};
const edit_model_config_options = {};

const update_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [
    {
      type: 'input',
      name: 'account_name',
      label: '账户名',
      label_i18n: t('page.baseinfo.ccbankaccountscc.form.label.account_name'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写账户名',
      placeholder_i18n: t('page.baseinfo.ccbankaccountscc.form.placeholder.account_name'),
    },
    {
      type: 'input',
      name: 'bank_name',
      label: '银行名称',
      label_i18n: t('银行名称'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写银行名称',
      placeholder_i18n: t('请填写银行名称'),
    },
    {
      type: 'input',
      name: 'bank_address',
      label: '银行地址',
      label_i18n: t('银行地址'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写银行地址',
      placeholder_i18n: t('请填写银行地址'),
    },
    {
      type: 'input',
      name: 'swift_code',
      label: 'Swift Code',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: 'Swift Code',
    },
    {
      type: 'input',
      name: 'account_flag',
      label: '账户代号',
      label_i18n: t('账户代号'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写账户代号',
      placeholder_i18n: t('请填写账户代号'),
    },
    {
      type: 'input',
      name: 'sort_code',
      label: 'Sort Code',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: 'Sort Code',
    },
    {
      type: 'remoteselect',
      name: 'cc_company_id',
      label: 'CC公司',
      label_i18n: t('CC公司'),
      disabled: false,
      placeholder: '请选择CC公司',
      placeholder_i18n: t('请选择CC公司'),
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-cccompany',
    },
  ],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    account_name: null,
    account_number: null,
    account_flag: null,
    sort_code: null,
    cc_company_id: null,
  }),
};
const update_model_config_options = {};

const titleList = {
  title: 'CC银行账户管理',
  addModalTitle: t('新建CC银行账户'),
  updateTitle: t('修改CC银行账户'),
  is_create: true, // 是否有【新建】按钮
  is_update: true, // 是否有【修改】按钮
  is_remove: true, // 是否有【删除】按钮
  is_recover: true, // 是否有【恢复】按钮
};

const api_fields: Record = {
  update: {
    cc_bank_accounts_cc_id: 'id',
  },
  delete: {
    cc_bank_accounts_cc_id: 'id',
  },
  recover: {
    cc_bank_accounts_cc_id: 'id',
  },
  updateData: {
    org_id: 'org_id',
    cc_bank_accounts_cc_id: 'id',
  },
};

const baseColumns: TableColumn[] = [
  {
    title: t('page.baseinfo.ccbankaccountscc.column.title.index'),
    dataIndex: 'index',
    width: 40,
  },
  {
    title: t('page.baseinfo.ccbankaccountscc.column.title.id'),
    dataIndex: 'id',
    width: 50,
    checked: false,
  },
  {
    title: t('page.baseinfo.ccbankaccountscc.column.title.account_name'),
    dataIndex: 'account_name',
    width: 120,
    checked: true,
  },
  {
    title: t('银行名称'),
    dataIndex: 'bank_name',
    width: 120,
    checked: true,
  },
  {
    title: t('银行地址'),
    dataIndex: 'bank_address',
    width: 120,
    checked: true,
  },
  {
    title: 'Swift Code',
    dataIndex: 'swift_code',
    width: 90,
    checked: true,
  },
  {
    title: t('账户代号'),
    dataIndex: 'account_flag',
    width: 120,
    checked: true,
  },
  {
    title: 'Sort Code',
    dataIndex: 'sort_code',
    width: 90,
    checked: true,
  },
  {
    title: t('CC公司'),
    dataIndex: 'cc_company__name',
    checked: true,
    width: 200,
  },
  {
    title: t('page.baseinfo.ccbankaccountscc.column.title.update_time'),
    dataIndex: 'update_time',
    width: 140,
    checked: true,
    content: (record: any) => {
      return getDateTime(record.update_time);
    },
  },
  {
    title: t('page.baseinfo.ccbankaccountscc.column.title.is_active'),
    dataIndex: 'is_active',
    checked: true,
    width: 100,
    cellComponent: 'boolean',
    name1: t('可用'),
    name2: t('禁用'),
    filterMultiple: false,
    filters: [
      { text: t('全部'), value: '' },
      { text: t('可用'), value: true },
      { text: t('禁用'), value: false },
    ],
  },

  {
    title: t('page.baseinfo.ccbankaccountscc.column.title.action'),
    width: 120,
    dataIndex: 'action',
    checked: false,
    cellComponent: 'action',
    fixed: 'right',
  },
];
const newSlotList: any = [];
Object.assign(newSlotList, slotList);
newSlotList.push();

export default () => {
  return {
    searchConfig: search_config,
    searchConfigOptions: search_options,
    formModalConfig: edit_model_config,
    formModalConfigOptions: edit_model_config_options,
    updateFormModalConfig: update_model_config,
    updateFormModalConfigOptions: update_model_config_options,
    queryData: query_c_c_bank_accounts_c_c_list,
    addData: create_c_c_bank_accounts_c_c,
    updateData: update_c_c_bank_accounts_c_c,
    removeData: delete_c_c_bank_accounts_c_c,
    recoverData: recover_c_c_bank_accounts_c_c,
    baseColumns: baseColumns,
    slotList: newSlotList,
    titleList: titleList,
    api_fields: api_fields,
    btn_list: btn_list,
    head_btn_list: head_btn_list,
    event_obj: event_obj,
  };
};
