import queryApiurl from '@/components/FormRender/RemoteModal/api-url.ts';
import queryUser from '@/components/FormRender/RemoteModal/sys-user.ts';
import queryUserForServiceList from '@/components/FormRender/RemoteModal/sys-user_for_service_list.ts';
import queryAdviser from '@/components/FormRender/RemoteModal/cerp-adviser';
import queryInvoiceList from '@/components/FormRender/RemoteModal/cerp-invoice';
import queryRemittanceset from '@/components/FormRender/RemoteModal/cerp-remittanceset';
import { ModalConfig } from '@/components/FormRender/RemoteModal/typing.ts';

export default (modalType: string): ModalConfig => {
  switch (modalType) {
    case 'cerp-invoice': {
      return {
        ...queryInvoiceList(),
      };
    }
    case 'cerp-remittanceset': {
      return {
        ...queryRemittanceset(),
      };
    }
    case 'cerp-adviser': {
      return {
        ...queryAdviser(),
      };
    }
    case 'api-url': {
      return {
        ...queryApiurl(),
      };
    }
    case 'sys-user': {
      return {
        ...queryUser(),
      };
    }
    case 'sys-user_for_service_list': {
      return {
        ...queryUserForServiceList(),
      };
    }
    default: {
      return {
        ...queryApiurl(),
      };
    }
  }
};
