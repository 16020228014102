<template>
  <div v-if="record.id" style="padding: 0">
    <div v-if="column.content ? column.content(record, text) : ''" style="padding: 0">
      <div style="border-radius: 3px; background-color: #ffffff;padding: 9px;display: inline-block;width: auto;height:auto;float: left;max-width: 60%">
        <p v-if="record.message_type == 0" style="margin-bottom: 0;white-space: pre-line">{{ record.content }}</p>
        <a v-if="record.message_type == 2" :href="record.content" target="_blank">{{ record.content }}</a>
        <a-image
          v-if="record.message_type == 1"
          :width="200"
          :src="`${record.content}`"
        >
        </a-image>
        <div v-if="record.message_type == 3">
          <span v-if="record?.reply_content && record?.reply_content.length > 0">{{ t('你是想问如下问题吗') }}？</span>
          <span v-if="record?.reply_content.length == 0">{{ t('未找到相关问题，请直接转人工') }}！</span>
          <template v-for="(obj, index) in record.reply_content" :key="index">
            <br>
            <a-button
              type="link"
              :size="'small'"
              style="width: 100%;"
              :title="obj.name"
            >
        <span style="color: #999999;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;float: left;max-width: 100%;
       user-select: text;
                    ">·{{obj.name}}
        </span>
            </a-button>
          </template>
        </div>
      </div>
      <div style="float: left;margin-left: 15px">
        <span style="color: #999999; font-size: 12px;" v-if="record.is_adviser_read">&nbsp;</span>
        <span style="color: #007FFF; font-size: 12px;" v-if="!record.is_adviser_read">&nbsp;</span><br/>
        <span style="color: #999999; font-size: 12px;">{{ getDateTime(record.create_time) }}</span>
      </div>
    </div>
    <div v-else style="padding: 0">
      <div style="border-radius: 3px; background-color: #C9E7FF;padding: 9px;display: inline-block;width: auto;height:auto;float: right;max-width: 60%">
        <p v-if="record.message_type == 0" style="margin-bottom: 0;white-space: pre-line">{{ record.content }}</p>
        <a v-if="record.message_type == 2" :href="record.content" target="_blank">{{ record.content }}</a>
        <a-image
          v-if="record.message_type == 1"
          :width="200"
          :src="`${record.content}`"
        >
        </a-image>
        <div v-if="record.message_type == 3">
          <span v-if="record?.reply_content && record?.reply_content.length > 0">{{ t('你是想问如下问题吗') }}？</span>
          <span v-if="record?.reply_content.length == 0">{{ t('未找到相关问题，请直接转人工') }}！</span>
          <template v-for="(obj, index) in record.reply_content" :key="index">
            <br>
            <a-button
              type="link"
              :size="'small'"
              style="width: 100%;"
              :title="obj.name"
            >
        <span style="color: #999999;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;float: left;max-width: 100%;
       user-select: text;
                    ">·{{obj.name}}
        </span>
            </a-button>
          </template>
        </div>
      </div>
      <div style="float: right;margin-right: 15px">
        <span v-if="record.is_adviser_read" style="color: #999999; font-size: 12px;float: right">{{ t('已读') }}</span>
        <span v-if="!record.is_adviser_read" style="color: #007FFF; font-size: 12px;float: right">{{ t('未读') }}</span><br />
        <span style="color: #999999; font-size: 12px;float: right">{{ getDateTime(record.create_time) }}</span>
      </div>
      <div/>
    </div>
  </div>
  <div v-else>
    <a-button
      type="link"
      :size="'small'"
      style="text-align: center;width: 100%;height: 50px;margin-top: 10px"
      @click="query_session_more(record.start_id)"
    >
      <span style="color: #999999">{{ t('点击加载更多') }}</span>
    </a-button>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { getDateTime } from '@/utils/function';
import { PictureOutlined } from '@ant-design/icons-vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'Cell_talk',
  props: {
    column: {
      type: Object,
      required: true,
      default: () => {
      },
    },
    index: {
      type: Number,
      required: true,
    },
    text: {
      type: [String, Number],
      required: true,
    },
    record: {
      type: Object,
      required: true,
      default: () => {
      },
    },
    query_session_more: {
      type: Function,
      required: false,
    },
  },
  setup() {
    const { t } = useI18n();
    return {
      t,
      getDateTime,
    }
  },
  components: {
    PictureOutlined,
  },
});
</script>
