
import { defineComponent } from 'vue';
import { getDateTime } from '@/utils/function';
import { PictureOutlined } from '@ant-design/icons-vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'Cell_talk',
  props: {
    column: {
      type: Object,
      required: true,
      default: () => {
      },
    },
    index: {
      type: Number,
      required: true,
    },
    text: {
      type: [String, Number],
      required: true,
    },
    record: {
      type: Object,
      required: true,
      default: () => {
      },
    },
    query_session_more: {
      type: Function,
      required: false,
    },
  },
  setup() {
    const { t } = useI18n();
    return {
      t,
      getDateTime,
    }
  },
  components: {
    PictureOutlined,
  },
});
