import { query_email_address_list } from '@/api/baseinfo-model';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';

const org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
const search_config: any = {
  model: {
    org_id: org?.id,
    is_active: true,
    page_index: 1,
    pageSize: 2000,
  },
  watch: [],
};

export default () => {
  return {
    getData: query_email_address_list,
    searchConfig: search_config,
  };
};
