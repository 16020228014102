<template>
  <a-dropdown
    v-if="current_org && current_org.name"
    class="ant-pro-dropdown ant-pro-dropdown-action"
    placement="bottomRight"
    overlayClassName="pro-components-header-dropdown-index-container"
  >
    <span>
      <!--      <a-avatar size="small" :src="org_list.avatar" class="ant-pro-header-account-avatar" />-->
      <span class="ant-pro-header-account-name anticon">{{ current_org.name }}</span>
    </span>
    <template v-slot:overlay>
      <a-menu class="ant-pro-dropdown-menu" :selected-keys="[]">
        <a-menu-item v-for="obj in org_list" :key="obj.id" @click="select_org(obj)">
          <user-outlined v-if="current_org.id == obj.id" class="prefixIcon" />
          <span v-if="current_org.id != obj.id">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
          {{ obj.name }}
        </a-menu-item>
      </a-menu>
    </template>
  </a-dropdown>
  <span v-else>
    <a-spin size="small" :style="{ marginLeft: 8, marginRight: 8 }" />
  </span>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { UserOutlined } from '@ant-design/icons-vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

import { CHANGE_CURRENT_ORG } from '@/store/modules/user/actions';

export default defineComponent({
  name: 'OrgNameNow',
  props: {
    org_list: {
      type: Array,
      default: () => [],
    },
    current_org: {
      type: Object,
      default: () => {},
    },
    menu: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const { t } = useI18n();
    const select_org = (obj: any) => {
      // ls.set(STORAGE_CURRENT_ORG_KEY, obj);
      store.dispatch(`user/${CHANGE_CURRENT_ORG}`, obj).then(() => {
        router.push({
          path: '/workplace',
        });
      });
    };

    return {
      t,
      select_org,
    };
  },
  components: {
    UserOutlined,
  },
});
</script>

<style lang="less">
@import './header-dropdown.less';

.ant-pro-header-account-name {
  vertical-align: unset;
}

.ant-pro-header-account-avatar {
  margin: 12px 8px 12px 0;
  color: @primary-color;
  vertical-align: top;
  background: hsla(0, 0%, 100%, 0.85);
}

.prefixIcon {
  color: @primary-color;
  font-size: @font-size-base;
}
</style>
