import mitt from 'mitt';
import defaultSlotList from '@/components/page-model/slotList';
import {
  bulk_pay_payslip_zero,
  cancel_pay_payslip,
  confirm_payslip,
  create_bank_transaction,
  create_payslip_pdf,
  modify_payslip_mangefee,
  pay_payslip,
  send_payslip_by_email,
  set_payslip_pay_month,
  set_payslip_pay_together_status,
  upload_payslip_pdf,
} from '@/api/cerp-model';
import { message, Modal } from 'ant-design-vue';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import { createVNode, reactive, ref } from 'vue';
import modalBox from '@/components/form-modal/modal-tools';
import moment from 'moment';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';

const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org

export const event_obj = mitt();
export const btn_list = [
  {
    name: '备注',
    type: 'dropdown',
    clazz: 'primary',
    cb: (record: any) => {
      const modifyModal: any = reactive({
        visible: false,
        component: 'NoteModel',
        params: {
          object_id: record.id,
          note_type_flag: 'cerp.payslip',
        },
      });
      modifyModal.visible = true;
      modifyModal.ok = () => {
        event_obj.emit('refresh');
      };
      modifyModal.cancel = () => {};
      modalBox(modifyModal);
    },
  },
  {
    name: '修改',
    type: 'dropdown',
    clazz: 'primary',
    is_show: (record: any) => {
      return (
        (record.status === 1 || record.status === 2 || record.status === 4) &&
        record.payment_status != 2
      );
    },
    cb: (record: any) => {
      const modifyModal: any = reactive({
        visible: false,
        component: 'PayslipModal',
        type: 'update',
        obj: {
          ...record,
        },
      });
      modifyModal.visible = true;
      modifyModal.ok = () => {
        event_obj.emit('refresh');
      };
      modifyModal.cancel = () => {};
      modalBox(modifyModal);
    },
  },
  {
    name: '预览',
    type: 'dropdown',
    clazz: 'primary',
    cb: (record: any) => {
      const modifyModal: any = reactive({
        visible: false,
        component: 'PreviewModal',
        type: 'payslip',
        obj: {
          ...record,
        },
      });
      modifyModal.visible = true;
      modalBox(modifyModal);
    },
  },
  {
    name: '付款',
    type: 'dropdown',
    clazz: 'primary',
    is_show: (record: any) => {
      return record.payment_status != 2;
    },
    cb: (record: any, dataSource: any, index: any, requestParams: any, t: any) => {
      const editModal = reactive({
        visible: true,
        title: t('付款（银行流水）'),
        loading: false,
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'vertical',
            col: [],
          },
          fields: [
            {
              type: 'input',
              name: 'name',
              label: '名称',
              label_i18n: '名称',
              placeholder_i18n: '请填写名称',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写名称',
            },
            {
              type: 'input',
              name: 'description',
              label: '描述',
              label_i18n: '描述',
              placeholder_i18n: '请填描述',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写描述',
            },
            {
              type: 'money',
              name: 'amount',
              label: '总金额',
              label_i18n: '总金额',
              placeholder_i18n: '请填写总金额',
              disabled: false,
              allowClear: true,
              inputType: 'number',
              defaultValue: '',
              placeholder: '请填写总金额',
            },
            {
              type: 'datepicker',
              name: 'date',
              label: '日期',
              label_i18n: '日期',
              placeholder_i18n: '请填写日期',
              disabled: false,
              allowClear: true,
              inputType: 'number',
              defaultValue: '',
              showTime: false,
              valueFormat: 'YYYY-MM-DD',
              placeholder: '请填写日期',
            },
            {
              type: 'select',
              name: 'transaction_type',
              label: '流水种类',
              label_i18n: '流水种类',
              placeholder_i18n: '请选择流水种类',
              mode: 'default',
              disabled: false,
              defaultValue: '',
              labelKey: 'name',
              valueKey: 'value',
              placeholder: '请选择流水种类',
              datasource: 'transaction_type',
            },
            {
              type: 'remoteselect',
              name: 'cc_bank_account_id',
              label: 'CC银行账户',
              label_i18n: 'CC银行账户',
              placeholder_i18n: '请选择CC银行账户',
              disabled: false,
              placeholder: '请选择CC银行账户',
              datasourceType: 'remote',
              mode: 'default',
              allowClear: true,
              labelKey: 'account_flag',
              valueKey: 'id',
              modalType: 'baseinfo-ccbankaccountscc',
            },
            {
              type: 'remoteselect',
              name: 'currency_id',
              labelKeyList: ['code', 'currency'],
              label: '币种',
              label_i18n: t('币种'),
              disabled: false,
              placeholder: '请选择薪资币种',
              placeholder_i18n: t('请选择薪资币种'),
              datasourceType: 'remote',
              mode: 'default',
              allowClear: true,
              modalType: 'baseinfo-country1',
            },
          ],
          rules: {
            name: [{ required: true, message: t('请填输入名称') }],
            amount: [{ required: true, message: t('请填输入总金额') }],
            transaction_type: [{ required: true, message: t('请填输入流水种类') }],
          },
          model: reactive({
            org_id: current_org?.id,
            is_active: true,
            name: record.payslip_no,
            description: null,
            amount: record.total_amount,
            currency_id: record.invoice__contract__currency_id,
            date: moment().format('YYYY-MM-DD HH:mm:ss'),
            transaction_type: '支出',
            cc_bank_account_id: record.cc_bank_account_id,
          }),
        },
        options: {
          transaction_type: [
            { value: '收入', name: t('收入') },
            { value: '支出', name: t('支出') },
          ],
        },
        ok: (payload: any) => {
          return new Promise(resolve => {
            editModal.loading = true;
            create_bank_transaction(payload).then((res: any) => {
              pay_payslip({
                org_id: current_org?.id,
                transaction_id: res?.id,
                payslip_id: record.id,
              })
                .then((res: any) => {
                  resolve(null);
                  event_obj.emit('refresh');
                  message.success(t('付款成功'));
                })
                .finally(() => {
                  editModal.loading = false;
                });
            });
          });
        },
      });
      modalBox(editModal);
    },
  },
  {
    name: '取消付款',
    type: 'dropdown',
    clazz: 'danger',
    is_show: (record: any) => {
      return record.transaction_id;
    },

    cb: (record: any, dataSource: any, index: any, requestParams: any, t: any) => {
      const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
      Modal.confirm({
        title: t('是否取消付款') + '?',
        icon: createVNode(ExclamationCircleOutlined),
        onOk() {
          return new Promise(resolve => {
            cancel_pay_payslip({
              org_id: current_org.id,
              payslip_id: record.id,
            }).then((res: any) => {
              message.success(t('取消付款成功'));
              Object.assign(record, res);
            }).finally(() => {
              resolve(null);
            })
          })
        },
        onCancel() {
        },
      });
    },
  },
  {
    name: '0付款',
    type: 'dropdown',
    clazz: 'primary',
    is_show: (record: any) => {
      return record.payment_status != 2;
    },
    cb: (record: any, dataSource: any, index: any, requestParams: any, t: any) => {
      const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
      Modal.confirm({
        title: t('是否0付款') + '?',
        icon: createVNode(ExclamationCircleOutlined),
        onOk() {
          return new Promise(resolve => {
            bulk_pay_payslip_zero({
              org_id: current_org.id,
              payslip_ids: [record.id],
            }).then((res: any) => {
              message.success(t('0付款成功'));
              Object.assign(record, res);
            }).finally(() => {
              resolve(null);
            })
          })
        },
        onCancel() {
        },
      });
    },
  },
  {
    name: '生成PDF',
    type: 'dropdown',
    clazz: 'primary',
    is_show: (record: any) => {
      return record.status === 1;
    },
    cb: (record: any, dataSource: any, index: any, requestParams: any, t: any) => {
      Modal.confirm({
        title: t('提示'),
        content: t('是否生成工资单PDF'),
        onOk() {
          return new Promise(resolve => {
            create_payslip_pdf({ org_id: current_org?.id, payslip_id: record.id })
              .then(() => {
                message.success(t('生成工资单PDF成功'));
                event_obj.emit('refresh');
              })
              .finally(() => {
                resolve(null);
              });
          });
        },
        onCancel() {
        },
      });
    },
  },
  {
    name: '手动上传PDF',
    type: 'dropdown',
    clazz: 'primary',
    cb: (record: any, dataSource: any, index: any, requestParams: any, t: any) => {
      const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
      const loading = ref(false);
      const editModal = reactive({
        visible: true,
        title: t('手动上传PDF'),
        group: 'PDF上传',
        type: 'picture',
        filetype: ['pdf'],
        component: 'upload-image-modal',
        loading: loading,
        ok: (payload: any) => {
          return new Promise(resolve => {
            loading.value = true;
            let nsfile_id = null;
            if (payload && payload.length > 0) {
              nsfile_id = payload[0];
              if (payload.length > 1) {
                message.error(t('默认只能上传第一个文件'));
              }
            }
            upload_payslip_pdf({
              payslip_id: record.id,
              nsfile_id: nsfile_id,
              org_id: current_org?.id,
            })
              .then(() => {
                message.success(t('操作成功'));
                event_obj.emit('refresh');
                resolve(null);
              })
              .finally(() => {
                loading.value = false;
              });
          });
        },
      });
      modalBox(editModal);
    },
  },
  {
    name: '发送邮件',
    type: 'dropdown',
    clazz: 'primary',
    is_show: (record: any) => {
      return record.status === 4;
    },
    cb: (record: any, dataSource: any, index: any, requestParams: any, t: any) => {
      Modal.confirm({
        title: t('提示'),
        content: t('是否发送邮件'),
        onOk() {
          return new Promise(resolve => {
            send_payslip_by_email({ org_id: current_org?.id, payslip_id: record.id })
              .then(() => {
                message.success(t('发送邮件成功'));
                event_obj.emit('refresh');
              })
              .finally(() => {
                resolve(null);
              });
          });
        },
        onCancel() {},
      });
    },
  },
  {
    name: '重新发送',
    type: 'dropdown',
    clazz: 'primary',
    is_show: (record: any) => {
      return record.status === 2;
    },
    cb: (record: any, dataSource: any, index: any, requestParams: any, t: any) => {
      Modal.confirm({
        title: t('提示'),
        content: t('是否重新发送邮件'),
        onOk() {
          return new Promise(resolve => {
            send_payslip_by_email({ org_id: current_org?.id, payslip_id: record.id })
              .then(() => {
                message.success(t('重新发送邮件成功'));
                event_obj.emit('refresh');
              })
              .finally(() => {
                resolve(null);
              });
          });
        },
        onCancel() {},
      });
    },
  },
  {
    name: '确认收到',
    type: 'dropdown',
    clazz: 'primary',
    is_show: (record: any) => {
      return record.status === 2;
    },
    cb: (record: any, dataSource: any, index: any, requestParams: any, t: any) => {
      Modal.confirm({
        title: t('提示'),
        content: t('是否确认收到工资单'),
        onOk() {
          return new Promise(resolve => {
            confirm_payslip({ org_id: current_org?.id, payslip_id: record.id })
              .then(() => {
                message.success(t('工资单确认成功'));
                event_obj.emit('refresh');
              })
              .finally(() => {
                resolve(null);
              });
          });
        },
        onCancel() {},
      });
    },
  },
  {
    name: '管理费',
    type: 'dropdown',
    clazz: 'primary',
    is_show: (record: any) => {
      return (
        (record.status === 1 || record.status === 2 || record.status === 4) &&
        record.payment_status != 2
      );
    },
    cb: (record: any, dataSource: any, index: any, requestParams: any, t: any) => {
      const editModal = reactive({
        visible: true,
        title: t('修改工资单管理费'),
        loading: false,
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'vertical',
            col: [],
          },
          fields: [
            {
              type: 'input',
              name: 'management_fee',
              label: '管理费',
              label_i18n: '管理费',
              placeholder_i18n: '请填写管理费',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写管理费',
            },
          ],
          rules: {
            management_fee: [{ required: true, message: t('请填写管理费描述') }],
          },
          model: {
            org_id: current_org?.id,
            is_active: true,
            payslip_id: record.id,
            management_fee: record.management_fee,
          },
        },
        options: {},
        ok: (payload: any) => {
          return new Promise(resolve => {
            editModal.loading = true;
            modify_payslip_mangefee(payload)
              .then(() => {
                message.success(t('修改成功'));
                event_obj.emit('refresh');
                resolve(null);
              })
              .finally(() => {
                editModal.loading = false;
              });
          });
        },
      });
      modalBox(editModal);
    },
  },
  {
    name: '修改合并付款状态',
    type: 'dropdown',
    clazz: 'primary',
    cb: (record: any, dataSource: any, index: any, requestParams: any, t: any) => {
      const editModal = reactive({
        visible: true,
        title: t('修改合并付款状态'),
        loading: false,
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'vertical',
            col: [],
          },
          fields: [

            {
              type: 'select',
              name: 'pay_together_status',
              label: '合并付款状态',
              label_i18n: '合并付款状态',
              mode: 'default',
              disabled: false,
              defaultValue: '',
              labelKey: 'name',
              valueKey: 'value',
              datasource: 'pay_together_status',
            },
          ],
          rules: {
            pay_together_status: [{ required: true, message: t('请选择合并付款状态') }],
          },
          model: {
            org_id: current_org?.id,
            payslip_id: record.id,
            pay_together_status: record.pay_together_status,
          },
        },
        options: {
          pay_together_status: [
            { value: 0, name: t('无') },
            { value: 1, name: t('同下次薪资合并') },
            { value: 2, name: t('暂且不付') },
            { value: 3, name: t('合并所有历史付款') },
            { value: 4, name: t('分开支付') },
          ],
        },
        ok: (payload: any) => {
          return new Promise(resolve => {
            editModal.loading = true;
            set_payslip_pay_together_status(payload)
              .then(() => {
                message.success(t('修改成功'));
                event_obj.emit('refresh');
                resolve(null);
              })
              .finally(() => {
                editModal.loading = false;
              });
          });
        },
      });
      modalBox(editModal);
    },
  },
  {
    name: '修改付款月份',
    type: 'dropdown',
    clazz: 'primary',
    cb: (record: any, dataSource: any, index: any, requestParams: any, t: any) => {
      const editModal = reactive({
        visible: true,
        title: t('修改付款月份'),
        loading: false,
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'vertical',
            col: [],
          },
          fields: [
            {
              type: 'monthpicker',
              name: 'pay_month',
              label: '付款月份',
              label_i18n: '付款月份',
              placeholder_i18n: '请填写付款月份',
              disabled: false,
              allowClear: true,
              inputType: 'number',
              defaultValue: '',
              showTime: false,
              valueFormat: 'YYYY-MM-DD',
              format: 'YYYY-MM',
              placeholder: '请填写付款月份',
              disabledTime: (date: any) => {
                return date.format('YYYY-MM') < moment().format('YYYY-MM') || date.format('YYYY-MM') > moment(record.invoice__contract__end_date).format('YYYY-MM');
              },
            },
          ],
          rules: {
            pay_month: [{ required: true, message: t('请选择付款月份') }],
          },
          model: {
            org_id: current_org?.id,
            payslip_id: record.id,
            pay_month: record.pay_month,
          },
        },
        options: {},
        ok: (payload: any) => {
          return new Promise(resolve => {
            editModal.loading = true;
            set_payslip_pay_month(payload)
              .then(() => {
                message.success(t('修改付款月份成功'));
                event_obj.emit('refresh');
                resolve(null);
              })
              .finally(() => {
                editModal.loading = false;
              });
          });
        },
      });
      modalBox(editModal);
    },
  },
];
export const slotList = defaultSlotList;
export const config = {
  creatCol: [7, 7],
  updateCol: [],
};

export const head_btn_list = [];
