import modalBox from '@/components/form-modal/modal-tools';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import ls from '@/utils/local-storage';
import { reactive, ref } from 'vue';
import { message, Modal, notification } from 'ant-design-vue';
import { create_adviser, query_insurance_list } from '@/api/cerp-model';
import {
  query_c_c_company_template_list,
  query_country_list,
  query_managementfeeformula_list,
} from '@/api/baseinfo-model';
import { create_contract } from '@/api/cerp/contract';
import { event_obj } from '@/layouts/cerp-adviser-config.ts';
import { get_fields, options, settings } from '@/components/page-model/cerp/cerp-contract-editModal.ts';
import cerp_adviser_editModal from '@/components/page-model/cerp/cerp-adviser-editModal.ts';
import { query_person_for_cmes_list } from '@/api/sys-model';
import { isNumber } from 'lodash';

const trim = (str: any) => {
  return str.replace(/(^\s*)|(\s*$)/g, '');
};
export const btn_obj = {
  cb: (t: any) => {
    const {
      settings_adviser,
      fields_adviser,
      fields_adviser_update,
      options_adviser,
      baseColumns,
    } = cerp_adviser_editModal(t, {});
    //新顾问 按钮被点击
    const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org

    const editModal = {
      visible: true,
      title: '新建顾问',
      loading: false,
      form: {
        settings: settings_adviser,
        fields: fields_adviser,
        rules: {
          // personal_email: [{ required: true, message: '请输入个人邮箱' }],
          first_name: [{ required: true, message: '请填输入名字' }],
          last_name: [{ required: true, message: '请填输入姓' }],
          person_type_id: [{ required: true, message: '请选择个人类别' }],
          status: [{ required: true, message: '请选择个人状态' }],
        },
        model: reactive({
          org_id: current_org?.id,
          is_active: true,
          title: '',
          first_name: null,
          last_name: null,
          other_name: null,
          birth_date: null,
          nationality: null,
          phone: null,
          mobile: null,
          personal_email: null,
          work_email: null,
          wechat: null,
          whats_app: null,
          qq: null,
          skypelid: null,
          tag_ids: null,
          user_email_template_id: null,
          person_type_id: null,
          status: null,
          home_address_id: null,
          person_note: null,
          work_address_id: null,
          employee_number: null,
          creater_id: null,
          creater: null,
        }),
        watch: {
          creater_id: (data: any) => {
            query_person_for_cmes_list({
              org_id: current_org?.id,
              ids: [data.creater_id],
            }).then((res: any) => {
              data['creater'] = res.data[0].realname;
            });
          },
          mobile: (data: any) => {
            if (data?.mobile && data.mobile.includes('+')) {
              data.mobile = data.mobile.replace(/\+/g, '00');
            }
          },
        },
      },
      options: options_adviser,
      ok: (payload: any) => {
        // 模态框，确认按钮点击。
        // todo: 创建新顾问，并询问是否录入 合同。
        return new Promise(resolve => {
          if (!payload.personal_email && !payload.work_email) {
            notification.error({
              message: t('提示'),
              description: '个人邮箱、工作邮箱 至少要填写一个！',
            });
            return false;
          }
          editModal.loading = true;
          resolve(null);
          create_adviser({
            ...payload,
            first_name: trim(payload.first_name),
            last_name: trim(payload.last_name),
            phone: payload.phone?.trim(),
            mobile: payload.mobile?.trim(),
          })
            .then(function (param: any) {
              event_obj.emit('refresh');
              // todo: 顾问已经创建成功。
              Modal.confirm({
                title: t('提示'),
                content: '是否创建顾问的合同？',
                onOk() {
                  // todo: 创建合同的模态框
                  // todo: 此处创建合同时，顾问要用input，防止多查数据，导致网络缓慢
                  const insurance_fee = ref(false);
                  const is_insurance_fee = ref(false);
                  const is_c_insurance_fee = ref(false);
                  const is_adviser_fee = ref(false);
                  const is_c_adviser_fee = ref(false);
                  let description = '';
                  const editContractModal = reactive({
                    visible: true,
                    title: '创建合同',
                    loading: false,
                    form: {
                      settings: settings,
                      fields: get_fields({
                        is_current: false, insurance_fee: insurance_fee,
                        is_insurance_fee: is_insurance_fee, is_c_insurance_fee: is_c_insurance_fee,
                        is_adviser_fee: is_adviser_fee,
                        is_c_adviser_fee: is_c_adviser_fee,
                      }, t),
                      rules: {
                        consultant: [{ required: true, message: t('请填写职务') }],
                        recuitment_company_id: [{ required: true, message: t('请选择顾问公司') }],
                        recuitment_company_person_id: [
                          { required: true, message: t('请选择请款邮箱地址') },
                        ],
                        work_location_id: [{ required: true, message: t('请选择工作地点') }],
                        start_end_date: [{ required: true, message: t('请填写合同起始结束日期') }],
                        rate_type: [{ required: true, message: t('请选择薪资种类') }],
                        currency_id: [{ required: true, message: t('请选择薪资币种') }],
                        basic_rate: [{ required: true, message: t('请填写基本单位薪资') }],
                        is_current: [{ required: true, message: t('是否当前合同') }],
                        contract_email_template_id: [
                          { required: true, message: t('请选择合同邮箱模板') },
                        ],
                        payslip_email_template_id: [
                          { required: true, message: t('请选择工资单邮箱模板') },
                        ],
                        invoice_email_template_id: [
                          { required: true, message: t('请选择请款单邮箱模板') },
                        ],
                        cc_email_id: [{ required: true, message: t('请选择CC邮箱') }],
                        end_client_id: [{ required: true, message: t('请选择终端用户') }],
                        manage_fee: [{ required: true, message: t('请填写管理费描述') }],
                        contract_template_id: [{ required: true, message: t('请选择合同模板') }],
                        cc_company_id: [{ required: true, message: t('请选择CC公司') }],
                        adviser_email_template_id: [
                          { required: true, message: t('请选择请款时顾问提示邮箱模板') },
                        ],
                        bank_charge: [{ required: true, message: t('请填写银行转账费') }],
                        management_fee_formula_id: [
                          { required: true, message: t('请选择管理费计算方式') },
                        ],
                        expense_email_template_id: [{ required: true, message: t('请选择报销请款单邮箱模板') }],
                        overtime_email_template_id: [{ required: true, message: t('请选择加班请款单邮箱模板') }],
                        cn_email_template_id: [{ required: true, message: t('请选择CN请款单邮箱模板') }],
                        expiration_email_template_id: [{ required: true, message: t('请选择合同到期提醒邮箱模板') }],
                        sign_email_template_id: [{ required: true, message: t('请选择签署合同确认邮箱模板') }],
                        solution_type: [{ required: true, message: t('请选择是否运用本地解决方案') }],
                        cc_expense_email_template_id: [{ required: true, message: t('请选择CC报销请款单邮箱模板') }],
                      },
                      model: reactive({
                        org_id: current_org?.id,
                        is_active: true,
                        adviser_id: Number(param.id),
                        adviser__full_name: param.full_name,
                        recuitment_company_id: null,
                        recuitment_company_person_id: null,
                        work_location_id: null,
                        start_date: null,
                        end_date: null,
                        termination_date: null,
                        rate_type: null,
                        start_end_date: null,
                        currency_id: null,
                        pay_currency_id: null,
                        cc_company_id: null,
                        basic_rate: null,
                        cc_management_fee: null,
                        bank_charge: null,
                        accommodation_rate: null,
                        overtime_rate: null,
                        remark: null,
                        nsfile_id: null,
                        consultant_bank_charge: null,
                        company_bank_charge: null,
                        extra_info: null,
                        work_hour_template_id: null,
                        fte: 1,
                        annual_leave: null,
                        cc_management_fee_rate: null,
                        local_management_fee: null,
                        local_management_fee_rate: null,
                        is_bank_charge_payment: null,
                        insurance_payment_flag: null,
                        management_fee_payment_flag: null,
                        management_fee_formula_id: null,
                        income_tax: null,
                        social_insurance: null,
                        local_management_fee_fixed: null,
                        exchange_rate_id: null,
                        is_current: null,
                        end_client_id: null,
                        local_deduct_fee: null,
                        contract_email_template_id: null,
                        creater_id: null,
                        creater: null,
                        project_name: null,
                        local_salary: null,
                        cc_expense_email_template_id: 18,
                        insurance_type: 0,
                        solution_type: 1,
                        c_insurance_fee: 0,
                        insurance_fee: 0,
                        cc_email_id: 4,
                        adviser_email_template_id: 6,
                        invoice_email_template_id: 7,
                        payslip_email_template_id: 8,
                        expense_email_template_id: 15,
                        overtime_email_template_id: 16,
                        cn_email_template_id: 17,
                        contract_template_id: 3,
                        tip_day: 10,
                        expiration_email_template_id: 22,
                        sign_email_template_id: 26,
                      }),
                      watch: {
                        management_fee_formula_id: (data1: any) => {
                          if (data1.management_fee_payment_flag == 0) {
                            if (data1.management_fee_formula_id) {
                              query_managementfeeformula_list({
                                org_id: current_org?.id,
                                ids: [data1.management_fee_formula_id],
                              }).then((res: any) => {
                                if (res && res.data) {
                                  data1.manage_fee = res.data[0].description;
                                  data1.manage_fee = data1.manage_fee
                                    .replace(/<local_rate>/g, data1.local_management_fee_rate ? data1.local_management_fee_rate?.toString() : '0')
                                    .replace(/<cc_rate>/g, data1.cc_management_fee_rate ? data1.cc_management_fee_rate?.toString() : '0')
                                    .replace(/<basic_rate>/g, data1.basic_rate ? data1.basic_rate?.toString() : '0')
                                    .replace(/<over_rate>/g, data1.overtime_rate ? data1.overtime_rate?.toString() : '0')
                                    .replace(/<cc_fee>/g, data1.cc_management_fee ? data1.cc_management_fee?.toString() : '0')
                                    .replace(/<local_fee>/g, data1.local_management_fee ? data1.local_management_fee?.toString() : '0')
                                    .replace(/<local_fixed>/g, data1.local_management_fee_fixed ? data1.local_management_fee_fixed?.toString() : '0')
                                    .replace(/<local_deduct>/g, data1.local_deduct_fee ? data1.local_deduct_fee?.toString() : '0')
                                    .replace(/<local_salaryvalue>/g, data1.local_salary ? data1.local_salary?.toString() : '0')
                                    .replace(/<rate_type>/g, data1.rate_type == 0 ? 'DAY' : data1.rate_type == 1 ? 'MONTH' : data1.rate_type == 2 ? 'YEAR' : data1.rate_type == 3 ? 'HOUR' : data1.rate_type == 4 ? 'WEEK' : '')
                                    .replace(/<currency>/g, data1.currency__currency ? data1.currency__currency?.toString() : '')
                                    .replace(/<local_pay_currency>/g, data1.local_pay_currency__currency ? data1.local_pay_currency__currency?.toString() : '')
                                    .replace(/<accommodation_rate>/g, data1.accommodation_rate ? data1.accommodation_rate?.toString() : '0');
                                  description = res.data[0].description;
                                }
                              });
                            }
                          } else {
                            if (data1.currency_id) {
                              query_country_list({
                                org_id: current_org?.id,
                                ids: [data1.currency_id],
                              }).then((res: any) => {
                                if (res && res.data) {
                                  data1.manage_fee = res.data[0].currency + '0.00';
                                }
                              });
                            }
                          }
                        },
                        management_fee_payment_flag: (data1: any) => {
                          if (data1.management_fee_payment_flag == 0) {
                            data1.manage_fee = description
                              .replace(/<local_rate>/g, data1.local_management_fee_rate ? data1.local_management_fee_rate?.toString() : '0')
                              .replace(/<cc_rate>/g, data1.cc_management_fee_rate ? data1.cc_management_fee_rate?.toString() : '0')
                              .replace(/<basic_rate>/g, data1.basic_rate ? data1.basic_rate?.toString() : '0')
                              .replace(/<over_rate>/g, data1.overtime_rate ? data1.overtime_rate?.toString() : '0')
                              .replace(/<cc_fee>/g, data1.cc_management_fee ? data1.cc_management_fee?.toString() : '0')
                              .replace(/<local_fee>/g, data1.local_management_fee ? data1.local_management_fee?.toString() : '0')
                              .replace(/<local_fixed>/g, data1.local_management_fee_fixed ? data1.local_management_fee_fixed?.toString() : '0')
                              .replace(/<local_deduct>/g, data1.local_deduct_fee ? data1.local_deduct_fee?.toString() : '0')
                              .replace(/<local_salaryvalue>/g, data1.local_salary ? data1.local_salary?.toString() : '0')
                              .replace(/<rate_type>/g, data1.rate_type == 0 ? 'DAY' : data1.rate_type == 1 ? 'MONTH' : data1.rate_type == 2 ? 'YEAR' : data1.rate_type == 3 ? 'HOUR' : data1.rate_type == 4 ? 'WEEK' : '')
                              .replace(/<currency>/g, data1.currency__currency ? data1.currency__currency?.toString() : '')
                              .replace(/<local_pay_currency>/g, data1.local_pay_currency__currency ? data1.local_pay_currency__currency?.toString() : '')
                              .replace(/<accommodation_rate>/g, data1.accommodation_rate ? data1.accommodation_rate?.toString() : '0');
                          } else {
                            if (data1.currency_id) {
                              query_country_list({
                                org_id: current_org?.id,
                                ids: [data1.currency_id],
                              }).then((res: any) => {
                                if (res && res.data) {
                                  data1.manage_fee = res.data[0].currency + '0.00';
                                }
                              });
                            }
                          }
                        },
                        bank_charge: (data1: any) => {
                          if (data1['is_bank_charge_payment']) {
                            if (isNumber(data1.bank_charge) && isNumber(data1.company_bank_charge)) {
                              data1.consultant_bank_charge = data1.bank_charge - data1.company_bank_charge;
                            }
                          } else {
                            if (isNumber(data1.bank_charge) && isNumber(data1.consultant_bank_charge)) {
                              data1.company_bank_charge = data1.bank_charge - data1.consultant_bank_charge;
                            }
                          }
                        },
                        is_bank_charge_payment: (data1: any) => {
                          if (data1['is_bank_charge_payment']) {
                            is_adviser_fee.value = true;
                            is_c_adviser_fee.value = false;
                            data1.consultant_bank_charge = data1.bank_charge;
                            data1.company_bank_charge = data1.bank_charge - data1.consultant_bank_charge;
                          } else {
                            is_c_adviser_fee.value = true;
                            is_adviser_fee.value = false;
                            data1.company_bank_charge = data1.bank_charge;
                            data1.consultant_bank_charge = data1.bank_charge - data1.company_bank_charge;
                          }
                        },
                        consultant_bank_charge: (data1: any) => {
                          data1.company_bank_charge = data1.bank_charge - data1.consultant_bank_charge;
                        },
                        company_bank_charge: (data1: any) => {
                          data1.consultant_bank_charge = data1.bank_charge - data1.company_bank_charge;
                        },
                        insurance_type: (data1: any) => {
                          if (data1['insurance_type'] != 0) {
                            insurance_fee.value = true;
                            data1['insurance_payment_flag'] = -1;
                          } else {
                            insurance_fee.value = false;
                          }
                        },
                        creater_id: (data: any) => {
                          query_person_for_cmes_list({
                            org_id: current_org?.id,
                            ids: [data.creater_id],
                          }).then((res: any) => {
                            data['creater'] = res.data[0].realname;
                          });
                        },
                        insurance_payment_flag: (data: any) => {
                          if (data['insurance_payment_flag'] == -1) {
                            is_insurance_fee.value = true;
                            is_c_insurance_fee.value = true;
                            data['c_insurance_fee'] = 0;
                            data['insurance_fee'] = 0;
                          } else if (data['insurance_payment_flag'] == 0) {
                            is_insurance_fee.value = false;
                            is_c_insurance_fee.value = true;
                            query_insurance_list({
                              org_id: current_org.id,
                              is_current: true,
                              adviser_id: data.adviser_id,
                            }).then((res: any) => {
                              data['insurance_fee'] = res.data[0].month_insurance_fee;
                            });
                          } else if (data['insurance_payment_flag'] == 1) {
                            is_insurance_fee.value = true;
                            is_c_insurance_fee.value = false;
                            query_insurance_list({
                              org_id: current_org.id,
                              is_current: true,
                              adviser_id: data.adviser_id,
                            }).then((res: any) => {
                              data['c_insurance_fee'] = res.data[0].month_insurance_fee;
                            });
                          }
                        },
                        insurance_fee: (data: any) => {
                          if (data['insurance_payment_flag'] == 0) {
                            query_insurance_list({
                              org_id: current_org.id,
                              is_current: true,
                              adviser_id: data.adviser_id,
                            }).then((res: any) => {
                              data['c_insurance_fee'] = res.data[0].month_insurance_fee - (data['insurance_fee'] ? data['insurance_fee'] : 0);
                            });
                          }
                        },
                        c_insurance_fee: (data: any) => {
                          if (data['insurance_payment_flag'] == 1) {
                            query_insurance_list({
                              org_id: current_org.id,
                              is_current: true,
                              adviser_id: data.adviser_id,
                            }).then((res: any) => {
                              data['insurance_fee'] = res.data[0].month_insurance_fee - (data['c_insurance_fee'] ? data['c_insurance_fee'] : 0);
                            });
                          }
                        },
                        cc_company_id: (data: any) => {
                          query_c_c_company_template_list({
                            org_id: current_org.id,
                            cc_company_id: data.cc_company_id,
                          }).then((res: any) => {
                            if (res && res.data && res.data.length > 0) {
                              data.recuitment_company_person_id = res.data[0].recuitment_company_person_id;
                              data.contract_template_id = res.data[0].contract_template_id;
                              data.cc_email_id = res.data[0].cc_email_id;
                              data.cc_expense_email_template_id = res.data[0].cc_expense_email_template_id;
                              data.invoice_email_template_id = res.data[0].invoice_email_template_id;
                              data.contract_email_template_id = res.data[0].contract_email_template_id;
                              data.expense_email_template_id = res.data[0].expense_email_template_id;
                              data.expiration_email_template_id = res.data[0].expiration_email_template_id;
                              data.adviser_email_template_id = res.data[0].adviser_email_template_id;
                              data.overtime_email_template_id = res.data[0].overtime_email_template_id;
                              data.sign_email_template_id = res.data[0].sign_email_template_id;
                              data.payslip_email_template_id = res.data[0].payslip_email_template_id;
                              data.cn_email_template_id = res.data[0].cn_email_template_id;
                            }
                          });
                        },
                      },
                    },
                    options: options(t),
                    ok: (contract_payload: any) => {
                      return new Promise(resolve1 => {
                        editContractModal.loading = true;
                        create_contract({
                          ...contract_payload,
                          start_date: contract_payload.start_end_date[0],
                          end_date: contract_payload.start_end_date[1],
                        })
                          .then(() => {
                            message.success('创建合同成功');
                            event_obj.emit('refresh');
                            resolve1(null);
                          })
                          .finally(() => {
                            editContractModal.loading = false;
                          });
                      });
                    },
                  });
                  modalBox(editContractModal);
                },
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                onCancel() {
                },
              });
            })
            .finally(() => {
              editModal.loading = false;
            });
        });
      },
    };
    modalBox(editModal);
  },
};
