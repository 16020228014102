import request from '@/utils/request';
import { PageResult } from '@/api/typing';

export async function queryRole(params?: { [key: string]: any }): Promise<PageResult<RuleItem>> {
  return request.post('/permission/query_role_list', params);
}

export async function removeRole(params: Record<string, any>) {
  return request.post('/permission/delete_role', params);
}

export async function recoverRole(params: Record<string, any>) {
  return request.post('/permission/recover_role', params);
}

export async function addRole(params: Record<string, any>) {
  return request.post('/permission/create_role', params);
}

export async function updateRole(params: Record<string, any>) {
  return request.post('/permission/update_role', params);
}

interface RuleItem {
  key: string | number;
  callNo: number;
  avatar: string;
  desc: string;
  disabled: false;
  href: string;
  name: string;
  owner: string;
  progress: number;
  status: number;
  updatedAt: string;
  createdAt: string;
}

// export function useRoleData() {
//   const state = reactive({
//     loading: true,
//     current: 0,
//     pageSize: 10,
//     total: 0,
//     dataSource: [] as RuleItem[],
//   });
//
//   const fetchRole = (params?: Record<string, any>) => {
//     // 使 table 打开加载状态指示
//     state.loading = true;
//     // 发起 AJAX 请求到后端
//     queryRole(
//       Object.assign(
//         {
//           current: state.current,
//           pageSize: state.pageSize,
//         },
//         params,
//       ),
//     )
//       .then((res: RoleResponse) => {
//         // 更新数据
//         state.dataSource = res.data;
//         state.total = res.total;
//       })
//       .finally(() => {
//         // 使 table 关闭加载状态指示
//         state.loading = false;
//       });
//   };
//
//   return {
//     state,
//     fetch: fetchRole,
//   };
// }
