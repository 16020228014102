import {
  create_beneficiary,
  delete_beneficiary,
  query_beneficiary_list,
  recover_beneficiary,
  update_beneficiary,
} from '@/api/cerp-model';
import { TableColumn } from '@/typing';
import { Record, SearchConfig } from '@/components/page-model/typing';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import {
  btn_list,
  config,
  event_obj,
  head_btn_list,
  slotList,
} from '@/components/page-model2/cerp/cerp-beneficiary-config';

import { reactive } from 'vue';
import { getDate, getDateTime } from '@/utils/function';

import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const None = null;
const True = true;
const False = false;

const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org

const search_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'inline',
  },
  fields: [
    {
      type: 'remotemodal',
      name: 'adviser_id',
      label: '隶属顾问',
      label_i18n: '隶属顾问',
      placeholder_i18n: '请选择隶属顾问',
      disabled: false,
      defaultValue: '',
      labelKey: 'full_name',
      valueKey: 'id',
      placeholder: '隶属顾问',
      show_search: false,
      maxTagCount: 5,
      mode: 'default',
      title: t('选择隶属顾问'),
      modalType: 'cerp-adviser',
    },
    {
      type: 'input',
      name: 'name',
      label: '姓名',
      label_i18n: '姓名',
      placeholder_i18n: '请填写姓名',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写姓名',
    },
    {
      type: 'radio',
      name: 'gender',
      label: '性别',
      label_i18n: '性别',
      placeholder_i18n: '性别',
      disabled: false,
      labelKey: 'name',
      valueKey: 'value',
      defaultValue: '',
    },
    {
      type: 'datepicker',
      name: 'date_of_birth',
      label: '出生日期',
      label_i18n: '出生日期',
      placeholder_i18n: '请填写出生日期',
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      showTime: false,
      valueFormat: 'YYYY-MM-DD',
      placeholder: '请填写出生日期',
    },
    {
      type: 'remoteselect',
      name: 'id_type_id',
      label: '证件类别',
      label_i18n: '证件类别',
      placeholder_i18n: '请选择证件类别',
      disabled: false,
      placeholder: '请选择证件类别',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-idtype',
    },
    {
      type: 'input',
      name: 'id_number',
      label: '证件号',
      label_i18n: '证件号',
      placeholder_i18n: '请填写证件号',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写证件号',
    },
    {
      type: 'datepicker',
      name: 'id_expiry_date',
      label: '证件有效期',
      label_i18n: '证件有效期',
      placeholder_i18n: '请填写证件有效期',
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      showTime: false,
      valueFormat: 'YYYY-MM-DD',
      placeholder: '请填写证件有效期',
    },
  ],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    adviser_id: null,
    name: null,
    gender: null,
    date_of_birth: null,
    id_type_id: null,
    id_number: null,
    id_expiry_date: null,
  }),
};
const search_options = {
  gender: [
    { value: 0, name: '男' },
    { value: 1, name: '女' },
  ],
  is_active: [
    { value: None, name: '全部' },
    { value: True, name: '可用' },
    { value: False, name: '禁用' },
  ],
};

const edit_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [
    {
      type: 'remotemodal',
      name: 'adviser_id',
      label: '隶属顾问',
      label_i18n: '隶属顾问',
      placeholder_i18n: '请选择隶属顾问',
      disabled: false,
      defaultValue: '',
      labelKey: 'full_name',
      valueKey: 'id',
      placeholder: '隶属顾问',
      show_search: false,
      maxTagCount: 5,
      mode: 'default',
      title: t('选择隶属顾问'),
      modalType: 'cerp-adviser',
    },
    {
      type: 'input',
      name: 'name',
      label: '姓名',
      label_i18n: '姓名',
      placeholder_i18n: '请填写姓名',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写姓名',
    },
    {
      type: 'radio',
      name: 'gender',
      label: '性别',
      label_i18n: '性别',
      placeholder_i18n: '性别',
      disabled: false,
      labelKey: 'name',
      valueKey: 'value',
      defaultValue: '',
    },
    {
      type: 'datepicker',
      name: 'date_of_birth',
      label: '出生日期',
      label_i18n: '出生日期',
      placeholder_i18n: '请填写出生日期',
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      showTime: false,
      valueFormat: 'YYYY-MM-DD',
      placeholder: '请填写出生日期',
    },
    {
      type: 'remoteselect',
      name: 'id_type_id',
      label: '证件类别',
      label_i18n: '证件类别',
      placeholder_i18n: '请选择证件类别',
      disabled: false,
      placeholder: '请选择证件类别',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-idtype',
    },
    {
      type: 'input',
      name: 'id_number',
      label: '证件号',
      label_i18n: '证件号',
      placeholder_i18n: '请填写证件号',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写证件号',
    },
    {
      type: 'datepicker',
      name: 'id_expiry_date',
      label: '证件有效期',
      label_i18n: '证件有效期',
      placeholder_i18n: '请填写证件有效期',
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      showTime: false,
      valueFormat: 'YYYY-MM-DD',
      placeholder: '请填写证件有效期',
    },
    {
      type: 'select',
      name: 'relation',
      label: '与被保险人关系',
      label_i18n: '与被保险人关系',
      placeholder_i18n: '请选择与被保险人关系',
      mode: 'default',
      disabled: false,
      defaultValue: '',
      labelKey: 'name',
      valueKey: 'value',
      placeholder: '请选择与被保险人关系',
      datasource: 'relation',
    },
    {
      type: 'input',
      name: 'ratio',
      label: '受益比例',
      label_i18n: '受益比例',
      placeholder_i18n: '请填写受益比例',
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      placeholder: '请填写受益比例',
    },
  ],
  rules: {
    name: [{ required: true, message: '请填输入姓名' }],
    gender: [{ required: true, message: '请填输入性别' }],
    date_of_birth: [{ required: true, message: '请填输入出生日期' }],
    id_number: [{ required: true, message: '请填输入证件号' }],
    // id_expiry_date: [{ required: true, message: '请填输入证件有效期' }],
  },
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    adviser_id: null,
    name: null,
    relation: null,
    ratio: null,
    gender: null,
    date_of_birth: null,
    id_type_id: null,
    id_number: null,
    id_expiry_date: null,
  }),
};
const edit_model_config_options = {
  gender: [
    { value: 0, name: '男' },
    { value: 1, name: '女' },
  ],
  relation: [
    { value: 0, name: '本人' },
    { value: 1, name: '配偶' },
    { value: 2, name: '子女' },
    { value: 3, name: '父母' },
    { value: 4, name: '其他' },
  ],
};

const update_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [
    {
      type: 'remotemodal',
      name: 'adviser_id',
      label: '隶属顾问',
      label_i18n: '隶属顾问',
      placeholder_i18n: '请选择隶属顾问',
      disabled: true,
      defaultValue: '',
      labelKey: 'full_name',
      valueKey: 'id',
      placeholder: '隶属顾问',
      show_search: false,
      maxTagCount: 5,
      mode: 'default',
      title: t('选择隶属顾问'),
      modalType: 'cerp-adviser',
    },
    {
      type: 'input',
      name: 'name',
      label: '姓名',
      label_i18n: '姓名',
      placeholder_i18n: '请填写姓名',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写姓名',
    },
    {
      type: 'radio',
      name: 'gender',
      label: '性别',
      label_i18n: '性别',
      placeholder_i18n: '性别',
      disabled: false,
      labelKey: 'name',
      valueKey: 'value',
      defaultValue: '',
    },
    {
      type: 'datepicker',
      name: 'date_of_birth',
      label: '出生日期',
      label_i18n: '出生日期',
      placeholder_i18n: '请填写出生日期',
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      showTime: false,
      valueFormat: 'YYYY-MM-DD',
      placeholder: '请填写出生日期',
    },
    {
      type: 'remoteselect',
      name: 'id_type_id',
      label: '证件类别',
      label_i18n: '证件类别',
      placeholder_i18n: '请选择证件类别',
      disabled: false,
      placeholder: '请选择证件类别',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-idtype',
    },
    {
      type: 'input',
      name: 'id_number',
      label: '证件号',
      label_i18n: '证件号',
      placeholder_i18n: '请填写证件号',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写证件号',
    },
    {
      type: 'datepicker',
      name: 'id_expiry_date',
      label: '证件有效期',
      label_i18n: '证件有效期',
      placeholder_i18n: '请填写证件有效期',
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      showTime: false,
      valueFormat: 'YYYY-MM-DD',
      placeholder: '请填写证件有效期',
    },
    {
      type: 'select',
      name: 'relation',
      label: '与被保险人关系',
      label_i18n: '与被保险人关系',
      placeholder_i18n: '请选择与被保险人关系',
      mode: 'default',
      disabled: false,
      defaultValue: '',
      labelKey: 'name',
      valueKey: 'value',
      placeholder: '请选择与被保险人关系',
      datasource: 'relation',
    },
    {
      type: 'input',
      name: 'ratio',
      label: '受益比例',
      label_i18n: '受益比例',
      placeholder_i18n: '请填写受益比例',
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      placeholder: '请填写受益比例',
    },
  ],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    adviser_id: null,
    name: null,
    ratio: null,
    relation: null,
    gender: null,
    date_of_birth: null,
    id_type_id: null,
    id_number: null,
    id_expiry_date: null,
  }),
};
const update_model_config_options = {
  gender: [
    { value: 0, name: t('男') },
    { value: 1, name: t('女') },
  ],
  relation: [
    { value: 0, name: t('本人') },
    { value: 1, name: t('配偶') },
    { value: 2, name: t('子女') },
    { value: 3, name: t('父母') },
    { value: 4, name: t('其他') },
  ],
};

const titleList = {
  title: '受益人管理',
  addModalTitle: '新建受益人',
  updateTitle: t('修改受益人'),
  is_create: false, // 是否有【新建】按钮
  is_update: true, // 是否有【修改】按钮
  is_remove: true, // 是否有【删除】按钮
  is_recover: true, // 是否有【恢复】按钮
};

const api_fields: Record = {
  update: {
    beneficiary_id: 'id',
  },
  delete: {
    beneficiary_id: 'id',
  },
  recover: {
    beneficiary_id: 'id',
  },
  updateData: {
    org_id: 'org_id',
    beneficiary_id: 'id',
  },
};

const baseColumns: TableColumn[] = [
  {
    title: t('序号'),
    dataIndex: 'index',
    width: 50,
    fixed: 'left',
  },
  {
    title: 'ID',
    width: 150,
    dataIndex: 'id',
    checked: false,
  },
  {
    title: t('姓名'),
    minWidth: 150,
    dataIndex: 'name',
    checked: true,
    fixed: 'left',
  },
  {
    title: t('是否可用'),
    width: 100,
    dataIndex: 'is_active',
    checked: false,
    cellComponent: 'boolean',
    name1: t('可用'),
    name2: t('禁用'),
    filterMultiple: false,
    filters: [
      { text: t('全部'), value: '' },
      { text: t('可用'), value: true },
      { text: t('禁用'), value: false },
    ],
  },
  {
    title: t('性别'),
    width: 150,
    dataIndex: 'gender',
    checked: true,
    cellComponent: 'custom',
    field_0: t('男'),
    field_1: t('女'),
  },
  {
    title: t('出生日期'),
    width: 150,
    dataIndex: 'date_of_birth',
    checked: true,
    content: (record: any) => {
      return getDate(record.date_of_birth);
    },
  },
  {
    title: t('证件类别名'),
    width: 150,
    dataIndex: 'id_type__name',
    checked: true,
  },
  {
    title: t('证件号'),
    width: 150,
    dataIndex: 'id_number',
    checked: true,
  },
  {
    title: t('证件有效期'),
    width: 150,
    dataIndex: 'id_expiry_date',
    checked: true,
    content: (record: any) => {
      return getDate(record.id_expiry_date);
    },
  },
  {
    title: t('受益比例'),
    width: 150,
    dataIndex: 'ratio',
    checked: true,
  },
  {
    title: t('与被保险人关系'),
    width: 150,
    dataIndex: 'relation',
    checked: true,
    cellComponent: 'custom',
    field_0: t('本人'),
    field_1: t('配偶'),
    field_2: t('子女'),
    field_3: t('父母'),
    field_4: t('其他'),
  },
  // {
  //   title: '保险单号',
  //   width: 150,
  //   dataIndex: 'insurance__policy_no',
  //   checked: true,
  // },
  {
    title: t('创建时间'),
    width: 150,
    dataIndex: 'create_time',
    checked: true,
    content: (record: any) => {
      return getDateTime(record.create_time);
    },
  },
  {
    title: t('更新时间'),
    width: 150,
    dataIndex: 'update_time',
    checked: true,
    content: (record: any) => {
      return getDateTime(record.update_time);
    },
  },

  {
    title: t('操作'),
    width: 120,
    dataIndex: 'action',
    checked: false,
    cellComponent: 'action',
    fixed: 'right',
  },
];
const newSlotList: any = [];
Object.assign(newSlotList, slotList);
newSlotList.push({
    type: 'custom',
    slotName: 'gender',
    field_0: '男',
    field_1: '女',
  },
  {
    type: 'custom',
    slotName: 'relation',
    field_0: '本人',
    field_1: '配偶',
    field_2: '子女',
    field_3: '父母',
    field_4: '其他',
  });

export default () => {
  return {
    searchConfig: search_config,
    searchConfigOptions: search_options,
    formModalConfig: edit_model_config,
    formModalConfigOptions: edit_model_config_options,
    updateFormModalConfig: update_model_config,
    updateFormModalConfigOptions: update_model_config_options,
    queryData: query_beneficiary_list,
    addData: create_beneficiary,
    updateData: update_beneficiary,
    removeData: delete_beneficiary,
    recoverData: recover_beneficiary,
    baseColumns: baseColumns,
    slotList: newSlotList,
    titleList: titleList,
    api_fields: api_fields,
    btn_list: btn_list,
    head_btn_list: head_btn_list,
    event_obj: event_obj,
  };
};
