
import { defineComponent, ref } from 'vue';
import { set_global_custom_data_by_flag, get_global_custom_data_by_flag } from '@/api/sys-model';
import { notification } from 'ant-design-vue';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';

export default defineComponent({
  name: 'WorkPlaceEditModel',
  props: {
    visible: {
      type: Boolean,
      required: true,
      default: () => false,
    },
    flag: {
      type: String,
      default: () => {},
    },
  },
  components: {},
  emits: ['ok', 'cancel'],
  setup(props, { emit }) {
    const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
    const editorele = ref({});
    const loading = ref(true);
    const code = ref<string>('');
    const mode = 'javascript';

    get_global_custom_data_by_flag({ org_id: current_org?.id, flag: props.flag })
      .then(function (res) {
        if (res) {
          code.value = JSON.stringify(res, null, 4);
        } else {
          code.value = '{}';
        }
      })
      .finally(() => {
        loading.value = false;
      });

    const sendMessage = () => {
      // emit('send-message');
    };
    const handleCancel = () => {
      emit('cancel');
    };
    const handleOk = () => {
      try {
        JSON.parse(code.value);
        loading.value = true;
        set_global_custom_data_by_flag({
          org_id: current_org?.id,
          flag: props.flag,
          data: code.value,
        })
          .then(function (res) {
            emit('ok', code.value);
          })
          .finally(() => {
            loading.value = false;
          });
      } catch (e) {
        notification.error({
          message: '格式不正确',
          description: '请修改格式，符合JSON格式',
        });
      }
    };

    return {
      loading,
      code,
      editorele,
      mode,
      sendMessage,
      handleCancel,
      handleOk,
    };
  },
});
