import request from '@/utils/request';
import { PageResult } from '@/api/typing';

export type RequestParams =
  | {
      pageSize: number;
      current: number;
      [key: string]: any;
    }
  | undefined;

export async function queryGroup(params?: RequestParams): Promise<PageResult<GroupItem>> {
  return request.post('/org/query_group_by_org_list', params);
}

export async function removeGroup(params: Record<string, any>): Promise<PageResult<GroupItem>> {
  return request.post('/org/remove_group', params);
}

export async function recoverGroup(params: Record<string, any>): Promise<PageResult<GroupItem>> {
  return request.post('/org/recover_group', params);
}

export async function addGroup(params: Record<string, any>): Promise<PageResult<GroupItem>> {
  return request.post('/org/create_group', params);
}

export async function updateGroup(params: Record<string, any>): Promise<PageResult<GroupItem>> {
  return request.post('/org/update_group', params);
}
export async function queryMemberByGroup(params: RequestParams): Promise<PageResult<GroupItem>> {
  return request.post('/org/query_member_by_group_list', params);
}

export interface GroupItem {
  key: string | number;
  callNo: number;
  avatar: string;
  desc: string;
  disabled: false;
  href: string;
  name: string;
  owner: string;
  progress: number;
  status: number;
  updatedAt: string;
  createdAt: string;
  id: number;
}
