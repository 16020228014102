import {
  create_insurance,
  delete_insurance,
  query_insurance_list,
  recover_insurance,
  update_insurance,
  upload_insurance_appendix,
  upload_insurance_other_appendix,
} from '@/api/cerp-model';
import { TableColumn } from '@/typing';
import { Record, SearchConfig } from '@/components/page-model/typing';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import {
  btn_list,
  config,
  event_obj,
  head_btn_list,
  slotList,
} from '@/components/page-model2/cerp/cerp-insurance-config';
import { createVNode, reactive, ref } from 'vue';
import { message, Modal, notification } from 'ant-design-vue';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { getDate, getDateTime } from '@/utils/function';

import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const None = null;
const True = true;
const False = false;

const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org

const search_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'inline',
  },
  fields: [
    {
      type: 'remoteselect',
      name: 'insurance_company_id',
      label: '保险公司',
      label_i18n: '保险公司',
      placeholder_i18n: '请选择保险公司',
      disabled: false,
      placeholder: '请选择保险公司',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      labelKey: 'company_name',
      valueKey: 'id',
      modalType: 'baseinfo-insurancecompany',
    },
    {
      type: 'remotemodal',
      name: 'adviser_id',
      label: '隶属顾问',
      label_i18n: '隶属顾问',
      placeholder_i18n: '请选择隶属顾问',
      disabled: false,
      defaultValue: '',
      labelKey: 'full_name',
      valueKey: 'id',
      placeholder: '隶属顾问',
      show_search: false,
      maxTagCount: 5,
      mode: 'default',
      title: t('选择隶属顾问'),
      modalType: 'cerp-adviser',
    },
    {
      type: 'input',
      name: 'policy_no',
      label: '保险单号',
      label_i18n: '保险单号',
      placeholder_i18n: '请填写保险单号',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写保险单号',
    },
    {
      type: 'datepicker',
      name: 'effective_date_time',
      label: '起保日期时间',
      label_i18n: '起保日期时间',
      placeholder_i18n: '请填写起保日期时间',
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      showTime: false,
      valueFormat: 'YYYY-MM-DD',
      placeholder: '请填写起保日期时间',
    },
    {
      type: 'datepicker',
      name: 'expiry_date_time',
      label: '停保日期时间',
      label_i18n: '停保日期时间',
      placeholder_i18n: '请填写停保日期时间',
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      showTime: false,
      valueFormat: 'YYYY-MM-DD',
      placeholder: '请填写停保日期时间',
    },
    {
      type: 'input',
      name: 'is_current',
      label: '当前保险',
      label_i18n: '当前保险',
      placeholder_i18n: '请填写当前保险',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写当前保险',
    },
    {
      type: 'input',
      name: 'insurance_fee',
      label: '保费',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写保费',
    },
  ],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    adviser_id: null,
    insurance_company_id: null,
    policy_no: null,
    effective_date_time: null,
    expiry_date_time: null,
    is_current: null,
    insurance_fee: null,
  }),
};
const search_options = {
  is_active: [
    { value: None, name: '全部' },
    { value: True, name: '可用' },
    { value: False, name: '禁用' },
  ],
};

const edit_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [
    {
      type: 'remoteselect',
      name: 'insurance_company_id',
      label: '保险公司',
      label_i18n: '保险公司',
      placeholder_i18n: '请选择保险公司',
      disabled: false,
      placeholder: '请选择保险公司',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      labelKey: 'company_name',
      valueKey: 'id',
      modalType: 'baseinfo-insurancecompany',
    },
    {
      type: 'input',
      name: 'policy_no',
      label: '保险单号',
      label_i18n: '保险单号',
      placeholder_i18n: '请填写保险单号',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写保险单号',
    },
    {
      type: 'datepicker',
      name: 'effective_date_time',
      label: '起保日期时间',
      label_i18n: '起保日期时间',
      placeholder_i18n: '请填写起保日期时间',
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      showTime: false,
      valueFormat: 'YYYY-MM-DD',
      placeholder: '请填写起保日期时间',
    },
    {
      type: 'datepicker',
      name: 'expiry_date_time',

      label: '停保日期时间',
      label_i18n: '停保日期时间',
      placeholder_i18n: '请填写停保日期时间',
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      showTime: false,
      valueFormat: 'YYYY-MM-DD',
      placeholder: '请填写停保日期时间',
    },
    {
      type: 'radio',
      name: 'is_current',
      label: '当前保险',
      label_i18n: '当前保险',
      placeholder_i18n: '请填写当前保险',
      disabled: false,
      labelKey: 'name',
      valueKey: 'value',
      defaultValue: '',
    },
    {
      type: 'input',
      name: 'insurance_fee',
      label: '保费',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写保费',
    },
    {
      type: 'input',
      name: 'month_insurance_fee',
      label: '每月保费',
      label_i18n: '每月保费',
      placeholder_i18n: '请填写每月保费',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写每月保费',
    },
  ],
  rules: {
    effective_date_time: [{ required: true, message: '请填输入起保日期时间' }],
    expiry_date_time: [{ required: true, message: '请填输入停保日期时间' }],
    is_current: [{ required: true, message: '请填输入当前保险' }],
    insurance_fee: [{ required: true, message: '请填输入保费' }],
  },
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    insurance_company_id: null,
    policy_no: null,
    effective_date_time: null,
    expiry_date_time: null,
    is_current: true,
    insurance_fee: null,
  }),
};
const edit_model_config_options = {
  is_current: [
    { value: True, name: t('是') },
    { value: False, name: t('否') },
  ],
};

const update_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [
    {
      type: 'input',
      name: 'adviser__full_name',
      label: '顾问',
      label_i18n: '顾问',
      placeholder_i18n: '请选择隶属顾问',
      disabled: true,
      placeholder: '请选择隶属顾问',
      datasourceType: 'remote',
      allowClear: false,
    },
    {
      type: 'remoteselect',
      name: 'insurance_company_id',
      label: '保险公司',
      label_i18n: '保险公司',
      placeholder_i18n: '请选择保险公司',
      disabled: false,
      placeholder: '请选择保险公司',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      labelKey: 'company_name',
      valueKey: 'id',
      modalType: 'baseinfo-insurancecompany',
    },
    {
      type: 'input',
      name: 'policy_no',
      label: '保险单号',
      label_i18n: '保险单号',
      placeholder_i18n: '请填写保险单号',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写保险单号',
    },
    {
      type: 'datepicker',
      name: 'effective_date_time',
      label: '起保日期时间',
      label_i18n: '起保日期时间',
      placeholder_i18n: '请填写起保日期时间',
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      showTime: false,
      valueFormat: 'YYYY-MM-DD',
      placeholder: '请填写起保日期时间',
    },
    {
      type: 'datepicker',
      name: 'expiry_date_time',

      label: '停保日期时间',
      label_i18n: '停保日期时间',
      placeholder_i18n: '请填写停保日期时间',
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      showTime: false,
      valueFormat: 'YYYY-MM-DD',
      placeholder: '请填写停保日期时间',
    },
    {
      type: 'radio',
      name: 'is_current',
      label: '当前保险',
      label_i18n: '当前保险',
      placeholder_i18n: '请填写当前保险',
      disabled: false,
      labelKey: 'name',
      valueKey: 'value',
      defaultValue: '',
    },
    {
      type: 'input',
      name: 'insurance_fee',
      label: '保费',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写保费',
    },
    {
      type: 'input',
      name: 'month_insurance_fee',
      label: '每月保费',
      label_i18n: '每月保费',
      placeholder_i18n: '请填写每月保费',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写每月保费',
    },
  ],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    insurance_company_id: null,
    policy_no: null,
    effective_date_time: null,
    expiry_date_time: null,
    is_current: null,
    insurance_fee: null,
    nsfile_id: null,
  }),
};
const update_model_config_options = {
  is_current: [
    { value: True, name: t('是') },
    { value: False, name: t('否') },
  ],
};

const titleList = {
  title: t('保险管理'),
  addModalTitle: t('新建保险'),
  updateTitle: t('修改保险'),
  is_create: false, // 是否有【新建】按钮
  is_update: false, // 是否有【修改】按钮
  is_remove: false, // 是否有【删除】按钮
  is_recover: true, // 是否有【恢复】按钮
};

const api_fields: Record = {
  update: {
    insurance_id: 'id',
  },
  delete: {
    insurance_id: 'id',
  },
  recover: {
    insurance_id: 'id',
  },
  updateData: {
    org_id: 'org_id',
    insurance_id: 'id',
  },
};

const baseColumns: TableColumn[] = [
  {
    title: t('序号'),
    dataIndex: 'index',
    width: 50,
  },
  {
    title: 'ID',
    width: 150,
    dataIndex: 'id',
    checked: false,
  },
  {
    title: t('隶属顾问'),
    width: 150,
    dataIndex: 'adviser__full_name',
    checked: true,
    sorter: {
      multiple: 1,
    },
  },
  {
    title: t('是否可用'),
    width: 100,
    dataIndex: 'is_active',
    checked: false,
    cellComponent: 'boolean',
    name1: t('可用'),
    name2: t('禁用'),
    filterMultiple: false,
    filters: [
      { text: t('全部'), value: '' },
      { text: t('可用'), value: true },
      { text: t('禁用'), value: false },
    ],
  },
  {
    title: t('保单'),
    width: 200,
    dataIndex: 'appendix_json',
    checked: true,
    cellComponent: 'clicklist',
    cb: (record: any) => {
      if (record && record.file_url) {
        const loading = ref(false);
        const url: string =
          'https://erp-cc.oss-cn-hongkong.aliyuncs.com/' + encodeURIComponent(record.file_url);
        window.open(url, '_blank');
        // axios
        //   .get('/' + record.file_url, {
        //     responseType: 'blob',
        //   })
        //   .then((res: any) => {
        //     const blob = res.data;
        //     // FileReader主要用于将文件内容读入内存
        //     const reader = new FileReader();
        //     reader.readAsDataURL(blob);
        //     // onload当读取操作成功完成时调用
        //     reader.onload = (e: any) => {
        //       const a = document.createElement('a');
        //       // 获取文件名fileName
        //       let fileName = res.config['url'].split('/');
        //       fileName = fileName[fileName.length - 1];
        //       // fileName = fileName.replace(/"/g, "");
        //       a.download = fileName;
        //       a.href = e.target.result;
        //       document.body.appendChild(a);
        //       a.click();
        //       document.body.removeChild(a);
        //     };
        //   });
      } else {
        notification.error({
          message: t('提示'),
          description: t('无对应附件'),
        });
      }
    },
    del: (record: any, text: any, file: any) => {
      if (file.file_id) {
        Modal.confirm({
          title: t('提示'),
          content: t('是否删除') + '[' + file.name + ']',
          icon: createVNode(ExclamationCircleOutlined),
          onOk() {
            return new Promise(resolve => {
              let ids: any = [];
              text.forEach((itme: any) => {
                if (itme.file_id != file.file_id) {
                  ids.push(itme.file_id);
                }
              });
              if (ids.length == 0) {
                ids = 0;
              }
              upload_insurance_appendix({
                insurance_id: record.id,
                appendix_ids: ids,
                org_id: current_org?.id,
              })
                .then(() => {
                  message.success(t('删除成功'));
                  event_obj.emit('refresh');
                })
                .finally(() => {
                  resolve(null);
                });
            });
          },
          onCancel() {},
        });
      }
    },
  },
  {
    title: t('其他附件'),
    width: 200,
    dataIndex: 'other_appendix_json',
    checked: true,
    cellComponent: 'clicklist',
    cb: (record: any) => {
      if (record && record.file_url) {
        const loading = ref(false);
        const url: string =
          'https://erp-cc.oss-cn-hongkong.aliyuncs.com/' + encodeURIComponent(record.file_url);
        window.open(url, '_blank');
        // axios
        //   .get('/' + record.file_url, {
        //     responseType: 'blob',
        //   })
        //   .then((res: any) => {
        //     const blob = res.data;
        //     // FileReader主要用于将文件内容读入内存
        //     const reader = new FileReader();
        //     reader.readAsDataURL(blob);
        //     // onload当读取操作成功完成时调用
        //     reader.onload = (e: any) => {
        //       const a = document.createElement('a');
        //       // 获取文件名fileName
        //       let fileName = res.config['url'].split('/');
        //       fileName = fileName[fileName.length - 1];
        //       // fileName = fileName.replace(/"/g, "");
        //       a.download = fileName;
        //       a.href = e.target.result;
        //       document.body.appendChild(a);
        //       a.click();
        //       document.body.removeChild(a);
        //     };
        //   });
      } else {
        notification.error({
          message: t('提示'),
          description: t('无对应附件'),
        });
      }
    },
    del: (record: any, text: any, file: any) => {
      if (file.file_id) {
        Modal.confirm({
          title: t('提示'),
          content: t('是否删除') + '[' + file.name + ']',
          icon: createVNode(ExclamationCircleOutlined),
          onOk() {
            return new Promise(resolve => {
              let ids: any = [];
              text.forEach((itme: any) => {
                if (itme.file_id != file.file_id) {
                  ids.push(itme.file_id);
                }
              });
              if (ids.length == 0) {
                ids = 0;
              }
              upload_insurance_other_appendix({
                insurance_id: record.id,
                other_appendix_ids: ids,
                org_id: current_org?.id,
              })
                .then(() => {
                  message.success(t('删除成功'));
                  event_obj.emit('refresh');
                })
                .finally(() => {
                  resolve(null);
                });
            });
          },
          onCancel() {},
        });
      }
    },
  },
  {
    title: t('保险公司'),
    width: 150,
    dataIndex: 'insurance_company__company_name',
    checked: true,
  },
  {
    title: t('保险单号'),
    width: 150,
    dataIndex: 'policy_no',
    checked: true,
  },
  {
    title: t('起保日期时间'),
    width: 150,
    dataIndex: 'effective_date_time',
    checked: true,
    sorter: {
      multiple: 1,
    },
    content: (record: any) => {
      return getDate(record.effective_date_time);
    },
  },
  {
    title: t('停保日期时间'),
    width: 150,
    dataIndex: 'expiry_date_time',
    checked: true,
    sorter: {
      multiple: 1,
    },
    content: (record: any) => {
      return getDate(record.expiry_date_time);
    },
  },
  {
    title: t('受益人'),
    width: 120,
    dataIndex: 'beneficiaries',
    checked: true,
    cellComponent: 'list',
    listKey: 'name',
  },
  {
    title: t('当前保险'),
    width: 150,
    dataIndex: 'is_current',
    checked: true,
    cellComponent: 'boolean',
    name1: t('是'),
    name2: t('否'),
    filterMultiple: false,
    filters: [
      { text: t('全部'), value: '' },
      { text: t('是'), value: true },
      { text: t('否'), value: false },
    ],
  },
  {
    title: t('保险类型'),
    width: 150,
    dataIndex: 'insurance_type__name',
    checked: true,
  },
  {
    title: t('每月保费'),
    width: 150,
    dataIndex: 'month_insurance_fee',
    checked: true,
  },
  {
    title: t('备注'),
    width: 150,
    dataIndex: 'remark',
    checked: true,
  },
  {
    title: t('创建时间'),
    width: 150,
    dataIndex: 'create_time',
    checked: true,
    sorter: {
      multiple: 1,
    },
    content: (record: any) => {
      return getDateTime(record.create_time);
    },
  },
  {
    title: t('更新时间'),
    width: 150,
    dataIndex: 'update_time',
    checked: true,
    sorter: {
      multiple: 1,
    },
    content: (record: any) => {
      return getDateTime(record.update_time);
    },
  },

  {
    title: t('操作'),
    width: 100,
    dataIndex: 'action',
    checked: false,
    cellComponent: 'action',
    fixed: 'right',
  },
];
const newSlotList: any = [];
Object.assign(newSlotList, slotList);
newSlotList.push(
  {
    type: 'custom',
    // slotName: 'is_active',
    field_true: '可用',
    field_false: '禁用',
  },
  {
    type: 'clickList',
    slotName: 'appendix_json',
    cb: (record: any) => {
      if (record && record.file_url) {
        const loading = ref(false);
        const url: string =
          'https://erp-cc.oss-cn-hongkong.aliyuncs.com/' + encodeURIComponent(record.file_url);
        window.open(url, '_blank');
        // axios.get('/' + record.file_url,{
        //   responseType: 'blob' }).then((res: any) => {
        //   var blob = res.data;
        //   // FileReader主要用于将文件内容读入内存
        //   var reader = new FileReader();
        //   reader.readAsDataURL(blob);
        //   // onload当读取操作成功完成时调用
        //   reader.onload = (e: any) => {
        //     var a = document.createElement('a');
        //     // 获取文件名fileName
        //     var fileName = res.config["url"].split("/");
        //     fileName = fileName[fileName.length - 1];
        //     // fileName = fileName.replace(/"/g, "");
        //     a.download = fileName;
        //     a.href = e.target.result;
        //     document.body.appendChild(a);
        //     a.click();
        //     document.body.removeChild(a);
        //   }
        // })
      } else {
        notification.error({
          message: t('提示'),
          description: t('无对应附件'),
        });
      }
    },
    del: (record: any, text: any, file: any) => {
      if (file.file_id) {
        Modal.confirm({
          title: t('提示'),
          content: t('是否删除') + '[' + file.name + ']',
          icon: createVNode(ExclamationCircleOutlined),
          onOk() {
            return new Promise(resolve => {
              let ids: any = [];
              text.forEach((itme: any) => {
                if (itme.file_id != file.file_id) {
                  ids.push(itme.file_id);
                }
              });
              if (ids.length == 0) {
                ids = 0;
              }
              update_insurance({
                insurance_id: record.id,
                appendix_ids: ids,
                org_id: current_org?.id,
              })
                .then(() => {
                  message.success(t('删除成功'));
                  event_obj.emit('refresh');
                })
                .finally(() => {
                  resolve(null);
                });
            });
          },
          onCancel() {},
        });
      }
    },
  },
);

export default () => {
  return {
    searchConfig: search_config,
    searchConfigOptions: search_options,
    formModalConfig: edit_model_config,
    formModalConfigOptions: edit_model_config_options,
    updateFormModalConfig: update_model_config,
    updateFormModalConfigOptions: update_model_config_options,
    queryData: query_insurance_list,
    addData: create_insurance,
    updateData: update_insurance,
    removeData: delete_insurance,
    recoverData: recover_insurance,
    baseColumns: baseColumns,
    slotList: newSlotList,
    titleList: titleList,
    api_fields: api_fields,
    btn_list: btn_list,
    head_btn_list: head_btn_list,
    event_obj: event_obj,
  };
};
