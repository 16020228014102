import mitt from 'mitt';
import defaultSlotList from '@/components/page-model/slotList';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import { reactive } from 'vue';
import { create_next_of_kin } from '@/api/cerp-model';
import { message } from 'ant-design-vue';
import modalBox from '@/components/form-modal/modal-tools';

export const event_obj = mitt();
export const btn_list = [
  {
    name: '备注',
    type: 'dropdown',
    clazz: 'primary',
    cb: (record: any) => {
      const modifyModal: any = reactive({
        visible: false,
        component: 'NoteModel',
        params: {
          object_id: record.id,
          note_type_flag: 'cerp.nextofkin',
        },
      });
      modifyModal.visible = true;
      modifyModal.ok = () => {
        event_obj.emit('refresh');
      };
      modifyModal.cancel = () => {
      };
      modalBox(modifyModal);
    },
  },
];
export const slotList = defaultSlotList;
export const config = {
  creatCol: [],
  updateCol: [],
};

export const head_btn_list = [
  {
    name: '新建',
    clazz: 'primary',
    cb: (record: any, dataSource: any, t: any) => {
      const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
      const editModal = reactive({
        visible: true,
        title: t('新建紧急联系人'),
        loading: false,
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'vertical',
            col: [],
          },
          fields: [
            {
              type: 'input',
              name: 'adviser__full_name',
              label: '顾问',
              label_i18n: '顾问',
              placeholder_i18n: '请选择隶属顾问',
              disabled: true,
              placeholder: '请选择隶属顾问',
              datasourceType: 'remote',
              allowClear: false,
            },
            {
              type: 'input',
              name: 'name',
              label: '姓名',
              label_i18n: '姓名',
              placeholder_i18n: '请填写姓名',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写姓名',
            },
            {
              type: 'input',
              name: 'releationship',
              label: '关系',
              label_i18n: '关系',
              placeholder_i18n: '请填写关系',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写关系',
            },
            {
              type: 'input',
              name: 'contact_number',
              label: '联系电话',
              label_i18n: '联系电话',
              placeholder_i18n: '请填写联系电话',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写联系电话',
            },
            {
              type: 'input',
              name: 'email',
              label: '联系邮箱',
              label_i18n: '联系邮箱',
              placeholder_i18n: '请填写联系邮箱',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写联系邮箱',
            },
          ],
          rules: {
            name: [{ required: true, message: t('请填输入姓名') }],
            releationship: [{ required: true, message: t('请填输入关系') }],
          },
          model: {
            org_id: current_org?.id,
            is_active: true,
            adviser_id: record.id,
            adviser__full_name: record.name,
            name: null,
            releationship: null,
            contact_number: null,
            email: null,
          },
        },
        options: {},
        ok: (payload: any) => {
          return new Promise(resolve => {
            editModal.loading = true;
            create_next_of_kin(payload)
              .then(() => {
                message.success(t('新建成功'));
                event_obj.emit('refresh');
                resolve(null);
              })
              .finally(() => {
                editModal.loading = false;
              });
          });
        },
      });
      modalBox(editModal);
    },
  },
];
