import {
  create_invoice,
  delete_invoice,
  query_invoice_list,
  recover_invoice,
  update_invoice,
  upload_invoice_appendix,
} from '@/api/cerp-model';
import { TableColumn } from '@/typing';
import { Record, SearchConfig } from '@/components/page-model/typing';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import {
  btn_list,
  config,
  event_obj,
  head_btn_list,
  slotList,
} from '@/components/page-model2/cerp/cerp-invoice-config';
import { createVNode, reactive } from 'vue';
import { message, Modal, notification } from 'ant-design-vue';
import numerals from '@/utils/numeral';
import router from '@/router';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { getDate, getDateTime } from '@/utils/function';

import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const None = null;
const True = true;
const False = false;

const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org

const search_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'inline',
  },
  fields: [
    {
      type: 'remotemodal',
      name: 'adviser_id',
      label: '隶属顾问',
      label_i18n: '隶属顾问',
      placeholder_i18n: '请选择隶属顾问',
      disabled: false,
      defaultValue: '',
      labelKey: 'full_name',
      valueKey: 'id',
      placeholder: '隶属顾问',
      show_search: false,
      maxTagCount: 5,
      mode: 'default',
      title: t('选择隶属顾问'),
      modalType: 'cerp-adviser',
    },
    // {
    //   type: 'remoteselect',
    //   name: 'invoice_type_id',
    //   label: '请款类型',
    //   disabled: false,
    //   placeholder: '请选择请款类型',
    //   datasourceType: 'remote',
    //   mode: 'default',
    //   allowClear: true,
    //   modalType: 'baseinfo-invoicetype',
    // },
    // {
    //   type: 'remoteselect',
    //   name: 'bank_account_id',
    //   label: '银行账户',
    //   disabled: false,
    //   placeholder: '请选择银行账户',
    //   datasourceType: 'remote',
    //   mode: 'default',
    //   allowClear: true,
    //   labelKey: 'account_flag',
    //   valueKey: 'id',
    //   modalType: 'baseinfo-ccbankaccountscc',
    // },
    {
      type: 'select',
      name: 'status',
      label: '状态',
      label_i18n: '状态',
      mode: 'default',
      disabled: false,
      defaultValue: '',
      labelKey: 'name',
      valueKey: 'value',
      placeholder: '请选择状态',
      datasource: 'status',
    },
    {
      type: 'select',
      name: 'is_active',
      label: '是否可用',
      mode: 'default',
      disabled: false,
      defaultValue: '',
      labelKey: 'name',
      valueKey: 'value',
      placeholder: '请选择是否可用',
      datasource: 'is_active',
    },
  ],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    status: null,
    contract_id: null,
    adviser_id: null,
    invoice_type_id: null,
    bank_account_id: null,
  }),
};
const search_options = {
  is_active: [
    { value: None, name: '全部' },
    { value: True, name: '可用' },
    { value: False, name: '禁用' },
  ],
  status: [
    { value: None, name: '全部' },
    { value: 1, name: t('新创建') },
    { value: 2, name: t('已发送') },
    { value: 3, name: t('已收到请款') },
    { value: 4, name: t('已作废') },
    { value: 5, name: t('生成工资单') },
    { value: 6, name: t('生成PDF') },
    { value: 7, name: t('垫付') },
  ],
};

const edit_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [
    {
      type: 'remoteselect',
      name: 'contract_id',
      label: '合同',
      label_i18n: '合同',
      placeholder_i18n: '请选择合同',
      disabled: false,
      placeholder: '请选择合同',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      labelKey: 'id',
      valueKey: 'id',
      modalType: 'cerp-contract',
    },
    {
      type: 'datepicker',
      name: 'invoice_start_date',
      label: '请款起始日期',
      label_i18n: '请款起始日期',
      placeholder_i18n: '请款起始日期',
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      showTime: true,
      valueFormat: 'YYYY-MM-DD',
      placeholder: '请填写请款起始日期',
    },
    {
      type: 'datepicker',
      name: 'invoice_end_date',
      label: '请款截止日期',
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      showTime: true,
      valueFormat: 'YYYY-MM-DD',
      placeholder: '请填写请款截止日期',
    },
    {
      type: 'datepicker',
      name: 'date_submitted',
      label: '提交日期',
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      showTime: true,
      valueFormat: 'YYYY-MM-DD',
      placeholder: '请填写提交日期',
    },
    {
      type: 'input',
      name: 'work_unit',
      label: '普通工时单位',
      label_i18n: '普通工时单位',
      placeholder_i18n: '请填写普通工时单位',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写普通工时单位',
    },
    {
      type: 'input',
      name: 'accommodation_unit',
      label: '宿款单位',
      label_i18n: '宿款单位',
      placeholder_i18n: '请填写宿款单位',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写宿款单位',
    },
    {
      type: 'input',
      name: 'overtime',
      label: '加班单位',
      label_i18n: '加班单位',
      placeholder_i18n: '请填写加班单位',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写加班单位',
    },
    {
      type: 'remoteselect',
      name: 'custom_id',
      label: '自定义',
      disabled: false,
      placeholder: '请选择自定义',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'cerp-customfieldset',
    },
    {
      type: 'input',
      name: 'remark',
      label: '备注',
      label_i18n: '备注',
      placeholder_i18n: '请填写备注',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写备注',
    },
    {
      type: 'remoteselect',
      name: 'invoice_type_id',
      label: '请款类型',
      label_i18n: '请款类型',
      placeholder_i18n: '请选择请款类型',
      disabled: false,
      placeholder: '请选择请款类型',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-invoicetype',
    },
    {
      type: 'input',
      name: 'payment_term',
      label: '付款周期',
      label_i18n: '付款周期',
      placeholder_i18n: '请填写付款周期',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写付款周期',
    },
    {
      type: 'remoteselect',
      name: 'bank_account_id',
      label: '银行账户',
      label_i18n: '银行账户',
      placeholder_i18n: '请选择银行账户',
      disabled: false,
      placeholder: '请选择银行账户',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      labelKey: 'account_flag',
      valueKey: 'id',
      modalType: 'baseinfo-ccbankaccountscc',
    },
  ],
  rules: {
    work_unit: [{ required: true, message: '请填输入普通工时单位' }],
    accommodation_unit: [{ required: true, message: '请填输入宿款单位' }],
    overtime: [{ required: true, message: '请填输入加班单位' }],
    remark: [{ required: true, message: '请填输入备注' }],
    payment_term: [{ required: true, message: '请填输入付款周期' }],
  },
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    contract_id: null,
    invoice_start_date: null,
    invoice_end_date: null,
    date_submitted: null,
    work_unit: null,
    accommodation_unit: null,
    overtime: null,
    custom_id: null,
    remark: null,
    invoice_type_id: null,
    payment_term: null,
    bank_account_id: null,
  }),
};
const edit_model_config_options = {};

const update_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [
    {
      type: 'monthpicker',
      name: 'service_month',
      label: '服务月份',
      label_i18n: '服务月份',
      placeholder_i18n: '请填写服务月份',
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      showTime: false,
      valueFormat: 'YYYY-MM-DD',
      format: 'YYYY-MM',
      placeholder: '请填写服务月份',
    },
    {
      type: 'datepicker',
      name: 'invoice_start_date',
      label: '请款起始日期',
      label_i18n: '请款起始日期',
      placeholder_i18n: '请款起始日期',
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      showTime: true,
      valueFormat: 'YYYY-MM-DD HH:mm',
      format: 'YYYY-MM-DD HH:mm',
      placeholder: '请填写请款起始日期',
    },
    {
      type: 'datepicker',
      name: 'invoice_end_date',
      label: '请款截止日期',
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      showTime: true,
      valueFormat: 'YYYY-MM-DD HH:mm',
      format: 'YYYY-MM-DD HH:mm',
      placeholder: '请填写请款截止日期',
    },
    {
      type: 'datepicker',
      name: 'date_submitted',
      label: '提交日期',
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      showTime: true,
      valueFormat: 'YYYY-MM-DD HH:mm',
      format: 'YYYY-MM-DD HH:mm',
      placeholder: '请填写提交日期',
    },
    {
      type: 'money',
      name: 'basic_amount',
      label: '基本工资总额',
      label_i18n: '基本工资总额',
      placeholder_i18n: '请填写基本工资总额',
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      placeholder: '请填写基本工资总额',
    },
    {
      type: 'input',
      name: 'work_unit',
      label: '普通工时单位',
      label_i18n: '普通工时单位',
      placeholder_i18n: '请填写普通工时单位',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写普通工时单位',
    },
    {
      type: 'input',
      name: 'accommodation_unit',
      label: '宿款单位',
      label_i18n: '宿款单位',
      placeholder_i18n: '请填写宿款单位',
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      placeholder: '请填写宿款单位',
    },
    {
      type: 'money',
      name: 'accommodation_amount',
      label: '住宿总额',
      label_i18n: '住宿总额',
      placeholder_i18n: '请填写住宿总额',
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      placeholder: '请填写住宿总额',
    },
    {
      type: 'input',
      name: 'overtime',
      label: '加班单位',
      label_i18n: '加班单位',
      placeholder_i18n: '请填写加班单位',
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      placeholder: '请填写加班单位',
    },
    {
      type: 'input',
      name: 'remark',
      label: '备注',
      label_i18n: '备注',
      placeholder_i18n: '请填写备注',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写备注',
    },
    {
      type: 'input',
      name: 'payment_term',
      label: '付款周期',
      label_i18n: '付款周期',
      placeholder_i18n: '请填写付款周期',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写付款周期',
    },
    {
      type: 'remoteselect',
      name: 'bank_account_id',
      label: '银行账户',
      label_i18n: '银行账户',
      placeholder_i18n: '请选择银行账户',
      disabled: false,
      placeholder: '请选择银行账户',
      datasourceType: 'remote',
      mode: 'default',
      labelKeyList: ['account_holder', 'account_number'],
      allowClear: true,
      labelKey: 'account_number',
      modalType: 'cerp-bankaccount',
    },
  ],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    contract_id: null,
    invoice_start_date: null,
    invoice_end_date: null,
    date_submitted: null,
    work_unit: null,
    accommodation_unit: null,
    overtime: null,
    custom_id: null,
    remark: null,
    invoice_type_id: null,
    payment_term: null,
    bank_account_id: null,
  }),
};
const update_model_config_options = {};

const titleList = {
  title: '请款单管理',
  addModalTitle: '新建请款单',
  updateTitle: '修改请款单',
  is_create: false, // 是否有【新建】按钮
  is_update: false, // 是否有【修改】按钮
  is_remove: false, // 是否有【删除】按钮
  is_recover: false, // 是否有【恢复】按钮
};

const api_fields: Record = {
  update: {
    invoice_id: 'id',
  },
  delete: {
    invoice_id: 'id',
  },
  recover: {
    invoice_id: 'id',
  },
  updateData: {
    org_id: 'org_id',
    invoice_id: 'id',
  },
};

const baseColumns: TableColumn[] = [
  {
    title: t('序号'),
    dataIndex: 'index',
    width: 50,
    fixed: 'left',
  },
  {
    title: 'ID',
    width: 150,
    dataIndex: 'id',
    checked: false,
    fixed: 'left',
  },
  {
    title: t('顾问'),
    width: 150,
    dataIndex: 'adviser__full_name',
    checked: true,
    sorter: {
      multiple: 1,
    },
    fixed: 'left',
  },
  {
    title: t('请款单号'),
    width: 160,
    dataIndex: 'invoice_no',
    checked: true,
    sorter: {
      multiple: 1,
    },
    fixed: 'left',
  },
  {
    title: t('版本号'),
    width: 90,
    dataIndex: 'version',
    checked: true,
  },
  {
    title: t('工号'),
    width: 80,
    dataIndex: 'adviser__employee_number',
    checked: true,
  },
  {
    title: t('是否可用'),
    width: 100,
    dataIndex: 'is_active',
    checked: false,
    cellComponent: 'boolean',
    name1: t('可用'),
    name2: t('禁用'),
    filterMultiple: false,
    filters: [
      { text: t('全部'), value: '' },
      { text: t('可用'), value: true },
      { text: t('禁用'), value: false },
    ],
  },
  {
    title: t('状态'),
    width: 100,
    dataIndex: 'status',
    checked: true,
    content: (record: any) => {
      let color = '';
      if (record.invoice_type != 5) {
        switch (record.status) {
          case 1:
            color = t('新创建');
            break;
          case 2:
            color = t('已发送');
            break;
          case 3:
            color = t('已收到请款');
            break;
          case 4:
            color = t('已作废');
            break;
          case 5:
            color = t('生成工资单');
            break;
          case 6:
            color = t('生成PDF');
            break;
          case 7:
            color = t('垫付');
            break;
          default:
            color = '';
            break;
        }
      } else if (record.invoice_type == 5) {
        switch (record.status) {
          case 1:
            color = t('新创建');
            break;
          case 2:
            color = t('已发送');
            break;
          case 3:
            color = t('已收到请款');
            break;
          case 4:
            color = t('已作废');
            break;
          case 5:
            color = t('报销已入账');
            break;
          case 6:
            color = t('生成PDF');
            break;
          case 7:
            color = t('垫付');
            break;
          default:
            color = '';
            break;
        }
      }

      return color;
    },
    filterMultiple: false,
    filters: [
      { text: t('全部'), value: '' },
      { text: t('新创建'), value: 1 },
      { text: t('已发送'), value: 2 },
      { text: t('已收到请款'), value: 3 },
      { text: t('已作废'), value: 4 },
      { text: t('生成工资单'), value: 5 },
      { text: t('生成PDF'), value: 6 },
      { text: t('垫付'), value: 7 },
    ],
  },
  {
    title: t('pdf生成状态'),
    width: 140,
    dataIndex: 'pdf_status',
    checked: true,
    content: (record: any) => {
      let color = '';
      switch (record.pdf_status) {
        case 1:
          color = t('正在生成');
          break;
        case 2:
          color = t('失败');
          break;
        case 3:
          color = t('成功');
          break;
        case 0:
          color = t('未开始');
          break;
        default:
          color = '';
          break;
      }
      return color;
    },
    filterMultiple: false,
    filters: [
      { text: t('全部'), value: '' },
      { text: t('未开始'), value: 0 },
      { text: t('正在生成'), value: 1 },
      { text: t('失败'), value: 2 },
      { text: t('成功'), value: 3 },
    ],
  },
  {
    title: t('pdf生成日志'),
    width: 100,
    dataIndex: 'pdf_log',
    checked: true,
  },
  {
    title: t('邮件发送状态'),
    width: 120,
    dataIndex: 'email_record__status',
    checked: true,
    color: (record: any) => {
      let color = '';
      switch (record.email_record__status) {
        case 1:
          color = 'font-weight: bolder';
          break;
        case 3:
          color = 'font-weight: bolder';
          break;
        case 2:
          color = 'color: #FF0000;font-weight: bolder';
          break;
        case 0:
          color = 'font-weight: bolder';
          break;
        default:
          color = '';
          break;
      }
      return color;
    },
    content: (record: any) => {
      let color = '';
      switch (record.email_record__status) {
        case 1:
          color = t('发送成功');
          break;
        case 2:
          color = t('发送失败');
          break;
        case 3:
          color = t('手动发送成功');
          break;
        case 0:
          color = t('已发送');
          break;
        default:
          color = '';
          break;
      }
      return color;
    },
  },
  {
    title: t('邮件发送日志'),
    width: 120,
    dataIndex: 'email_record__log',
    checked: true,
  },
  {
    title: '邮件发送时间',
    width: 120,
    dataIndex: 'email_record__create_time',
    checked: true,
    content: (record: any) => {
      return getDateTime(record.email_record__create_time);
    },
  },
  {
    title: t('邮件更新时间'),
    width: 120,
    dataIndex: 'email_record__update_time',
    checked: true,
    content: (record: any) => {
      return getDateTime(record.email_record__update_time);
    },
  },
  {
    title: t('请款类型'),
    width: 100,
    dataIndex: 'invoice_type',
    checked: true,
    content: (record: any) => {
      let color = '';
      switch (record.invoice_type) {
        case 1:
          color = t('工资');
          break;
        case 2:
          color = t('报销');
          break;
        case 3:
          color = 'credit note';
          break;
        case 4:
          color = t('加班');
          break;
        case 5:
          color = t('CC报销');
          break;
        default:
          color = '';
          break;
      }
      return color;
    },
    filterMultiple: false,
    filters: [
      { text: t('全部'), value: '' },
      { text: t('工资'), value: 1 },
      { text: t('报销'), value: 2 },
      { text: 'credit note', value: 3 },
      { text: t('加班'), value: 4 },
      { text: t('CC报销'), value: 5 },
    ],
  },
  {
    title: 'PDF',
    width: 200,
    dataIndex: 'files_json',
    checked: true,
    cellComponent: 'click',
    content: (record: any) => {
      if (record.files_json) {
        return record.files_json?.name;
      } else {
        return '';
      }
    },
    cb: (record: any) => {
      if (record.files_json && record.files_json.file_url) {
        const url: string =
          'https://erp-cc.oss-cn-hongkong.aliyuncs.com/' +
          encodeURIComponent(record.files_json.file_url);
        window.open(url, '_blank');
        // axios.get('/' + record.files_json.file_url,{
        //   responseType: 'blob' }).then((res: any) => {
        //   var blob = res.data;
        //   // FileReader主要用于将文件内容读入内存
        //   var reader = new FileReader();
        //   reader.readAsDataURL(blob);
        //   // onload当读取操作成功完成时调用
        //   reader.onload = (e: any) => {
        //     var a = document.createElement('a');
        //     // 获取文件名fileName
        //     var fileName = res.config["url"].split("/");
        //     fileName = fileName[fileName.length - 1];
        //     // fileName = fileName.replace(/"/g, "");
        //     a.download = fileName;
        //     a.href = e.target.result;
        //     document.body.appendChild(a);
        //     a.click();
        //     document.body.removeChild(a);
        //   }
        // })
      } else {
        notification.error({
          message: t('提示'),
          description: t('无对应附件'),
        });
      }
    },
  },
  {
    title: 'Excel',
    width: 200,
    dataIndex: 'excel_files_json',
    checked: true,
    cellComponent: 'click',
    content: (record: any) => {
      if (record.excel_files_json) {
        return record.excel_files_json?.name;
      } else {
        return '';
      }
    },
    cb: (record: any) => {
      if (record.excel_files_json && record.excel_files_json.file_url) {
        const url: string =
          'https://erp-cc.oss-cn-hongkong.aliyuncs.com/' +
          encodeURIComponent(record.excel_files_json.file_url);
        window.open(url, '_blank');
        // axios.get('/' + record.excel_files_json.file_url,{
        //   responseType: 'blob' }).then((res: any) => {
        //   var blob = res.data;
        //   // FileReader主要用于将文件内容读入内存
        //   var reader = new FileReader();
        //   reader.readAsDataURL(blob);
        //   // onload当读取操作成功完成时调用
        //   reader.onload = (e: any) => {
        //     var a = document.createElement('a');
        //     // 获取文件名fileName
        //     var fileName = res.config["url"].split("/");
        //     fileName = fileName[fileName.length - 1];
        //     // fileName = fileName.replace(/"/g, "");
        //     a.download = fileName;
        //     a.href = e.target.result;
        //     document.body.appendChild(a);
        //     a.click();
        //     document.body.removeChild(a);
        //   }
        // })
      } else {
        notification.error({
          message: t('提示'),
          description: t('无对应附件'),
        });
      }
    },
  },
  {
    title: t('CC公司'),
    width: 200,
    dataIndex: 'contract__cc_company__name',
    checked: true,
  },
  {
    title: t('顾问公司'),
    width: 200,
    dataIndex: 'contract__recuitment_company__company_name',
    checked: true,
  },
  {
    title: t('合同起始日期'),
    width: 150,
    dataIndex: 'contract__start_date',
    checked: true,
    content: (record: any) => {
      return getDate(record.contract__start_date);
    },
  },
  {
    title: t('合同结束日期'),
    width: 150,
    dataIndex: 'contract__end_date',
    checked: true,
    content: (record: any) => {
      return getDate(record.contract__end_date);
    },
  },
  {
    title: t('汇款凭证'),
    width: 200,
    dataIndex: 'remittance__remittance_set__remittance_name',
    checked: true,
    cellComponent: 'click',
    content: (record: any) => {
      if (record.remittance__remittance_set__remittance_name) {
        return record.remittance__remittance_set__remittance_name;
      } else {
        return '';
      }
    },
    cb: (record: any) => {
      router.push({
        path: '/adviser-manage/cerp-remittanceset',
        query: { ids: [parseInt(record.remittance__remittance_set_id)], t: new Date().getTime() },
      });
    },
  },
  {
    title: t('汇款凭证项金额'),
    width: 140,
    dataIndex: 'remittance__amount',
    checked: true,
    color: (record: any) => {
      if (!record.remittance__amount) {
        return '';
      }
      if (Number(record.total_amount.toFixed(0)) < Number(record.remittance__amount.toFixed(0))) {
        return 'color: #00BBFF;font-weight: bolder';
      } else if (
        Number(record.total_amount.toFixed(0)) == Number(record.remittance__amount.toFixed(0))
      ) {
        return 'color: #00DD00;font-weight: bolder';
      } else if (
        Number(record.total_amount.toFixed(0)) > Number(record.remittance__amount.toFixed(0))
      ) {
        return 'color: #FF0000;font-weight: bolder';
      }
    },
    content: (record: any) => {
      if (record.remittance__amount) {
        return numerals.money(record.remittance__amount);
      } else {
        return '';
      }
    },
  },
  {
    title: t('汇率'),
    width: 150,
    dataIndex: 'exchange_rate',
    checked: true,
  },
  {
    title: t('服务月份'),
    width: 150,
    dataIndex: 'service_month',
    checked: true,
    sorter: {
      multiple: 1,
    },
    cellComponent: 'month',
  },
  {
    title: t('请款起始日期'),
    width: 150,
    dataIndex: 'invoice_start_date',
    checked: true,
    sorter: {
      multiple: 1,
    },
    content: (record: any) => {
      return getDate(record.invoice_start_date);
    },
  },
  {
    title: t('请款截止日期'),
    width: 150,
    dataIndex: 'invoice_end_date',
    checked: true,
    sorter: {
      multiple: 1,
    },
    content: (record: any) => {
      return getDate(record.invoice_end_date);
    },
  },
  {
    title: t('提交日期'),
    width: 150,
    dataIndex: 'date_submitted',
    checked: true,
    content: (record: any) => {
      return getDate(record.date_submitted);
    },
  },
  {
    title: t('普通工时单位'),
    width: 150,
    dataIndex: 'work_unit',
    checked: true,
  },
  {
    title: t('基本工资总额'),
    width: 150,
    dataIndex: 'basic_amount',
    checked: true,
    cellComponent: 'money',
  },
  {
    title: t('宿款单位'),
    width: 150,
    dataIndex: 'accommodation_unit',
    checked: true,
  },
  {
    title: t('住宿总额'),
    width: 150,
    dataIndex: 'accommodation_amount',
    checked: true,
    cellComponent: 'money',
  },
  {
    title: t('加班单位'),
    width: 150,
    dataIndex: 'overtime',
    checked: true,
  },
  {
    title: t('加班总额'),
    width: 150,
    dataIndex: 'overtime_amount',
    checked: true,
    cellComponent: 'money',
  },
  {
    title: t('管理费'),
    width: 150,
    dataIndex: 'management_fee',
    checked: true,
    cellComponent: 'money',
  },
  {
    title: t('总金额'),
    width: 150,
    dataIndex: 'total_amount',
    checked: true,
    cellComponent: 'money',
  },
  {
    title: t('银行转账费'),
    width: 100,
    dataIndex: 'bank_charge_cost',
    checked: true,
    cellComponent: 'money',
  },
  {
    title: t('保险费'),
    width: 100,
    dataIndex: 'insurance_fee',
    checked: true,
    cellComponent: 'money',
  },
  {
    title: t('备注'),
    width: 150,
    dataIndex: 'remark',
    checked: true,
    cellComponent: 'remark',
  },
  {
    title: t('计算公式备注'),
    width: 150,
    dataIndex: 'formula_note',
    checked: true,
  },
  {
    title: t('付款周期（天）'),
    width: 150,
    dataIndex: 'payment_term',
    checked: true,
  },
  {
    title: t('CC收款账户'),
    width: 150,
    dataIndex: 'bank_account__account_flag',
    checked: true,
  },
  {
    title: t('账号'),
    width: 150,
    dataIndex: 'bank_account__account_number',
    checked: true,
  },
  {
    title: t('创建人'),
    width: 150,
    dataIndex: 'founder',
    checked: true,
  },
  {
    title: t('修改人'),
    width: 150,
    dataIndex: 'reviser',
    checked: true,
  },
  {
    title: t('工时证明文件'),
    width: 200,
    dataIndex: 'appendix_json_list',
    checked: true,
    cellComponent: 'clicklist',
    cb: (record: any) => {
      if (record && record.file_url) {
        const url: string =
          'https://erp-cc.oss-cn-hongkong.aliyuncs.com/' + encodeURIComponent(record.file_url);
        window.open(url, '_blank');
        // axios.get('/' + record.file_url,{
        //   responseType: 'blob' }).then((res: any) => {
        //   var blob = res.data;
        //   // FileReader主要用于将文件内容读入内存
        //   var reader = new FileReader();
        //   reader.readAsDataURL(blob);
        //   // onload当读取操作成功完成时调用
        //   reader.onload = (e: any) => {
        //     var a = document.createElement('a');
        //     // 获取文件名fileName
        //     var fileName = res.config["url"].split("/");
        //     fileName = fileName[fileName.length - 1];
        //     // fileName = fileName.replace(/"/g, "");
        //     a.download = fileName;
        //     a.href = e.target.result;
        //     document.body.appendChild(a);
        //     a.click();
        //     document.body.removeChild(a);
        //   }
        // })
      } else {
        notification.error({
          message: t('提示'),
          description: t('无对应附件'),
        });
      }
    },
    del: (record: any, text: any, file: any) => {
      if (file.file_id) {
        Modal.confirm({
          title: t('提示'),
          content: t('是否删除') + '[' + file.name + ']',
          icon: createVNode(ExclamationCircleOutlined),
          onOk() {
            return new Promise(resolve => {
              let ids: any = [];
              text.forEach((itme: any) => {
                if (itme.file_id != file.file_id) {
                  ids.push(itme.file_id);
                }
              });
              if (ids.length == 0) {
                ids = 0;
              }
              upload_invoice_appendix({
                invoice_id: record.id,
                appendix_ids: ids,
                org_id: current_org?.id,
              })
                .then(() => {
                  message.success(t('删除成功'));
                  event_obj.emit('refresh');
                })
                .finally(() => {
                  resolve(null);
                });
            });
          },
          onCancel() {},
        });
      }
    },
  },
  // {
  //   title: '付款状态',
  //   width: 200,
  //   dataIndex: 'payment_status',
  //   checked: true,
  //   slots: { customRender: 'payment_status' },
  // },
  {
    title: t('创建时间'),
    width: 150,
    dataIndex: 'create_time',
    checked: true,
    sorter: {
      multiple: 1,
    },
    content: (record: any) => {
      return getDateTime(record.create_time);
    },
  },
  {
    title: t('更新时间'),
    width: 150,
    dataIndex: 'update_time',
    checked: true,
    content: (record: any) => {
      return getDateTime(record.update_time);
    },
  },

  {
    title: t('操作'),
    width: 100,
    dataIndex: 'action',
    checked: false,
    cellComponent: 'action',
    fixed: 'right',
  },
];
const newSlotList: any = [];
Object.assign(newSlotList, slotList);
newSlotList.push(
  {
    type: 'custom',
    // slotName: 'is_active',
    field_true: '可用',
    field_false: '禁用',
  },
  {
    type: 'clickList',
    slotName: 'appendix_json',
    cb: (record: any) => {
      if (record && record.file_url) {
        const url: string =
          'https://erp-cc.oss-cn-hongkong.aliyuncs.com/' + encodeURIComponent(record.file_url);
        window.open(url, '_blank');
        // axios.get('/' + record.file_url,{
        //   responseType: 'blob' }).then((res: any) => {
        //   var blob = res.data;
        //   // FileReader主要用于将文件内容读入内存
        //   var reader = new FileReader();
        //   reader.readAsDataURL(blob);
        //   // onload当读取操作成功完成时调用
        //   reader.onload = (e: any) => {
        //     var a = document.createElement('a');
        //     // 获取文件名fileName
        //     var fileName = res.config["url"].split("/");
        //     fileName = fileName[fileName.length - 1];
        //     // fileName = fileName.replace(/"/g, "");
        //     a.download = fileName;
        //     a.href = e.target.result;
        //     document.body.appendChild(a);
        //     a.click();
        //     document.body.removeChild(a);
        //   }
        // })
      } else {
        notification.error({
          message: t('提示'),
          description: t('无对应附件'),
        });
      }
    },
    del: (record: any, text: any, file: any) => {
      if (file.file_id) {
        Modal.confirm({
          title: t('提示'),
          content: t('是否删除') + '[' + file.name + ']',
          icon: createVNode(ExclamationCircleOutlined),
          onOk() {
            return new Promise(resolve => {
              let ids: any = [];
              text.forEach((itme: any) => {
                if (itme.file_id != file.file_id) {
                  ids.push(itme.file_id);
                }
              });
              if (ids.length == 0) {
                ids = 0;
              }
              upload_invoice_appendix({
                invoice_id: record.id,
                appendix_ids: ids,
                org_id: current_org?.id,
              })
                .then(() => {
                  message.success(t('删除成功'));
                  event_obj.emit('refresh');
                })
                .finally(() => {
                  resolve(null);
                });
            });
          },
          onCancel() {},
        });
      }
    },
  },
  {
    type: 'color',
    slotName: 'status',
    cb: (record: any) => {
      if (
        record.status == 1 ||
        record.status == 2 ||
        record.status == 3 ||
        record.status == 5 ||
        record.status == 6
      ) {
        return 'font-weight: bolder';
      } else if (record.status == 4) {
        return 'color: #FF0000;font-weight: bolder';
      }
    },
    content: (record: any) => {
      if (record.status == 1) {
        return t('新创建');
      } else if (record.status == 2) {
        return t('已发送');
      } else if (record.status == 3) {
        return t('已收到请款');
      } else if (record.status == 4) {
        return t('已作废');
      } else if (record.status == 5) {
        return t('生成工资单');
      } else if (record.status == 6) {
        return t('生成PDF');
      } else if (record.status == 7) {
        return t('垫付');
      }
    },
  },
  {
    type: 'color',
    slotName: 'email_record__status',
    cb: (record: any) => {
      if (record.email_record__status == 0 || record.email_record__status == 1) {
        return 'font-weight: bolder';
      } else if (record.email_record__status == 2) {
        return 'color: #FF0000;font-weight: bolder';
      }
    },
    content: (record: any) => {
      if (record.email_record__status == 1) {
        return t('发送成功');
      } else if (record.email_record__status == 2) {
        return t('发送失败');
      } else if (record.email_record__status == 0) {
        return t('已发送');
      }
    },
  },
  {
    type: 'custom',
    slotName: 'payment_status',
    field_0: '未付款',
    field_1: t('收到款项'),
    field_2: t('已付款'),
    field_3: t('垫付'),
  },
  {
    type: 'custom',
    slotName: 'pdf_status',
    field_0: t('未开始'),
    field_1: t('正在生成'),
    field_2: t('失败'),
    field_3: t('成功'),
  },
  {
    type: 'custom',
    slotName: 'invoice_type',
    field_1: t('工资'),
    field_2: t('报销'),
    field_3: 'credit note',
    field_4: t('加班'),
    field_5: t('CC报销'),
  },
  {
    type: 'click',
    slotName: 'files_json',
    content: (record: any) => {
      if (record.files_json) {
        return record.files_json?.name;
      } else {
        return '';
      }
    },
    cb: (record: any) => {
      if (record.files_json && record.files_json.file_url) {
        const url: string =
          'https://erp-cc.oss-cn-hongkong.aliyuncs.com/' +
          encodeURIComponent(record.files_json.file_url);
        window.open(url, '_blank');
        // axios.get('/' + record.files_json.file_url,{
        //   responseType: 'blob' }).then((res: any) => {
        //   var blob = res.data;
        //   // FileReader主要用于将文件内容读入内存
        //   var reader = new FileReader();
        //   reader.readAsDataURL(blob);
        //   // onload当读取操作成功完成时调用
        //   reader.onload = (e: any) => {
        //     var a = document.createElement('a');
        //     // 获取文件名fileName
        //     var fileName = res.config["url"].split("/");
        //     fileName = fileName[fileName.length - 1];
        //     // fileName = fileName.replace(/"/g, "");
        //     a.download = fileName;
        //     a.href = e.target.result;
        //     document.body.appendChild(a);
        //     a.click();
        //     document.body.removeChild(a);
        //   }
        // })
      } else {
        notification.error({
          message: t('提示'),
          description: t('无对应附件'),
        });
      }
    },
  },
  {
    type: 'click',
    slotName: 'remittance__remittance_set__remittance_name',
    content: (record: any) => {
      if (record.remittance__remittance_set__remittance_name) {
        return record.remittance__remittance_set__remittance_name;
      } else {
        return '';
      }
    },
    cb: (record: any) => {
      router.push({
        path: '/adviser-manage/cerp-remittanceset',
        query: { ids: [parseInt(record.remittance__remittance_set_id)], t: new Date().getTime() },
      });
    },
  },
  // {
  //   type: 'color_btn',
  //   slotName: 'remittance__remittance_set__remittance_name',
  //   cb: (record: any) => {
  //     router.push({path: '/adviser-manage/cerp-remittanceset', query: {ids: [parseInt(record.remittance__remittance_set_id)], t: (new Date()).getTime()}});
  //   },
  //   rt: (record: any) => {
  //     return '';
  //   },
  // },
  {
    type: 'click',
    slotName: 'excel_files_json',
    content: (record: any) => {
      if (record.excel_files_json) {
        return record.excel_files_json?.name;
      } else {
        return '';
      }
    },
    cb: (record: any) => {
      if (record.excel_files_json && record.excel_files_json.file_url) {
        const url: string =
          'https://erp-cc.oss-cn-hongkong.aliyuncs.com/' +
          encodeURIComponent(record.excel_files_json.file_url);
        window.open(url, '_blank');
        // axios.get('/' + record.excel_files_json.file_url,{
        //   responseType: 'blob' }).then((res: any) => {
        //   var blob = res.data;
        //   // FileReader主要用于将文件内容读入内存
        //   var reader = new FileReader();
        //   reader.readAsDataURL(blob);
        //   // onload当读取操作成功完成时调用
        //   reader.onload = (e: any) => {
        //     var a = document.createElement('a');
        //     // 获取文件名fileName
        //     var fileName = res.config["url"].split("/");
        //     fileName = fileName[fileName.length - 1];
        //     // fileName = fileName.replace(/"/g, "");
        //     a.download = fileName;
        //     a.href = e.target.result;
        //     document.body.appendChild(a);
        //     a.click();
        //     document.body.removeChild(a);
        //   }
        // })
      } else {
        notification.error({
          message: t('提示'),
          description: t('无对应附件'),
        });
      }
    },
  },
  {
    type: 'color',
    slotName: 'remittance__amount',
    cb: (record: any) => {
      if (!record.remittance__amount) {
        return '';
      }
      if (Number(record.total_amount.toFixed(0)) < Number(record.remittance__amount.toFixed(0))) {
        return 'color: #00BBFF;font-weight: bolder';
      } else if (
        Number(record.total_amount.toFixed(0)) == Number(record.remittance__amount.toFixed(0))
      ) {
        return 'color: #00DD00;font-weight: bolder';
      } else if (
        Number(record.total_amount.toFixed(0)) > Number(record.remittance__amount.toFixed(0))
      ) {
        return 'color: #FF0000;font-weight: bolder';
      }
    },
    content: (record: any) => {
      if (record.remittance__amount) {
        return numerals.money(record.remittance__amount);
      } else {
        return '';
      }
    },
  },
);

export default () => {
  return {
    searchConfig: search_config,
    searchConfigOptions: search_options,
    formModalConfig: edit_model_config,
    formModalConfigOptions: edit_model_config_options,
    updateFormModalConfig: update_model_config,
    updateFormModalConfigOptions: update_model_config_options,
    queryData: query_invoice_list,
    addData: create_invoice,
    updateData: update_invoice,
    removeData: delete_invoice,
    recoverData: recover_invoice,
    baseColumns: baseColumns,
    slotList: newSlotList,
    titleList: titleList,
    api_fields: api_fields,
    btn_list: btn_list,
    head_btn_list: head_btn_list,
    event_obj: event_obj,
  };
};
