<template>
  <a-modal
    :title="title"
    width="550px"
    :visible="true"
    :maskClosable="false"
    :confirmLoading="loading"
    :footer="null"

    okText="确定"
    centered
    @OK="() => {}"
    @cancel="() => {}"
  >
    <a-image style="text-align: center" :width="500" :src="url" />
  </a-modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ImageDisplayModal',
  props: {
    title: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
    },
  },
  setup() {
    return {};
  },
});
</script>
