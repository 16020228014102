
import {
  computed,
  defineComponent,
  getCurrentInstance,
  inject,
  onUnmounted,
  reactive,
  ref,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { genMenuInfo } from '@/utils/menu-util';
import { default as WrapContent } from '@/components/base-layouts/wrap-content/index.vue';
import { default as GlobalFooter } from '@/components/base-layouts/global-footer/index.vue';
import { default as SiderMenu } from '@/components/base-layouts/sider-menu/index.vue';
import { default as HeaderView } from '@/components/base-layouts/header/index.vue';
import { default as SelectLang } from '@/components/select-lang/index.vue';
import { default as OrgNameNow } from '@/components/org-name-now.vue';
import { default as AvatarDropdown } from '@/components/avatar-dropdown.vue';
import { default as SettingDrawer } from '@/components/setting-drawer/index.vue';
import { MultiTab } from '@/components/multi-tab';
import { BellOutlined, MessageOutlined } from '@ant-design/icons-vue';
import { useStore } from 'vuex';
import { injectMenuState } from './use-menu-state';
import isGotoOldRouter from '@/router/return-old-router';
import { default as mqttState } from '@/components/mqtt-state.vue';
import FormModal from '@/components/form-modal/form-modal.vue';
import { btn_obj } from '@/layouts/create-consultant.ts';
import { useRoute, useRouter } from 'vue-router';
import { query_person_to_company_list } from '@/api/baseinfo-model';
import {
  GET_COMPANY,
  GET_NO_READ_NUMBER,
  GET_NO_RECEIVE_NUM,
  LOGOUT,
} from '@/store/modules/user/actions';
import bus from '@/utils/bus';
import { notification } from 'ant-design-vue';
import { debounce } from 'lodash';

export default defineComponent({
  name: 'BasicLayout',
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const { t } = useI18n();
    const menuState = injectMenuState();
    const select_jump: any = ref('1');
    const select_company: any = ref(null);
    const company_list: any = ref([]);
    const search_value: any = ref('');
    const isMobile = inject('isMobile', ref(false));
    const currentUser = computed(() => store.getters['user/currentUser']);
    const current_org = computed(() => store.getters['user/current_org']);
    const no_read_number = computed(() => store.getters['user/no_read_number']);
    const no_receive_num = computed(() => store.getters['user/no_receive_num']);
    store.dispatch(`user/${GET_NO_READ_NUMBER}`, { org_id: Number(current_org.value?.id) });
    store.dispatch(`user/${GET_NO_RECEIVE_NUM}`, {
      org_id: Number(current_org.value?.id),
      is_no_service: true,
      is_auto_reply: false,
      is_closed: false,
    });
    const timer: any = setInterval(() => {
      store.dispatch(`user/${GET_NO_READ_NUMBER}`, { org_id: Number(current_org.value?.id) });
      store.dispatch(`user/${GET_NO_RECEIVE_NUM}`, {
        org_id: Number(current_org.value?.id),
        is_no_service: true,
        is_auto_reply: false,
        is_closed: false,
      });
    }, 60000); // 刷新未读数 1分钟
    const org_list = computed(() => store.getters['user/org_list']);
    const info = computed(() => store.getters['user/info']);
    const current_company = computed(() => store.getters['user/current_company']);
    select_company.value = current_company.value?.id ? current_company.value?.id : null;
    const hasSideMenu = computed(
      () => menuState.layout.value === 'side' || menuState.layout.value === 'left',
    );
    const hasTopMenu = computed(() => menuState.layout.value === 'top');

    onUnmounted(() => {
      clearInterval(timer);
    });
    const mqtt_state = ref(0);
    const modal_list: any = reactive([]);
    let $bus: {
      emit: (arg0: string, arg1: any) => void;
      off: (arg0: string) => void;
      on: (arg0: string, arg1: any) => void;
    } | null = null;
    $bus = getCurrentInstance()?.appContext.config.globalProperties.$bus;

    $bus?.on('modal.add', (modal: any) => {
      if (!modal.id) {
        modal.id = Date.now();
      }

      modal_list.push(modal);
    });
    $bus?.on('mqtt_state', (mqttstate: any) => {
      mqtt_state.value = mqttstate;
    });
    query_person_to_company_list({
      org_id: current_org.value?.id,
      user_id: info.value.id,
    }).then((res: any) => {
      company_list.value.length = 0;
      let ids: any = [];
      let num = 0;
      if (res && res.data && res.data.length > 0) {
        if (res.data[0]?.cc_companys && res.data[0]?.cc_companys.length > 0) {
          res.data[0]?.cc_companys.forEach((item: any) => {
            company_list.value.push({
              name: item.name,
              id: item.id,
            });
            ids += item.id + ',';
            num += 1;
          });
        }
      }
      if (num > 0) {
        company_list.value.reverse();
        company_list.value.push({
          name: '全部公司',
          id: ids,
        });
        company_list.value.reverse();
        store.dispatch(`user/${GET_COMPANY}`, { id: ids, name: '全部公司' });
        select_company.value = ids;
      } else {
        store.dispatch(`user/${GET_COMPANY}`, { id: -1, name: '全部公司' });
        select_company.value = null;
      }
    });
    const modal_update = (data: any, modal: any) => {
      if (modal.update) {
        const result = modal.update(data, modal);
        if (result instanceof Promise) {
          result.then(function () {
            modal_list.splice(modal_list.indexOf(modal, 0), 1);
          });
        } else if (result === true || result === null || result === undefined) {
          modal_list.splice(modal_list.indexOf(modal, 0), 1);
        }
      } else {
        modal_list.splice(modal_list.indexOf(modal, 0), 1);
      }
    };
    const modal_ok = (data: any, modal: any) => {
      if (modal.ok) {
        const result = modal.ok(data, modal);
        if (result instanceof Promise) {
          result.then(function () {
            modal_list.splice(modal_list.indexOf(modal, 0), 1);
          });
        } else if (result === true || result === null || result === undefined) {
          modal_list.splice(modal_list.indexOf(modal, 0), 1);
        }
      } else {
        modal_list.splice(modal_list.indexOf(modal, 0), 1);
      }
    };

    const modal_cancel = (data: any, modal: any) => {
      if (modal.cancel) {
        modal.cancel(data, modal);
      }
      modal_list.splice(modal_list.indexOf(modal, 0), 1);
    };

    const openSearch = (searchValue: string) => {
      if (searchValue && select_jump.value == '1') {
        router.push({ path: '/adviser-manage/cerp-adviser', query: { q: searchValue } });
      } else if (searchValue && select_jump.value == '2') {
        router.push({ path: '/adviser-manage/cerp-contract', query: { q: searchValue } });
      }
      search_value.value = '';
    };
    const to_kefu = () => {
      router.push({
        path: '/kefu/kefu-session',
      });
    };

    // gen menus
    const allowRouters = computed(() => store.getters[`user/allowRouters`]); // genMenuInfo(filterMenu(routes)).menus;
    const menus = computed(() => genMenuInfo(allowRouters.value).menus);
    // 返回旧版本
    const returnOldRouter = (selectedKeys: any) => {
      isGotoOldRouter(selectedKeys[selectedKeys.length - 1]);
    };
    const selectCompany = (value: any) => {
      const path_arr = route.path.split('/');
      let list = company_list.value.filter((item: any) => item.id == value);
      if (list && list?.length > 0) {
        store.dispatch(`user/${GET_COMPANY}`, { id: list[0].id, name: list[0].name });
        if (path_arr[path_arr.length - 1] == 'cerp-contract') {
          router.push({
            path: '/adviser-manage/cerp-contract',
            query: {
              cc_company_name: list[0].name == '全部公司' ? '' : list[0].name,
              cc_company_id: list[0].id,
            },
          });
        } else if (path_arr[path_arr.length - 1] == 'cerp-extra_field') {
          router.push({
            path: '/adviser-manage/cerp-extra_field',
            query: {
              cc_company_name: list[0].name == '全部公司' ? '' : list[0].name,
              cc_company_id: list[0].id,
            },
          });
        } else if (path_arr[path_arr.length - 1] == 'receipt-contract') {
          router.push({
            path: '/receipt/receipt-contract',
            query: {
              cc_company_name: list[0].name == '全部公司' ? '' : list[0].name,
              cc_company_id: list[0].id,
            },
          });
        } else if (path_arr[path_arr.length - 1] == 'receipt-receipt') {
          router.push({
            path: '/receipt/receipt-receipt',
            query: {
              cc_company_name: list[0].name == '全部公司' ? '' : list[0].name,
              cc_company_id: list[0].id,
            },
          });
        } else if (path_arr[path_arr.length - 1] == 'receipt-payment') {
          router.push({
            path: '/receipt/receipt-payment',
            query: {
              cc_company_name: list[0].name == '全部公司' ? '' : list[0].name,
              cc_company_id: list[0].id,
            },
          });
        } else if (path_arr[path_arr.length - 1] == 'baseinfo-time_calendar') {
          router.push({
            path: '/static-info-manage/baseinfo-time_calendar',
            query: {
              cc_company_name: list[0].name == '全部公司' ? '' : list[0].name,
              cc_company_id: list[0].id,
            },
          });
        } else if (path_arr[path_arr.length - 1] == 'baseinfo-cccompany_contract') {
          router.push({
            path: '/static-info-manage/baseinfo-cccompany_contract',
            query: {
              cc_company_name: list[0].name == '全部公司' ? '' : list[0].name,
              cc_company_id: list[0].id,
            },
          });
        }
        bus.emit('selectCompany');
      }
    };
    const logout = debounce(() => {
      notification.error({
        message: t('登录过期'),
        description: t('请重新登录'),
      });
      store.dispatch(`user/${LOGOUT}`).then(() => {
        location.href = '/';
      });
    }, 4000);
    bus.on('LOGOUT', () => {
      logout();
    });
    return {
      t,
      currentUser,
      org_list,
      select_jump,
      company_list,
      select_company,
      search_value,
      current_org,
      menus,
      ...menuState,
      hasSideMenu,
      hasTopMenu,
      mqtt_state,
      isMobile,
      no_read_number,
      no_receive_num,
      to_kefu,
      returnOldRouter,
      isGotoOldRouter,
      openSearch,
      modal_list,
      modal_ok,
      modal_update,
      modal_cancel,
      btn_obj,
      selectCompany,
    };
  },
  components: {
    FormModal,
    MultiTab,
    BellOutlined,
    MessageOutlined,
    WrapContent,
    SiderMenu,
    GlobalFooter,
    HeaderView,
    SelectLang,
    OrgNameNow,
    AvatarDropdown,
    SettingDrawer,
    mqttState,
  },
});
