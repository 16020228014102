<template>
  <a-modal
    :confirmLoading="loading"
    :maskClosable="false"
    :title="title"
    :visible="true"
    centered
    width="500px"
    @cancel="
      () => {
        // resetFields();
        $emit('cancel');
      }
    "
  >
    <a-table
      :columns="columns"
      :data-source="dataSource"
      :pagination="false"
      :scroll="{ y: 500 }"
      bordered
      class="ant-table-striped attribute-table"
      size="small"
    >
      <template #footer>共{{ dataSource.length }}个属性。</template>
    </a-table>
    <template #footer>
      <div></div>
    </template>
  </a-modal>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';

const columns = [
  {
    title: '序号',
    dataIndex: 'key',
    width: '10%',
  },
  {
    title: '属性',
    dataIndex: 'name',
    width: '45%',
    slots: { customRender: 'name' },
  },
  {
    title: '值',
    dataIndex: 'value',
    width: '45%',
    slots: { customRender: 'value' },
  },
];

interface DataItem {
  key: string;
  name: string;
  value: string;
}

export default defineComponent({
  name: 'AttributeTableModal',
  props: {
    title: {
      type: String,
      required: false,
      default: () => '',
    },
    value: {
      type: Array,
      required: true,
      default: () => [],
    },
    loading: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  emits: ['update:value'],
  setup(props, { emit }) {
    const dataSource: any = ref([]);
    if (props.value && props.value.hasOwnProperty('length')) {
      props.value.map((item: any, index: number) => {
        dataSource.value.push({ key: (index + 1).toString(), ...item });
      });
    }

    return {
      props,
      columns,
      dataSource,
    };
  },

  data() {
    return {
      model: this.value,
    };
  },
  components: {},
});
</script>
<style lang="less">
.attribute-table {
  .ant-table-footer {
    padding: 5px;
  }
}
</style>
