
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ImageDisplayModal',
  props: {
    title: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
    },
  },
  setup() {
    return {};
  },
});
