import menuTreeSelect from '@/components/FormRender/RemoteTreeSelect/menu-tree-select.ts';
import { RemoteSelect } from '@/components/FormRender/RemoteSelect/typing';

export default (modalType: string): RemoteSelect => {
  switch (modalType) {
    case 'menu-tree-select': {
      return {
        ...menuTreeSelect(),
      };
    }
    default: {
      return {
        ...menuTreeSelect(),
      };
    }
  }
};
