import { queryMenu2 } from '@/api/permission/menu-manage.ts';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';

const org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
const search_config: any = {
  model: {
    org_id: org?.id,
    is_active: true,
    pageSize: 2000,
  },
};

export default () => {
  return {
    getData: queryMenu2,
    searchConfig: search_config,
  };
};
