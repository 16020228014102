import { query_remittance_set_list } from '@/api/cerp-model';
import { useI18n } from 'vue-i18n';

interface SearchConfig {
  rules: any;
  fields: any;
  settings: any;
  model: any;
}

export default () => {
  const { t } = useI18n();
  const apiurl_config: SearchConfig = {
    settings: {
      name: '表单名称',
      labelAlign: 'right',
      layout: 'inline',
    },
    fields: [
      {
        type: 'input',
        name: 'remittance_name',
      label: '名称',
      label_i18n: '名称',
      placeholder_i18n: '请填写名称',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写名称',
    },
    {
      type: 'remoteselect',
      name: 'transaction_id',
      label: '银行流水',
      label_i18n: '银行流水',
      placeholder_i18n: '请填写银行流水',
      disabled: false,
      placeholder: '请选择银行流水',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'cerp-banktransaction',
    },
  ],
  rules: {},
  model: {
    transaction_id: null,
    remittance_name: null,
  },
};
const apiurl_ConfigOptions = {};
const apiurl_columns = [
  {
    title: t('名称'),
    width: 150,
    dataIndex: 'remittance_name',
    checked: true,
    fixed: 'left',
  },
  {
    title: t('描述'),
    width: 150,
    dataIndex: 'description',
    checked: true,
  },
  {
    title: t('日期'),
    width: 150,
    dataIndex: 'date_received',
    checked: true,
    slots: { customRender: 'datetime' },
  },
  {
    title: t('总金额'),
    width: 150,
    dataIndex: 'total_amount',
    checked: true,
  },
  {
    title: t('银行流水'),
    width: 150,
    dataIndex: 'transaction_id',
    checked: true,
  },
  {
    title: t('创建时间'),
    width: 150,
    dataIndex: 'create_time',
    checked: true,

    slots: { customRender: 'datetime' },
  },
  {
    title: t('更新时间'),
    width: 150,
    dataIndex: 'update_time',
    checked: true,

    slots: { customRender: 'datetime' },
  },
  {
    title: t('是否可用'),
    width: 150,
    dataIndex: 'is_active',
    checked: true,

    slots: { customRender: 'is_active' },
    filterMultiple: false,
    filters: [
      { text: t('全部'), value: '' },
      { text: t('可用'), value: true },
      { text: t('禁用'), value: false },
    ],
  },
];
const slotList = [
  {
    slotName: 'index', // 槽名
  },
  {
    slotName: 'status',
    type: 'boolean', // 类型：'boolean' - text字段有无、(true/false)
    name1: '正常', // text字段有或true 展示
    name2: '禁用', // text字段无或false 展示
  },
  {
    slotName: 'bool',
    type: 'boolean', // 类型：'boolean' - text字段有无、(true/false)
    name1: '是', // text字段有或true 展示
    name2: '否', // text字段无或false 展示
  },
  {
    slotName: 'time',
    type: 'time', // 类型：'time' - text字段为时间
  },
  {
    slotName: 'datetime',
    type: 'datetime', // 类型：'time' - text字段为时间
  },
  {
    slotName: 'date',
    type: 'date', // 类型：'date' - text字段为日期
  },
  {
    slotName: 'list',
    type: 'list', // 类型：'list' - text字段为数组
  },
  {
    type: 'custom',
    slotName: 'status',
    field_work: '工作',
    field_leave: '离职',
  },
];

  return {
    searchConfig: apiurl_config,
    searchConfigOptions: apiurl_ConfigOptions,
    getData: query_remittance_set_list,
    columns: apiurl_columns,
    slotList: slotList,
  };
};
