
import { defineComponent } from 'vue';

import { getDateTime, getDay_no_year, getMonth_no_year } from '@/utils/function';

export default defineComponent({
  name: 'Cell_hui_hua',
  props: {
    column: {
      type: Object,
      required: true,
      default: () => {},
    },
    index: {
      type: Number,
      required: true,
    },
    text: {
      type: [String, Number],
      required: true,
    },
    record: {
      type: Object,
      required: true,
      default: () => {},
    },
    search: {
      type: Object,
      required: true,
      default: () => {},
    },
    pageSize: {
      type: Number,
      required: true,
      default: () => 20,
    },
    current: {
      type: Number,
      required: true,
      default: () => 1,
    },
  },
  setup() {
    return {
      getDateTime,
      getMonth_no_year,
      getDay_no_year,
    }
  }
});
