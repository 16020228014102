<template>
  <a-modal
    :title="title"
    width="720px"
    :visible="true"
    :maskClosable="false"
    :confirmLoading="loading"
    wrapClassName="custom-form-modal"

    :okText="okText ? okText : '保存'"
    centered
    @ok="hold(code)"
    @cancel="
      () => {
        $emit('cancel');
      }
    "
  >
    <json-viewer :value="code" boxed sort ></json-viewer>
  </a-modal>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'JsonViewModal',
  props: {
    title: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
    },
    mode: {
      type: String,
      required: true,
      default: () => 'javascript',
    },
    params: {
      type: String,
      required: true,
    },
    hold: {
      type: Function,
      required: true,
    },
    okText: {
      type: String,
      default: () => '',
    },
  },
  setup(props, { emit }) {
    const code = ref<string>('');

    code.value = props.params ? JSON.parse(props.params) : '';
    const sendMessage = () => {
      emit('send-message');
    };
    return {
      code,
      sendMessage,
    };
  },
});
</script>
<style>
.custom-form-modal .ant-modal-body {
  height: 600px;
  padding: 24px;
  overflow: auto;
}

</style>
