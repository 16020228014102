<template>
  <a-tag color="green" v-if="record.is_change_steel">
    {{ record.stop_before_steel__name }}
  </a-tag>
  <forward-filled
    style="margin-right: 2px; font-size: 15px"
    v-if="record.is_change_steel"
  />
  <a-tag color="red" v-if="record.is_change_steel">
    {{ record.stop_after_steel__name }}
  </a-tag>
  <a-tag color="green" v-if="record.is_change_specification">
    {{ record.stop_before_specification__name }}
  </a-tag>
  <forward-filled
    style="margin-right: 2px; font-size: 15px"
    v-if="record.is_change_specification"
  />
  <a-tag color="red" v-if="record.is_change_specification">
    {{ record.stop_after_specification__name }}
  </a-tag>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
export default defineComponent({
  name: 'Cell_tag_tag',
  props: {
    column: {
      type: Object,
      required: true,
      default: () => {},
    },
    index: {
      type: Number,
      required: true,
    },
    text: {
      type: [String , Number],
      required: true,
    },
    record: {
      type: Object,
      required: true,
      default: () => {},
    },
    pageSize: {
      type: Number,
      required: true,
      default: () => 20,
    },
    current: {
      type: Number,
      required: true,
      default: () => 1,
    },
  },
});
</script>
<style lang="less">
.needCopyRow {
  td {
    position: relative;
  }
}
.large-font {
  font-size: 17px;
  font-weight: 500;
}
.large-number-font {
  font-size: 24px;
  font-weight: 500;
}
</style>