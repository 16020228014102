<template>
  <a-tree-select
    v-model:value="modalRef.selectedRows"
    :placeholder="
      field.placeholder_i18n == undefined ? field.placeholder : t(field.placeholder_i18n)
    "
    :disabled="field.disabled"
    :default-value="field.defaultValue"
    :allow-clear="field.allowClear"
    :tree-data="modalRef.datasource"
    :tree-checkable="field.multiple"
    :tree-node-filter-prop="field.valueKey"
    :tree-label-prop="field.labelKey"
    @change="handleChange"
  />
</template>

<script lang="ts">
import { defineComponent, reactive } from 'vue';
import { RequestData } from '@/utils/hooks/useFetchData';
import getFormConfig from '@/components/FormRender/RemoteTreeSelect/index';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'RemoteTreeSelect',
  props: {
    field: {
      type: Object,
      required: true,
    },
    value: {
      type: Array,
      Number,
      required: true,
    },
    search_config: {
      type: Object,
      required: true,
    },
  },
  emits: ['update:value'],
  setup(props, { emit }) {
    const { t } = useI18n();
    const modalRef = reactive({
      selectedRows: new Array<any>(),
      datasource: new Array<any>(),
    });
    const { searchConfig, getData } = getFormConfig(props.field.modalType);
    const search_modelRef = reactive({
      ...searchConfig.model,
    });

    if (props.value) {
      modalRef.selectedRows.push(props.value);
    }

    getData({ ...search_modelRef, ...props.search_config }).then(function (res: RequestData<any>) {
      modalRef.datasource = res.data;
    });
    const handleChange = () => {
      emit('update:value', modalRef.selectedRows);
    };

    return {
      t,
      props,
      modalRef,
      search_modelRef,
      handleChange,
    };
  },

  data() {
    return {
      model: this.value,
    };
  },
});
</script>
