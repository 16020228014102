import {queryRole} from '@/api/permission/role-manage';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';

const org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
const search_config: any = {
  model: {
    org_id: org?.id,
    aim_org_id: org?.id,
    is_active: true,
    pageSize: 1000,
  },
  watch: [],
};

export default () => {
  return {
    getData: queryRole,
    searchConfig: search_config,
  };
};
