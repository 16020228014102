<template>
  <span>{{ getMonth(text) }}</span>
</template>

<script lang="ts">
import {defineComponent, ref} from 'vue';
import { getMonth } from '@/utils/function';
export default defineComponent({
  name: 'Cell_month',
  props: {
    column: {
      type: Object,
      required: true,
      default: () => {},
    },
    index: {
      type: Number,
      required: true,
    },
    text: {
      type: [String , Number],
      required: true,
    },
    record: {
      type: Object,
      required: true,
      default: () => {},
    },
    pageSize: {
      type: Number,
      required: true,
      default: () => 20,
    },
    current: {
      type: Number,
      required: true,
      default: () => 1,
    },
  },
  setup() {
    return {
      getMonth
    };
  },
});
</script>
