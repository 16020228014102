import request from '@/utils/request';
import { PageResult } from '@/api/typing';

export async function create_address(params: Record<string, any>) {
  return request.post('/cerp/create_address', params);
}

export async function update_address(params: Record<string, any>) {
  return request.post('/cerp/update_address', params);
}

export async function delete_address(params: Record<string, any>) {
  return request.post('/cerp/delete_address', params);
}

export async function upload_insurance_appendix(params: Record<string, any>) {
  return request.post('/cerp/upload_insurance_appendix', params);
}

export async function upload_insurance_other_appendix(params: Record<string, any>) {
  return request.post('/cerp/upload_insurance_other_appendix', params);
}

export async function upload_bank_account_appendix(params: Record<string, any>) {
  return request.post('/cerp/upload_bank_account_appendix', params);
}

export async function get_adviser_by_employee_number(params: Record<string, any>) {
  return request.post('/cerp/get_adviser_by_employee_number', params);
}

export async function create_adviser_user_account(params: Record<string, any>) {
  return request.post('/cerp/create_adviser_user_account', params);
}

export async function delete_adviser_user_account(params: Record<string, any>) {
  return request.post('/cerp/delete_adviser_user_account', params);
}

export async function send_adviser_user_account_email(params: Record<string, any>) {
  return request.post('/cerp/send_adviser_user_account_email', params);
}

export async function enable_adviser_account(params: Record<string, any>) {
  return request.post('/cerp/enable_adviser_account', params);
}

export async function reset_adviser_password(params: Record<string, any>) {
  return request.post('/cerp/reset_adviser_password', params);
}

export async function pay_payslip(params: Record<string, any>) {
  return request.post('/cerp/pay_payslip', params);
}

export async function bulk_create_remittance(params: Record<string, any>) {
  return request.post('/cerp/bulk_create_remittance', params);
}

export async function bulk_create_payslip(params: Record<string, any>) {
  return request.post('/cerp/bulk_create_payslip', params);
}

export async function bulk_pay_payslip(params: Record<string, any>) {
  return request.post('/cerp/bulk_pay_payslip', params);
}

export async function set_remittance_set_transaction(params: Record<string, any>) {
  return request.post('/cerp/set_remittance_set_transaction', params);
}

export async function import_remittanceset(params: Record<string, any>) {
  return request.post('/cerp/import_remittanceset', params);
}

export async function get_adviser_of_login(params: Record<string, any>) {
  return request.post('/cerp/get_adviser_of_login', params);
}

export async function import_remittance(params: Record<string, any>) {
  return request.post('/cerp/import_remittance', params);
}

export async function create_contract_draft(params: Record<string, any>) {
  return request.post('/cerp/create_contract_draft', params);
}

export async function view_contract_pdf(params: Record<string, any>) {
  return request.post('/cerp/view_contract_pdf', params);
}

export async function send_contract_by_email(params: Record<string, any>) {
  return request.post('/cerp/send_contract_by_email', params);
}

export async function send_contract_by_manul(params: Record<string, any>) {
  return request.post('/cerp/send_contract_by_manul', params);
}

export async function get_contract_by_service_month(params: Record<string, any>) {
  return request.post('/cerp/get_contract_by_service_month', params);
}

export async function cancel_pay_payslip(params: Record<string, any>) {
  return request.post('/cerp/cancel_pay_payslip', params);
}

export async function bulk_pay_payslip_zero(params: Record<string, any>) {
  return request.post('/cerp/bulk_pay_payslip_zero', params);
}

export async function delete_adviser_end_date(params: Record<string, any>) {
  return request.post('/cerp/delete_adviser_end_date', params);
}

export async function delete_person_to_adviser_relation(params: Record<string, any>) {
  return request.post('/cerp/delete_person_to_adviser_relation', params);
}

export async function bulk_send_invoice_by_email(params: Record<string, any>) {
  return request.post('/cerp/bulk_send_invoice_by_email', params);
}

export async function bulk_send_payslip_by_email(params: Record<string, any>) {
  return request.post('/cerp/bulk_send_payslip_by_email', params);
}

export async function analyse_bank_transaction_report(params: Record<string, any>) {
  return request.post('/cerp/analyse_bank_transaction_report', params);
}

export async function get_current_contract(params: Record<string, any>) {
  return request.post('/cerp/get_current_contract', params);
}

export async function bulk_send_invoice_by_manul(params: Record<string, any>) {
  return request.post('/cerp/bulk_send_invoice_by_manul', params);
}

export async function bulk_create_invoice_pdf(params: Record<string, any>) {
  return request.post('/cerp/bulk_create_invoice_pdf', params);
}

export async function bulk_create_payslip_pdf(params: Record<string, any>) {
  return request.post('/cerp/bulk_create_payslip_pdf', params);
}

export async function send_contract(params: Record<string, any>) {
  return request.post('/cerp/send_contract', params);
}

export async function bulk_create_remittanceset(params: Record<string, any>) {
  return request.post('/cerp/bulk_create_remittanceset', params);
}

export async function create_payslip_pdf(params: Record<string, any>) {
  return request.post('/cerp/create_payslip_pdf', params);
}

export async function upload_payslip_pdf(params: Record<string, any>) {
  return request.post('/cerp/upload_payslip_pdf', params);
}

export async function bulk_create_todoevent(params: Record<string, any>) {
  return request.post('/cerp/bulk_create_todoevent', params);
}

export async function import_invoice(params: Record<string, any>) {
  return request.post('/cerp/import_invoice', params);
}

export async function bulk_create_invoice(params: Record<string, any>) {
  return request.post('/cerp/bulk_create_invoice', params);
}

export async function create_contract_pdf(params: Record<string, any>) {
  return request.post('/cerp/create_contract_pdf', params);
}

export async function modify_payslip_mangefee(params: Record<string, any>) {
  return request.post('/cerp/modify_payslip_mangefee', params);
}

export async function create_extra_field(params: Record<string, any>) {
  return request.post('/cerp/create_extra_field', params);
}
export async function update_extra_field(params: Record<string, any>) {
  return request.post('/cerp/update_extra_field', params);
}
export async function delete_extra_field(params: Record<string, any>) {
  return request.post('/cerp/delete_extra_field', params);
}
export async function recover_extra_field(params: Record<string, any>) {
  return request.post('/cerp/recover_extra_field', params);
}

export async function set_payslip_pay_together_status(params: Record<string, any>) {
  return request.post('/cerp/set_payslip_pay_together_status', params);
}

export async function set_payslip_pay_month(params: Record<string, any>) {
  return request.post('/cerp/set_payslip_pay_month', params);
}

export async function resume_invoice(params: Record<string, any>) {
  return request.post('/cerp/resume_invoice', params);
}

export async function create_invoice_pdf(params: Record<string, any>) {
  return request.post('/cerp/create_invoice_pdf', params);
}

export async function upload_invoice_appendix(params: Record<string, any>) {
  return request.post('/cerp/upload_invoice_appendix', params);
}

export async function upload_invoice_pdf(params: Record<string, any>) {
  return request.post('/cerp/upload_invoice_pdf', params);
}

export async function modify_invoice_mangefee(params: Record<string, any>) {
  return request.post('/cerp/modify_invoice_mangefee', params);
}

export async function recover_address(params: Record<string, any>) {
  return request.post('/cerp/recover_address', params);
}

export async function upload_contract_wechat_appendix(params: Record<string, any>) {
  return request.post('/cerp/upload_contract_wechat_appendix', params);
}

export async function upload_contract_local_appendix(params: Record<string, any>) {
  return request.post('/cerp/upload_contract_local_appendix', params);
}

export async function query_address_list(params?: { [key: string]: any }): Promise<PageResult> {
  return request.post('/cerp/query_address_list', params);
}

export async function query_error_custom_field_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/cerp/query_error_custom_field_list', params);
}

export async function query_social_insurance_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/cerp/query_social_insurance_list', params);
}

export async function query_extra_field_list(params?: { [key: string]: any }): Promise<PageResult> {
  return request.post('/cerp/query_extra_field_list', params);
}

export async function query_exchange_rate_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/cerp/query_exchange_rate_list', params);
}

export async function create_adviser(params: Record<string, any>) {
  return request.post('/cerp/create_adviser', params);
}

export async function bulk_create_extra_field(params: Record<string, any>) {
  return request.post('/cerp/bulk_create_extra_field', params);
}

export async function get_extra_month(params: Record<string, any>) {
  return request.post('/cerp/get_extra_month', params);
}

export async function get_cc_company_by_adviser_id(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/cerp/get_cc_company_by_adviser_id', params);
}

export async function import_extra_field(params: Record<string, any>) {
  return request.post('/cerp/import_extra_field', params);
}

export async function update_adviser(params: Record<string, any>) {
  return request.post('/cerp/update_adviser', params);
}

export async function recover_exchange_rate(params: Record<string, any>) {
  return request.post('/cerp/recover_exchange_rate', params);
}

export async function delete_exchange_rate(params: Record<string, any>) {
  return request.post('/cerp/delete_exchange_rate', params);
}

export async function create_exchange_rate(params: Record<string, any>) {
  return request.post('/cerp/create_exchange_rate', params);
}

export async function delete_adviser(params: Record<string, any>) {
  return request.post('/cerp/delete_adviser', params);
}

export async function update_exchange_rate(params: Record<string, any>) {
  return request.post('/cerp/update_exchange_rate', params);
}

export async function recover_adviser(params: Record<string, any>) {
  return request.post('/cerp/recover_adviser', params);
}

export async function query_adviser_list(params?: { [key: string]: any }): Promise<PageResult> {
  return request.post('/cerp/query_adviser_list', params);
}

export async function query_notice_record_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/cerp/query_notice_record_list', params);
}

export async function create_bank_account(params: Record<string, any>) {
  return request.post('/cerp/create_bank_account', params);
}

export async function update_bank_account(params: Record<string, any>) {
  return request.post('/cerp/update_bank_account', params);
}

export async function delete_bank_account(params: Record<string, any>) {
  return request.post('/cerp/delete_bank_account', params);
}

export async function recover_bank_account(params: Record<string, any>) {
  return request.post('/cerp/recover_bank_account', params);
}

export async function query_bank_account_by_select_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/cerp/query_bank_account_by_select_list', params);
}

export async function query_bank_account_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/cerp/query_bank_account_list', params);
}

export async function create_bank_transaction(params: Record<string, any>) {
  return request.post('/cerp/create_bank_transaction', params);
}

export async function update_bank_transaction(params: Record<string, any>) {
  return request.post('/cerp/update_bank_transaction', params);
}

export async function delete_bank_transaction(params: Record<string, any>) {
  return request.post('/cerp/delete_bank_transaction', params);
}

export async function recover_bank_transaction(params: Record<string, any>) {
  return request.post('/cerp/recover_bank_transaction', params);
}

export async function query_bank_transaction_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/cerp/query_bank_transaction_list', params);
}

export async function create_beneficiary(params: Record<string, any>) {
  return request.post('/cerp/create_beneficiary', params);
}

export async function update_beneficiary(params: Record<string, any>) {
  return request.post('/cerp/update_beneficiary', params);
}

export async function delete_beneficiary(params: Record<string, any>) {
  return request.post('/cerp/delete_beneficiary', params);
}

export async function recover_beneficiary(params: Record<string, any>) {
  return request.post('/cerp/recover_beneficiary', params);
}

export async function query_beneficiary_by_select_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/cerp/query_beneficiary_by_select_list', params);
}

export async function query_beneficiary_list(params?: { [key: string]: any }): Promise<PageResult> {
  return request.post('/cerp/query_beneficiary_list', params);
}

export async function create_contract(params: Record<string, any>) {
  return request.post('/cerp/create_contract', params);
}

export async function update_contract(params: Record<string, any>) {
  return request.post('/cerp/update_contract', params);
}

export async function upload_contract_appendix(params: Record<string, any>) {
  return request.post('/cerp/upload_contract_appendix', params);
}

export async function set_contract_upload_status(params: Record<string, any>) {
  return request.post('/cerp/set_contract_upload_status', params);
}

export async function cc_sign_contract(params: Record<string, any>) {
  return request.post('/cerp/cc_sign_contract', params);
}

export async function delete_contract(params: Record<string, any>) {
  return request.post('/cerp/delete_contract', params);
}

export async function recover_contract(params: Record<string, any>) {
  return request.post('/cerp/recover_contract', params);
}

export async function query_contract_list(params?: { [key: string]: any }): Promise<PageResult> {
  return request.post('/cerp/query_contract_list', params);
}

export async function query_tip_record_list(params?: { [key: string]: any }): Promise<PageResult> {
  return request.post('/cerp/query_tip_record_list', params);
}

export async function query_month_work_hour_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/cerp/query_month_work_hour_list', params);
}

export async function query_wechat_update_record_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/cerp/query_wechat_update_record_list', params);
}

export async function create_custom_field(params: Record<string, any>) {
  return request.post('/cerp/create_custom_field', params);
}

export async function update_month_work_hour(params: Record<string, any>) {
  return request.post('/cerp/update_month_work_hour', params);
}

export async function update_month_work_hour_data(params: Record<string, any>) {
  return request.post('/cerp/update_month_work_hour_data', params);
}

export async function lock_month_work_hour(params: Record<string, any>) {
  return request.post('/cerp/lock_month_work_hour', params);
}

export async function unlock_month_work_hour(params: Record<string, any>) {
  return request.post('/cerp/unlock_month_work_hour', params);
}

export async function create_month_work_hour_pdf(params: Record<string, any>) {
  return request.post('/cerp/create_month_work_hour_pdf', params);
}

export async function send_month_work_hour_email(params: Record<string, any>) {
  return request.post('/cerp/send_month_work_hour_email', params);
}

export async function get_adviser_month_work_hour_calendar(params: Record<string, any>) {
  return request.post('/cerp/get_adviser_month_work_hour_calendar', params);
}

export async function get_adviser_work_hour_for_invoice(params: Record<string, any>) {
  return request.post('/cerp/get_adviser_work_hour_for_invoice', params);
}

export async function get_cc_bank_by_contract_id(params: Record<string, any>) {
  return request.post('/cerp/get_cc_bank_by_contract_id', params);
}

export async function deal_wechatupdaterecord(params: Record<string, any>) {
  return request.post('/cerp/deal_wechatupdaterecord', params);
}

export async function update_custom_field(params: Record<string, any>) {
  return request.post('/cerp/update_custom_field', params);
}

export async function delete_custom_field(params: Record<string, any>) {
  return request.post('/cerp/delete_custom_field', params);
}

export async function recover_custom_field(params: Record<string, any>) {
  return request.post('/cerp/recover_custom_field', params);
}

export async function query_custom_field_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/cerp/query_custom_field_list', params);
}

export async function create_custom_field_set(params: Record<string, any>) {
  return request.post('/cerp/create_custom_field_set', params);
}

export async function update_custom_field_set(params: Record<string, any>) {
  return request.post('/cerp/update_custom_field_set', params);
}

export async function delete_custom_field_set(params: Record<string, any>) {
  return request.post('/cerp/delete_custom_field_set', params);
}

export async function recover_custom_field_set(params: Record<string, any>) {
  return request.post('/cerp/recover_custom_field_set', params);
}

export async function query_custom_field_set_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/cerp/query_custom_field_set_list', params);
}

export async function create_insurance(params: Record<string, any>) {
  return request.post('/cerp/create_insurance', params);
}

export async function update_insurance(params: Record<string, any>) {
  return request.post('/cerp/update_insurance', params);
}

export async function delete_insurance(params: Record<string, any>) {
  return request.post('/cerp/delete_insurance', params);
}

export async function recover_insurance(params: Record<string, any>) {
  return request.post('/cerp/recover_insurance', params);
}

export async function query_insurance_by_select_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/cerp/query_insurance_by_select_list', params);
}

export async function query_insurance_list(params?: { [key: string]: any }): Promise<PageResult> {
  return request.post('/cerp/query_insurance_list', params);
}

export async function create_invoice(params: Record<string, any>) {
  return request.post('/cerp/create_invoice', params);
}

export async function update_invoice(params: Record<string, any>) {
  return request.post('/cerp/update_invoice', params);
}

export async function delete_invoice(params: Record<string, any>) {
  return request.post('/cerp/delete_invoice', params);
}

export async function recover_invoice(params: Record<string, any>) {
  return request.post('/cerp/recover_invoice', params);
}

export async function query_invoice_list(params?: { [key: string]: any }): Promise<PageResult> {
  return request.post('/cerp/query_invoice_list', params);
}

export async function cancel_invoice(params: Record<string, any>) {
  return request.post('/cerp/cancel_invoice', params);
}

export async function send_invoice_by_email$(params: Record<string, any>) {
  return request.post('/cerp/send_invoice_by_email$', params);
}

export async function create_next_of_kin(params: Record<string, any>) {
  return request.post('/cerp/create_next_of_kin', params);
}

export async function update_next_of_kin(params: Record<string, any>) {
  return request.post('/cerp/update_next_of_kin', params);
}

export async function delete_next_of_kin(params: Record<string, any>) {
  return request.post('/cerp/delete_next_of_kin', params);
}

export async function recover_next_of_kin(params: Record<string, any>) {
  return request.post('/cerp/recover_next_of_kin', params);
}

export async function query_next_of_kin_by_select_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/cerp/query_next_of_kin_by_select_list', params);
}

export async function query_next_of_kin_list(params?: { [key: string]: any }): Promise<PageResult> {
  return request.post('/cerp/query_next_of_kin_list', params);
}

export async function create_note(params: Record<string, any>) {
  return request.post('/cerp/create_note', params);
}

export async function update_note(params: Record<string, any>) {
  return request.post('/cerp/update_note', params);
}

export async function delete_note(params: Record<string, any>) {
  return request.post('/cerp/delete_note', params);
}

export async function recover_note(params: Record<string, any>) {
  return request.post('/cerp/recover_note', params);
}

export async function query_note_list(params?: { [key: string]: any }): Promise<PageResult> {
  return request.post('/cerp/query_note_list', params);
}

export async function create_payslip(params: Record<string, any>) {
  return request.post('/cerp/create_payslip', params);
}

export async function create_payslip_for_payment_later(params: Record<string, any>) {
  return request.post('/cerp/create_payslip_for_payment_later', params);
}

export async function update_payslip(params: Record<string, any>) {
  return request.post('/cerp/update_payslip', params);
}

export async function query_paslip_custom_field_list(params: Record<string, any>) {
  return request.post('/cerp/query_paslip_custom_field_list', params);
}

export async function delete_payslip(params: Record<string, any>) {
  return request.post('/cerp/delete_payslip', params);
}

export async function recover_payslip(params: Record<string, any>) {
  return request.post('/cerp/recover_payslip', params);
}

export async function query_payslip_list(params?: { [key: string]: any }): Promise<PageResult> {
  return request.post('/cerp/query_payslip_list', params);
}

export async function send_payslip_by_email(params: Record<string, any>) {
  return request.post('/cerp/send_payslip_by_email', params);
}

export async function confirm_payslip(params: Record<string, any>) {
  return request.post('/cerp/confirm_payslip', params);
}

export async function create_person_reference_number(params: Record<string, any>) {
  return request.post('/cerp/create_person_reference_number', params);
}

export async function update_person_reference_number(params: Record<string, any>) {
  return request.post('/cerp/update_person_reference_number', params);
}

export async function delete_person_reference_number(params: Record<string, any>) {
  return request.post('/cerp/delete_person_reference_number', params);
}

export async function recover_person_reference_number(params: Record<string, any>) {
  return request.post('/cerp/recover_person_reference_number', params);
}

export async function query_person_reference_number_by_select_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/cerp/query_person_reference_number_by_select_list', params);
}

export async function query_person_reference_number_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/cerp/query_person_reference_number_list', params);
}

export async function create_remittance(params: Record<string, any>) {
  return request.post('/cerp/create_remittance', params);
}

export async function update_remittance(params: Record<string, any>) {
  return request.post('/cerp/update_remittance', params);
}

export async function delete_remittance(params: Record<string, any>) {
  return request.post('/cerp/delete_remittance', params);
}

export async function recover_remittance(params: Record<string, any>) {
  return request.post('/cerp/recover_remittance', params);
}

export async function query_remittance_list(params?: { [key: string]: any }): Promise<PageResult> {
  return request.post('/cerp/query_remittance_list', params);
}

export async function create_remittance_set(params: Record<string, any>) {
  return request.post('/cerp/create_remittance_set', params);
}

export async function update_remittance_set(params: Record<string, any>) {
  return request.post('/cerp/update_remittance_set', params);
}

export async function delete_remittance_set(params: Record<string, any>) {
  return request.post('/cerp/delete_remittance_set', params);
}

export async function recover_remittance_set(params: Record<string, any>) {
  return request.post('/cerp/recover_remittance_set', params);
}

export async function set_contract_current(params: Record<string, any>) {
  return request.post('/cerp/set_contract_current', params);
}

export async function query_remittance_set_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/cerp/query_remittance_set_list', params);
}

export async function query_data_list(params?: { [key: string]: any }): Promise<PageResult> {
  return request.post('/cerp/query_data_list', params);
}

export async function send_invoice_by_email(params: Record<string, any>) {
  return request.post('/cerp/send_invoice_by_email', params);
}

export async function send_invoice_by_manul(params: Record<string, any>) {
  return request.post('/cerp/send_invoice_by_manul', params);
}

export async function create_todoevent(params: Record<string, any>) {
  return request.post('/cerp/create_todoevent', params);
}

export async function update_todoevent(params: Record<string, any>) {
  return request.post('/cerp/update_todoevent', params);
}

export async function delete_todoevent(params: Record<string, any>) {
  return request.post('/cerp/delete_todoevent', params);
}

export async function query_todoevent_list(params: Record<string, any>) {
  return request.post('/cerp/query_todoevent_list', params);
}

export async function recover_todoevent(params: Record<string, any>) {
  return request.post('/cerp/recover_todoevent', params);
}

export async function query_change_history_list(params: Record<string, any>) {
  return request.post('/cerp/query_change_history_list', params);
}

export async function get_pdf_ocr_info(params: Record<string, any>) {
  return request.post('/cerp/get_pdf_ocr_info', params);
}

export async function set_contract_termination_date(params: Record<string, any>) {
  return request.post('/cerp/set_contract_termination_date', params);
}

export async function delete_contract_termination_date(params: Record<string, any>) {
  return request.post('/cerp/delete_contract_termination_date', params);
}

export async function import_todoevent(params: Record<string, any>) {
  return request.post('/cerp/import_todoevent', params);
}

export async function create_work_hour(params: Record<string, any>) {
  return request.post('/cerp/create_work_hour', params);
}

export async function create_month_work_hour(params: Record<string, any>) {
  return request.post('/cerp/create_month_work_hour', params);
}

export async function update_work_hour(params: Record<string, any>) {
  return request.post('/cerp/update_work_hour', params);
}

export async function delete_work_hour(params: Record<string, any>) {
  return request.post('/cerp/delete_work_hour', params);
}

export async function recover_work_hour(params: Record<string, any>) {
  return request.post('/cerp/recover_work_hour', params);
}

export async function query_payslip_detail_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/cerp/query_payslip_detail_list', params);
}

export async function query_work_hour_list(params?: { [key: string]: any }): Promise<PageResult> {
  return request.post('/cerp/query_work_hour_list', params);
}
