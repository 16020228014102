
import { onMounted, PropType, watch } from 'vue';
import expand from 'emmet';
import CodeMirror from 'codemirror';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material-darker.css';
import 'codemirror/mode/jinja2/jinja2.js';
import 'codemirror/mode/python/python.js';
import 'codemirror/mode/javascript/javascript.js';
import 'codemirror/mode/htmlmixed/htmlmixed.js';
import 'codemirror/mode/css/css.js';
import 'codemirror/mode/sass/sass.js';

type modeType = 'javascript' | 'htmlmixed' | 'css' | 'python' | 'jinja2';

function getWord(line: string, ch: number): [string, number] {
  const getNearTagChar = (str: string): string => {
    for (let i = str.length - 1; i > 0; i--) {
      if (str[i] === '>' || str[i] === '<') return str[i];
    }
    return str[0] || '<';
  };
  // 光标位于行末或单词末尾
  if (ch === line.length || (line.length > ch + 1 && (/\s/.test(line[ch]) || line[ch] === '<'))) {
    let i;
    for (i = ch - 1; i >= 0; i--) {
      if (/\s/.test(line[i]) || (line[i] === '>' && getNearTagChar(line.slice(0, i)) === '<')) {
        break;
      }
    }
    return [line.slice(i + 1, ch), i + 1];
  }
  return ['', 0];
}

function debounce(fn: any, delay = 1000) {
  let timer: any;
  return function (this: any) {
    const context = this;
    const args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  };
}

export default {
  name: 'CodeEditor',
  props: {
    editorValue: {
      type: String,
      default: '',
    },
    mode: {
      type: String as PropType<modeType>,
      required: true,
      default: 'javascript',
    },
    options: {
      type: Object,
      default: () => {
        return {
          lineNumbers: true,
          line: true,
          indentUnit: 2,
          lineWidth: 4,
          lineColor: '#b99944',
          immediate: true,
        };
      },
    },
  },
  setup(props: any, context: any) {
    let cmInstance: any;
    const code_edit_id: string = 'code_edit_id' + new Date().getTime();

    onMounted(async () => {
      const editor = document.getElementById(code_edit_id);
      let word: string, wordIndex: number;
      cmInstance = CodeMirror.fromTextArea(editor as HTMLTextAreaElement, props.options);
      cmInstance.setOption('mode', props.mode);
      cmInstance.setOption('theme', 'material-darker');
      cmInstance.setValue(props.editorValue);
      cmInstance.on('change', (coder: any) => {
        if (props.mode === 'htmlmixed' || props.mode === 'css') {
          // HTML模式下使用Emmet
          const { line: lineIndex, ch } = coder.getCursor();
          const line = coder.getLine(lineIndex);
          const wordResult = getWord(line, ch);
          word = wordResult[0];
          wordIndex = wordResult[1];
        }
        context.emit('update:editorValue', coder.getValue());
      });
      cmInstance.on(
        'change',
        debounce(() => {
          context.emit('debounce-update');
        }, 5000) as (instance: any) => void,
      );

      if (props.mode === 'htmlmixed' || props.mode === 'css') {
        cmInstance.setOption('extraKeys', {
          Tab: function (coder: any) {
            const indent = coder.getOption('indentUnit') || 2;
            const spaces = Array(indent + 1).join(' ');
            if (!word) {
              coder.replaceSelection(spaces);
            } else {
              try {
                const typeMap: any = {
                  htmlmixed: 'markup',
                  css: 'stylesheet',
                };
                const emmet = expand(word, { type: typeMap[props.mode] });
                const { line, ch } = coder.getCursor();
                coder.setSelection({ line, ch }, { line, ch: wordIndex });
                const formatterEmmet = emmet
                  .split('\n')
                  .map((line, index) => {
                    if (index > 0) {
                      line = line.replace(/\t/g, spaces);
                      line = Array(wordIndex + 1).join(' ') + line;
                    }
                    return line;
                  })
                  .join('\n');
                coder.replaceSelection(formatterEmmet);
              } catch (e) {
                console.error(e);
                coder.replaceSelection(spaces);
              }
            }
          },
        });
      }
    });

    watch(
      () => props.mode,
      val => {
        cmInstance.setOption('mode', val);
      },
    );
    // watch(
    //   () => props.editorValue,
    //   val => {
    //     cmInstance.setValue(val);
    //   },
    // );

    return {
      code_edit_id,
      handleCodeMirrorRefresh() {
        cmInstance.refresh();
      },
    };
  },
};
