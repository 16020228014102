import { PageConfig } from '@/components/page-model/typing';

export default (modalType: string): PageConfig => {
  const allComponents = require.context('@/components/page-model2', true, /^.*\.ts$/);
  const res_components: { [key: string]: any } = {};
  allComponents.keys().forEach(full_file_name => {
    const comp = allComponents(full_file_name);
    const file_name_list = full_file_name.split('/');
    const file_name = file_name_list[file_name_list.length - 1].replace('.ts', '');
    res_components[file_name] = comp.default;
  });
  return {
    ...res_components[modalType](),
  };
};
