import { GetterTree } from 'vuex';
import { UserState } from './typing';
import { RootState } from '@/store/root-state';

export const getters: GetterTree<UserState, RootState> = {
  role: state => state.role,
  info: state => state.extra,
  username: state => state.username,
  nickname: state => state.nickname,
  avatar: state => state.avatar,
  allowRouters: state => state.allowRouters,
  currentUser: state => state,
  org_list: state => state.org_list,
  current_org: state => state.current_org,
  current_company: state => state.current_company,
  sessionid2: state => state.sessionid2,
  is_super_user: state => state.is_super_user,
  service: state => state.service,
  no_read_number: state => state.no_read_number,
  no_receive_num: state => state.no_receive_num,
  custome_data_user: state => state.custome_data_user,
  custome_data_org: state => state.custome_data_org,
  my_menu_data_list: state => state.my_menu_data_list,
};
