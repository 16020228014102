import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { ResponseBody } from '@/api/typing';
import { notification } from 'ant-design-vue';

import ls from '@/utils/local-storage';
import {
  STORAGE_CURRENT_ORG_KEY,
  STORAGE_LANG_KEY,
  OPERATING_TIME_KEY,
} from '@/store/mutation-type';
import moment from 'moment';
import bus from '@/utils/bus';

// 这里是用于设定请求后端时，所用的 Token KEY
// 可以根据自己的需要修改，常见的如 Access-Token，Authorization
// 需要注意的是，请尽量保证使用中横线`-` 来作为分隔符，
// 避免被 nginx 等负载均衡器丢弃了自定义的请求头
export const REQUEST_TOKEN_KEY = 'sessionid';

export interface ResponseData {
  success: boolean;
  message: string;
  status_code: number;
  result: any;
  dialog: number;
}

// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 60000, // 请求超时时间
});

// 异常拦截处理器
const errorHandler = (error: AxiosError): Promise<any> => {
  if (error.response) {
    const { data = {}, status, statusText } = error.response;
    // 403 无权限
    if (status === 403) {
      notification.error({
        message: 'Forbidden',
        description: (data && data.message) || statusText,
      });
    }
    // 401 未登录/未授权
    if (status === 401 && data.result && data.result.isLogin) {
      notification.error({
        message: 'Unauthorized',
        description: 'Authorization verification failed',
      });
    }
  }
  return Promise.reject(error);
};

// 请求拦截器
const requestHandler = (
  config: AxiosRequestConfig,
): AxiosRequestConfig | Promise<AxiosRequestConfig> => {
  // const sessionid2 = localStorage.get(STORAGE_SESSIONID2);
  // 如果 sessionid2 存在
  // 让每个请求携带自定义 sessionid2
  // if (sessionid2) {
  // config.headers['sessionid2'] = sessionid2;
  // }
  let date = new Date(); // 当前时间
  let operating_time = ls.get(OPERATING_TIME_KEY); // 上一次操作时间
  if (
    operating_time &&
    config.url != '/sys/logout' &&
    config.url != '/service/get_service_no_read_number' &&
    config.url != '/service/query_service_session_list'
  ) {
    let duration = moment.duration(moment(date).diff(moment(operating_time))); // 计算当前时间与上一次操作时间差
    if (duration.asHours() > 2) {
      ls.set(OPERATING_TIME_KEY, null);
      bus?.emit('LOGOUT');
      return {};
    } else {
      ls.set(OPERATING_TIME_KEY, moment(new Date()).format('YYYY-MM-DD HH:mm:ss'));
    }
  } else {
    if (
      config.url != '/sys/logout' &&
      config.url != '/service/get_service_no_read_number' &&
      config.url != '/service/query_service_session_list'
    ) {
      ls.set(OPERATING_TIME_KEY, moment(new Date()).format('YYYY-MM-DD HH:mm:ss'));
    }
  }

  if (config.data && (config.data.org_id == undefined || !config.data.org_id)) {
    const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
    config.data.org_id = current_org?.id;
  }
  config.headers['requesttype'] = 'ant';
  return config;
};

// Add a request interceptor
request.interceptors.request.use(requestHandler, errorHandler);

// 响应拦截器
const responseHandler = (
  response: AxiosResponse,
): ResponseBody<any> | AxiosResponse<any> | Promise<any> | any => {
  const res: ResponseData = response.data;
  let is_CN = ls.get(STORAGE_LANG_KEY) === 'zh-CN';
  if (res.success) {
    return res.result;
  } else if (res.status_code == 1) {
    notification.error({
      message: is_CN ? '登录过期' : 'Login expired',
      description: is_CN ? '请重新登录' : 'Please log in again',
    });
    location.href = '/static_file_pc/user/login';
  } else if (res.status_code == 4) {
    notification.error({
      message: is_CN ? '服务器端错误' : 'Server side error',
      description: res.message,
    });
  } else if (res.status_code == 14) {
    notification.error({
      message: is_CN ? '服务器端错误' : 'Server side error',
      description: res.message,
    });
  }
  return Promise.reject(res);
};

// Add a response interceptor
request.interceptors.response.use(responseHandler, errorHandler);

export { AxiosResponse };

export default request;
