import request from '@/utils/request';

export async function queryPerson(params?: { [key: string]: any }): Promise<RoleResponse> {
  return request.post('/sys/query_person_list', params);
}

export async function removePerson(params: Record<string, any>) {
  return request.post('/sys/delete_user', params);
}
export async function recoverPerson(params: Record<string, any>) {
  return request.post('/sys/recover_user', params);
}
export async function resetPassword(params: Record<string, any>) {
  return request.post('/sys/reset_password', params);
}
export async function addPerson(params: Record<string, any>) {
  return request.post('/sys/create_user', params);
  // return request('/sys.create_user', {
  //   method: 'POST',
  //   data: {
  //     ...params,
  //     method: 'post',
  //   },
  // });
}

export async function updatePerson(params: Record<string, any>) {
  return request.post('/sys/update_user', params);
}
export async function addPersonGroup(params: Record<string, any>) {
  return request.post('/org/add_person_group', params);
}
export async function queryGroupByOrgList(params: Record<string, any>) {
  return request.post('/org/query_group_by_org_list', params);
}

interface RuleItem {
  key: string | number;
  callNo: number;
  avatar: string;
  desc: string;
  disabled: false;
  href: string;
  name: string;
  owner: string;
  progress: number;
  status: number;
  updatedAt: string;
  createdAt: string;
}

interface RoleResponse {
  current: number;
  data: RuleItem[];
  pageSize: string;
  success: boolean;
  total: number;
}
