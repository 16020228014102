<template>
  <a-layout-footer style="padding: 0"></a-layout-footer>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  setup() {
    return {};
  },
});
</script>

<style lang="less" scoped>
@import url('./index.less');
</style>
