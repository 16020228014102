<template>
  <a-modal
    width="1000px"
    v-bind="changeUserModal"
    v-if="changeUserModal.visible"
    :maskClosable="false"
    :footer="null"
    @cancel="
      () => {
        changeUserModal.visible = false;
      }
    "
  >
    <search-render
      ref="config_render"
      :form="searchConfig"
      :model="searchConfig.model"
      :options="searchConfigOptions"
      :validateInfos="validateInfos"
      :resetFields="resetFields"
      @search="search"
    />
    <a-table
      :scroll="{ y: tableSize.y, x: 1000 }"
      :row-class-name="stripe"
      :size="state.tableSize"
      :loading="state.loading"
      :columns="dynamicColumns"
      :data-source="state.dataSource"
      :indentSize="0"
      :rowKey="'id'"
      :pagination="{
        current: state.current,
        pageSize: state.pageSize,
        total: state.total,
      }"
    >
      <template #index1="{ index }">
        {{ state.pageSize * (state.current - 1) + index + 1 }}
      </template>
      <template #name="{ text, record }">
        <span v-if="record.parent" style="padding-left: 30px">{{ text }}</span>
        <span v-if="!record.parent" class="ant-btn ant-btn-link">{{ text }}</span>
      </template>
      <template #id="{ text }">
        <span>#{{ text }}</span>
      </template>
      <template #role_list="{ text }">
        <a-tag color="green" v-for="item in text" :key="item.id">
          {{ item.name }}
        </a-tag>
      </template>
      <template #action="{ record }">
        <a-button
          type="danger"
          @click="() => handleChangeUserBtn(record.id)"
          v-if="record.is_active"
        >
          切换用户
        </a-button>
      </template>
    </a-table>
  </a-modal>
</template>

<script lang="ts">
import { defineComponent, reactive, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { CHECK_IS_SUPER_USER, GET_MY_MENU_DATA_LIST, SWITCH_USER } from '@/store/modules/user/actions';
import { TableColumn } from '@/typing';
import { useFetchData } from '@/utils/hooks/useFetchData';
import { querySwitchUserList } from '@/api/sys';
import { useTableDynamicColumns } from '@/utils/hooks/useTableColumn';
import { SearchConfig } from '@/components/page-model/typing';
import { useForm } from 'ant-design-vue/es/form';

export default defineComponent({
  name: 'SwitchUserModal',
  props: {},
  emits: ['ok'],
  setup(props, { emit }) {
    const store = useStore();
    const { t } = useI18n();
    const current_org = store.getters['user/current_org'];
    const is_super_user = ref(false);
    const tableSize = reactive({ y: window.innerHeight - 130 });
    const baseColumns: TableColumn[] = [
      {
        title: '序号',
        dataIndex: 'index1',
        width: 60,
        slots: { customRender: 'index1' },
      },
      {
        title: '账号',
        dataIndex: 'username',
        width: 60,
        slots: { customRender: 'username' },
      },
      {
        title: '姓名',
        dataIndex: 'realname',
        width: 60,
        slots: { customRender: 'realname' },
      },
      {
        title: '角色',
        dataIndex: 'role_list',
        width: 60,
        slots: { customRender: 'role_list' },
      },
      {
        title: t('操作'),
        dataIndex: 'action',
        width: 200,
        slots: { customRender: 'action' },
      },
    ];

    const searchConfig: SearchConfig = {
      settings: {
        labelAlign: 'right',
        layout: 'inline',
      },
      fields: [
        {
          type: 'remoteselect',
          name: 'aim_org_id',
          label: '目标组织',
          label_i18n: '目标组织',
          placeholder_i18n: '请选择目标组织',
          disabled: false,
          labelKey: 'name',
          valueKey: 'id',
          placeholder: '请填组织id',
          datasourceType: 'remote',
          datasource: 'aim_org_id',
          mode: 'default',
          modalType: 'select-org-id',
        },
      ],
      rules: {},
      model: reactive({
        org_id: current_org?.id,
        aim_org_id: current_org?.id,
      }),
    };
    const searchConfigOptions = {};
    const { resetFields, validateInfos } = useForm(searchConfig.model, searchConfig.rules);
    const search = () => {
      setPageInfo({
        current: 1,
        ...searchConfig.model,
      });
      reload();
    };
    const changeUserModal = reactive({
      visible: true,
      title: '切换用户',
    });

    const handleChangeUserBtn = (user_id: any) => {
      store
        .dispatch(`user/${SWITCH_USER}`, {
          org_id: current_org.id,
          user_id: user_id,
        })
        .then(() => {
          const c_org = store.getters['user/current_org'];
          store
            .dispatch(`user/${GET_MY_MENU_DATA_LIST}`, { org_id: Number(c_org?.id) })
            .then(() => {
              emit('ok');
            });
        });
    };
    const {
      state: columnState,
      dynamicColumns,
      dynamicColumnItems,
      handleColumnAllClick,
      handleColumnChange,
      reset,
      move,
    } = useTableDynamicColumns(baseColumns, false);
    const { stripe, reload, setPageInfo, context: state } = useFetchData(querySwitchUserList, {
      current: 1,
      pageSize: 2000,
      tableSize: 'small', // 'default' | 'middle' | 'small'
      stripe: true,
      requestParams: {
        org_id: current_org?.id,
        aim_org_id: searchConfig.model.aim_org_id,
      },
    });
    store
      .dispatch(`user/${CHECK_IS_SUPER_USER}`, {
        org_id: current_org?.id,
      })
      .then(() => {
        is_super_user.value = store.getters['user/is_super_user'];
      });
    return {
      t,
      changeUserModal,
      tableSize,
      state,
      stripe,
      columnState,
      dynamicColumns,
      dynamicColumnItems,
      handleColumnChange,
      handleColumnAllClick,
      reset,
      move,
      reload,
      searchConfig,
      searchConfigOptions,
      resetFields,
      validateInfos,

      is_super_user,

      search,
      handleChangeUserBtn,
    };
  },
  components: {},
});
</script>
