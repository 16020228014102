import mitt from 'mitt';
import defaultSlotList from '@/components/page-model/slotList';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import {
  create_month_work_hour_pdf,
  lock_month_work_hour,
  send_month_work_hour_email,
  unlock_month_work_hour,
  update_month_work_hour,
} from '@/api/cerp-model';
import { message, Modal } from 'ant-design-vue';
import modalBox from '@/components/form-modal/modal-tools';
import { reactive } from 'vue';
import moment from 'moment';

export const event_obj = mitt();
const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
export const btn_list = [
  {
    name: '锁定',
    type: 'dropdown',
    clazz: 'primary',
    is_show: (record: any) => {
      return record.is_lock === false;
    },
    cb: (record: any, dataSource: any, index: any, requestParams: any, t: any) => {
      Modal.confirm({
        title: t('提示'),
        content: t('是否锁定') + '?',
        onOk() {
          return new Promise(resolve => {
            lock_month_work_hour({ org_id: current_org?.id, month_work_hour_id: record.id })
              .then(() => {
                message.success(t('锁定成功'));
                event_obj.emit('refresh');
              })
              .finally(() => {
                resolve(null);
              });
          });
        },
        onCancel() {
        },
      });
    },
  },
  {
    name: '取消锁定',
    type: 'dropdown',
    clazz: 'primary',
    is_show: (record: any) => {
      return record.is_lock === true;
    },
    cb: (record: any, dataSource: any, index: any, requestParams: any, t: any) => {
      Modal.confirm({
        title: t('提示'),
        content: t('是否取消锁定') + '?',
        onOk() {
          return new Promise(resolve => {
            unlock_month_work_hour({ org_id: current_org?.id, month_work_hour_id: record.id })
              .then(() => {
                message.success(t('取消锁定成功'));
                event_obj.emit('refresh');
              })
              .finally(() => {
                resolve(null);
              });
          });
        },
        onCancel() {
        },
      });
    },
  },
  {
    name: '生成顾问月工时PDF',
    type: 'dropdown',
    clazz: 'primary',
    is_show: (record: any) => {
      return record.is_lock === true;
    },
    cb: (record: any, dataSource: any, index: any, requestParams: any, t: any) => {
      Modal.confirm({
        title: t('提示'),
        content: t('是否生成顾问月工时PDF') + '?',
        onOk() {
          return new Promise(resolve => {
            create_month_work_hour_pdf({ org_id: current_org?.id, month_work_hour_id: record.id })
              .then(() => {
                message.success(t('操作成功'));
                event_obj.emit('refresh');
              })
              .finally(() => {
                resolve(null);
              });
          });
        },
        onCancel() {
        },
      });
    },
  },
  {
    name: '发送顾问月工时邮件',
    type: 'dropdown',
    clazz: 'primary',
    is_show: (record: any) => {
      return record.is_lock === true;
    },
    cb: (record: any, dataSource: any, index: any, requestParams: any, t: any) => {
      Modal.confirm({
        title: t('提示'),
        content: t('是否发送顾问月工时邮件') + '?',
        onOk() {
          return new Promise(resolve => {
            send_month_work_hour_email({ org_id: current_org?.id, month_work_hour_id: record.id })
              .then(() => {
                message.success(t('操作成功'));
                event_obj.emit('refresh');
              })
              .finally(() => {
                resolve(null);
              });
          });
        },
        onCancel() {
        },
      });
    },
  },
  {
    name: '修改',
    type: 'dropdown',
    clazz: 'primary',
    is_show: (record: any) => {
      return record.is_lock === false;
    },
    cb: (record: any, dataSource: any, index: any, requestParams: any, t: any) => {
      const editModal = reactive({
        visible: true,
        title: t('修改顾问工时'),
        loading: false,
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'vertical',
            col: [],
          },
          fields: [
            {
              type: 'input',
              name: 'work_month',
              label: '月份',
              label_i18n: '月份',
              placeholder_i18n: '请填写月份',
              disabled: true,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写月份',
            },
            {
              type: 'input',
              name: 'total_num',
              label: '当月工时数',
              label_i18n: '当月工时数',
              placeholder_i18n: '请填写当月工时数',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写当月工时数',
            },
            {
              type: 'input',
              name: 'used_annual_leave',
              label: '当月年假天数',
              label_i18n: '当月年假天数',
              placeholder_i18n: '请填写当月年假天数',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写当月年假天数',
            },
          ],
          rules: {},
          model: {
            org_id: current_org?.id,
            is_active: true,
            month_work_hour_id: record.id,
            total_num: record.total_num,
            work_month: moment(record.work_month).format('YYYY-MM'),
            used_annual_leave: record.used_annual_leave,
          },
        },
        options: {},
        ok: (payload: any) => {
          return new Promise(resolve => {
            editModal.loading = true;
            update_month_work_hour(payload)
              .then(() => {
                message.success(t('修改成功'));
                event_obj.emit('refresh');
                resolve(null);
              })
              .finally(() => {
                editModal.loading = false;
              });
          });
        },
      });
      modalBox(editModal);
    },
  },
];
export const slotList = defaultSlotList;
export const config = {
  creatCol: [],
  updateCol: [],
};

export const head_btn_list = [];
