import { SearchConfig } from '@/components/page-model/typing';
import { reactive } from 'vue';
import { config } from '@/components/page-model4/baseinfo/baseinfo-questiongroup-config';

export default (current_org: any) => {
  const None = null;
  const True = true;
  const False = false;

  const update_model_config: SearchConfig = {
    settings: {
      labelAlign: 'right',
      layout: 'vertical',
      col: config.creatCol,
    },
    fields: [
      {
        type: 'input',
        name: 'name',
        label: '名称',
        label_i18n: '名称',
        disabled: false,
        allowClear: true,
        inputType: 'text',
        defaultValue: '',
        placeholder: '请填写名称',
        placeholder_i18n: '请填写名称',
      },
    ],
    rules: {},
    model: reactive({
      org_id: current_org?.id,
      is_active: true,
      name: null,
    }),
  };
  const update_model_config_options = {};

  return {
    update_model_config: update_model_config,
    update_model_config_options: update_model_config_options,
  };
}
