import { create_address, delete_address, query_address_list, recover_address, update_address } from '@/api/cerp-model';
import { TableColumn } from '@/typing';
import { Record, SearchConfig } from '@/components/page-model/typing';
import { useI18n } from 'vue-i18n';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import {
  btn_list,
  config,
  event_obj,
  head_btn_list,
  slotList,
} from '@/components/form-modal/table-modal/cerp-address-config';

import { reactive } from 'vue';

export default () => {
  const None = null;
  const True = true;
  const False = false;

  const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org

  const { t } = useI18n();
  const search_config: SearchConfig = {
    settings: {
      labelAlign: 'right',
      layout: 'inline',
    },
    fields: [
      {
        type: 'remotemodal',
        name: 'adviser_id',
        label: '隶属顾问',
        label_i18n: t('page.cerp.address.form.label.adviser_id'),
        disabled: false,
        defaultValue: '',
        labelKey: 'full_name',
        valueKey: 'id',
        placeholder: '隶属顾问',
        placeholder_i18n: t('page.cerp.address.form.placeholder.adviser_id'),
        show_search: false,
        maxTagCount: 5,
        mode: 'default',
        title: t('选择隶属顾问'),
        modalType: 'cerp-adviser',
      },
      {
        type: 'input',
        name: 'street',
        label: '街道',
        label_i18n: t('page.cerp.address.form.label.street'),
        disabled: false,
        allowClear: true,
        inputType: 'text',
        defaultValue: '',
        placeholder: '请填写街道',
        placeholder_i18n: t('page.cerp.address.form.placeholder.street'),
      },
      {
        type: 'input',
        name: 'city',
        label: '城市',
        label_i18n: t('page.cerp.address.form.label.city'),
        disabled: false,
        allowClear: true,
        inputType: 'text',
        defaultValue: '',
        placeholder: '请填写城市',
        placeholder_i18n: t('page.cerp.address.form.placeholder.city'),
      },
      {
        type: 'input',
        name: 'state',
        label: '州/省',
        label_i18n: t('page.cerp.address.form.label.state'),
        disabled: false,
        allowClear: true,
        inputType: 'text',
        defaultValue: '',
        placeholder: '请填写州/省',
        placeholder_i18n: t('page.cerp.address.form.placeholder.state'),
      },
      {
        type: 'remoteselect',
        name: 'country_id',
        label: '国家',
        label_i18n: t('page.cerp.address.form.label.country_id'),
        disabled: false,
        placeholder: '请选择国家',
        placeholder_i18n: t('page.cerp.address.form.placeholder.country_id'),
        datasourceType: 'remote',
        mode: 'default',
        allowClear: true,
        modalType: 'baseinfo-country',
      },
      {
        type: 'input',
        name: 'postcode',
        label: '邮编',
        label_i18n: t('page.cerp.address.form.label.postcode'),
        disabled: false,
        allowClear: true,
        inputType: 'text',
        defaultValue: '',
        placeholder: '请填写邮编',
        placeholder_i18n: t('page.cerp.address.form.placeholder.postcode'),
      },
      {
        type: 'input',
        name: 'display_name',
        label: '显示名称',
        label_i18n: t('page.cerp.address.form.label.display_name'),
        disabled: false,
        allowClear: true,
        inputType: 'text',
        defaultValue: '',
        placeholder: '请填写显示名称',
        placeholder_i18n: t('page.cerp.address.form.placeholder.display_name'),
      },
    ],
    rules: {},
    model: reactive({
      org_id: current_org?.id,
      is_active: true,
      adviser_id: null,
      street: null,
      city: null,
      state: null,
      country_id: null,
      postcode: null,
      display_name: null,
      address_type: null,
    }),
  };
  const search_options = {
    is_active: [
      { value: None, name: '全部' },
      { value: True, name: '可用' },
      { value: False, name: '禁用' },
    ],
  };

  const edit_model_config: SearchConfig = {
    settings: {
      labelAlign: 'right',
      layout: 'vertical',
      col: config.creatCol,
    },
    fields: [
      {
        type: 'remotemodal',
        name: 'adviser_id',
        label: '隶属顾问',
        label_i18n: t('page.cerp.address.form.label.adviser_id'),
        disabled: false,
        defaultValue: '',
        labelKey: 'full_name',
        valueKey: 'id',
        placeholder: '隶属顾问',
        placeholder_i18n: t('page.cerp.address.form.placeholder.adviser_id'),
        show_search: false,
        maxTagCount: 5,
        mode: 'default',
        title: t('选择隶属顾问'),
        modalType: 'cerp-adviser',
      },
      {
        type: 'input',
        name: 'street',
        label: '街道',
        label_i18n: t('page.cerp.address.form.label.street'),
        disabled: false,
        allowClear: true,
        inputType: 'text',
        defaultValue: '',
        placeholder: '请填写街道',
        placeholder_i18n: t('page.cerp.address.form.placeholder.street'),
      },
      {
        type: 'input',
        name: 'city',
        label: '城市',
        label_i18n: t('page.cerp.address.form.label.city'),
        disabled: false,
        allowClear: true,
        inputType: 'text',
        defaultValue: '',
        placeholder: '请填写城市',
        placeholder_i18n: t('page.cerp.address.form.placeholder.city'),
      },
      {
        type: 'input',
        name: 'state',
        label: '州/省',
        label_i18n: t('page.cerp.address.form.label.state'),
        disabled: false,
        allowClear: true,
        inputType: 'text',
        defaultValue: '',
        placeholder: '请填写州/省',
        placeholder_i18n: t('page.cerp.address.form.placeholder.state'),
      },
      {
        type: 'remoteselect',
        name: 'country_id',
        label: '国家',
        label_i18n: t('page.cerp.address.form.label.country_id'),
        disabled: false,
        placeholder: '请选择国家',
        placeholder_i18n: t('page.cerp.address.form.placeholder.country_id'),
        datasourceType: 'remote',
        mode: 'default',
        allowClear: true,
        modalType: 'baseinfo-country',
      },
      {
        type: 'input',
        name: 'postcode',
        label: '邮编',
        label_i18n: t('page.cerp.address.form.label.postcode'),
        disabled: false,
        allowClear: true,
        inputType: 'text',
        defaultValue: '',
        placeholder: '请填写邮编',
        placeholder_i18n: t('page.cerp.address.form.placeholder.postcode'),
      },
      {
        type: 'radio',
        name: 'address_type',
        label: '地址类型',
        label_i18n: t('page.cerp.address.form.label.address_type'),
        disabled: false,
        labelKey: 'name',
        valueKey: 'value',
        defaultValue: '',
      },
    ],
    rules: {},
    model: reactive({
      org_id: current_org?.id,
      is_active: true,
      adviser_id: null,
      street: null,
      city: null,
      state: null,
      country_id: null,
      postcode: null,
      address_type: null,
    }),
  };
  const edit_model_config_options = {
    address_type: [
      { value: 0, name: '家庭地址' },
      { value: 1, name: '工作地址' },
    ],
  };

  const update_model_config: SearchConfig = {
    settings: {
      labelAlign: 'right',
      layout: 'vertical',
      col: config.creatCol,
    },
    fields: [
      {
        type: 'input',
        name: 'adviser__full_name',
        label: '顾问',
        label_i18n: t('page.cerp.address.form.label.adviser__full_name'),
        disabled: true,
        placeholder: '请选择隶属顾问',
        placeholder_i18n: t('page.cerp.address.form.placeholder.adviser__full_name'),
        datasourceType: 'remote',
        allowClear: false,
      },
      {
        type: 'input',
        name: 'street',
        label: '街道',
        label_i18n: t('page.cerp.address.form.label.street'),
        disabled: false,
        allowClear: true,
        inputType: 'text',
        defaultValue: '',
        placeholder: '请填写街道',
        placeholder_i18n: t('page.cerp.address.form.placeholder.street'),
      },
      {
        type: 'input',
        name: 'city',
        label: '城市',
        label_i18n: t('page.cerp.address.form.label.city'),
        disabled: false,
        allowClear: true,
        inputType: 'text',
        defaultValue: '',
        placeholder: '请填写城市',
        placeholder_i18n: t('page.cerp.address.form.placeholder.city'),
      },
      {
        type: 'input',
        name: 'state',
        label: '州/省',
        label_i18n: t('page.cerp.address.form.label.state'),
        disabled: false,
        allowClear: true,
        inputType: 'text',
        defaultValue: '',
        placeholder: '请填写州/省',
        placeholder_i18n: t('page.cerp.address.form.placeholder.state'),
      },
      {
        type: 'remoteselect',
        name: 'country_id',
        label: '国家',
        label_i18n: t('page.cerp.address.form.label.country_id'),
        disabled: false,
        placeholder: '请选择国家',
        placeholder_i18n: t('page.cerp.address.form.placeholder.country_id'),
        datasourceType: 'remote',
        mode: 'default',
        allowClear: true,
        modalType: 'baseinfo-country',
      },
      {
        type: 'input',
        name: 'postcode',
        label: '邮编',
        label_i18n: t('page.cerp.address.form.label.postcode'),
        disabled: false,
        allowClear: true,
        inputType: 'text',
        defaultValue: '',
        placeholder: '请填写邮编',
        placeholder_i18n: t('page.cerp.address.form.placeholder.postcode'),
      },
      {
        type: 'radio',
        name: 'address_type',
        label: '地址类型',
        label_i18n: t('page.cerp.address.form.label.address_type'),
        disabled: false,
        labelKey: 'name',
        valueKey: 'value',
        defaultValue: '',
      },
    ],
    rules: {},
    model: reactive({
      org_id: current_org?.id,
      is_active: true,
      adviser_id: null,
      street: null,
      city: null,
      state: null,
      country_id: null,
      postcode: null,
      address_type: null,
    }),
  };
  const update_model_config_options = {
    address_type: [
      { value: 0, name: '家庭地址' },
      { value: 1, name: '工作地址' },
    ],
  };

  const titleList = {
    title: '',
    addModalTitle: '新建地址',
    updateTitle: '修改地址',
    is_create: false, // 是否有【新建】按钮
    is_update: false, // 是否有【修改】按钮
    is_remove: true, // 是否有【删除】按钮
    is_recover: true, // 是否有【恢复】按钮
  };

  const api_fields: Record = {
    update: {
      address_id: 'id',
    },
    delete: {
      address_id: 'id',
    },
    recover: {
      address_id: 'id',
    },
    updateData: {
      org_id: 'org_id',
      address_id: 'id',
    },
  };

  const baseColumns: TableColumn[] = [
    {
      title: t('page.cerp.address.column.title.index'),
      dataIndex: 'index',
      width: 50,
      slots: { customRender: 'index' },
    },
    {
      title: t('page.cerp.address.column.title.id'),
      width: 150,
      dataIndex: 'id',
      checked: false,
    },
    {
      title: t('page.cerp.address.column.title.adviser__full_name'),
      width: 150,
      dataIndex: 'adviser__full_name',
      checked: true,
    },
    {
      title: t('page.cerp.address.column.title.country__name'),
      width: 150,
      dataIndex: 'country__name',
      checked: true,
    },
    {
      title: t('page.cerp.address.column.title.display_name'),
      width: 150,
      dataIndex: 'display_name',
      checked: true,
    },
    {
      title: t('page.cerp.address.column.title.postcode'),
      width: 150,
      dataIndex: 'postcode',
      checked: true,
    },
    {
      title: t('page.cerp.address.column.title.create_time'),
      width: 150,
      dataIndex: 'create_time',
      checked: true,

      slots: { customRender: 'datetime' },
    },
    {
      title: t('page.cerp.address.column.title.update_time'),
      width: 150,
      dataIndex: 'update_time',
      checked: true,

      slots: { customRender: 'datetime' },
    },
    {
      title: t('page.cerp.address.column.title.address_type'),
      width: 150,
      dataIndex: 'address_type',
      checked: true,

      slots: { customRender: 'address_type' },
      filterMultiple: false,
      filters: [
        { text: t('全部'), value: '' },
        { text: '家庭地址', value: 0 },
        { text: '工作地址', value: 1 },
      ],
    },
    {
      title: t('page.cerp.address.column.title.is_active'),
      width: 150,
      dataIndex: 'is_active',
      checked: true,

      slots: { customRender: 'is_active' },
      filterMultiple: false,
      filters: [
        { text: t('全部'), value: '' },
        { text: t('可用'), value: true },
        { text: t('禁用'), value: false },
      ],
    },

    {
      title: t('page.cerp.address.column.title.action'),
      width: 200,
      dataIndex: 'action',
      checked: true,
      slots: { customRender: 'action' },
      fixed: 'right',
    },
  ];
  const newSlotList: any = [];
  Object.assign(newSlotList, slotList);
  newSlotList.push(
    {
      type: 'custom',
      // slotName: 'is_active',
      field_true: '可用',
      field_false: '禁用',
    },
    {
      type: 'custom',
      slotName: 'address_type',
      field_0: '家庭地址',
      field_1: '工作地址',
    },
  );

  return {
    searchConfig: search_config,
    searchConfigOptions: search_options,
    formModalConfig: edit_model_config,
    formModalConfigOptions: edit_model_config_options,
    updateFormModalConfig: update_model_config,
    updateFormModalConfigOptions: update_model_config_options,
    queryData: query_address_list,
    addData: create_address,
    updateData: update_address,
    removeData: delete_address,
    recoverData: recover_address,
    baseColumns: baseColumns,
    slotList: newSlotList,
    titleList: titleList,
    api_fields: api_fields,
    btn_list: btn_list,
    head_btn_list: head_btn_list,
    event_obj: event_obj,
  };
};
