<template>
  <a-modal :maskClosable="false" :title="t('备注')" :visible="true" width="1050px" @cancel="handleCancel">
    <a-textarea :rows="4" v-model:value="value" />
    <a-form-item>
      <a-button html-type="submit" :loading="submitting" type="primary" @click="handleSubmit">
        {{ t('添加备注') }}
      </a-button>
    </a-form-item>
    <a-list
      v-if="comments.length"
      :data-source="comments"
      :header="`${comments.length}` + t('条备注')"
      item-layout="horizontal"
    >
      <template #renderItem="{ item }">
        <a-list-item>
          <a-comment
            :author="item.person_name"
            :avatar="item.avatar"
            :content="item.note"
            :datetime="item.create_time"
          >
            <template #avatar>
              <!--              <a-avatar-->
              <!--                src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"-->
              <!--                alt="Han Solo"-->
              <!--              />-->
            </template>
          </a-comment>
        </a-list-item>
      </template>
    </a-list>
    <template #footer>
      <a-button key="back" @click="handleCancel">{{ t('关闭') }}</a-button>
    </template>
  </a-modal>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { create_note, query_note_list } from '@/api/cerp-model';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import { message } from 'ant-design-vue';
import moment from 'moment';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'NoteModel',
  props: {
    visible: {
      type: Boolean,
      required: true,
      default: () => false,
    },
    params: {
      type: Object,
      required: true,
    },
  },
  emits: ['ok', 'cancel'],
  setup(props, { emit }) {
    const confirmLoading = ref<boolean>(false);
    const submitting = ref<boolean>(false);
    const { t } = useI18n();

    const state = ref({
      note: null,
      date: null,
    });
    const comments = ref([]);
    const value = ref<string>('');

    const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
    const loadData = () => {
      query_note_list({
        org_id: current_org?.id,
        object_id: props.params?.object_id,
        note_type_flag: props.params?.note_type_flag,
      }).then((res: any) => {
        comments.value = res.data;
      });
    };

    const handleSubmit = () => {
      submitting.value = true;
      create_note({
        object_id: props.params.object_id,
        note_type_flag: props.params.note_type_flag,
        note: value.value,
        date: moment().format('YYYY-MM-DD HH:mm:ss'),
        org_id: current_org?.id,
        cc_company_id: props.params.note_type_flag == 'cerp.contract' ? props.params.cc_company_id : null,
      })
        .then(() => {
          message.success(t('添加成功'));
          // event_obj.emit('ok');
        })
        .finally(() => {
          submitting.value = false;
          value.value = '';
          loadData();
        });
    };

    const handleCancel = () => {
      emit('cancel');
    };
    loadData();
    return {
      t,
      state,
      handleCancel,
      handleSubmit,
      comments,
      submitting,
      value,
      confirmLoading,
    };
  },
  components: {},
});
</script>
