import { notification } from 'ant-design-vue';

const urlmap: any = {
  '/permission/menu-manage': 'http://localhost:3105/#/main/menu_manage',
  '/permission/role-manage': 'http://localhost:3105/#/main/role_manage',
  '/permission/auth-manage': 'http://localhost:3105/#/main/auth_manage',
  '/permission/user-manage': 'http://localhost:3105/#/main/user_manage',
  '/permission/group-manage': 'http://localhost:3105/#/main/group_manage',
  '/permission/apiurl-manage/index-1': 'http://localhost:3105/#/main/apiurl_manage',
  '/permission/apiurl-manage/index-2': 'http://localhost:3105/#/main/apiurl_manage',
};

const isGotoOldRouter = (path: string) => {
  if (urlmap[path]) {
    window.location.href = urlmap[path];
  } else {
    notification.error({
      message: '返回失败',
      description: '无对应旧版界面',
    });
  }
};
export default isGotoOldRouter;
