<template>
  <a-row :gutter="24" type="flex">
    <a-col :span="3">
      <a-tooltip>
        <template #title v-if="record.adviser">
          <span>{{ record.adviser ? record.adviser : '顾问' }}</span>
        </template>
        <a-badge :count="record.no_read_num" style="margin-top: 3px">
            <div
              v-if="!record.is_closed"
              style="border-radius: 2px;height: 40px;width: 40px;background-color: #0089FF;">
              <div style="padding-left: 4px;padding-top: 2px"><span style="color: #ebedf0;font-size: 10px">{{ getMonth_no_year(record.create_time) }}</span></div>
              <div style="height: 30px;width: 40px;position: relative;top: -3px"><span style="font-size: 22px;padding-bottom: 4px;padding-right: 4px; float: right;color: #ebedf0">{{ getDay_no_year(record.create_time) }}</span></div>
            </div>
            <div
              v-if="record.is_closed"
              style="border-radius: 2px;height: 40px;width: 40px;background-color: #999999;">
              <div style="padding-left: 4px;padding-top: 2px"><span style="color: #ebedf0;font-size: 10px">{{ getMonth_no_year(record.create_time) }}</span></div>
              <div style="height: 30px;width: 40px;position: relative;top: -3px"><span style="font-size: 22px;padding-bottom: 4px;padding-right: 4px; float: right;color: #ebedf0">{{ getDay_no_year(record.create_time) }}</span></div>
            </div>
        </a-badge>
      </a-tooltip>
    </a-col>
    <a-col :span="12" style="width: 100%">
      <div style=" overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                      width: 200px;
        user-select: text;">
        {{ record.name }}
      </div>

      <div style="color: #999999; font-size: 12px;overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                      width: 200px;
                      margin-top: 3px;
        user-select: text;">{{ record.message_type == 1 ? '[图片]' : record.content }}</div>
    </a-col>

    <a-col :span="9">
      <a-badge :dot="!record.is_reply && record.no_read_num !== 0 && (search.tags == 'my_tab')">
        <span style="color: #999999; font-size: 12px;float: right;margin-right: 3px">{{
            getDateTime(record.update_time)
          }}</span>
      </a-badge>
      <div style="color: #999999; font-size: 12px;overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                      width: 95px;
                      margin-top: 5px;float: right;margin-right: 3px;
        user-select: text;">{{ record.adviser }}</div>
    </a-col>
  </a-row>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { getDateTime, getDay_no_year, getMonth_no_year } from '@/utils/function';

export default defineComponent({
  name: 'Cell_hui_hua',
  props: {
    column: {
      type: Object,
      required: true,
      default: () => {},
    },
    index: {
      type: Number,
      required: true,
    },
    text: {
      type: [String, Number],
      required: true,
    },
    record: {
      type: Object,
      required: true,
      default: () => {},
    },
    search: {
      type: Object,
      required: true,
      default: () => {},
    },
    pageSize: {
      type: Number,
      required: true,
      default: () => 20,
    },
    current: {
      type: Number,
      required: true,
      default: () => 1,
    },
  },
  setup() {
    return {
      getDateTime,
      getMonth_no_year,
      getDay_no_year,
    }
  }
});
</script>
