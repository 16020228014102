<template>
  <a-tag :color="column.color ? column.color(record) : 'green'">
                    <span>
                      {{ column.content(record) }}
                    </span>
  </a-tag>
  <span>{{ column.content1(record) }}</span>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
export default defineComponent({
  name: 'Cell_stop_tag',
  props: {
    column: {
      type: Object,
      required: true,
      default: () => {},
    },
    index: {
      type: Number,
      required: true,
    },
    text: {
      type: [String , Number],
      required: true,
    },
    record: {
      type: Object,
      required: true,
      default: () => {},
    },
    pageSize: {
      type: Number,
      required: true,
      default: () => 20,
    },
    current: {
      type: Number,
      required: true,
      default: () => 1,
    },
  },
});
</script>
<style lang="less">
.needCopyRow {
  td {
    position: relative;
  }
}
.large-font {
  font-size: 17px;
  font-weight: 500;
}
.large-number-font {
  font-size: 24px;
  font-weight: 500;
}
</style>