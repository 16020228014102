import mitt from 'mitt';
import defaultSlotList from '@/components/page-model/slotList';
import { reactive, ref } from 'vue';
import { create_person_reference_number, update_person_reference_number } from '@/api/cerp-model';
import { message } from 'ant-design-vue';
import modalBox from '@/components/form-modal/modal-tools';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';

export const event_obj = mitt();
export const btn_list = [
  {
    name: '修改',
    clazz: 'primary',
    type: 'dropdown',
    cb: (data: any, dataSource: any, index: any, requestParams: any, t: any) => {
      const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
      const editModal = reactive({
        visible: true,
        title: t('修改个人参考号'),
        loading: false,
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'vertical',
            col: [],
          },
          fields: [
            {
              type: 'input',
              name: 'adviser__full_name',
              label: '隶属顾问',
              label_i18n: '隶属顾问',
              placeholder_i18n: '请选择隶属顾问',
              disabled: true,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
            },
            {
              type: 'input',
              name: 'name',
              label: '名称',
              label_i18n: '名称',
              placeholder_i18n: '请填写名称',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写名称',
            },
            {
              type: 'input',
              name: 'description',
              label: '描述',
              label_i18n: '描述',
              placeholder_i18n: '请填描述',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写描述',
            },
            {
              type: 'rangepicker',
              name: 'start_end_date',
              label: '开始-截止时间',
              label_i18n: '开始-截止时间',
              placeholder_i18n: '开始-截止时间',
              disabled: false,
              allowClear: true,
              inputType: 'number',
              defaultValue: '',
              showTime: false,
              valueFormat: 'YYYY-MM-DD',
              placeholder: '请填写起保日期时间',
            },
            {
              type: 'remoteselect',
              name: 'reference_number_type_id',
              label: '参考号类别',
              label_i18n: '参考号类别',
              placeholder_i18n: '请选择参考号类别',
              disabled: false,
              placeholder: '请选择参考号类别',
              datasourceType: 'remote',
              mode: 'default',
              allowClear: true,
              modalType: 'baseinfo-referencenumbertype',
            },
          ],
          rules: {},
          model: reactive({
            org_id: current_org?.id,
            person_reference_number_id: data?.id,
            is_active: true,
            adviser_id: data.adviser_id,
            adviser__full_name: data.adviser__full_name,
            name: data.name,
            description: data.description,
            start_end_date: [data?.start_date, data?.end_date],
            start_date: data.start_date,
            end_date: data.end_date,
            appendix_ids: data.appendix_ids,
            reference_number_type_id: data.reference_number_type_id,
          }),
        },
        options: {
          is_current: [
            { value: true, name: t('是') },
            { value: false, name: t('否') },
          ],
        },
        ok: (payload: any) => {
          return new Promise(resolve => {
            editModal.loading = true;
            update_person_reference_number({
              ...payload,
              start_date: payload.start_end_date[0],
              end_date: payload.start_end_date[1],
            })
              .then(() => {
                message.success(t('修改成功'));
                event_obj.emit('refresh');
                resolve(null);
              })
              .finally(() => {
                editModal.loading = false;
              });
          });
        },
      });
      modalBox(editModal);
    },
  },
  {
    name: '上传附件',
    type: 'dropdown',
    clazz: 'primary',
    cb: (data: any, dataSource: any, index: any, requestParams: any, t: any) => {
      const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
      const loading = ref(false);
      const editModal = reactive({
        visible: true,
        title: t('上传附件'),
        group: '个人参考号',
        type: 'picture',
        component: 'upload-image-modal',
        loading: loading,
        ok: (payload: any) => {
          return new Promise(resolve => {
            loading.value = true;
            if (data.appendix_ids) {
              payload.push(...data.appendix_ids.split(','));
            }
            update_person_reference_number({
              person_reference_number_id: data.id,
              appendix_ids: payload,
              org_id: current_org?.id,
            })
              .then(() => {
                message.success(t('添加成功'));
                event_obj.emit('refresh');
                resolve(null);
              })
              .finally(() => {
                loading.value = false;
              });
          });
        },
      });
      modalBox(editModal);
    },
  },
  {
    name: '备注',
    type: 'dropdown',
    clazz: 'primary',
    cb: (record: any) => {
      const modifyModal: any = reactive({
        visible: false,
        component: 'NoteModel',
        params: {
          object_id: record.id,
          note_type_flag: 'cerp.personreferencenumber',
        },
      });
      modifyModal.visible = true;
      modifyModal.ok = () => {
        event_obj.emit('refresh');
      };
      modifyModal.cancel = () => {};
      modalBox(modifyModal);
    },
  },
];
export const slotList = defaultSlotList;
export const config = {
  creatCol: [],
  updateCol: [],
};

export const head_btn_list = [
  {
    name: '新建',
    clazz: 'primary',
    cb: (record: any, dataSource: any, t: any) => {
      const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
      const editModal = reactive({
        visible: true,
        title: t('新增个人参考号'),
        loading: false,
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'vertical',
            col: [],
          },
          fields: [
            {
              type: 'input',
              name: 'adviser__full_name',
              label: '顾问',
              label_i18n: '顾问',
              placeholder_i18n: '请选择隶属顾问',
              disabled: true,
              placeholder: '请选择隶属顾问',
              datasourceType: 'remote',
              allowClear: false,
            },
            {
              type: 'input',
              name: 'name',
              label: '名称',
              label_i18n: '名称',
              placeholder_i18n: '请填写名称',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写名称',
            },
            {
              type: 'input',
              name: 'description',
              label: '描述',
              label_i18n: '描述',
              placeholder_i18n: '请填描述',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写描述',
            },
            {
              type: 'rangepicker',
              name: 'start_end_date',
              label: '开始-截止时间',
              label_i18n: '开始-截止时间',
              placeholder_i18n: '开始-截止时间',
              disabled: false,
              allowClear: true,
              inputType: 'number',
              defaultValue: '',
              showTime: false,
              valueFormat: 'YYYY-MM-DD',
              placeholder: '请填写开始-截止时间',
            },
            {
              type: 'remoteselect',
              name: 'reference_number_type_id',
              label: '参考号类别',
              label_i18n: '参考号类别',
              placeholder_i18n: '请选择参考号类别',
              disabled: false,
              placeholder: '请选择参考号类别',
              datasourceType: 'remote',
              mode: 'default',
              allowClear: true,
              modalType: 'baseinfo-referencenumbertype',
            },
          ],
          rules: {
            name: [{ required: true, message: t('请填输入名称') }],
          },
          model: {
            org_id: current_org?.id,
            is_active: true,
            adviser_id: record.id,
            adviser__full_name: record.name,
            name: null,
            start_end_date: null,
            description: null,
            start_date: null,
            end_date: null,
            reference_number_type_id: null,
          },
        },
        options: {},
        ok: (payload: any) => {
          let start_date: any = null;
          let end_date: any = null;
          if (payload.start_end_date != null && payload.start_end_date.length == 2) {
            start_date = payload.start_end_date[0];
            end_date = payload.start_end_date[1];
          }
          return new Promise(resolve => {
            editModal.loading = true;
            create_person_reference_number({
              ...payload,
              start_date: start_date,
              end_date: end_date,
            })
              .then(() => {
                message.success(t('新增成功'));
                event_obj.emit('refresh');
                resolve(null);
              })
              .finally(() => {
                editModal.loading = false;
              });
          });
        },
      });
      modalBox(editModal);
    },
  },
];
