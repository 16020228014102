<template>
  <a-tooltip>
    <template #title v-if="column.content && column.content(record, text)">
      <span
        v-if="column.content"
      >
                    {{ column.content(record, text) }}
                  </span>
      <span
        v-else
      >
                    {{ text }}
                  </span>
    </template>
    <a-button
      style="padding-left: 1px;width: 100%;"
      type="link"
      @click="column.cb(record, text)"
    >
                  <span
                    v-if="column.content"
                    style="
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                      width: 100%;
        user-select: text;
                    "
                  >
                    {{ column.content(record, text) }}
                  </span>
      <span
        v-else
        style="
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                      width: 100%;
        user-select: text;
                    "
      >
                    {{ text }}
                  </span>
    </a-button>
  </a-tooltip>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
export default defineComponent({
  name: 'Cell_click',
  props: {
    column: {
      type: Object,
      required: true,
      default: () => {},
    },
    index: {
      type: Number,
      required: true,
    },
    text: {
      type: [String, Number],
      required: true,
    },
    record: {
      type: Object,
      required: true,
      default: () => {},
    },
    pageSize: {
      type: Number,
      required: true,
      default: () => 20,
    },
    current: {
      type: Number,
      required: true,
      default: () => 1,
    },
  },
});
</script>
