<template>
  <a-button
    size="small"
    type="link"
    v-if="column.content ? (column.content(record, text).length > 0) : (text?.length > 0 && text)"
    @click="attributetable(column.content ? column.content(record, text) : text, record, column)"
  >
    查看内容
  </a-button>
  <span v-else>空</span>
</template>

<script lang="ts">
import { defineComponent, reactive } from 'vue';
import modalBox from '@/components/form-modal/modal-tools';

export default defineComponent({
  name: 'Cell_attributetable',
  props: {
    column: {
      type: Object,
      required: true,
      default: () => {
      },
    },
    index: {
      type: Number,
      required: true,
    },
    text: {
      type: [String, Number, Array],
      required: false,
      default: () => '',
    },
    record: {
      type: Object,
      required: true,
      default: () => {
      },
    },
    pageSize: {
      type: Number,
      required: true,
      default: () => 20,
    },
    current: {
      type: Number,
      required: true,
      default: () => 1,
    },
  },
  setup() {
    const attributetable = (text: any, record: any, column: any) => {
      const editModal = reactive({
        visible: true,
        loading: false,
        title: (record.name ? record.name + '-' : '') + column.title,
        component: 'AttributeTableModal',
        value: text,
      });
      modalBox(editModal);
    };
    return {
      attributetable,
    };
  },
});
</script>
