
import { useForm } from 'ant-design-vue/es/form';
import { computed, defineComponent, reactive } from 'vue';

const permissionActions = ['add', 'delete', 'update', 'query', 'import', 'export'];
export default defineComponent({
  name: 'FormModal',
  props: {
    title: {
      type: String,
      required: true,
    },
    form: {
      type: Object,
      default: () => {},
    },
    options: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
    },
  },
  emits: ['ok', 'cancel'],
  setup(props, { emit }) {
    const width = computed(() => {
      //?width:form.settings.col.length==0?'400px':'800px'
      if (props.form.settings.col == null || props.form.settings.col.length == 0) {
        return '500px';
      }
      if (props.form.settings.col != null && props.form.settings.col.length == 1) {
        return '500px';
      }
      if (props.form.settings.col != null && props.form.settings.col.length == 2) {
        return '800px';
      }
      if (props.form.settings.col != null && props.form.settings.col.length == 3) {
        return '1200px';
      }
    });
    const modelRef = reactive({ ...props.form.model });
    // if (props.form.watch) {
    //   for (let k in props.form.watch) {
    //     watch(
    //       () => modelRef[k],
    //       () => {
    //         props.form.watch[k](modelRef, props.form);
    //       },
    //     );
    //   }
    // }

    if (props.form.rules) {
      for (const key of Object.keys(props.form.rules)) {
        if (!modelRef.hasOwnProperty(key)) {
          modelRef[key] = null;
        }
      }
    }
    const rulesRef = reactive({ ...props.form.rules });
    // watchEffect(() => {
    //   if (props.form.model) {
    //     console.log('update model', props.form.model);
    //   }
    // });
    const { resetFields, validate, validateInfos } = useForm(modelRef, rulesRef);
    const handleSubmit = (e: Event) => {
      e.preventDefault();
      validate()
        .then(() => {
          emit('ok', { ...modelRef });
        })
        .finally(() => {})
        .catch(err => {
          console.log('error', err);
        });
    };
    return {
      props,
      width,
      modelRef,
      handleSubmit,
      resetFields,
      validateInfos,
      permissionActions,
    };
  },
});
