import request from '@/utils/request';
import { PageResult } from '@/api/typing';

export async function create_git_project(params: Record<string, any>) {
  return request.post('/apidoc/create_git_project', params);
}

export async function update_git_project(params: Record<string, any>) {
  return request.post('/apidoc/update_git_project', params);
}

export async function delete_git_project(params: Record<string, any>) {
  return request.post('/apidoc/delete_git_project', params);
}

export async function recover_git_project(params: Record<string, any>) {
  return request.post('/apidoc/recover_git_project', params);
}

export async function query_git_project_list(params?: { [key: string]: any }): Promise<PageResult> {
  return request.post('/apidoc/query_git_project_list', params);
}

export async function create_api_info(params: Record<string, any>) {
  return request.post('/apidoc/create_api_info', params);
}

export async function update_api_info(params: Record<string, any>) {
  return request.post('/apidoc/update_api_info', params);
}

export async function delete_api_info(params: Record<string, any>) {
  return request.post('/apidoc/delete_api_info', params);
}

export async function recover_api_info(params: Record<string, any>) {
  return request.post('/apidoc/recover_api_info', params);
}

export async function query_api_info_list(params?: { [key: string]: any }): Promise<PageResult> {
  return request.post('/apidoc/query_api_info_list', params);
}

export async function get_api_info(params: Record<string, any>) {
  return request.post('/apidoc/get_api_info', params);
}

export async function create_app(params: Record<string, any>) {
  return request.post('/apidoc/create_app', params);
}

export async function update_app(params: Record<string, any>) {
  return request.post('/apidoc/update_app', params);
}

export async function delete_app(params: Record<string, any>) {
  return request.post('/apidoc/delete_app', params);
}

export async function recover_app(params: Record<string, any>) {
  return request.post('/apidoc/recover_app', params);
}

export async function query_app_list(params?: { [key: string]: any }): Promise<PageResult> {
  return request.post('/apidoc/query_app_list', params);
}

export async function change_app_field_items(params: Record<string, any>) {
  return request.post('/apidoc/change_app_field_items', params);
}

export async function create_git_branch(params: Record<string, any>) {
  return request.post('/apidoc/create_git_branch', params);
}

export async function update_git_branch(params: Record<string, any>) {
  return request.post('/apidoc/update_git_branch', params);
}

export async function delete_git_branch(params: Record<string, any>) {
  return request.post('/apidoc/delete_git_branch', params);
}

export async function recover_git_branch(params: Record<string, any>) {
  return request.post('/apidoc/recover_git_branch', params);
}

export async function query_git_branch_list(params?: { [key: string]: any }): Promise<PageResult> {
  return request.post('/apidoc/query_git_branch_list', params);
}

export async function create_list_result_field(params: Record<string, any>) {
  return request.post('/apidoc/create_list_result_field', params);
}

export async function update_list_result_field(params: Record<string, any>) {
  return request.post('/apidoc/update_list_result_field', params);
}

export async function delete_list_result_field(params: Record<string, any>) {
  return request.post('/apidoc/delete_list_result_field', params);
}

export async function recover_list_result_field(params: Record<string, any>) {
  return request.post('/apidoc/recover_list_result_field', params);
}

export async function query_list_result_field_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/apidoc/query_list_result_field_list', params);
}

export async function create_page_info(params: Record<string, any>) {
  return request.post('/apidoc/create_page_info', params);
}

export async function update_page_info(params: Record<string, any>) {
  return request.post('/apidoc/update_page_info', params);
}

export async function delete_page_info(params: Record<string, any>) {
  return request.post('/apidoc/delete_page_info', params);
}

export async function recover_page_info(params: Record<string, any>) {
  return request.post('/apidoc/recover_page_info', params);
}

export async function query_page_info_list(params?: { [key: string]: any }): Promise<PageResult> {
  return request.post('/apidoc/query_page_info_list', params);
}

export async function create_parameter(params: Record<string, any>) {
  return request.post('/apidoc/create_parameter', params);
}

export async function update_parameter(params: Record<string, any>) {
  return request.post('/apidoc/update_parameter', params);
}

export async function delete_parameter(params: Record<string, any>) {
  return request.post('/apidoc/delete_parameter', params);
}

export async function recover_parameter(params: Record<string, any>) {
  return request.post('/apidoc/recover_parameter', params);
}

export async function query_parameter_list(params?: { [key: string]: any }): Promise<PageResult> {
  return request.post('/apidoc/query_parameter_list', params);
}

export async function create_project(params: Record<string, any>) {
  return request.post('/apidoc/create_project', params);
}

export async function update_project(params: Record<string, any>) {
  return request.post('/apidoc/update_project', params);
}

export async function delete_project(params: Record<string, any>) {
  return request.post('/apidoc/delete_project', params);
}

export async function recover_project(params: Record<string, any>) {
  return request.post('/apidoc/recover_project', params);
}

export async function query_project_by_select_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/apidoc/query_project_by_select_list', params);
}

export async function query_project_list(params?: { [key: string]: any }): Promise<PageResult> {
  return request.post('/apidoc/query_project_list', params);
}

export async function change_project_field_items(params: Record<string, any>) {
  return request.post('/apidoc/change_project_field_items', params);
}

export async function create_value_type(params: Record<string, any>) {
  return request.post('/apidoc/create_value_type', params);
}

export async function update_value_type(params: Record<string, any>) {
  return request.post('/apidoc/update_value_type', params);
}

export async function delete_value_type(params: Record<string, any>) {
  return request.post('/apidoc/delete_value_type', params);
}

export async function recover_value_type(params: Record<string, any>) {
  return request.post('/apidoc/recover_value_type', params);
}

export async function query_value_type_list(params?: { [key: string]: any }): Promise<PageResult> {
  return request.post('/apidoc/query_value_type_list', params);
}
