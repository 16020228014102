import mitt from 'mitt';
import { btnTd } from '@/api/typing';
import defaultSlotList from '@/components/page-model/slotList';
import {
  cc_sign_contract,
  create_contract_pdf,
  create_month_work_hour,
  create_work_hour,
  delete_contract_termination_date,
  query_insurance_list,
  query_month_work_hour_list,
  send_contract_by_email,
  send_contract_by_manul,
  set_contract_termination_date,
  set_contract_upload_status,
  update_contract,
  upload_contract_appendix,
  upload_contract_wechat_appendix,
  upload_contract_local_appendix,
  view_contract_pdf,
} from '@/api/cerp-model';
import { message, Modal, notification } from 'ant-design-vue';
import { set_contract_current } from '@/api/cerp-model.ts';
import { reactive, ref } from 'vue';
import modalBox from '@/components/form-modal/modal-tools';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import {
  get_fields,
  options,
  settings,
} from '@/components/page-model/cerp/cerp-contract-editModal.ts';
import { query_person_for_cmes_list } from '@/api/sys-model';
import {
  query_c_c_company_template_list,
  query_country_list,
  query_managementfeeformula_list,
} from '@/api/baseinfo-model';
import { isNumber } from 'lodash';
import moment from 'moment';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
export const event_obj = mitt();
export const btn_list = [
  {
    name: '浏览合同',
    type: 'dropdown',
    clazz: 'primary',
    cb: (data: any) => {
      const list: any = [];
      data.email_address_list.forEach((item: any) => {
        list.push(item.id);
      });
      const cc_address_ids: any = [];
      data.invoice_cc_address_list.forEach((item: any) => {
        cc_address_ids.push(item.id);
      });
      const insurance_fee = ref(false);
      const is_insurance_fee = ref(false);
      const is_c_insurance_fee = ref(false);
      if (data.insurance_type != 0) {
        insurance_fee.value = true;
        is_insurance_fee.value = true;
        is_c_insurance_fee.value = true;
      }
      if (data.insurance_payment_flag == -1) {
        is_insurance_fee.value = true;
        is_c_insurance_fee.value = true;
      } else if (data.insurance_payment_flag == 0) {
        is_insurance_fee.value = false;
        is_c_insurance_fee.value = true;
      } else if (data.insurance_payment_flag == 1) {
        is_insurance_fee.value = true;
        is_c_insurance_fee.value = false;
      }
      const fields = get_fields(
        {
          is_current: true,
          insurance_fee: insurance_fee,
          is_insurance_fee: is_insurance_fee,
          is_c_insurance_fee: is_c_insurance_fee,
        },
        t,
      );
      fields.map((item: any) => {
        item.disabled = true;
      });
      const editModal = reactive({
        visible: true,
        title: t('浏览合同'),
        loading: false,
        form: {
          settings: settings,
          fields: fields,
          rules: {},
          model: reactive({
            org_id: current_org?.id,
            is_active: true,
            contract_id: data.id,
            adviser_id: Number(data.adviser_id),
            adviser__full_name: data.adviser__full_name,
            recuitment_company_id: data.recuitment_company_id,
            expense_email_template_id: data.expense_email_template_id,
            overtime_email_template_id: data.overtime_email_template_id,
            cn_email_template_id: data.cn_email_template_id,
            recuitment_company_person_id: data.recuitment_company_person_id,
            work_location_id: data.work_location_id,
            start_end_date: [data.start_date, data.end_date],
            termination_date: data.termination_date,
            rate_type: data.rate_type,
            currency_id: data.currency_id,
            pay_currency_id: data.pay_currency_id,
            basic_rate: data.basic_rate,
            cc_management_fee: data.cc_management_fee,
            bank_charge: data.bank_charge,
            accommodation_rate: data.accommodation_rate,
            overtime_rate: data.overtime_rate,
            remark: data.remark,
            nsfile_id: data.nsfile_id,
            cc_management_fee_rate: data.cc_management_fee_rate,
            local_management_fee: data.local_management_fee,
            local_management_fee_rate: data.local_management_fee_rate,
            cc_expense_email_template_id: data.cc_expense_email_template_id,
            local_salary: data.local_salary,
            is_bank_charge_payment: data.is_bank_charge_payment,
            insurance_payment_flag: data.insurance_payment_flag,
            management_fee_payment_flag: data.management_fee_payment_flag,
            management_fee_formula_id: data.management_fee_formula_id,
            income_tax: data.income_tax,
            social_insurance: data.social_insurance,
            local_management_fee_fixed: data.local_management_fee_fixed,
            exchange_rate_id: data.exchange_rate_id,
            is_current: data.is_current,
            end_client_id: data.end_client_id,
            local_deduct_fee: data.local_deduct_fee,
            cc_email_id: data.cc_email_id,
            contact_person_id: data.contact_person_id,
            adviser_email_template_id: data.adviser_email_template_id,
            invoice_email_template_id: data.invoice_email_template_id,
            payslip_email_template_id: data.payslip_email_template_id,
            contract_email_template_id: data.contract_email_template_id,
            consultant_bank_charge: data.consultant_bank_charge,
            company_bank_charge: data.company_bank_charge,
            extra_info: data.extra_info,
            work_hour_template_id: data.work_hour_template_id,
            fte: data.fte,
            annual_leave: data.annual_leave,
            creater: data.creater,
            creater_id: data.creater_id,
            contract_template_id: data.contract_template_id,
            cc_company_id: data.cc_company_id,
            consultant: data.consultant,
            insurance_fee: data.insurance_fee,
            c_insurance_fee: data.c_insurance_fee,
            insurance_type: data.insurance_type,
            solution_type: data.solution_type,
            manage_fee: data.manage_fee,
            project_name: data.project_name,
            local_pay_currency_id: data.local_pay_currency_id,
            tip_day: data.tip_day,
            expiration_email_template_id: data.expiration_email_template_id,
            sign_email_template_id: data.sign_email_template_id,
            email_address_ids: list,
            invoice_cc_address_ids: cc_address_ids,
          }),
          watch: {},
        },
        options: options(t),
        ok: (payload: any) => {
          return new Promise(resolve => {
            resolve(null);
          });
        },
      });
      modalBox(editModal);
    },
  },
  {
    name: '转为当前合同',
    type: 'dropdown',
    clazz: 'primary',
    is_show: (record: any) => {
      return !record.is_current;
    },
    cb: (data: any) => {
      // 模态框，确认按钮点击。
      // todo: 创建新顾问，并询问是否录入 合同。
      // todo: 顾问已经创建成功。
      Modal.confirm({
        title: t('提示'),
        content: t('是否转为当前合同') + '?',
        onOk() {
          return new Promise(resolve => {
            set_contract_current({ org_id: current_org.id, contract_id: data.id })
              .then(function (param: any) {
                message.success(t('修改成功'));
                event_obj.emit('refresh');
              })
              .finally(() => {
                resolve(null);
              });
          });
        },
        onCancel() {},
      });
    },
  },
  {
    name: '上传合同附件',
    type: 'dropdown',
    clazz: 'primary',
    cb: (data: any) => {
      const loading = ref(false);
      const editModal = reactive({
        visible: true,
        title: t('上传合同附件'),
        group: '合同',
        type: 'picture',
        component: 'upload-image-modal',
        loading: loading,
        ok: (payload: any) => {
          return new Promise(resolve => {
            loading.value = true;
            if (data.appendix_ids) {
              payload.push(...data.appendix_ids.split(','));
            }
            upload_contract_appendix({
              contract_id: data.id,
              appendix_ids: payload,
              org_id: current_org?.id,
            })
              .then(() => {
                message.success(t('添加成功'));
                event_obj.emit('refresh');
                resolve(null);
              })
              .finally(() => {
                loading.value = false;
              });
          });
        },
      });
      modalBox(editModal);
    },
  },
  {
    name: '上传小程序附件',
    type: 'dropdown',
    clazz: 'primary',
    cb: (data: any) => {
      const loading = ref(false);
      const editModal = reactive({
        visible: true,
        title: t('上传小程序附件'),
        group: '小程序',
        type: 'picture',
        component: 'upload-image-modal',
        loading: loading,
        ok: (payload: any) => {
          return new Promise(resolve => {
            loading.value = true;
            if (data.wechat_appendix_ids) {
              payload.push(...data.wechat_appendix_ids.split(','));
            }
            upload_contract_wechat_appendix({
              contract_id: data.id,
              appendix_ids: payload,
              org_id: current_org?.id,
            })
              .then(() => {
                message.success(t('添加成功'));
                event_obj.emit('refresh');
                resolve(null);
              })
              .finally(() => {
                loading.value = false;
              });
          });
        },
      });
      modalBox(editModal);
    },
  },
  {
    name: '创建合同草稿',
    type: 'dropdown',
    clazz: 'primary',
    is_show: (record: any) => {
      return record.qiyuesuo_status != 4;
    },
    cb: (data: any) => {
      Modal.confirm({
        title: t('提示'),
        content: t('确认创建合同草稿') + '?',
        onOk() {
          return new Promise(resolve => {
            create_contract_pdf({ org_id: current_org?.id, contract_id: data.id })
              .then(() => {
                message.success(t('创建合同草稿成功'));
                event_obj.emit('refresh');
              })
              .finally(() => {
                resolve(null);
              });
          });
        },
        onCancel() {},
      });
    },
  },
  {
    name: '修改',
    type: 'dropdown',
    clazz: 'primary',
    cb: (data: any) => {
      const list: any = [];
      data.email_address_list.forEach((item: any) => {
        list.push(item.id);
      });
      const cc_address_ids: any = [];
      data.invoice_cc_address_list.forEach((item: any) => {
        cc_address_ids.push(item.id);
      });
      const insurance_fee = ref(false);
      const is_insurance_fee = ref(false);
      const is_c_insurance_fee = ref(false);
      const is_adviser_fee = ref(false);
      const is_c_adviser_fee = ref(false);
      let description = '';
      if (data.management_fee_formula_id) {
        query_managementfeeformula_list({
          org_id: current_org?.id,
          ids: [data.management_fee_formula_id],
        }).then((res: any) => {
          if (res && res.data) {
            description = res.data[0].description;
          }
        });
      }
      if (data.management_fee_formula_id) {
        query_managementfeeformula_list({
          org_id: current_org?.id,
          ids: [data.management_fee_formula_id],
        }).then((res: any) => {
          if (res && res.data) {
            description = res.data[0].description;
          }
        });
      }
      if (data.is_bank_charge_payment) {
        is_adviser_fee.value = true;
      } else {
        is_c_adviser_fee.value = true;
      }
      if (data.insurance_type != 0) {
        insurance_fee.value = true;
        is_insurance_fee.value = true;
        is_c_insurance_fee.value = true;
      }
      if (data.insurance_payment_flag == -1) {
        is_insurance_fee.value = true;
        is_c_insurance_fee.value = true;
      } else if (data.insurance_payment_flag == 0) {
        is_insurance_fee.value = false;
        is_c_insurance_fee.value = true;
      } else if (data.insurance_payment_flag == 1) {
        is_insurance_fee.value = true;
        is_c_insurance_fee.value = false;
      }
      const editModal = reactive({
        visible: true,
        title: t('修改合同'),
        loading: false,
        form: {
          settings: settings,
          fields: get_fields(
            {
              is_current: true,
              insurance_fee: insurance_fee,
              is_insurance_fee: is_insurance_fee,
              is_c_insurance_fee: is_c_insurance_fee,
              is_adviser_fee: is_adviser_fee,
              is_c_adviser_fee: is_c_adviser_fee,
            },
            t,
          ),
          rules: {
            recuitment_company_id: [{ required: true, message: t('请选择顾问公司') }],
            consultant: [{ required: true, message: t('请填写职务') }],
            recuitment_company_person_id: [{ required: true, message: t('请选择请款邮箱地址') }],
            work_location_id: [{ required: true, message: t('请选择工作地点') }],
            start_end_date: [{ required: true, message: t('请填写合同起始结束日期') }],
            management_fee_formula_id: [{ required: true, message: t('请选择管理费计算方式') }],
            insurance_type: [{ required: true, message: t('请选择保险类型') }],
            solution_type: [{ required: true, message: t('请选择是否运用本地解决方案') }],
            currency_id: [{ required: true, message: t('请选择薪资币种') }],
            adviser_email_template_id: [
              { required: true, message: t('请选择请款时顾问提示邮箱模板') },
            ],
            bank_charge: [{ required: true, message: t('请填写银行转账费') }],
            cc_management_fee_rate: [{ required: true, message: t('请填写cc管理费率') }],
            basic_rate: [{ required: true, message: t('请填写基本单位薪资') }],
            cc_company_id: [{ required: true, message: t('请选择CC公司') }],
            contract_template_id: [{ required: true, message: t('请选择合同模板') }],
            manage_fee: [{ required: true, message: t('请填写管理费描述') }],
            cc_email_id: [{ required: true, message: t('请选择CC邮箱') }],
            invoice_email_template_id: [{ required: true, message: t('请选择请款单邮箱模板') }],
            payslip_email_template_id: [{ required: true, message: t('请选择工资单邮箱模板') }],
            end_client_id: [{ required: true, message: t('请选择终端用户') }],
            contract_email_template_id: [{ required: true, message: t('请选择合同邮箱模板') }],
            expense_email_template_id: [{ required: true, message: t('请选择报销请款单邮箱模板') }],
            overtime_email_template_id: [
              { required: true, message: t('请选择加班请款单邮箱模板') },
            ],
            cn_email_template_id: [{ required: true, message: t('请选择CN请款单邮箱模板') }],
            expiration_email_template_id: [
              { required: true, message: t('请选择合同到期提醒邮箱模板') },
            ],
            sign_email_template_id: [{ required: true, message: t('请选择签署合同确认邮箱模板') }],
          },
          model: reactive({
            org_id: current_org?.id,
            is_active: true,
            contract_id: data.id,
            adviser_id: Number(data.adviser_id),
            adviser__full_name: data.adviser__full_name,
            recuitment_company_id: data.recuitment_company_id,
            expense_email_template_id: data.expense_email_template_id,
            overtime_email_template_id: data.overtime_email_template_id,
            cn_email_template_id: data.cn_email_template_id,
            recuitment_company_person_id: data.recuitment_company_person_id,
            work_location_id: data.work_location_id,
            start_end_date: [data.start_date, data.end_date],
            termination_date: data.termination_date,
            rate_type: data.rate_type,
            currency_id: data.currency_id,
            pay_currency_id: data.pay_currency_id,
            basic_rate: data.basic_rate,
            contact_person_id: data.contact_person_id,
            cc_management_fee: data.cc_management_fee,
            bank_charge: data.bank_charge,
            accommodation_rate: data.accommodation_rate,
            overtime_rate: data.overtime_rate,
            remark: data.remark,
            nsfile_id: data.nsfile_id,
            cc_management_fee_rate: data.cc_management_fee_rate,
            local_management_fee: data.local_management_fee,
            local_management_fee_rate: data.local_management_fee_rate,
            cc_expense_email_template_id: data.cc_expense_email_template_id,
            local_salary: data.local_salary,
            is_bank_charge_payment: data.is_bank_charge_payment,
            insurance_payment_flag: data.insurance_payment_flag,
            management_fee_payment_flag: data.management_fee_payment_flag,
            management_fee_formula_id: data.management_fee_formula_id,
            income_tax: data.income_tax,
            social_insurance: data.social_insurance,
            local_management_fee_fixed: data.local_management_fee_fixed,
            exchange_rate_id: data.exchange_rate_id,
            is_current: data.is_current,
            end_client_id: data.end_client_id,
            local_deduct_fee: data.local_deduct_fee,
            cc_email_id: data.cc_email_id,
            adviser_email_template_id: data.adviser_email_template_id,
            invoice_email_template_id: data.invoice_email_template_id,
            payslip_email_template_id: data.payslip_email_template_id,
            contract_email_template_id: data.contract_email_template_id,
            contract_template_id: data.contract_template_id,
            cc_company_id: data.cc_company_id,
            consultant: data.consultant,
            solution_type: data.solution_type,
            insurance_fee: data.insurance_fee,
            c_insurance_fee: data.c_insurance_fee,
            insurance_type: data.insurance_type,
            manage_fee: data.manage_fee,
            creater_id: data.creater_id,
            creater: data.creater,
            project_name: data.project_name,
            tip_day: data.tip_day,
            expiration_email_template_id: data.expiration_email_template_id,
            sign_email_template_id: data.sign_email_template_id,
            consultant_bank_charge: data.consultant_bank_charge,
            company_bank_charge: data.company_bank_charge,
            extra_info: data.extra_info,
            work_hour_template_id: data.work_hour_template_id,
            fte: data.fte,
            annual_leave: data.annual_leave,
            email_address_ids: list,
            invoice_cc_address_ids: cc_address_ids,
          }),
          watch: {
            management_fee_formula_id: (data1: any) => {
              if (data1.management_fee_payment_flag == 0) {
                if (data1.management_fee_formula_id) {
                  query_managementfeeformula_list({
                    org_id: current_org?.id,
                    ids: [data1.management_fee_formula_id],
                  }).then((res: any) => {
                    if (res && res.data) {
                      data1.manage_fee = res.data[0].description;
                      data1.manage_fee = data1.manage_fee
                        .replace(
                          /<local_rate>/g,
                          data1.local_management_fee_rate
                            ? data1.local_management_fee_rate?.toString()
                            : '0',
                        )
                        .replace(
                          /<cc_rate>/g,
                          data1.cc_management_fee_rate
                            ? data1.cc_management_fee_rate?.toString()
                            : '0',
                        )
                        .replace(
                          /<basic_rate>/g,
                          data1.basic_rate ? data1.basic_rate?.toString() : '0',
                        )
                        .replace(
                          /<over_rate>/g,
                          data1.overtime_rate ? data1.overtime_rate?.toString() : '0',
                        )
                        .replace(
                          /<cc_fee>/g,
                          data1.cc_management_fee ? data1.cc_management_fee?.toString() : '0',
                        )
                        .replace(
                          /<local_fee>/g,
                          data1.local_management_fee ? data1.local_management_fee?.toString() : '0',
                        )
                        .replace(
                          /<local_fixed>/g,
                          data1.local_management_fee_fixed
                            ? data1.local_management_fee_fixed?.toString()
                            : '0',
                        )
                        .replace(
                          /<local_deduct>/g,
                          data1.local_deduct_fee ? data1.local_deduct_fee?.toString() : '0',
                        )
                        .replace(
                          /<local_salaryvalue>/g,
                          data1.local_salary ? data1.local_salary?.toString() : '0',
                        )
                        .replace(
                          /<rate_type>/g,
                          data1.rate_type == 0
                            ? 'DAY'
                            : data1.rate_type == 1
                            ? 'MONTH'
                            : data1.rate_type == 2
                            ? 'YEAR'
                            : data1.rate_type == 3
                            ? 'HOUR'
                            : data1.rate_type == 4
                            ? 'WEEK'
                            : '',
                        )
                        .replace(
                          /<currency>/g,
                          data1.currency__currency ? data1.currency__currency?.toString() : '',
                        )
                        .replace(
                          /<local_pay_currency>/g,
                          data1.local_pay_currency__currency
                            ? data1.local_pay_currency__currency?.toString()
                            : '',
                        )
                        .replace(
                          /<accommodation_rate>/g,
                          data1.accommodation_rate ? data1.accommodation_rate?.toString() : '0',
                        );
                      description = res.data[0].description;
                    }
                  });
                }
              } else {
                if (data1.currency_id) {
                  query_country_list({
                    org_id: current_org?.id,
                    ids: [data1.currency_id],
                  }).then((res: any) => {
                    if (res && res.data) {
                      data1.manage_fee = res.data[0].currency + '0.00';
                    }
                  });
                }
              }
            },
            management_fee_payment_flag: (data1: any) => {
              if (data1.management_fee_payment_flag == 0) {
                data1.manage_fee = description
                  .replace(
                    /<local_rate>/g,
                    data1.local_management_fee_rate
                      ? data1.local_management_fee_rate?.toString()
                      : '0',
                  )
                  .replace(
                    /<cc_rate>/g,
                    data1.cc_management_fee_rate ? data1.cc_management_fee_rate?.toString() : '0',
                  )
                  .replace(/<basic_rate>/g, data1.basic_rate ? data1.basic_rate?.toString() : '0')
                  .replace(
                    /<over_rate>/g,
                    data1.overtime_rate ? data1.overtime_rate?.toString() : '0',
                  )
                  .replace(
                    /<cc_fee>/g,
                    data1.cc_management_fee ? data1.cc_management_fee?.toString() : '0',
                  )
                  .replace(
                    /<local_fee>/g,
                    data1.local_management_fee ? data1.local_management_fee?.toString() : '0',
                  )
                  .replace(
                    /<local_fixed>/g,
                    data1.local_management_fee_fixed
                      ? data1.local_management_fee_fixed?.toString()
                      : '0',
                  )
                  .replace(
                    /<local_deduct>/g,
                    data1.local_deduct_fee ? data1.local_deduct_fee?.toString() : '0',
                  )
                  .replace(
                    /<local_salaryvalue>/g,
                    data1.local_salary ? data1.local_salary?.toString() : '0',
                  )
                  .replace(
                    /<rate_type>/g,
                    data1.rate_type == 0
                      ? 'DAY'
                      : data1.rate_type == 1
                      ? 'MONTH'
                      : data1.rate_type == 2
                      ? 'YEAR'
                      : data1.rate_type == 3
                      ? 'HOUR'
                      : data1.rate_type == 4
                      ? 'WEEK'
                      : '',
                  )
                  .replace(
                    /<currency>/g,
                    data1.currency__currency ? data1.currency__currency?.toString() : '',
                  )
                  .replace(
                    /<local_pay_currency>/g,
                    data1.local_pay_currency__currency
                      ? data1.local_pay_currency__currency?.toString()
                      : '',
                  )
                  .replace(
                    /<accommodation_rate>/g,
                    data1.accommodation_rate ? data1.accommodation_rate?.toString() : '0',
                  );
              } else {
                if (data1.currency_id) {
                  query_country_list({
                    org_id: current_org?.id,
                    ids: [data1.currency_id],
                  }).then((res: any) => {
                    if (res && res.data) {
                      data1.manage_fee = res.data[0].currency + '0.00';
                    }
                  });
                }
              }
            },
            cc_management_fee_rate: (data1: any) => {
              if (!data1['cc_management_fee_rate']) {
                setTimeout(() => {
                  data1['cc_management_fee_rate'] = 0;
                });
              }
            },
            bank_charge: (data1: any) => {
              if (data1['is_bank_charge_payment']) {
                if (isNumber(data1.bank_charge) && isNumber(data1.company_bank_charge)) {
                  data1.consultant_bank_charge = data1.bank_charge - data1.company_bank_charge;
                }
              } else {
                if (isNumber(data1.bank_charge) && isNumber(data1.consultant_bank_charge)) {
                  data1.company_bank_charge = data1.bank_charge - data1.consultant_bank_charge;
                }
              }
            },
            is_bank_charge_payment: (data1: any) => {
              if (data1['is_bank_charge_payment']) {
                is_adviser_fee.value = true;
                is_c_adviser_fee.value = false;
                data1.consultant_bank_charge = data1.bank_charge;
                data1.company_bank_charge = data1.bank_charge - data1.consultant_bank_charge;
              } else {
                is_c_adviser_fee.value = true;
                is_adviser_fee.value = false;
                data1.company_bank_charge = data1.bank_charge;
                data1.consultant_bank_charge = data1.bank_charge - data1.company_bank_charge;
              }
            },
            consultant_bank_charge: (data1: any) => {
              if (isNumber(data1.bank_charge) && isNumber(data1.consultant_bank_charge)) {
                data1.company_bank_charge = data1.bank_charge - data1.consultant_bank_charge;
              }
            },
            company_bank_charge: (data1: any) => {
              if (isNumber(data1.bank_charge) && isNumber(data1.company_bank_charge)) {
                data1.consultant_bank_charge = data1.bank_charge - data1.company_bank_charge;
              }
            },
            insurance_type: (data1: any) => {
              if (data1['insurance_type'] != 0) {
                insurance_fee.value = true;
                data1['insurance_payment_flag'] = -1;
              } else {
                insurance_fee.value = false;
              }
            },
            creater_id: (data: any) => {
              query_person_for_cmes_list({
                org_id: current_org?.id,
                ids: [data.creater_id],
              }).then((res: any) => {
                data['creater'] = res.data[0].realname;
              });
            },
            insurance_payment_flag: (data: any) => {
              if (data['insurance_payment_flag'] == -1) {
                is_insurance_fee.value = true;
                is_c_insurance_fee.value = true;
                data['c_insurance_fee'] = 0;
                data['insurance_fee'] = 0;
              } else if (data['insurance_payment_flag'] == 0) {
                is_insurance_fee.value = false;
                is_c_insurance_fee.value = true;
                query_insurance_list({
                  org_id: current_org.id,
                  is_current: true,
                  adviser_id: data.adviser_id,
                }).then((res: any) => {
                  data['insurance_fee'] = res.data[0].month_insurance_fee;
                });
              } else if (data['insurance_payment_flag'] == 1) {
                is_insurance_fee.value = true;
                is_c_insurance_fee.value = false;
                query_insurance_list({
                  org_id: current_org.id,
                  is_current: true,
                  adviser_id: data.adviser_id,
                }).then((res: any) => {
                  data['c_insurance_fee'] = res.data[0].month_insurance_fee;
                });
              }
            },
            insurance_fee: (data: any) => {
              if (data['insurance_payment_flag'] == 0) {
                query_insurance_list({
                  org_id: current_org.id,
                  is_current: true,
                  adviser_id: data.adviser_id,
                }).then((res: any) => {
                  data['c_insurance_fee'] =
                    res.data[0].month_insurance_fee -
                    (data['insurance_fee'] ? data['insurance_fee'] : 0);
                });
              }
            },
            c_insurance_fee: (data: any) => {
              if (data['insurance_payment_flag'] == 1) {
                query_insurance_list({
                  org_id: current_org.id,
                  is_current: true,
                  adviser_id: data.adviser_id,
                }).then((res: any) => {
                  data['insurance_fee'] =
                    res.data[0].month_insurance_fee -
                    (data['c_insurance_fee'] ? data['c_insurance_fee'] : 0);
                });
              }
            },
            cc_company_id: (data: any) => {
              query_c_c_company_template_list({
                org_id: current_org.id,
                cc_company_id: data.cc_company_id,
              }).then((res: any) => {
                if (res && res.data && res.data.length > 0) {
                  data.recuitment_company_person_id = res.data[0].recuitment_company_person_id;
                  data.contract_template_id = res.data[0].contract_template_id;
                  data.cc_email_id = res.data[0].cc_email_id;
                  data.cc_expense_email_template_id = res.data[0].cc_expense_email_template_id;
                  data.invoice_email_template_id = res.data[0].invoice_email_template_id;
                  data.contract_email_template_id = res.data[0].contract_email_template_id;
                  data.expense_email_template_id = res.data[0].expense_email_template_id;
                  data.expiration_email_template_id = res.data[0].expiration_email_template_id;
                  data.adviser_email_template_id = res.data[0].adviser_email_template_id;
                  data.overtime_email_template_id = res.data[0].overtime_email_template_id;
                  data.sign_email_template_id = res.data[0].sign_email_template_id;
                  data.payslip_email_template_id = res.data[0].payslip_email_template_id;
                  data.cn_email_template_id = res.data[0].cn_email_template_id;
                }
              });
            },
          },
        },
        options: options(t),
        ok: (payload: any) => {
          return new Promise(resolve => {
            editModal.loading = true;
            update_contract({
              ...payload,
              start_date: payload.start_end_date[0],
              end_date: payload.start_end_date[1],
            })
              .then(() => {
                message.success(t('修改成功'));
                event_obj.emit('refresh');
                resolve(null);
              })
              .finally(() => {
                editModal.loading = false;
              });
          });
        },
      });
      modalBox(editModal);
    },
  },
  {
    name: '合同预览',
    type: 'dropdown',
    clazz: 'primary',
    is_show: (record: any) => {
      return record.qiyuesuo_status != null;
    },
    cb: (data: any) => {
      view_contract_pdf({ org_id: current_org?.id, contract_id: data.id }).then((res: any) => {
        if (res && res.file_url) {
          window.open('https://erp-cc.oss-cn-hongkong.aliyuncs.com/' + res.file_url, '_blank');
          // axios.get('/' + res.file_url,{
          //   responseType: 'blob' }).then((res: any) => {
          //   var blob = res.data;
          //   // FileReader主要用于将文件内容读入内存
          //   var reader = new FileReader();
          //   reader.readAsDataURL(blob);
          //   // onload当读取操作成功完成时调用
          //   reader.onload = (e: any) => {
          //     var a = document.createElement('a');
          //     // 获取文件名fileName
          //     var fileName = res.config["url"].split("/");
          //     fileName = fileName[fileName.length - 1];
          //     // fileName = fileName.replace(/"/g, "");
          //     a.download = fileName;
          //     a.href = e.target.result;
          //     document.body.appendChild(a);
          //     a.click();
          //     document.body.removeChild(a);
          //   }
          // })
        } else {
          notification.error({
            message: t('提示'),
            description: t('没有可预览的合同'),
          });
        }
      });
    },
  },
  {
    name: '发送合同',
    type: 'dropdown',
    clazz: 'primary',
    is_show: (record: any) => {
      return record.qiyuesuo_status == 1;
    },
    cb: (record: any) => {
      Modal.confirm({
        title: t('提示'),
        content: t('确认发送当前合同' + '?'),
        onOk() {
          return new Promise(resolve => {
            send_contract_by_email({ org_id: current_org?.id, contract_id: record.id })
              .then(() => {
                message.success(t('发送当前合同成功'));
                event_obj.emit('refresh');
              })
              .finally(() => {
                resolve(null);
              });
          });
        },
        onCancel() {},
      });
    },
  },
  {
    name: '手动发送合同',
    type: 'dropdown',
    clazz: 'primary',
    is_show: (record: any) => {
      return record.qiyuesuo_status == 1;
    },
    cb: (record: any) => {
      Modal.confirm({
        title: t('提示'),
        content: t('确认手动发送当前合同' + '?'),
        onOk() {
          return new Promise(resolve => {
            send_contract_by_manul({ org_id: current_org?.id, contract_id: record.id })
              .then(() => {
                message.success(t('手动发送当前合同成功'));
                event_obj.emit('refresh');
              })
              .finally(() => {
                resolve(null);
              });
          });
        },
        onCancel() {},
      });
    },
  },
  {
    name: '三方合同上传确认',
    type: 'dropdown',
    clazz: 'primary',
    is_show: (record: any) => {
      return !record.is_three_upload;
    },
    cb: (record: any) => {
      Modal.confirm({
        title: t('提示'),
        content: t('确认已上传三方合同'),
        onOk() {
          return new Promise(resolve => {
            set_contract_upload_status({
              org_id: current_org?.id,
              is_three_upload: true,
              contract_id: record.id,
            })
              .then(() => {
                message.success(t('确认三方合同上传成功'));
                event_obj.emit('refresh');
              })
              .finally(() => {
                resolve(null);
              });
          });
        },
        onCancel() {},
      });
    },
  },
  {
    name: '作废三方合同',
    type: 'dropdown',
    clazz: 'primary',
    is_show: (record: any) => {
      return record.is_three_upload;
    },
    cb: (record: any) => {
      Modal.confirm({
        title: t('提示'),
        content: t('作废三方合同') + '?',
        onOk() {
          return new Promise(resolve => {
            set_contract_upload_status({
              org_id: current_org?.id,
              is_three_upload: false,
              contract_id: record.id,
            })
              .then(() => {
                message.success(t('三方合同作废'));
                event_obj.emit('refresh');
              })
              .finally(() => {
                resolve(null);
              });
          });
        },
        onCancel() {},
      });
    },
  },
  {
    name: '双方合同上传确认',
    type: 'dropdown',
    clazz: 'primary',
    is_show: (record: any) => {
      return !record.is_double_upload;
    },
    cb: (record: any) => {
      Modal.confirm({
        title: t('提示'),
        content: t('确认已上传双方合同'),
        onOk() {
          return new Promise(resolve => {
            set_contract_upload_status({
              org_id: current_org?.id,
              is_double_upload: true,
              contract_id: record.id,
            })
              .then(() => {
                message.success(t('确认双方合同上传成功'));
                event_obj.emit('refresh');
              })
              .finally(() => {
                resolve(null);
              });
          });
        },
        onCancel() {},
      });
    },
  },
  {
    name: '作废双方合同',
    type: 'dropdown',
    clazz: 'primary',
    is_show: (record: any) => {
      return record.is_double_upload;
    },
    cb: (record: any) => {
      Modal.confirm({
        title: t('提示'),
        content: t('确认作废双方合同'),
        onOk() {
          return new Promise(resolve => {
            set_contract_upload_status({
              org_id: current_org?.id,
              is_double_upload: false,
              contract_id: record.id,
            })
              .then(() => {
                message.success(t('双方合同作废'));
                event_obj.emit('refresh');
              })
              .finally(() => {
                resolve(null);
              });
          });
        },
        onCancel() {},
      });
    },
  },
  {
    name: '备注',
    type: 'dropdown',
    clazz: 'primary',
    cb: (record: any) => {
      const modifyModal: any = reactive({
        visible: false,
        component: 'NoteModel',
        params: {
          object_id: record.id,
          note_type_flag: 'cerp.contract',
        },
      });
      modifyModal.visible = true;
      modifyModal.ok = () => {
        event_obj.emit('refresh');
      };
      modifyModal.cancel = () => {};
      modalBox(modifyModal);
    },
  },
  {
    name: '签署合同',
    type: 'dropdown',
    clazz: 'primary',
    is_show: (record: any) => {
      return record.qiyuesuo_status == 3;
    },
    cb: (record: any) => {
      Modal.confirm({
        title: t('提示'),
        content: t('确认签署合同'),
        onOk() {
          return new Promise(resolve => {
            cc_sign_contract({ org_id: current_org?.id, contract_id: record.id })
              .then(() => {
                message.success(t('签署成功'));
                event_obj.emit('refresh');
              })
              .finally(() => {
                resolve(null);
              });
          });
        },
        onCancel() {},
      });
    },
  },
  {
    name: '创建顾问全部工时',
    type: 'dropdown',
    clazz: 'primary',
    is_show: (record: any) => {
      return record.qiyuesuo_status == 4 && !record.is_create_work_hour;
    },
    cb: (record: any) => {
      Modal.confirm({
        title: t('提示'),
        content: t('确认创建顾问全部工时'),
        onOk() {
          return new Promise(resolve => {
            create_work_hour({ org_id: current_org?.id, contract_id: record.id })
              .then(() => {
                message.success(t('创建顾问全部工时成功'));
                event_obj.emit('refresh');
              })
              .finally(() => {
                resolve(null);
              });
          });
        },
        onCancel() {},
      });
    },
  },
  {
    name: '更新顾问月工时',
    type: 'dropdown',
    clazz: 'primary',
    is_show: (record: any) => {
      return record.qiyuesuo_status == 4;
    },
    cb: (record: any) => {
      const month1: any = ref('');
      const month2: any = ref('');
      month1.value = record.start_date;
      month2.value = record.end_date;
      const editModal = reactive({
        visible: true,
        title: t('更新顾问月工时'),
        loading: false,
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'vertical',
            col: [],
          },
          fields: [
            {
              type: 'monthpicker',
              name: 'work_month',
              label: '工时月份',
              label_i18n: '工时月份',
              placeholder_i18n: '请填写服务月份',
              disabled: false,
              allowClear: true,
              inputType: 'number',
              defaultValue: '',
              showTime: false,
              valueFormat: 'YYYY-MM-DD',
              format: 'YYYY-MM',
              placeholder: '请填写服务月份',
              disabledTime: (date: any) => {
                return month1.value
                  ? date.add(1, 'months').format('YYYY-MM') < month1.value ||
                      date.subtract(1, 'months').format('YYYY-MM') > month2.value
                  : date.format('YYYY-MM') > moment().format('YYYY-MM');
              },
            },
          ],
          rules: {
            work_month: [{ required: true, message: t('请选择工时月份') }],
          },
          model: reactive({
            org_id: current_org?.id,
            contract_id: record?.id,
            work_month: null,
          }),
        },
        options: options(t),
        ok: (payload: any) => {
          return new Promise(resolve => {
            editModal.loading = true;
            query_month_work_hour_list({
              ...payload,
              is_active: true,
              is_lock: true,
            }).then((res1: any) => {
              if (res1 && res1.data && res1.data.length > 0 && res1.data[0].is_lock) {
                notification.error({
                  message: t('提示'),
                  description: t('顾问当月工时已锁定'),
                });
              } else {
                create_month_work_hour({
                  ...payload,
                })
                  .then(() => {
                    message.success(t('操作成功'));
                    event_obj.emit('refresh');
                    resolve(null);
                  })
                  .finally(() => {
                    editModal.loading = false;
                  });
              }
            });
          });
        },
      });
      modalBox(editModal);
    },
  },
  {
    name: '终止合同',
    type: 'dropdown',
    clazz: 'primary',
    cb: (record: any) => {
      const editModal = reactive({
        visible: true,
        title: t('终止合同'),
        loading: false,
        form: {
          settings: settings,
          fields: [
            {
              type: 'datepicker',
              name: 'termination_date',
              label: '终止日期',
              label_i18n: '终止日期',
              placeholder_i18n: '请选择终止日期',
              disabled: false,
              allowClear: true,
              inputType: 'number',
              defaultValue: '',
              showTime: false,
              valueFormat: 'YYYY-MM-DD',
              placeholder: '请选择终止日期',
            },
          ],
          rules: {
            termination_date: [{ required: true, message: t('请选择终止日期') }],
          },
          model: reactive({
            org_id: current_org?.id,
            contract_id: record?.id,
            termination_date: null,
            note: '合同终止',
            note_type_flag: 'cerp.contract',
          }),
        },
        options: options(t),
        ok: (payload: any) => {
          return new Promise(resolve => {
            editModal.loading = true;
            set_contract_termination_date({
              ...payload,
              org_id: current_org?.id,
              contract_id: record?.id,
              note: '合同终止',
              note_type_flag: 'cerp.contract',
            })
              .then(() => {
                message.success(t('终止合同成功'));
                event_obj.emit('refresh');
                resolve(null);
              })
              .finally(() => {
                editModal.loading = false;
              });
          });
        },
      });
      modalBox(editModal);
    },
  },
  {
    name: '删除合同终止日期',
    type: 'dropdown',
    clazz: 'primary',
    cb: (record: any) => {
      Modal.confirm({
        title: t('提示'),
        content: t('是否删除合同终止日期' + '?'),
        onOk() {
          return new Promise(resolve => {
            delete_contract_termination_date({ org_id: current_org.id, contract_id: record.id })
              .then(function (param: any) {
                message.success(t('删除成功'));
                event_obj.emit('refresh');
              })
              .finally(() => {
                resolve(null);
              });
          });
        },
        onCancel() {},
      });
    },
  },
  {
    name: '上传本地合同',
    type: 'dropdown',
    clazz: 'primary',
    cb: (data: any) => {
      const loading = ref(false);
      const editModal = reactive({
        visible: true,
        title: t('上传本地合同'),
        group: '合同',
        type: 'picture',
        component: 'upload-image-modal',
        loading: loading,
        ok: (payload: any) => {
          return new Promise(resolve => {
            loading.value = true;
            if (data.wechat_appendix_ids) {
              payload.push(...data.wechat_appendix_ids.split(','));
            }
            upload_contract_local_appendix({
              contract_id: data.id,
              appendix_ids: payload,
              org_id: current_org?.id,
            })
              .then(() => {
                message.success(t('添加成功'));
                event_obj.emit('refresh');
                resolve(null);
              })
              .finally(() => {
                loading.value = false;
              });
          });
        },
      });
      modalBox(editModal);
    },
  },
];
export const slotList = defaultSlotList;
export const config = {
  creatCol: [9, 9, 9, 10],
  updateCol: [7, 7, 7, 7],
};

export const head_btn_list: btnTd[] = [];
