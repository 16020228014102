
import { computed, createVNode, defineComponent, reactive, ref, watch } from 'vue';
import { message, Modal, notification } from 'ant-design-vue';
import {
  create_invoice,
  delete_extra_field,
  get_adviser_work_hour_for_invoice,
  get_cc_bank_by_contract_id,
  query_contract_list,
  query_extra_field_list,
  query_invoice_list,
  update_invoice,
} from '@/api/cerp-model';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import { CloseCircleFilled, ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons-vue';
import { event_obj } from '@/components/page-model2/cerp/cerp-invoice-config.ts';
import { useForm } from 'ant-design-vue/es/form';
import moment from 'moment';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'SetrecordModel',
  props: {
    visible: {
      type: Boolean,
      required: true,
      default: () => false,
    },
    obj: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  emits: ['ok', 'cancel'],
  setup(props, { emit }) {
    const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
    const { t } = useI18n();
    const cardDataSource: Array<any> = reactive([]);
    const store = useStore();
    const current_company = computed(() => store.getters['user/current_company']);
    const is_bank = ref(false);
    const extra_ids: any = ref([]);
    const is_insurance_payment = ref(false);
    const month1: any = ref('');
    const month2: any = ref('');
    const current_contract_id: any = ref(null);
    const contract_list: any = ref([]);
    const is_show_options: any = ref([
      { value: true, label: t('显示') },
      { value: false, label: t('隐藏') },
    ]);
    const refresh_contract_list = (cc_company_id: any) => {
      query_contract_list({
        org_id: current_org?.id,
        is_active: true,
        cc_company_id: cc_company_id,
        pageSize: 2000,
        adviser_id: props.type == 'update' ? props.obj.adviser_id : props.obj.id,
      }).then((res: any) => {
        contract_list.value.length = 0;
        if (props.type !== 'update') {
          modal.contract_id = null;
        }
        if (res && res.data && res.data.length > 0) {
          res.data.map((item: any) => {
            contract_list.value.push({
              value: item.id,
              name:
                item.start_date +
                '~' +
                (item.termination_date ? item.termination_date : item.end_date),
            });
          });
        }
      });
    };
    if (props.type == 'update' && props.obj.customfield_list.length > 0) {
      cardDataSource.push(...props.obj.customfield_list);
    } else {
      cardDataSource.push({
        custom_field_name: null,
        custom_field_value: null,
        extra_id: null,
        is_show: true,
      });
    }
    const confirmLoading = ref<boolean>(false);
    const modal: any = reactive({
      org_id: current_org?.id,
      is_active: true,
      adviser_id: props.type == 'update' ? props.obj.adviser_id : props.obj.id,
      adviser__full_name: props.type == 'update' ? props.obj.adviser__full_name : props.obj.name,
      contract_id:
        props.type == 'update' ? Number(props.obj.contract_id) : current_contract_id.value,
      cc_company_id: props.type == 'update' ? Number(props.obj.cc_company_id) : null,
      invoice_start_date:
        props.type == 'update' ? props.obj.invoice_start_date : moment().format('YYYY-MM-DD'),
      invoice_id: props.type == 'update' ? props.obj.id : null,
      invoice_end_date: props.type == 'update' ? props.obj.invoice_end_date : null,
      date_submitted: props.type == 'update' ? props.obj.date_submitted : null,
      work_unit: props.type == 'update' ? props.obj.work_unit : 0,
      accommodation_unit: props.type == 'update' ? props.obj.accommodation_unit : 0,
      service_month: props.type == 'update' ? props.obj.service_month : null,
      overtime: props.type == 'update' ? props.obj.overtime : 0,
      custom_id: props.type == 'update' ? props.obj.custom_id : null,
      remark: props.type == 'update' ? props.obj.remark : null,
      invoice_type: props.type == 'update' ? props.obj.invoice_type : null,
      payment_term: props.type == 'update' ? props.obj.payment_term : 15,
      bank_account_id: props.type == 'update' ? props.obj.bank_account_id : null,
      bank_charge_cost: props.type == 'update' ? props.obj.bank_charge_cost : null,
      insurance_fee: props.type == 'update' ? props.obj.insurance_fee : null,
      basic_amount: props.type == 'update' ? props.obj.basic_amount : 0,
      accommodation_amount: props.type == 'update' ? props.obj.accommodation_amount : 0,
      overtime_amount: props.type == 'update' ? props.obj.overtime_amount : 0,
      formula_note: props.type == 'update' ? props.obj.formula_note : null,
      is_cc_to_adviser: props.type == 'update' ? props.obj.is_cc_to_adviser : true,
      is_vat: props.type == 'update' ? props.obj.is_vat : false,
      overtime_rate: props.type == 'update' ? props.obj.overtime_rate : null,
      company_bank_charge: props.type == 'update' ? props.obj.company_bank_charge : null,
    });
    refresh_contract_list(
      Number(current_company.value?.id) ? Number(current_company.value?.id) : null,
    );
    watch(
      () => modal.cc_company_id,
      () => {
        refresh_contract_list(modal.cc_company_id);
      },
    );
    const modifyModal = reactive({
      title: t('请款单'),
      form: {
        settings: {
          labelAlign: 'right',
          layout: 'vertical',
          col: [16, 16, 15],
        },
        fields: [
          {
            type: 'input',
            name: 'adviser__full_name',
            label: '顾问',
            label_i18n: '顾问',
            placeholder_i18n: '请选择隶属顾问',
            disabled: true,
            placeholder: '请选择隶属顾问',
            datasourceType: 'remote',
            allowClear: false,
          },
          {
            type: 'datepicker',
            name: 'invoice_start_date',
            label: '请款日期',
            label_i18n: '请款日期',
            placeholder_i18n: '请填写请款起始日期',
            disabled: false,
            allowClear: true,
            inputType: 'number',
            defaultValue: '',
            showTime: false,
            valueFormat: 'YYYY-MM-DD',
            format: 'YYYY-MM-DD',
            placeholder: '请填写请款起始日期',
          },
          {
            type: 'select',
            name: 'invoice_type',
            label: '请款类型',
            label_i18n: '请款类型',
            placeholder_i18n: '请选择请款类型',
            mode: 'default',
            disabled: props?.type == 'update',
            defaultValue: '',
            labelKey: 'name',
            valueKey: 'value',
            placeholder: '请选择请款类型',
            datasource: 'invoice_type',
          },
          {
            type: 'title',
            name: 'Basic Information',
          },
          {
            type: 'money',
            name: 'basic_rate',
            label: '基本单位薪资',
            label_i18n: '基本单位薪资',
            placeholder_i18n: '基本单位薪资',
            disabled: true,
            datasourceType: 'remote',
            allowClear: false,
          },
          {
            type: 'number',
            name: 'cc_management_fee_rate',
            label: 'cc管理费率（%）',
            label_i18n: 'cc管理费率（%）',
            placeholder_i18n: 'cc管理费率（%）',
            disabled: true,
            allowClear: false,
          },
          {
            type: 'number',
            name: 'accommodation_rate',
            label: '住宿费率',
            label_i18n: '住宿费率',
            placeholder_i18n: '住宿费率',
            disabled: true,
            datasourceType: 'remote',
            allowClear: false,
          },
          {
            type: 'select',
            name: 'management_fee_payment_flag',
            label: '管理费支付方式',
            label_i18n: '管理费支付方式',
            placeholder_i18n: '管理费支付方式',
            mode: 'default',
            disabled: true,
            defaultValue: '',
            labelKey: 'name',
            valueKey: 'value',
            datasource: 'management_fee_payment_flag',
          },
          {
            type: 'title',
            name: 'Local Salary',
          },
          {
            type: 'money',
            name: 'local_management_fee_fixed',
            label: '本地发薪工资',
            label_i18n: '本地发薪工资',
            disabled: true,
            datasourceType: 'remote',
            allowClear: false,
          },
          {
            type: 'money',
            name: 'local_deduct_fee',
            label: '本地扣减费',
            label_i18n: '本地扣减费',
            disabled: true,
            datasourceType: 'remote',
            allowClear: false,
          },
          {
            type: 'title',
            name: 'Work Information',
          },
          {
            type: 'number',
            name: 'work_unit',
            label: '普通工时单位',
            label_i18n: '普通工时单位',
            placeholder_i18n: '请填写普通工时单位',
            disabled: false,
            allowClear: true,
            defaultValue: 0,
            placeholder: '请填写普通工时单位',
          },
          {
            type: 'money',
            name: 'basic_amount',
            label: '基本工资总额',
            label_i18n: '基本工资总额',
            placeholder_i18n: '请填写基本工资总额',
            disabled: false,
            allowClear: true,
            defaultValue: 0,
            placeholder: '请填写基本工资总额',
          },
          {
            type: 'textarea',
            name: 'formula_note',
            label: '计算公式备注',
            label_i18n: '计算公式备注',
            placeholder_i18n: '请填写计算公式备注',
            disabled: false,
            allowClear: true,
            inputType: 'text',
            maxlength: 200,
            defaultValue: '',
            placeholder: '请填写计算公式备注',
          },
          {
            type: 'textarea',
            name: 'remark',
            label: '备注',
            label_i18n: '备注',
            placeholder_i18n: '请填写备注',
            disabled: false,
            allowClear: true,
            inputType: 'text',
            maxlength: 200,
            defaultValue: '',
            placeholder: '请填写备注',
          },
          {
            type: 'remoteselect',
            name: 'cc_company_id',
            label: 'CC公司',
            label_i18n: 'CC公司',
            placeholder_i18n: '请选择CC公司',
            disabled: props?.type == 'update',
            placeholder: '请选择CC公司',
            datasourceType: 'remote',
            mode: 'default',
            allowClear: true,
            modalType: 'baseinfo-cccompany',
          },
          {
            type: 'select',
            name: 'contract_id',
            label: '合同',
            label_i18n: '合同',
            placeholder_i18n: '请选择合同',
            mode: 'default',
            disabled: props?.type == 'update',
            defaultValue: '',
            labelKey: 'name',
            valueKey: 'value',
            placeholder: '请选择合同',
            datasource: 'contract_id',
          },
          {
            type: 'monthpicker',
            name: 'service_month',
            label: '服务月份',
            label_i18n: '服务月份',
            placeholder_i18n: '请填写服务月份',
            disabled: false,
            allowClear: true,
            inputType: 'number',
            defaultValue: '',
            showTime: false,
            valueFormat: 'YYYY-MM-DD',
            format: 'YYYY-MM',
            disabledTime: (date: any) => {
              return month1.value
                ? date.add(1, 'months').format('YYYY-MM') < month1.value ||
                    date.subtract(1, 'months').format('YYYY-MM') > month2.value
                : date.format('YYYY-MM') > moment().format('YYYY-MM');
            },
            placeholder: '请填写服务月份',
          },
          {
            type: 'kong_title',
            height: 30,
          },
          {
            type: 'input',
            name: 'currency__currency',
            label: '薪资币种',
            label_i18n: '薪资币种',
            disabled: true,
            datasourceType: 'remote',
            allowClear: false,
          },
          {
            type: 'money',
            name: 'cc_management_fee',
            label: 'cc管理费',
            label_i18n: 'cc管理费',
            disabled: true,
            datasourceType: 'remote',
            allowClear: false,
          },
          {
            type: 'number',
            name: 'overtime_rate',
            label: '加班费率',
            label_i18n: '加班费率',
            disabled: false,
            allowClear: false,
          },
          {
            type: 'select',
            name: 'is_bank_charge_payment',
            label: '银行转账费支付方式',
            label_i18n: '银行转账费支付方式',
            mode: 'default',
            disabled: true,
            defaultValue: '',
            labelKey: 'name',
            valueKey: 'value',
            datasource: 'is_bank_charge_payment',
          },
          {
            type: 'kong_title',
            height: 30,
          },
          {
            type: 'money',
            name: 'local_management_fee',
            label: '本地管理费',
            label_i18n: '本地管理费',
            disabled: true,
            datasourceType: 'remote',
            allowClear: false,
          },
          {
            type: 'kong',
          },
          {
            type: 'kong_title',
            height: 30,
          },
          {
            type: 'number',
            name: 'overtime',
            label: '加班单位',
            label_i18n: '加班单位',
            placeholder_i18n: '请填写加班单位',
            disabled: false,
            allowClear: true,
            defaultValue: 0,
            placeholder: '请填写加班单位',
          },
          {
            type: 'number',
            name: 'overtime_amount',
            label: '加班总额',
            label_i18n: '加班总额',
            placeholder_i18n: '请填写加班总额',
            disabled: false,
            allowClear: true,
            defaultValue: 0,
            placeholder: '请填写加班总额',
          },
          {
            type: 'money',
            name: 'bank_charge_cost',
            label: '银行转账费',
            label_i18n: '银行转账费',
            placeholder_i18n: '请填写银行转账费',
            disabled: false,
            allowClear: true,
            inputType: 'number',
            defaultValue: '',
            placeholder: '请填写银行转账费',
          },
          {
            type: 'radio',
            name: 'is_vat',
            label: '是否使用VAT模板',
            label_i18n: '是否使用VAT模板',
            disabled: false,
            labelKey: 'name',
            valueKey: 'value',
            defaultValue: '',
          },
          {
            type: 'input',
            name: 'recuitment_company__company_name',
            label: '顾问公司',
            label_i18n: '顾问公司',
            disabled: true,
            datasourceType: 'remote',
            allowClear: false,
          },
          {
            type: 'remoteselect',
            name: 'bank_account_id',
            label: 'CC银行收款账户',
            label_i18n: 'CC银行收款账户',
            placeholder_i18n: '请选择银行收款账户',
            disabled: false,
            placeholder: '请选择银行收款账户',
            datasourceType: 'remote',
            mode: 'default',
            labelKey: 'account_flag',
            allowClear: true,
            modalType: 'baseinfo-ccbankaccountscc',
          },
          {
            type: 'number',
            name: 'payment_term',
            label: '付款周期',
            label_i18n: '付款周期',
            placeholder_i18n: '请填写付款周期',
            disabled: false,
            allowClear: true,
            inputType: 'text',
            defaultValue: '',
            placeholder: '请填写付款周期',
          },
          {
            type: 'kong_title',
            height: 30,
          },
          {
            type: 'select',
            name: 'rate_type',
            label: '薪资种类',
            label_i18n: '薪资种类',
            mode: 'default',
            disabled: true,
            defaultValue: '',
            labelKey: 'name',
            valueKey: 'value',
            datasource: 'rate_type',
          },
          {
            type: 'input',
            name: 'management_fee_formula__name',
            label: '管理费计算方式',
            label_i18n: '管理费计算方式',
            disabled: true,
            datasourceType: 'remote',
            allowClear: false,
          },
          {
            type: 'money',
            name: 'insurance_fee',
            label: '保险费',
            label_i18n: '保险费',
            placeholder_i18n: '请填写保险费',
            disabled: false,
            allowClear: true,
            inputType: 'number',
            defaultValue: '',
            placeholder: '请填写保险费',
          },
          {
            type: 'select',
            name: 'insurance_payment_flag',
            label: '保险费支付方式',
            label_i18n: '保险费支付方式',
            mode: 'default',
            disabled: true,
            defaultValue: '',
            labelKey: 'name',
            valueKey: 'value',
            datasource: 'insurance_payment_flag',
          },
          {
            type: 'kong_title',
            height: 30,
          },
          {
            type: 'input',
            name: 'local_management_fee_rate',
            label: '本地管理费率（%）',
            label_i18n: '本地管理费率（%）',
            disabled: true,
            datasourceType: 'remote',
            allowClear: false,
          },
          {
            type: 'kong',
          },
          {
            type: 'kong_title',
            height: 30,
          },
          {
            type: 'number',
            name: 'accommodation_unit',
            label: '宿款单位',
            label_i18n: '宿款单位',
            placeholder_i18n: '请填写宿款单位',
            disabled: false,
            formatter: (value: any) => {
              let str = value.split('');
              if (value == '') {
                return '';
              } else if (str[value.length - 1] == '.') {
                return value;
              } else {
                return Math.round(Number(`${value}`) * 100) / 100;
              }
            },
            allowClear: true,
            defaultValue: 0,
            placeholder: '请填写宿款单位',
          },
          {
            type: 'money',
            name: 'accommodation_amount',
            label: '住宿总额',
            label_i18n: '住宿总额',
            placeholder_i18n: '请填写住宿总额',
            disabled: false,
            allowClear: true,
            defaultValue: 0,
            placeholder: '请填写住宿总额',
          },
          {
            type: 'radio',
            name: 'is_cc_to_adviser',
            label: '请款回执是否发送顾问',
            label_i18n: '请款回执是否发送顾问',
            disabled: false,
            labelKey: 'name',
            valueKey: 'value',
            defaultValue: '',
          },
        ],
        rules: {
          cc_company_id: [{ required: true, message: t('请选择CC公司') }],
          contract_id: [{ required: true, message: t('请选择合同') }],
          service_month: [{ required: true, message: t('请选择服务月份') }],
          invoice_start_date: [{ required: true, message: t('请选择起始日期') }],
          invoice_type: [{ required: true, message: t('请选择请款类型') }],
          bank_account_id: [{ required: true, message: t('请选择CC银行收款账户') }],
        },
        model: modal,
        watch: {
          contract_id: (data: any) => {
            if (data['contract_id']) {
              get_cc_bank_by_contract_id({
                org_id: current_org.id,
                contract_id: data.contract_id,
              }).then((item: any) => {
                modal.bank_account_id = item.cc_bank_account_id;
              });
              query_contract_list({
                org_id: current_org.id,
                ids: [data['contract_id']].join(','),
              }).then((res: any) => {
                if (res && res.data && res.data.length > 0) {
                  data.remark1 = data.remark;
                  modal.overtime_rate1 = modal.overtime_rate;
                  modal.insurance_fee1 = modal.insurance_fee;
                  Object.assign(data, res.data[0]);
                  data.remark = data.remark1;
                  modal.insurance_fee = modal.insurance_fee1;
                  modal.overtime_rate = modal.overtime_rate1;
                  month1.value = res.data[0].start_date;
                  month2.value = res.data[0].end_date;
                  if (res.data[0].termination_date) {
                    month2.value = res.data[0].termination_date;
                  }
                  if (props.type != 'update') {
                    modal.insurance_fee = res.data[0].insurance_fee;
                    modal.overtime_rate = res.data[0].overtime_rate;
                    if (data.invoice_type == 1) {
                      is_insurance_payment.value = true;
                      modal.insurance_fee = res.data[0].c_insurance_fee;
                    } else {
                      modal.insurance_fee = 0;
                      is_insurance_payment.value = false;
                    }
                    if (data.invoice_type == 1 && !res.data[0].is_bank_charge_payment) {
                      modal.bank_charge_cost = res.data[0].company_bank_charge;
                    } else {
                      modal.bank_charge_cost = 0;
                    }
                  }
                  if (data['invoice_type'] == 1) {
                    // if (!res.data[0].is_current) {
                    //   query_cc_company_list({
                    //     org_id: current_org.id,
                    //     ids: data.cc_company_id,
                    //   }).then((item: any) => {
                    //     if (item && item.data && item.data.length > 0) {
                    //       modal.bank_charge_cost = item.data[0].bank_charge;
                    //     }
                    //   });
                    // }
                    if (
                      new Date(
                        moment(res.data[0].start_date).format('YYYY-MM').replace(/-/g, '/'),
                      ) >
                        new Date(moment(data.service_month).format('YYYY-MM').replace(/-/g, '/')) ||
                      new Date(moment(res.data[0].end_date).format('YYYY-MM').replace(/-/g, '/')) <
                        new Date(moment(data.service_month).format('YYYY-MM').replace(/-/g, '/'))
                    ) {
                      notification.error({
                        message: t('提示'),
                        description: t('服务月份有误'),
                      });
                    }
                  }
                } else {
                  notification.error({
                    message: t('提示'),
                    description: t('无对应合同'),
                  });
                }
              });
              if (data.service_month && data.invoice_type == 1) {
                get_adviser_work_hour_for_invoice({
                  org_id: current_org.id,
                  contract_id: data['contract_id'],
                  work_month: data['service_month'],
                }).then((res: any) => {
                  data.overtime = res.overtime;
                  data.work_unit = res.work_unit;
                });
              }
            }
          },
          invoice_type: (data: any) => {
            if (
              data['invoice_type'] == 1 ||
              data['invoice_type'] == 2 ||
              data['invoice_type'] == 4
            ) {
              data.is_cc_to_adviser = true;
            } else {
              data.is_cc_to_adviser = false;
            }
            if (
              data['invoice_type'] == 2 ||
              data['invoice_type'] == 3 ||
              data['invoice_type'] == 4 ||
              data['invoice_type'] == 5
            ) {
              data['bank_charge_cost'] = 0;
              data['insurance_fee'] = 0;
              is_bank.value = false;
              is_insurance_payment.value = false;
            } else {
              is_bank.value = true;
              is_insurance_payment.value = true;
            }
            if (data['invoice_type'] == 1) {
              if (data.service_month) {
                query_extra_field_list({
                  org_id: current_org.id,
                  adviser_id: data.adviser_id,
                  month: data.service_month,
                  cc_company_id: data.cc_company_id,
                  is_active: true,
                  type: 0,
                  is_finished: false,
                }).then((res: any) => {
                  let list: any = [];
                  list = cardDataSource.filter((item: any) => !item.extra_id);
                  console.log('list', list);
                  console.log('list', list.length);
                  cardDataSource.length = 0;
                  if (list.length > 0) {
                    list.map((item: any) => {
                      cardDataSource.push(item);
                    });
                  }
                  if (res.data.length > 0) {
                    res.data.map((item: any) => {
                      cardDataSource.push({
                        custom_field_name: item.custom_field_name,
                        custom_field_value: item.custom_field_value,
                        extra_id: item.id,
                        is_show: item.is_show,
                      });
                    });
                  }
                });
              }
              if (data.service_month && data.invoice_type == 1 && data.contract_id) {
                get_adviser_work_hour_for_invoice({
                  org_id: current_org.id,
                  contract_id: data['contract_id'],
                  work_month: data['service_month'],
                }).then((res: any) => {
                  data.overtime = res.overtime;
                  data.work_unit = res.work_unit;
                });
              }
              if (data.contract_id) {
                query_contract_list({
                  org_id: current_org.id,
                  ids: [data.contract_id].join(','),
                }).then((res: any) => {
                  if (res && res.data && res.data.length > 0) {
                    if (
                      new Date(
                        moment(res.data[0].start_date).format('YYYY-MM').replace(/-/g, '/'),
                      ) >
                        new Date(moment(data.service_month).format('YYYY-MM').replace(/-/g, '/')) ||
                      new Date(moment(res.data[0].end_date).format('YYYY-MM').replace(/-/g, '/')) <
                        new Date(moment(data.service_month).format('YYYY-MM').replace(/-/g, '/'))
                    ) {
                      notification.error({
                        message: t('提示'),
                        description: t('服务月份有误'),
                      });
                    }
                    if (props.type != 'update') {
                      data.insurance_fee = res.data[0].c_insurance_fee;
                      data.bank_charge_cost = res.data[0].company_bank_charge;
                    }
                    // if (!res.data[0].is_current) {
                    //   query_cc_company_list({
                    //     org_id: current_org.id,
                    //     ids: data.cc_company_id,
                    //   }).then((item: any) => {
                    //     if (item && item.data && item.data.length > 0) {
                    //       modal.bank_charge_cost = item.data[0].bank_charge;
                    //     }
                    //   });
                    // }
                  } else {
                    notification.error({
                      message: t('提示'),
                      description: t('无对应合同'),
                    });
                  }
                });
              } else {
                notification.error({
                  message: t('提示'),
                  description: t('无对应合同'),
                });
              }
            }
          },
          service_month: (data: any) => {
            if (data.service_month && data.invoice_type == 1) {
              query_extra_field_list({
                org_id: current_org.id,
                adviser_id: data.adviser_id,
                month: data.service_month,
                cc_company_id: data.cc_company_id,
                is_active: true,
                type: 0,
                is_finished: false,
              }).then((res: any) => {
                let list: any = [];
                list = cardDataSource.filter((item: any) => !item.extra_id);
                console.log('list', list);
                console.log('list', list.length);
                cardDataSource.length = 0;
                if (list.length > 0) {
                  list.map((item: any) => {
                    cardDataSource.push(item);
                  });
                }
                if (res.data.length > 0) {
                  res.data.map((item: any) => {
                    cardDataSource.push({
                      custom_field_name: item.custom_field_name,
                      custom_field_value: item.custom_field_value,
                      extra_id: item.id,
                      is_show: item.is_show,
                    });
                  });
                }
              });
              if (data['contract_id']) {
                get_adviser_work_hour_for_invoice({
                  org_id: current_org.id,
                  contract_id: data['contract_id'],
                  work_month: data['service_month'],
                }).then((res: any) => {
                  data.overtime = res.overtime;
                  data.work_unit = res.work_unit;
                });
              }
            }
          },
          accommodation_unit: (data: any) => {
            data.accommodation_unit =
              Math.round(Number(data.accommodation_unit) * 1000000) / 1000000;
          },
          work_unit: (data: any) => {
            if (data.basic_rate && data.work_unit) {
              data.basic_amount =
                Math.round(
                  (Number(data.basic_rate) * 1000000 * (Number(data.work_unit) * 1000000)) /
                    1000000,
                ) / 1000000;
            } else {
              data.basic_amount = 0;
            }
          },
          basic_amount: (data: any) => {
            if (data.basic_rate && data.basic_amount) {
              data.work_unit =
                Math.round(
                  ((Number(data.basic_amount) * 1000000) / (Number(data.basic_rate) * 1000000)) *
                    1000000,
                ) / 1000000;
            } else {
              data.work_unit = 0;
            }
          },
        },
      },
      options: {
        rate_type: [
          { value: 0, name: t('日薪') },
          { value: 1, name: t('月薪') },
          { value: 2, name: t('年薪') },
          { value: 3, name: t('时薪') },
          { value: 4, name: t('周薪') },
        ],
        is_bank_charge_payment: [
          { value: true, name: t('顾问支付') },
          { value: false, name: t('顾问公司支付') },
        ],
        insurance_payment_flag: [
          { value: -1, name: t('无') },
          { value: 0, name: t('顾问支付') },
          { value: 1, name: t('顾问公司支付') },
        ],
        management_fee_payment_flag: [
          { value: 0, name: t('顾问支付') },
          { value: 1, name: t('顾问公司支付') },
        ],
        invoice_type: [
          { value: 1, name: t('工资') },
          { value: 2, name: t('报销') },
          { value: 3, name: 'credit note' },
          { value: 4, name: t('加班') },
          { value: 5, name: t('CC报销') },
        ],
        is_cc_to_adviser: [
          { value: true, name: t('是') },
          { value: false, name: t('否') },
        ],
        is_vat: [
          { value: true, name: t('是') },
          { value: false, name: t('否') },
        ],
        contract_id: contract_list.value,
      },
    });
    const { resetFields, validate, validateInfos } = useForm(modal, modifyModal.form.rules);
    if (props.type != 'update') {
      query_contract_list({
        org_id: current_org.id,
        adviser_id: props.obj.id,
        is_current: true,
        cc_company_id: current_company.value?.id,
      }).then((res: any) => {
        if (res && res.data && res.data.length > 0) {
          // get_cc_bank_by_contract_id({
          //   org_id: current_org.id,
          //   contract_id: res.data[0].id,
          // }).then((item: any) => {
          //   modal.bank_account_id = item.cc_bank_account_id;
          // });
          current_contract_id.value = res.data[0].id;
          // modal.insurance_fee1 = modal.insurance_fee;
          modal.remark1 = modal.remark;
          modal.overtime_rate1 = modal.overtime_rate;
          modal.insurance_fee1 = modal.insurance_fee;
          Object.assign(modal, res.data[0]);
          modal.remark = modal.remark1;
          modal.insurance_fee = modal.insurance_fee1;
          if (!modal.invoice_type || modal.invoice_type == 1) {
            modal.bank_charge_cost = modal.company_bank_charge;
          }
          modal.overtime_rate = modal.overtime_rate1;
          month1.value = res.data[0].start_date;
          month2.value = res.data[0].end_date;
          if (res.data[0].termination_date) {
            month2.value = res.data[0].termination_date;
          }
          // if (res.data[0].is_bank_charge_payment) {
          // is_bank.value = true;
          // } else {
          //   is_bank.value = false;
          // }
          if (props.type != 'update') {
            modal.insurance_fee = res.data[0].insurance_fee;
            modal.overtime_rate = res.data[0].overtime_rate;
            if (res.data[0].invoice_type == 1) {
              is_insurance_payment.value = true;
              modal.insurance_fee = modal.c_insurance_fee;
            } else {
              modal.insurance_fee = 0;
              is_insurance_payment.value = false;
            }
            if (res.data[0].invoice_type == 1 && !res.data[0].is_bank_charge_payment) {
              modal.bank_charge_cost = res.data[0].company_bank_charge;
            } else {
              modal.bank_charge_cost = 0;
            }
          }
          modal.recuitment_company__company_name = null;
          // if (props.type != 'update') {
          //   query_insurance_list({
          //     org_id: current_org.id,
          //     is_current: true,
          //     adviser_id: props.obj.id,
          //   }).then((res1: any) => {
          //     if (res1 && res1.data && res1.data.length > 0) {
          //       if (res.data[0].insurance_payment_flag == 1) {
          //         modal.insurance_fee = res1.data[0].c_insurance_fee;
          //       } else {
          //         modal.insurance_fee = 0;
          //       }
          //     }
          //   });
          // }
        } else {
          notification.error({
            message: t('错误'),
            description: t('顾问无CC公司当前合同，请创建'),
          });
        }
      });
    } else {
      query_contract_list({
        org_id: current_org.id,
        ids: [props.obj.contract_id].join(','),
      }).then((res: any) => {
        if (res && res.data && res.data.length > 0) {
          // modal.insurance_fee1 = modal.insurance_fee;
          modal.remark1 = modal.remark;
          modal.overtime_rate1 = modal.overtime_rate;
          modal.insurance_fee1 = modal.insurance_fee;
          Object.assign(modal, res.data[0]);
          modal.remark = modal.remark1;
          modal.insurance_fee = modal.insurance_fee1;
          if (!modal.invoice_type || modal.invoice_type == 1) {
            modal.bank_charge_cost = modal.company_bank_charge;
          }
          modal.overtime_rate = modal.overtime_rate1;
          month1.value = res.data[0].start_date;
          month2.value = res.data[0].end_date;
          if (res.data[0].termination_date) {
            month2.value = res.data[0].termination_date;
          }
          // if (res.data[0].is_bank_charge_payment) {
          // is_bank.value = true;
          // } else {
          //   is_bank.value = false;
          // }
          // if (props.type != 'update') {
          //   modal.insurance_fee = res.data[0].insurance_fee;
          //   modal.overtime_rate = res.data[0].overtime_rate;
          //   if (res.data[0].invoice_type == 1) {
          //     is_insurance_payment.value = true;
          //     modal.insurance_fee = modal.c_insurance_fee;
          //   } else {
          //     modal.insurance_fee = 0;
          //     is_insurance_payment.value = false;
          //   }
          //   if (res.data[0].invoice_type == 1 && !res.data[0].is_bank_charge_payment) {
          //     modal.bank_charge_cost = res.data[0].company_bank_charge;
          //   } else {
          //     modal.bank_charge_cost = 0;
          //   }
          // }
          // if (props.type != 'update') {
          //   query_insurance_list({
          //     org_id: current_org.id,
          //     is_current: true,
          //     adviser_id: props.obj.id,
          //   }).then((res1: any) => {
          //     if (res1 && res1.data && res1.data.length > 0) {
          //       if (res.data[0].insurance_payment_flag == 1) {
          //         modal.insurance_fee = res1.data[0].c_insurance_fee;
          //       } else {
          //         modal.insurance_fee = 0;
          //       }
          //     }
          //   });
          // }
        }
      });
    }

    const state = {
      card: {
        columns: [
          {
            title: t('自定义项描述'),
            dataIndex: 'custom_field_name',
            key: 'custom_field_name',
            width: '150px',
            slots: { customRender: 'custom_field_name' },
          },
          {
            title: t('自定义项值'),
            dataIndex: 'custom_field_value',
            key: 'custom_field_value',
            width: '100px',
            slots: { customRender: 'custom_field_value' },
          },
          {
            title: t('显示到工资单'),
            dataIndex: 'is_show',
            key: 'is_show',
            width: '60px',
            slots: { customRender: 'is_show' },
          },
          {
            title: '',
            dataIndex: 'del',
            key: 'del',
            width: '10px',
            slots: { customRender: 'del' },
          },
        ],
        dataSource: cardDataSource,
      },
    };
    const handleCancel = () => {
      emit('cancel');
    };
    const handleOk = () => {
      if (!modifyModal.form.model.basic_amount) {
        modifyModal.form.model.basic_amount = 0;
      }
      if (!modifyModal.form.model.work_unit) {
        modifyModal.form.model.work_unit = 0;
      }
      if (!modifyModal.form.model.accommodation_unit) {
        modifyModal.form.model.accommodation_unit = 0;
      }
      if (!modifyModal.form.model.accommodation_amount) {
        modifyModal.form.model.accommodation_amount = 0;
      }
      if (!modifyModal.form.model.overtime) {
        modifyModal.form.model.overtime = 0;
      }
      validate().then(() => {
        if (props.type == 'update') {
          confirmLoading.value = true;
          return new Promise(function (resolve) {
            const list: any = [...cardDataSource];
            let i = 0;
            list.map((item: any, index: any) => {
              if (!item.custom_field_name) {
                cardDataSource.splice(index - i, 1);
                i++;
              }
            });
            if (
              cardDataSource &&
              cardDataSource.length > 0 &&
              cardDataSource[0].custom_field_name
            ) {
              update_invoice({
                ...modifyModal.form.model,
                customfield_json: JSON.stringify(cardDataSource),
              })
                .then((res: any) => {
                  if (res.message) {
                    message.error(res.message);
                  } else {
                    message.success(t('修改成功'));
                  }
                  emit('ok');
                  if (extra_ids.value.length > 0) {
                    extra_ids.value.map((item: any) => {
                      delete_extra_field({
                        org_id: current_org.id,
                        extra_field_id: item,
                      }).then(() => {});
                    });
                  }
                  event_obj.emit('refresh');
                  resolve(null);
                })
                .finally(() => {
                  confirmLoading.value = false;
                });
            } else {
              update_invoice({
                ...modifyModal.form.model,
              })
                .then((res: any) => {
                  if (res.message) {
                    message.error(res.message);
                  } else {
                    message.success(t('修改成功'));
                  }
                  emit('ok');
                  if (extra_ids.value.length > 0) {
                    extra_ids.value.map((item: any) => {
                      delete_extra_field({
                        org_id: current_org.id,
                        extra_field_id: item,
                      }).then(() => {});
                    });
                  }
                  event_obj.emit('refresh');
                  resolve(null);
                })
                .finally(() => {
                  confirmLoading.value = false;
                });
            }
          });
        } else {
          confirmLoading.value = true;
          return new Promise(function (resolve) {
            const list: any = [...cardDataSource];
            let i = 0;
            list.map((item: any, index: any) => {
              if (!item.custom_field_name) {
                cardDataSource.splice(index - i, 1);
                i++;
              }
            });
            if (
              cardDataSource &&
              cardDataSource.length > 0 &&
              cardDataSource[0].custom_field_name
            ) {
              if (modifyModal.form.model.invoice_type == 1) {
                query_invoice_list({
                  org_id: current_org.id,
                  service_month: modifyModal.form.model.service_month,
                  invoice_type: 1,
                  is_active: true,
                  cc_company_id: modifyModal.form.model.cc_company_id,
                  adviser_id: modifyModal.form.model.adviser_id,
                }).then((res1: any) => {
                  if (res1 && res1.data && res1.data.length > 0 && res1.data.length < 2) {
                    Modal.confirm({
                      title: t('提示'),
                      content: t('当前已有一个工资类型请款单,是否创建另一个?'),
                      okText: t('是'),
                      cancelText: t('否'),
                      icon: createVNode(ExclamationCircleOutlined),
                      onOk() {
                        create_invoice({
                          ...modifyModal.form.model,
                          customfield_json: JSON.stringify(cardDataSource),
                        })
                          .then((res: any) => {
                            if (res.message) {
                              message.error(res.message);
                            } else {
                              message.success(t('创建成功'));
                            }
                            emit('ok');
                            if (extra_ids.value.length > 0) {
                              extra_ids.value.map((item: any) => {
                                delete_extra_field({
                                  org_id: current_org.id,
                                  extra_field_id: item,
                                }).then(() => {});
                              });
                            }
                            event_obj.emit('refresh');
                            resolve(null);
                          })
                          .finally(() => {
                            confirmLoading.value = false;
                          });
                      },
                      onCancel() {},
                    });
                  } else if (res1 && res1.data && res1.data.length >= 2) {
                    confirmLoading.value = false;
                    notification.error({
                      message: t('温馨提示'),
                      description: t('工资类型请款单已有2个，不可继续创建'),
                    });
                  } else {
                    create_invoice({
                      ...modifyModal.form.model,
                      customfield_json: JSON.stringify(cardDataSource),
                    })
                      .then((res: any) => {
                        if (res.message) {
                          message.error(res.message);
                        } else {
                          message.success(t('创建成功'));
                        }
                        emit('ok');
                        if (extra_ids.value.length > 0) {
                          extra_ids.value.map((item: any) => {
                            delete_extra_field({
                              org_id: current_org.id,
                              extra_field_id: item,
                            }).then(() => {});
                          });
                        }
                        event_obj.emit('refresh');
                        resolve(null);
                      })
                      .finally(() => {
                        confirmLoading.value = false;
                      });
                  }
                });
              } else {
                create_invoice({
                  ...modifyModal.form.model,
                  customfield_json: JSON.stringify(cardDataSource),
                })
                  .then((res: any) => {
                    if (res.message) {
                      message.error(res.message);
                    } else {
                      message.success(t('创建成功'));
                    }
                    emit('ok');
                    if (extra_ids.value.length > 0) {
                      extra_ids.value.map((item: any) => {
                        delete_extra_field({
                          org_id: current_org.id,
                          extra_field_id: item,
                        }).then(() => {});
                      });
                    }
                    event_obj.emit('refresh');
                    resolve(null);
                  })
                  .finally(() => {
                    confirmLoading.value = false;
                  });
              }
            } else {
              if (modifyModal.form.model.invoice_type == 1) {
                query_invoice_list({
                  org_id: current_org.id,
                  service_month: modifyModal.form.model.service_month,
                  invoice_type: 1,
                  is_active: true,
                  cc_company_id: modifyModal.form.model.cc_company_id,
                  adviser_id: modifyModal.form.model.adviser_id,
                }).then((res1: any) => {
                  if (res1 && res1.data && res1.data.length > 0 && res1.data.length < 2) {
                    Modal.confirm({
                      title: t('提示'),
                      content: t('当前已有一个工资类型请款单,是否创建另一个?'),
                      okText: t('是'),
                      cancelText: t('否'),
                      icon: createVNode(ExclamationCircleOutlined),
                      onOk() {
                        create_invoice({
                          ...modifyModal.form.model,
                        })
                          .then((res: any) => {
                            if (res.message) {
                              message.error(res.message);
                            } else {
                              message.success(t('创建成功'));
                            }
                            emit('ok');
                            if (extra_ids.value.length > 0) {
                              extra_ids.value.map((item: any) => {
                                delete_extra_field({
                                  org_id: current_org.id,
                                  extra_field_id: item,
                                }).then(() => {});
                              });
                            }
                            event_obj.emit('refresh');
                            resolve(null);
                          })
                          .finally(() => {
                            confirmLoading.value = false;
                          });
                      },
                      onCancel() {},
                    });
                  } else if (res1 && res1.data && res1.data.length >= 2) {
                    confirmLoading.value = false;
                    notification.error({
                      message: t('温馨提示'),
                      description: t('工资类型请款单已有2个，不可继续创建'),
                    });
                  } else {
                    create_invoice({
                      ...modifyModal.form.model,
                    })
                      .then((res: any) => {
                        if (res.message) {
                          message.error(res.message);
                        } else {
                          message.success(t('创建成功'));
                        }
                        emit('ok');
                        if (extra_ids.value.length > 0) {
                          extra_ids.value.map((item: any) => {
                            delete_extra_field({
                              org_id: current_org.id,
                              extra_field_id: item,
                            }).then(() => {});
                          });
                        }
                        event_obj.emit('refresh');
                        resolve(null);
                      })
                      .finally(() => {
                        confirmLoading.value = false;
                      });
                  }
                });
              } else {
                create_invoice({
                  ...modifyModal.form.model,
                })
                  .then((res: any) => {
                    if (res.message) {
                      message.error(res.message);
                    } else {
                      message.success(t('创建成功'));
                    }
                    emit('ok');
                    if (extra_ids.value.length > 0) {
                      extra_ids.value.map((item: any) => {
                        delete_extra_field({
                          org_id: current_org.id,
                          extra_field_id: item,
                        }).then(() => {});
                      });
                    }
                    event_obj.emit('refresh');
                    resolve(null);
                  })
                  .finally(() => {
                    confirmLoading.value = false;
                  });
              }
            }
          });
        }
      });
    };
    const add_custom_field = () => {
      if (cardDataSource.length >= 6) {
        notification.error({
          message: t('提示'),
          description: t('请款单自定义项最多6个'),
        });
      } else {
        cardDataSource.push({
          custom_field_name: null,
          custom_field_value: null,
          extra_id: null,
          is_show: true,
        });
      }
    };
    const del_custom_field = (index: any) => {
      if (cardDataSource[index].extra_id) {
        extra_ids.value.push(cardDataSource[index].extra_id);
      }
      cardDataSource.splice(index, 1);
    };
    return {
      message,
      t,
      state,
      modifyModal,
      confirmLoading,
      is_show_options,
      validateInfos,
      add_custom_field,
      del_custom_field,
      handleCancel,
      handleOk,
    };
  },
  components: {
    PlusOutlined,
    CloseCircleFilled,
  },
});
