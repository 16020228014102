import request from '@/utils/request';
import { PageResult } from '@/api/typing';

export async function queryMenu(params?: { [key: string]: any }): Promise<PageResult> {
  const res: PageResult = await request.post('/permission/query_menu_list', params);
  const parent_menu_list: Array<any> = [];
  const sub_menu_list: Array<any> = [];
  res.data.forEach((item: any) => {
    if (!item.parent) {
      item.key = item.id;
      item.value = item.id;
      item.title = item.name;
      parent_menu_list.push(item);
    } else {
      item.key = item.id;
      item.value = item.id;
      item.title = item.name;
      sub_menu_list.push(item);
    }
  });

  parent_menu_list.forEach(item => {
    if (!item.children) {
      item.children = [];
    }
    sub_menu_list.forEach(obj => {
      if (obj.parent?.id == item.id) {
        item.children.push(obj);
      }
    });
  });
  res.data = parent_menu_list;
  res.total = parent_menu_list.length;
  return res;
}

export async function queryMenu2(params?: { [key: string]: any }): Promise<PageResult> {
  const res: PageResult = await request.post('/permission/query_menu_list', params);
  const return_menu: any[] = [];
  const parent_menu_dict: any = {};
  res.data.forEach((item: any) => {
    item.key = item.id;
    item.value = item.id;
    item.title = item.name;
    if (!item.parent) {
      return_menu.push(item);
    } else {
      const f = parent_menu_dict[item.parent.id];
      if (!f) {
        parent_menu_dict[item.parent.id] = [item];
      } else {
        parent_menu_dict[item.parent.id].push(item);
      }
    }
  });
  return_menu.forEach((top_parent: any) => {
    if (parent_menu_dict[top_parent.id]) {
      top_parent.children = parent_menu_dict[top_parent.id];
      top_parent.children.forEach((item: any) => {
        if (parent_menu_dict[item.id]) {
          item.children = parent_menu_dict[item.id];
          item.children = item.children.sort((a: any, b: any) => a.index - b.index);
        }
      });
    } else {
      top_parent.children = [];
    }
    top_parent.children = top_parent.children.sort((a: any, b: any) => a.index - b.index);
  });
  if (return_menu.length != 0) {
    res.data = return_menu;
    res.total = return_menu.length;
  } else {
    res.total = res.data.length;
  }
  return res;
}

export async function removeMenu(params: Record<string, any>) {
  return request.post('/permission/delete_menu', params);
}

export async function addMenu(params: Record<string, any>) {
  return request.post('/permission/create_menu', params);
}

export async function updateMenu(params: Record<string, any>) {
  return request.post('/permission/update_menu', params);
}

export async function recoverMenu(params: Record<string, any>) {
  return request.post('/permission/recover_menu', params);
}
export async function query_menu_list(params: Record<string, any>) {
  return request.post('/permission/query_menu_list', params);
}
export async function query_my_menu(params: Record<string, any>) {
  return request.post('/permission/query_my_menu', params);
}
