<template>
  <div :class="classNames">
    <grid-content>
      <div v-if="hasChildren" :class="`${prefixedClassName}-children-content`">
        <slot />
      </div>
    </grid-content>
  </div>
</template>
<script lang="ts">
import {
  defineComponent,
  h,
  ref,
  toRefs,
  PropType,
  VNodeChild,
  withCtx,
  createTextVNode,
  reactive,
  onMounted,
} from 'vue';
import { useProProvider } from '../pro-provider';
import GridContent from '@/components/base-layouts/grid-content/index.vue';
import PageHeaderContent from '@/components/base-layouts/page-container/page-header-content.vue';
import { injectMenuState } from '@/layouts/use-menu-state';
import { RouterLink } from 'vue-router';

export interface Tab {
  key: string;
  tab: string | VNodeChild;
}

export interface Route {
  path: string;
  breadcrumbName: string;
  children: Array<{
    path: string;
    breadcrumbName: string;
  }>;
}

export interface BreadcrumbItemRender {
  route: Route;
  params: Record<any, any>;
  routes: Route[];
  paths: string[];
}

export default defineComponent({
  name: 'PageContainer3',
  props: {
    // 注意：tabList 优先级低于 v-slot:footer
    tabList: {
      type: Array as PropType<Tab[]>,
      default: (): Tab[] => [],
    },
    // 支持 v-model:tabActiveKey
    tabActiveKey: {
      type: String,
      default: (): string | unknown => null,
    },
    title: {
      type: [String, Boolean],
      default: false,
    },
    subTitle: {
      type: [String, Boolean],
      default: false,
    },
    ghost: {
      type: Boolean,
      default: false,
    },
    back: {
      type: Function,
      default: undefined,
    },
    prefixCls: {
      type: String,
      default: 'ant-pro',
    },
    showBreadcrumb: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['tab-change', 'update:tab-active-key'],
  setup(props, { attrs, slots, emit }) {
    const config = useProProvider();
    const { breadcrumb } = injectMenuState();
    const { getPrefixCls, contentWidth } = toRefs(config);
    const prefixedClassName = getPrefixCls.value('page-container');
    const classNames = ref({
      [prefixedClassName]: true,
      [`${prefixedClassName}-ghost`]: props.ghost,
    });
    // 当未设置 back props 或未监听 @back，不显示 back
    // props 的 back 事件优先级高于 @back，需要注意
    const onBack: any = props.back || attrs.onBack;
    const handleBack =
      (onBack &&
        ((): void => {
          // this.$emit('back')
          // call props back func
          onBack();
        })) ||
      undefined;
    const handleTabChange = (activeKey: string): void => {
      emit('tab-change', activeKey);
      emit('update:tab-active-key', activeKey);
    };

    const {
      default: hasChildren,
      extra: hasExtra,
      footer: hasFooter,
      content: hasContent,
      extraContent: hasExtraContent,
      tags: hasTags,
    } = slots;

    // 自定义面包屑渲染逻辑
    const customBreadcrumbRender = ({ route, params, routes }: BreadcrumbItemRender) => {
      const breadcrumbName = route.breadcrumbName;
      return (
        (routes.indexOf(route) === routes.length - 1 &&
          h('span', null, { default: withCtx(() => [createTextVNode(breadcrumbName)]) })) ||
        h(
          RouterLink,
          { to: { path: route.path || '/', params } },
          { default: withCtx(() => [createTextVNode(breadcrumbName)]) },
        )
      );
    };

    const tableSize = reactive({ y: window.innerHeight - 90 });
    onMounted(() => {
      window.onresize = function () {
        tableSize.y = window.innerHeight - 90;
      };
    });

    return {
      breadcrumb,
      hasChildren,
      hasExtra,
      hasFooter,
      hasContent,
      hasExtraContent,
      hasTags,

      classNames,
      prefixedClassName,
      contentWidth,

      handleBack,
      handleTabChange,

      customBreadcrumbRender,
      config,
      tableSize,
    };
  },
  components: {
    [GridContent.name]: GridContent,
    PageHeaderContent,
  },
});
</script>
<style lang="less">
.page-box {
  height: 100%;
}

.page-box-children {
  height: 100%;
  overflow-y: hidden;
}
</style>
