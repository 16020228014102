import { reactive } from 'vue';
import modalBox from '@/components/form-modal/modal-tools';
import {
  create_payslip_pdf,
  modify_payslip_mangefee,
  query_adviser_list,
  query_contract_list,
  query_payslip_list,
  send_payslip_by_email,
} from '@/api/cerp-model';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import { message, Modal } from 'ant-design-vue';
import { notification } from 'ant-design-vue/es';

export const btn_create_payslip = {
  cb: (record: any) => {
    console.log('record', record);

    const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
    query_contract_list({
      org_id: current_org?.id,
      ids: [record.contract_id],
    }).then((res: any) => {
      if (res && res.data && res.data.length > 0) {
        record.contract__is_double_upload = res.data[0].is_double_upload;
      }
      query_adviser_list({
        org_id: current_org?.id,
        ids: [record.adviser_id],
      }).then((res1: any) => {
        if (res1 && res1.data && res1.data.length > 0) {
          record.adviser__cc_bank_account_id = res1.data[0].cc_bank_account_id;
          record.adviser__pay_currency_id = res1.data[0].pay_currency_id;
        }
        if (record.contract__is_double_upload) {
          const modifyModal: any = reactive({
            visible: false,
            component: 'PayslipModal',
            type: '',
            obj: {
              ...record,
            },
          });
          modifyModal.visible = true;
          modifyModal.ok = (id: number) => {
            query_payslip_list({
              org_id: current_org?.id,
              ids: [id],
            }).then((res: any) => {
              record.is_payslip = true;
              Object.assign(record, res.data[0]);
            });
          };
          modifyModal.cancel = () => {};
          modalBox(modifyModal);
        } else {
          notification.error({
            message: '温馨提示',
            description: '双方合同没有确认上传！',
          });
        }
      });
    });
  },
};
// export const btn_create_bank = {
//   cb: (record: any) => {
//     const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
//     const editModal = reactive({
//       visible: true,
//       title: '付款（银行流水）',
//       loading: false,
//       form: {
//         settings: {
//           labelAlign: 'right',
//           layout: 'vertical',
//           col: [],
//         },
//         fields: [
//           {
//             type: 'input',
//             name: 'name',
//             label: '名称',
//             disabled: false,
//             allowClear: true,
//             inputType: 'text',
//             defaultValue: '',
//             placeholder: '请填写名称',
//           },
//           {
//             type: 'input',
//             name: 'description',
//             label: '描述',
//             disabled: false,
//             allowClear: true,
//             inputType: 'text',
//             defaultValue: '',
//             placeholder: '请填写描述',
//           },
//           {
//             type: 'money',
//             name: 'amount',
//             label: '总金额',
//             disabled: false,
//             allowClear: true,
//             inputType: 'number',
//             defaultValue: '',
//             placeholder: '请填写总金额',
//           },
//           {
//             type: 'datepicker',
//             name: 'date',
//             label: '日期',
//             disabled: false,
//             allowClear: true,
//             inputType: 'number',
//             defaultValue: '',
//             showTime: false,
//             valueFormat: 'YYYY-MM-DD',
//             placeholder: '请填写日期',
//           },
//           {
//             type: 'select',
//             name: 'transaction_type',
//             label: '流水种类',
//             mode: 'default',
//             disabled: false,
//             defaultValue: '',
//             labelKey: 'name',
//             valueKey: 'value',
//             placeholder: '请选择流水种类',
//             datasource: 'transaction_type',
//           },
//           {
//             type: 'remoteselect',
//             name: 'currency_id',
//             labelKey: 'currency',
//             label: '币种',
//             disabled: false,
//             placeholder: '请选择薪资币种',
//             datasourceType: 'remote',
//             mode: 'default',
//             allowClear: true,
//             modalType: 'baseinfo-country',
//           },
//           {
//             type: 'remoteselect',
//             name: 'payment_method_id',
//             label: '付款方式',
//             disabled: false,
//             placeholder: '请选择付款方式',
//             datasourceType: 'remote',
//             mode: 'default',
//             allowClear: true,
//             labelKey: 'name',
//             valueKey: 'id',
//             modalType: 'baseinfo-paymentmethod',
//           },
//         ],
//         rules: {
//           name: [{ required: true, message: '请填输入名称' }],
//           amount: [{ required: true, message: '请填输入总金额' }],
//           transaction_type: [{ required: true, message: '请填输入流水种类' }],
//         },
//         model: reactive({
//           org_id: current_org?.id,
//           is_active: true,
//           name: null,
//           description: null,
//           amount: null,
//           currency_id: null,
//           date: moment().format('YYYY-MM-DD HH:mm:ss'),
//           transaction_type: '支出',
//           cc_bank_account_id: null,
//         }),
//       },
//       options: {
//         transaction_type: [
//           { value: '收入', name: '收入' },
//           { value: '支出', name: '支出' },
//         ],
//       },
//       ok: (payload: any) => {
//         return new Promise(resolve => {
//           editModal.loading = true;
//           create_bank_transaction(payload)
//             .then((res: any) => {
//               return pay_payslip({
//                 org_id: current_org?.id,
//                 transaction_id: res?.id,
//                 payslip_id: record.id,
//               });
//             })
//             .then((res: any) => {
//               return query_payslip_list({
//                 org_id: current_org?.id,
//                 ids: [record.id],
//               });
//             })
//             .then((res1: any) => {
//               message.success('付款成功');
//               resolve(null);
//               Object.assign(record, res1.data[0]);
//             })
//             .finally(() => {
//               editModal.loading = false;
//             });
//         });
//       },
//     });
//     modalBox(editModal);
//   },
// };
export const btn_create_pdf = {
  cb: (record: any, t: any) => {
    const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
    Modal.confirm({
      title: t('提示'),
      content: '是否生成工资单PDF',
      onOk() {
        return new Promise(resolve => {
          create_payslip_pdf({ org_id: current_org?.id, payslip_id: record.id })
            .then(() => {
              return query_payslip_list({
                org_id: current_org?.id,
                ids: [record.id],
              });
            })
            .then((res: any) => {
              message.success('生成工资单PDF成功');
              Object.assign(record, res.data[0]);
              resolve(null);
            });
        });
      },
      onCancel() {},
    });
  },
};
const times: any = [];

export const clear = () => {
  times.forEach((item: any) => {
    clearInterval(item);
  });
};
export const btn_create_send = {
  cb: (record: any, t: any) => {
    const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
    Modal.confirm({
      title: t('提示'),
      content: '是否发送邮件',
      onOk() {
        return new Promise(resolve => {
          send_payslip_by_email({ org_id: current_org?.id, payslip_id: record.id }).then(() => {
            message.success('邮件发送成功');
            resolve(null);
            const time = setInterval(() => {
              query_payslip_list({
                org_id: current_org?.id,
                ids: [record.id],
              }).then((res: any) => {
                Object.assign(record, res.data[0]);
                if (res.data[0].status == 2) {
                  clearInterval(time);
                  times.remove(time);
                }
              });
            }, 1000);
            times.push(time);
          });
        });
      },
      onCancel() {},
    });
  },
};
export const btn_update_payslip = {
  cb: (record: any) => {
    const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
    const modifyModal: any = reactive({
      visible: false,
      component: 'PayslipModal',
      type: 'update',
      obj: {
        ...record,
      },
    });
    modifyModal.visible = true;
    modifyModal.ok = () => {
      query_payslip_list({
        org_id: current_org?.id,
        ids: [record.id],
      }).then((res: any) => {
        Object.assign(record, res.data[0]);
      });
    };
    modifyModal.cancel = () => {};
    modalBox(modifyModal);
  },
};
export const btn_refresh_payslip = {
  cb: (record: any) => {
    const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
    record.is_refresh_loading = true;
    query_payslip_list({
      org_id: current_org?.id,
      ids: [record.id],
    }).then((res: any) => {
      message.success('刷新成功');
      record.is_refresh_loading = false;
      Object.assign(record, res.data[0]);
    });
  },
};
export const btn_contract = {
  cb: (record: any) => {
    const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
    query_contract_list({
      org_id: current_org.id,
      ids: [record.invoice__contract_id],
    }).then((res: any) => {
      const modifyModal: any = reactive({
        visible: false,
        component: 'PreviewModal',
        type: 'contract',
        obj: {
          ...res.data[0],
        },
      });
      modifyModal.visible = true;
      modalBox(modifyModal);
    });
  },
};
export const btn_manage_payslip = {
  cb: (record: any) => {
    const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
    const editModal = reactive({
      visible: true,
      title: '修改工资单管理费',
      loading: false,
      form: {
        settings: {
          labelAlign: 'right',
          layout: 'vertical',
          col: [],
        },
        fields: [
          {
            type: 'input',
            name: 'payslip_no',
            label: '工资单',
            disabled: true,
            allowClear: true,
            inputType: 'text',
            defaultValue: '',
            placeholder: '请填写工资单',
          },
          {
            type: 'input',
            name: 'management_fee',
            label: '管理费',
            label_i18n: '管理费',
            placeholder_i18n: '请填写管理费',
            disabled: false,
            allowClear: true,
            inputType: 'text',
            defaultValue: '',
            placeholder: '请填写管理费',
          },
        ],
        rules: {
          management_fee: [{ required: true, message: '请填写管理费' }],
        },
        model: {
          org_id: current_org?.id,
          is_active: true,
          payslip_id: record.id,
          payslip_no: record.payslip_no,
          management_fee: record.management_fee,
        },
      },
      options: {},
      ok: (payload: any) => {
        return new Promise(resolve => {
          editModal.loading = true;
          modify_payslip_mangefee(payload)
            .then(() => {
              return query_payslip_list({
                org_id: current_org?.id,
                ids: [record.id],
              });
            })
            .then((res: any) => {
              message.success('修改工资单管理费成功');
              Object.assign(record, res.data[0]);
              resolve(null);
            })
            .finally(() => {
              editModal.loading = false;
            });
        });
      },
    });
    modalBox(editModal);
  },
};
