<template>
  <a-button
    type="primary"
    size="small"
    @click.stop="() => handleOpenEdit(record)"
    v-if="record.is_active && titleList.is_update && !column_dropdown_btn_dict"
  >
    {{ t('修改') }}
  </a-button>
  <a-button
    v-for="btn in column_normal_btn_list"
    size="small"
    v-show="btn?.is_show ? btn.is_show(record) : true"
    :key="btn.name"
    :type="btn.clazz"
    style="margin-left: 1px;margin-right: 1px"
    @click.stop="btn.cb(record, dataSource, index, requestParams, t)"
  >
    {{ (t && t(`${btn.name}`)) || btn.name }}
  </a-button>
  <a-button
    size="small"
    danger
    @click.stop="() => handleDelete(record)"
    v-if="record.is_active && titleList.is_remove && !column_dropdown_btn_dict"
  >
    {{ titleList.del_btn ? titleList.del_btn : t('删除') }}
  </a-button>

  <a-button
    size="small"
    type="dashed"
    @click.stop="() => handleRecover(record)"
    v-if="!record.is_active && titleList.is_recover && !column_dropdown_btn_dict"
  >
    {{ t('恢复') }}
  </a-button>
  <template v-if="column_dropdown_btn_dict">
    <a-dropdown-button
      @click.stop="column_dropdown_btn_dict.dropdown_btn.cb(record, dataSource, index, requestParams, t)"
      size="small"
    >
      <a-button
        :disabled="
          column_dropdown_btn_dict.dropdown_btn?.is_show
            ? !column_dropdown_btn_dict.dropdown_btn.is_show(record)
            : false
        "
        size="small"
        type="link"
        @click.stop="
          column_dropdown_btn_dict.dropdown_btn.cb(record, dataSource, index, requestParams, t)
        "
      >
        <span
          :style="column_dropdown_btn_dict.dropdown_btn?.is_show ? (!column_dropdown_btn_dict.dropdown_btn.is_show(record) ? 'color: #b8b8b8' : 'color: #252525') : 'color: #252525'"
        >
           {{ (t && t(`${column_dropdown_btn_dict.dropdown_btn.name}`)) || column_dropdown_btn_dict.dropdown_btn.name }}
        </span>
      </a-button>
      <template #overlay>
        <a-menu>
          <a-menu-item
            :key="btn.name"
            :disabled="btn?.is_show ? !btn.is_show(record) : false"
            @click.stop="btn.cb(record, dataSource, index, requestParams, t)"
            v-for="btn in column_dropdown_btn_dict.btn_list"
          >
            {{ (t && t(`${btn.name}`)) || btn.name }}
          </a-menu-item>

          <a-menu-item
            key="update"
            v-if="record.is_active && titleList.is_update"
            @click.stop="handleOpenEdit(record)"
          >
            <edit-outlined />
            {{ t('修改') }}
          </a-menu-item>
          <a-menu-item
            key="update"
            v-if="record.is_active && titleList.is_remove"
            @click.stop="handleDelete(record)"
          >
            <delete-outlined />
            {{ t('删除') }}
          </a-menu-item>
          <a-menu-item
            key="update"
            v-if="!record.is_active && titleList.is_recover"
            @click.stop="handleRecover(record)"
          >
            <redo-outlined />
            {{ t('恢复') }}
          </a-menu-item>
        </a-menu>
      </template>
    </a-dropdown-button>
  </template>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'Cell_action',
  props: {
    column: {
      type: Object,
      required: true,
      default: () => {
      },
    },
    index: {
      type: Number,
      required: true,
    },
    text: {
      type: [String, Number],
      required: false,
    },
    record: {
      type: Object,
      required: true,
      default: () => {},
    },
    pageSize: {
      type: Number,
      required: true,
      default: () => 20,
    },
    current: {
      type: Number,
      required: true,
      default: () => 1,
    },
    dataSource: {
      type: Array,
      required: false,
      default: () => [],
    },
    titleList: {
      type: Object,
      required: true,
      default: () => {},
    },
    column_dropdown_btn_dict: {
      type: Object,
      required: false,
      default: () => {},
    },
    column_normal_btn_list: {
      type: Array,
      required: true,
      default: () => [],
    },
    handleOpenEdit: {
      type: Function,
      required: true,
    },
    handleDelete: {
      type: Function,
      required: true,
    },
    handleRecover: {
      type: Function,
      required: true,
    },
    requestParams: {
      type: Object,
      required: false,
    },
  },
  setup(props) {
    const { t } = useI18n();
    return {
      t,
    };
  },
});
</script>
<style lang="less">
.needCopyRow {
  td {
    position: relative;
  }
}
.large-font {
  font-size: 17px;
  font-weight: 500;
}
.large-number-font {
  font-size: 24px;
  font-weight: 500;
}
</style>