<template>
  <a-list item-layout="horizontal" :data-source="[]"></a-list>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'NotificationSettings',
});
</script>
