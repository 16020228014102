<template>
  <a-button size="small" type="primary" @click="add_attr">
    <template #icon>
      <plus-outlined />
    </template>
    添加一行
  </a-button>
  <a-table
    :columns="columns"
    :data-source="dataSource"
    :pagination="false"
    :scroll="{ y: 200 }"
    bordered
    class="ant-table-striped attribute-table"
    size="small"
  >
    <template v-for="col in ['name', 'value']" :key="col" #[col]="{ record }">
      <div>
        <a-input v-model:value="editableData[record.key][col]" style="margin: -5px 0" />
      </div>
    </template>
    <template #operation="{ record }">
      <div class="editable-row-operations">
        <span>
          <a-popconfirm title="确认删除?" @confirm="cancel(record.key)">
            <a>删除</a>
          </a-popconfirm>
        </span>
      </div>
    </template>
    <template #footer>共{{ dataSource.length }}个属性。</template>
  </a-table>
</template>

<script lang="ts">
import type { UnwrapRef } from 'vue';
import { defineComponent, reactive, ref, watch } from 'vue';
import { cloneDeep } from 'lodash-es';
import { PlusOutlined } from '@ant-design/icons-vue';
import { useI18n } from 'vue-i18n';


interface DataItem {
  key: string;
  name: string;
  value: string;
}

export default defineComponent({
  name: 'AttributeTable',
  props: {
    field: {
      type: Object,
      required: true,
    },

    value: {
      type: Array,
      required: true,
      default: () => [],
    },
    formModel: {
      type: Object,
      required: true,
    },
    init_model: {
      type: Object,
      default: () => {
      },
    },
  },
  emits: ['update:value'],
  setup(props, { emit }) {
    const { t } = useI18n();
    const editableData: UnwrapRef<Record<string, DataItem>> = reactive({});
    const columns = [
      {
        title: '序号',
        dataIndex: 'key',
        width: '10%',
      },
      {
        title: '属性',
        dataIndex: 'name',
        width: '40%',
        slots: { customRender: 'name' },
      },
      {
        title: '值',
        dataIndex: 'value',
        width: '40%',
        slots: { customRender: 'value' },
      },
      {
        title: t('操作'),
        dataIndex: 'operation',
        slots: { customRender: 'operation' },
      },
    ];
    const edit = (key: string) => {
      editableData[key] = cloneDeep(
        dataSource.value.filter((item: DataItem) => key === item.key)[0],
      );
    };

    const dataSource: any = ref([]);
    if (props.value && props.value.hasOwnProperty('length')) {
      props.value.map((item: any, index: number) => {
        dataSource.value.push({ key: (index + 1).toString(), ...item });
        edit((index + 1).toString());
      });
    }
    watch(() => props.value?.length,
      () => {
        dataSource.value.length = 0;
        if (props.value && props.value.hasOwnProperty('length')) {
          props.value.map((item: any, index: number) => {
            dataSource.value.push({ key: (index + 1).toString(), ...item });
            edit((index + 1).toString());
          });
        }
      });
    const cancel = (key: string) => {
      dataSource.value = dataSource.value.filter((item: DataItem) => key !== item.key);
      delete editableData[key];
    };

    const add_attr = () => {
      if (dataSource.value.length == 0) {
        dataSource.value.push({ key: '1', name: '', value: '' });
        edit('1');
      } else {
        const last: DataItem = dataSource.value[dataSource.value.length - 1];
        const key: string = (Number(last.key) + 1).toString();
        dataSource.value.push({ key: key, name: '', value: '' });
        edit(key);
      }
    };

    const handleChange = () => {
      const list = dataSource.value.map((item: DataItem) => {
        return { name: editableData[item.key].name, value: editableData[item.key].value };
      });
      emit('update:value', list);
    };

    watch(() => editableData, handleChange, {
      deep: true,
    });

    return {
      props,
      columns,
      editableData,
      dataSource,
      add_attr,
      cancel,
    };
  },

  data() {
    return {
      model: this.value,
    };
  },
  components: {
    PlusOutlined,
  },
});
</script>
<style lang="less">
.attribute-table {
  .ant-table-footer {
    padding: 5px;
  }
}
</style>
