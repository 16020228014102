import request from '@/utils/request';
import { PageResult } from '@/api/typing';

export async function set_custom_data_by_flag(params: Record<string, any>) {
  return request.post('/sys/set_custom_data_by_flag', params);
}

export async function get_custom_data_by_flag(params: Record<string, any>) {
  return request.post('/sys/get_custom_data_by_flag', params);
}

export async function set_column_setting_for_person(params: Record<string, any>) {
  return request.post('/sys/set_column_setting_for_person', params);
}


export async function query_my_column_setting_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/sys/query_my_column_setting_list', params);
}
export async function query_org_for_login_list(params?: { [key: string]: any }): Promise<PageResult> {
  return request.post('/org/query_org_for_login_list', params);
}
export async function set_column_setting_for_org(params: Record<string, any>) {
  return request.post('/sys/set_column_setting_for_org', params);
}
export async function reset_password(params: Record<string, any>) {
  return request.post('/sys/reset_password', params);
}

export async function query_all_custom_data_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/sys/query_all_custom_data_list', params);
}

export async function set_global_custom_data_by_flag(params: Record<string, any>) {
  return request.post('/sys/set_global_custom_data_by_flag', params);
}

export async function get_wechat_user_icon(params: Record<string, any>) {
  return request.post('/sys/get_wechat_user_icon', params);
}

export async function get_global_custom_data_by_flag(params: Record<string, any>) {
  return request.post('/sys/get_global_custom_data_by_flag', params);
}

export async function query_all_global_custom_data_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/sys/query_all_global_custom_data_list', params);
}
export async function query_all_global_custom_data_by_flags_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/sys/query_all_global_custom_data_by_flags_list', params);
}
export async function query_user_list(params?: { [key: string]: any }): Promise<PageResult> {
  return request.post('/sys/query_user_list', params);
}
export async function query_person_for_cmes_list(params?: { [key: string]: any }): Promise<PageResult> {
  return request.post('/sys/query_person_for_cmes_list', params);
}

export async function query_user_for_service_list(params?: { [key: string]: any }): Promise<PageResult> {
  return request.post('/sys/query_user_for_service_list', params);
}
