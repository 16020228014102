<template>
  <a-dropdown
    v-if="currentUser && currentUser.nickname"
    class="ant-pro-dropdown ant-pro-dropdown-action"
    placement="bottomRight"
    overlayClassName="pro-components-header-dropdown-index-container"
  >
    <span>
      <!--      <a-avatar size="small" :src="currentUser.avatar" class="ant-pro-header-account-avatar" />-->
      <span class="ant-pro-header-account-name anticon" style="color: #1890ff">
        {{ currentUser.nickname }}
      </span>
    </span>
    <template v-slot:overlay>
      <a-menu class="ant-pro-dropdown-menu" :selected-keys="[]">
        <!--        <a-menu-item v-if="menu" key="center" @click="handleToCenter">-->
        <!--          <user-outlined />-->
        <!--          个人中心-->
        <!--        </a-menu-item>-->
        <!--        <a-menu-item v-if="menu" key="settings" @click="handleToSettings">-->
        <!--          <setting-outlined />-->
        <!--          个人设置-->
        <!--        </a-menu-item>-->
        <a-menu-item v-if="menu" key="settings" @click="handleChangePassword">
          <setting-outlined />
          {{ t('修改密码') }}
        </a-menu-item>
<!--        <a-menu-item v-if="menu" key="settings" @click="handleToModel">-->
<!--          <setting-outlined />-->
<!--          运维登录-->
<!--        </a-menu-item>-->
<!--        <a-menu-divider v-if="menu" />-->
        <a-menu-item v-if="menu && is_super_user" key="settings" @click="handleChangeUser">
          <setting-outlined />
          {{ t('切换用户') }}
        </a-menu-item>
        <a-menu-divider v-if="menu" />
        <a-menu-item key="logout" @click="handleLogout">
          <logout-outlined />
          {{ t('退出登录') }}
        </a-menu-item>
      </a-menu>
    </template>
  </a-dropdown>
  <span v-else>
    <a-spin size="small" :style="{ marginLeft: 8, marginRight: 8 }" />
  </span>

  <form-modal
    v-bind="editModal"
    v-if="editModal.visible"
    :loading="editModal.loading"
    @cancel="
      () => {
        editModal.visible = false;
      }
    "
    @ok="handlerOk"
  />
  <switch-user-modal
    v-bind="changeUserModal"
    v-if="changeUserModal.visible"
    @ok="handlerSwitchOk"
    @cancel="
      () => {
        changeUserModal.visible = false;
      }
    "
  />
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, ref } from 'vue';
import { LogoutOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons-vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { ABNORMAL_DATA, CHECK_IS_SUPER_USER, LOGIN_YUN_WEI, LOGOUT, UNFINISH_LIST } from '@/store/modules/user/actions';
import FormModal from '@/components/form-modal/form-modal.vue';
import SwitchUserModal from '@/components/switch-user-modal.vue';
import { message, notification } from 'ant-design-vue';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import { change_password } from '@/api/sys/';
import modalBox from '@/components/form-modal/modal-tools';
import moment from 'moment';

export default defineComponent({
  name: 'AvatarDropdown',
  props: {
    currentUser: {
      type: Object,
      default: () => null,
    },
    menu: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const { t } = useI18n();
    const current_org = store.getters['user/current_org'];
    const is_super_user = ref(false);

    const edit_model_config = {
      settings: {
        name: '表单名称',
        labelAlign: 'right',
        layout: 'vertical',
      },
      fields: [
        {
          type: 'input',
          name: 'username',
          label: '账号',
          label_i18n: '账号',
          placeholder_i18n: '请填写账号',
          disabled: false,
          allowClear: true,
          inputType: 'text',
          maxlength: 100,
          defaultValue: '',
          placeholder: '请填写角色名称',
        },
        {
          type: 'input',
          name: 'password',
          label: '密码',
          disabled: false,
          allowClear: true,
          inputType: 'text',
          defaultValue: '',
          placeholder: '请填写角色描述',
        },
      ],
      rules: {
        username: [{ required: true, message: '账号必须填写' }],
        password: [{ required: true, message: '密码必须填写' }],
      },
      model: {
        username: '',
        password: '',
      },
    };

    const editModal = reactive({
      visible: false,
      loading: false,
      title: '运维登录',
      form: edit_model_config,
      options: {},
    });
    const changeUserModal = reactive({
      visible: false,
      title: '切换用户',
    });
    const handleToCenter = () => {
      router.push({ path: '/account/center' });
    };
    const handleToSettings = () => {
      router.push({ path: '/account/settings' });
    };
    const handleLogout = () => {
      store.dispatch(`user/${LOGOUT}`).then(() => {
        location.href = '/';
      });
    };
    const handleToModel = () => {
      editModal.visible = true;
    };
    const handleChangeUser = () => {
      changeUserModal.visible = true;
    };

    const handlerOk = (data: any) => {
      // e.preventDefault();
      editModal.loading = true;
      store
        .dispatch(`user/${LOGIN_YUN_WEI}`, {
          ...data,
          org_id: current_org.id,
        })
        .then(() => {
          message.success('登录运维成功');
          editModal.visible = false;
          editModal.loading = false;
        });
    };
    const handlerSwitchOk = () => {
      changeUserModal.visible = false;
      message.success('切换用户成功');
      // store.dispatch(`user/${GET_INFO}`).then(()=>{
      location.href = '/';
      // });
    };
    const handleChangePassword = () => {
      const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
      const editModal = reactive({
        visible: true,
        loading: false,
        title: '修改密码',
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'horizontal',
          },
          fields: [
            {
              type: 'password',
              name: 'oldpassword',
              label: '旧密码',
              disabled: false,
              allowClear: true,
              inputType: 'number',
              defaultValue: '',
            },
            {
              type: 'password',
              name: 'newpassword',
              label: '新密码',
              disabled: false,
              allowClear: true,
              inputType: 'number',
              defaultValue: '',
            },
            {
              type: 'password',
              name: 'newpassword2',
              label: '确认密码',
              disabled: false,
              allowClear: true,
              inputType: 'number',
              defaultValue: '',
            },
          ],
          rules: {
            oldpassword: [{ required: true, message: '请输入旧密码' }],
            newpassword: [{ required: true, message: '请输入新密码' }],
          },
          model: reactive({
            org_id: current_org?.id,
            is_active: true,
            oldpassword: null,
            newpassword: null,
            newpassword2: null,
          }),
        },
        options: {},
        ok: (payload: any) => {
          if (payload.newpassword !== payload.newpassword2) {
            notification.error({
              message: '输入数值错误',
              description: '两次输入密码不一致',
            });
            return false;
          }
          return new Promise(resolve => {
            editModal.loading = true;
            change_password(payload)
              .then(() => {
                message.success('修改密码成功');
                resolve(null);
              })
              .finally(() => {
                editModal.loading = false;
              });
          });
        },
      });
      modalBox(editModal);
    };
    onMounted(() => {
      store
        .dispatch(`user/${CHECK_IS_SUPER_USER}`, {
          org_id: current_org?.id,
        })
        .then(() => {
          is_super_user.value = store.getters['user/is_super_user'];
        });
      store
        .dispatch(`user/${UNFINISH_LIST}`, {
          org_id: current_org?.id,
          t: t,
        })
        .then(() => {
        });
      store
        .dispatch(`user/${ABNORMAL_DATA}`, {
          org_id: current_org?.id,
          t: t,
          time_interval: [moment().subtract(7, 'day').format('YYYY-MM-DD HH:mm:ss'), moment().format('YYYY-MM-DD HH:mm:ss')],
        })
        .then(() => {
        });
    });

    return {
      t,
      editModal,
      changeUserModal,
      handlerSwitchOk,
      is_super_user,
      router,

      handleChangeUser,
      handleToCenter,
      handleToSettings,
      handleLogout,
      handleToModel,
      handlerOk,
      handleChangePassword,
    };
  },
  components: {
    UserOutlined,
    SettingOutlined,
    LogoutOutlined,
    FormModal,
    SwitchUserModal,
  },
});
</script>

<style lang="less">
@import './header-dropdown.less';

.ant-pro-header-account-name {
  vertical-align: unset;
}

.ant-pro-header-account-avatar {
  margin: 12px 8px 12px 0;
  color: @primary-color;
  vertical-align: top;
  background: hsla(0, 0%, 100%, 0.85);
}
</style>
