<template>
  <a-button
    style="padding-left: 1px"
    type="link"
    :size="'small'"
    @click="column.cb(record, text)"
  >
                  <span v-if="column.content" :style="column.rt ? column.rt(record, text) : ''">
                    {{ column.content(record, text) }}
                  </span>
    <span v-else :style="column.rt ? column.rt(record, text) : ''">
                    {{ text }}
                  </span>
  </a-button>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
export default defineComponent({
  name: 'Cell_click1',
  props: {
    column: {
      type: Object,
      required: true,
      default: () => {},
    },
    index: {
      type: Number,
      required: true,
    },
    text: {
      type: [String, Number],
      required: true,
    },
    record: {
      type: Object,
      required: true,
      default: () => {},
    },
    pageSize: {
      type: Number,
      required: true,
      default: () => 20,
    },
    current: {
      type: Number,
      required: true,
      default: () => 1,
    },
  },
});
</script>
