import { queryGroupByOrgList } from '@/api/permission/user-manage';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';

const org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
const search_config: any = {
  model: {
    org_id:org?.id,
    page_index:1,
    page_size:50,
    is_active:true,
  },
  watch: [],
};

export default () => {
  return {
    getData: queryGroupByOrgList,
    searchConfig: search_config,
  };
};
