import request from '@/utils/request';
import { PageResult } from '@/api/typing';
import {query_email_template_list} from "@/api/baseinfo-model";
import ls from "@/utils/local-storage";
import {STORAGE_CURRENT_ORG_KEY} from "@/store/mutation-type";

export async function create_service_session(params: Record<string, any>) {
  return request.post('/service/create_service_session', params);
}
export async function create_session_record_for_adviser(params: Record<string, any>) {
  return request.post('/service/create_session_record_for_adviser', params);
}
export async function create_session_record_for_service(params: Record<string, any>) {
  return request.post('/service/create_session_record_for_service', params);
}
export async function update_session_record(params: Record<string, any>) {
  return request.post('/service/update_session_record', params);
}
export async function delete_session_record(params: Record<string, any>) {
  return request.post('/service/delete_session_record', params);
}
export async function recover_session_record(params: Record<string, any>) {
  return request.post('/service/recover_session_record', params);
}
export async function get_service_no_read_number(params: Record<string, any>) {
  return request.post('/service/get_service_no_read_number', params);
}
export async function query_session_record_list(params: Record<string, any>) {
  return request.post('/service/query_session_record_list', params);
}
export async function set_session_record_read(params: Record<string, any>) {
  return request.post('/service/set_session_record_read', params);
}
export async function update_service_session(params: Record<string, any>) {
  return request.post('/service/update_service_session', params);
}
export async function delete_service_session(params: Record<string, any>) {
  return request.post('/service/delete_service_session', params);
}
export async function recover_service_session(params: Record<string, any>) {
  return request.post('/service/recover_service_session', params);
}
export async function deliver_service_session(params: Record<string, any>) {
  return request.post('/service/deliver_service_session', params);
}
export async function close_service_session(params: Record<string, any>) {
  return request.post('/service/close_service_session', params);
}
export async function get_service_session_no_read_number(params: Record<string, any>) {
  return request.post('/service/get_service_session_no_read_number', params);
}
export async function create_service(params: Record<string, any>) {
  return request.post('/service/create_service', params);
}
export async function update_service(params: Record<string, any>) {
  return request.post('/service/update_service', params);
}
export async function delete_service(params: Record<string, any>) {
  return request.post('/service/delete_service', params);
}
export async function recover_service(params: Record<string, any>) {
  return request.post('/service/recover_service', params);
}
export async function create_services(params: Record<string, any>) {
  return request.post('/service/create_service', params);
}
export async function update_services(params: Record<string, any>) {
  return request.post('/service/update_service', params);
}

export async function query_service_session_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/service/query_service_session_list', params);
}

export async function query_service_list(
  params: Record<string, any>,
): Promise<PageResult> {
  const res: PageResult = await request.post('/service/query_service_list', params);
  const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
  const ids: any = [];
  if(res && res.data && res.data.length > 0){
    res.data.forEach((item: any) => {
      ids.push(item.email_template_id);
    })
    query_email_template_list({
      org_id: current_org.id,
      ids: ids,
    }).then((res1: any) => {
      res.data.map((item: any) => {
        res1.data.map((item1: any) => {
          if(item.email_template_id == item1.id){
            item.email_template_name = item1.name;
          }
        })
      })
    })
  }

  return res;
}

export async function query_deliver_service_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/service/query_deliver_service_list', params);
}
export async function get_service_for_login_person(params: Record<string, any>) {
  return request.post('/service/get_service_for_login_person', params);
}