const defaultSlotList = [
  {
    slotName: 'index', // 槽名
  },
  {
    slotName: 'bool',
    type: 'boolean', // 类型：'boolean' - text字段有无、(true/false)
    name1: '是', // text字段有或true 展示
    name2: '否', // text字段无或false 展示
  },
  {
    slotName: 'is_bank_account_intact',
    type: 'boolean', // 类型：'boolean' - text字段有无、(true/false)
    name1: '是', // text字段有或true 展示
    name2: '否', // text字段无或false 展示
  },
  {
    slotName: 'is_active',
    type: 'boolean', // 类型：'boolean' - text字段有无、(true/false)
    name1: '可用', // text字段有或true 展示
    name2: '禁用', // text字段无或false 展示
  },
  {
    slotName: 'time',
    type: 'time', // 类型：'time' - text字段为时间
  },
  {
    slotName: 'money',
    type: 'money', // 类型：'number' - text字段为钱
  },
  {
    slotName: 'month',
    type: 'month', // 类型：'time' - text字段为时间
  },
  {
    slotName: 'datetime',
    type: 'datetime', // 类型：'time' - text字段为时间
  },
  {
    slotName: 'date',
    type: 'date', // 类型：'date' - text字段为日期
  },
  {
    slotName: 'full_date',
    type: 'full_date', // 类型：'date' - text字段为日期
  },
  {
    slotName: 'percent',
    type: 'percent', // 类型：'date' - text字段为日期
  },
  {
    slotName: 'toFixed2',
    type: 'toFixed2', // 类型：'date' - text字段为日期
  },
  {
    slotName: 'list',
    type: 'list', // 类型：'list' - text字段为数组
  },
  {
    slotName: 'remark',
    type: 'remark', // 类型：'list' - text字段为数组
  },
  {
    type: 'custom',
    slotName: 'insurance_type',
    field_0: '需要',
    field_1: '不需要–顾问在本地',
    field_2: '不需要–顾问自己有保单',
    field_3: '不需要–顾问公司保险',
  },
];
export default defaultSlotList;
