
import { defineComponent, computed, toRefs, PropType, ref } from 'vue';
import { useProProvider } from '../pro-provider/index';
import BaseMenu, { BaseMenuProps } from '../base-menu/index.vue';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons-vue';
import { findMenuChildren } from '@/utils/menu-util';
import { useRoute } from 'vue-router';
import { LayoutType } from '../typing';

const SiderMenuProps = Object.assign({}, BaseMenuProps, {
  prefixCls: {
    type: String,
    default: () => undefined,
  },
  breakpoint: {
    type: String,
    default: 'lg',
  },
  siderWidth: {
    type: Number,
    default: 208,
  },
  splitMenus: {
    type: Boolean,
    default: false,
  },
  collapsedButton: {
    type: Boolean,
    default: () => true,
  },
  collapsedWidth: {
    type: Number,
    default: 48,
  },
  headerHeight: {
    type: Number,
    default: 48,
  },
  theme: {
    type: String,
    default: () => undefined,
  },
  layout: {
    type: String as PropType<LayoutType>,
    default: 'side',
  },
  fixed: {
    type: Boolean,
    default: () => false,
  },
  collapsible: {
    type: Boolean,
    default: false,
  },
  collapsed: {
    type: Boolean,
    default: false,
  },
  customItem: {
    type: Function,
    default: undefined,
  },
});

export default defineComponent({
  name: 'SiderMenu',
  props: SiderMenuProps,
  inheritAttrs: false,
  emits: [
    'update:openKeys',
    'update:selectedKeys',
    'update:collapsed',
    'mouseenter',
    'mouseleave',
    'itemHover',
  ],
  setup(props, { emit }) {
    const {
      prefixCls: propPrefixCls,
      theme,
      layout,
      collapsed,
      collapsedWidth,
      siderWidth,
      splitMenus,
    } = toRefs(props);
    const route = useRoute();
    const { i18n, getPrefixCls } = useProProvider();
    const prefixCls = propPrefixCls.value || getPrefixCls('sider');
    const is_logo: any = ref(false);
    const str: any = window.location.host;
    if(str == 'prueba.cmes.work'){
      is_logo.value = true;
    }
    const isMix = computed(() => layout.value === 'mix');
    const runtimeTheme = computed(() => (layout.value === 'mix' && 'light') || theme.value);
    const runtimeSideWidth = computed(() =>
      collapsed.value ? collapsedWidth.value : siderWidth.value,
    );
    const computedMenus = computed(() =>
      splitMenus.value ? findMenuChildren(props.menus, route.matched[1].name) : props.menus,
    );

    const handleSelectedKeys = (selectedKeys: string[]): void => {
      emit('update:selectedKeys', selectedKeys);
    };
    const handleOpenKeys = (openKeys: string[]): void => {
      emit('update:openKeys', openKeys);
    };
    const handleCollapse = () => {
      emit('update:collapsed', !collapsed.value);
    };
    return {
      i18n,
      prefixCls,
      isMix,
      runtimeTheme,
      runtimeSideWidth,
      computedMenus,
      is_logo,

      handleSelectedKeys,
      handleOpenKeys,
      handleCollapse,
    };
  },
  components: {
    BaseMenu,
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    // [Menu.name]: Menu,
    // [Menu.Item.name]: Menu.Item,
  },
});
