import { query_month_work_hour_list, update_month_work_hour_data } from '@/api/cerp-model';
import { TableColumn } from '@/typing';
import { Record, SearchConfig } from '@/components/page-model/typing';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import {
  btn_list,
  config,
  event_obj,
  head_btn_list,
  slotList,
} from '@/components/page-model2/cerp/cerp-month_work_hour_list-config';

import { reactive } from 'vue';
import { getDate, getDateTime } from '@/utils/function';
import moment from 'moment';
import notification from '@/views/account/settings/pages/notification.vue';

import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const None = null;
const True = true;
const False = false;

const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org

const search_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'inline',
  },
  fields: [],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
  }),
};
const search_options = {
  is_active: [
    { value: None, name: '全部' },
    { value: True, name: '可用' },
    { value: False, name: '禁用' },
  ],
};

const edit_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [
    {
      type: 'input',
      name: 'note',
      label: '记录',
      label_i18n: '记录',
      placeholder_i18n: '请填写记录',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写记录',
    },
    {
      type: 'datepicker',
      name: 'date',
      label: '日期',
      label_i18n: '日期',
      placeholder_i18n: '请填写日期',
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      showTime: true,
      valueFormat: 'YYYY-MM-DD HH:mm:ss',
      placeholder: '请填写日期',
    },
    {
      type: 'remoteselect',
      name: 'note_type_id',
      label: '记录类型',
      label_i18n: '记录类型',
      placeholder_i18n: '请选择记录类型',
      disabled: false,
      placeholder: '请选择记录类型',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-notetype',
    },
    {
      type: 'input',
      name: 'object_id',
      label: '描述',
      label_i18n: '描述',
      placeholder_i18n: '请填描述',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写描述',
    },
  ],
  rules: {
    note: [{ required: true, message: '请填输入记录' }],
    object_id: [{ required: true, message: '请填输入描述' }],
  },
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    note: null,
    date: null,
    note_type_id: null,
    object_id: null,
  }),
};
const edit_model_config_options = {};

const update_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [
    {
      type: 'input',
      name: 'total_num',
      label: '当月工时数',
      label_i18n: '当月工时数',
      placeholder_i18n: '请填写当月工时数',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写当月年假天数',
    },
    {
      type: 'input',
      name: 'used_annual_leave',
      label: '当月年假天数',
      label_i18n: '当月年假天数',
      placeholder_i18n: '请填写当月年假天数',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写当月年假天数',
    },
  ],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    used_annual_leave: null,
    total_num: null,
  }),
};
const update_model_config_options = {};

const titleList = {
  title: '工时',
  addModalTitle: '新建备注',
  updateTitle: '修改备注',
  is_create: false, // 是否有【新建】按钮
  is_update: false, // 是否有【修改】按钮
  is_remove: false, // 是否有【删除】按钮
  is_recover: false, // 是否有【恢复】按钮
};

const api_fields: Record = {
  update: {
    month_work_hour_id: 'id',
  },
  delete: {
    month_work_hour_id: 'id',
  },
  recover: {
    month_work_hour_id: 'id',
  },
  updateData: {
    org_id: 'org_id',
    month_work_hour_id: 'id',
  },
};

const baseColumns: TableColumn[] = [
  {
    title: t('序号'),
    dataIndex: 'index',
    width: 50,
  },
  {
    title: t('月份'),
    width: 80,
    dataIndex: 'work_month',
    checked: true,
    content: (record: any) => {
      return moment(record.work_month).format('YYYY-MM');
    },
  },
  {
    title: t('月工时'),
    width: 60,
    dataIndex: 'total_num',
    checked: true,
  },
  {
    title: t('当月使用年假'),
    width: 105,
    dataIndex: 'used_annual_leave',
    checked: true,
  },
  {
    title: t('年假余额'),
    width: 80,
    dataIndex: 'work_hour__available_annual_leave',
    checked: true,
  },
  {
    title: t('是否锁定'),
    width: 80,
    dataIndex: 'is_lock',
    checked: true,
    cellComponent: 'boolean',
    name1: t('是'),
    name2: t('否'),
  },
  {
    title: 'PDF',
    width: 200,
    dataIndex: 'files_json',
    checked: true,
    cellComponent: 'click',
    content: (record: any) => {
      if (record.files_json) {
        return record.files_json?.name;
      } else {
        return '';
      }
    },
    cb: (record: any) => {
      if (record.files_json && record.files_json.file_url) {
        const url: string =
          'https://erp-cc.oss-cn-hongkong.aliyuncs.com/' + encodeURIComponent(record.files_json.file_url);
        window.open(url, '_blank');
        // axios.get('/' + record.files_json.file_url,{
        //   responseType: 'blob' }).then((res: any) => {
        //   var blob = res.data;
        //   // FileReader主要用于将文件内容读入内存
        //   var reader = new FileReader();
        //   reader.readAsDataURL(blob);
        //   // onload当读取操作成功完成时调用
        //   reader.onload = (e: any) => {
        //     var a = document.createElement('a');
        //     // 获取文件名fileName
        //     var fileName = res.config["url"].split("/");
        //     fileName = fileName[fileName.length - 1];
        //     // fileName = fileName.replace(/"/g, "");
        //     a.download = fileName;
        //     a.href = e.target.result;
        //     document.body.appendChild(a);
        //     a.click();
        //     document.body.removeChild(a);
        //   }
        // })
      } else {
        notification.error({
          message: t('提示'),
          description: t('无对应附件'),
        });
      }
    },
  },
  {
    title: 'Excel',
    width: 200,
    dataIndex: 'excel_files_json',
    checked: true,
    cellComponent: 'click',
    content: (record: any) => {
      if (record.excel_files_json) {
        return record.excel_files_json?.name;
      } else {
        return '';
      }
    },
    cb: (record: any) => {
      if (record.excel_files_json && record.excel_files_json.file_url) {
        const url: string =
          'https://erp-cc.oss-cn-hongkong.aliyuncs.com/' + encodeURIComponent(record.excel_files_json.file_url);
        window.open(url, '_blank');
        // axios.get('/' + record.excel_files_json.file_url,{
        //   responseType: 'blob' }).then((res: any) => {
        //   var blob = res.data;
        //   // FileReader主要用于将文件内容读入内存
        //   var reader = new FileReader();
        //   reader.readAsDataURL(blob);
        //   // onload当读取操作成功完成时调用
        //   reader.onload = (e: any) => {
        //     var a = document.createElement('a');
        //     // 获取文件名fileName
        //     var fileName = res.config["url"].split("/");
        //     fileName = fileName[fileName.length - 1];
        //     // fileName = fileName.replace(/"/g, "");
        //     a.download = fileName;
        //     a.href = e.target.result;
        //     document.body.appendChild(a);
        //     a.click();
        //     document.body.removeChild(a);
        //   }
        // })
      } else {
        notification.error({
          message: t('提示'),
          description: t('无对应附件'),
        });
      }
    },
  },
  {
    title: t('CC公司'),
    width: 200,
    dataIndex: 'work_hour__contract__cc_company__name',
    checked: true,
  },
  {
    title: t('是否当前合同'),
    width: 105,
    dataIndex: 'work_hour__contract__is_current',
    checked: true,
    cellComponent: 'boolean',
    name1: t('是'),
    name2: t('否'),
  },
  {
    title: t('合同起始日期'),
    width: 105,
    dataIndex: 'work_hour__contract__start_date',
    checked: true,
    content: (record: any) => {
      return getDate(record.work_hour__contract__start_date);
    },
  },
  {
    title: t('合同结束日期'),
    width: 105,
    dataIndex: 'work_hour__contract__end_date',
    checked: true,
    content: (record: any) => {
      return getDate(record.work_hour__contract__end_date);
    },
  },
  {
    title: t('邮件发送记录'),
    width: 150,
    dataIndex: 'email_record__log',
    checked: true,
  },
  {
    title: t('邮件发送状态'),
    width: 105,
    dataIndex: 'email_record__status',
    checked: true,
    content: (record: any) => {
      return record?.email_record__status == 1 ? t('发送成功') : record.email_record__status == 2 ? t('发送失败') : '';
    },
  },
  {
    title: t('邮件发送时间'),
    width: 120,
    dataIndex: 'email_record__update_time',
    checked: true,
    content: (record: any) => {
      return getDateTime(record.email_record__update_time);
    },
  },
  {
    title: t('PDF生成日志'),
    width: 150,
    dataIndex: 'pdf_log',
    checked: true,
  },
  {
    title: t('是否可用'),
    width: 100,
    dataIndex: 'is_active',
    checked: false,
    cellComponent: 'boolean',
    name1: t('可用'),
    name2: t('禁用'),
    filterMultiple: false,
    filters: [
      { text: t('全部'), value: '' },
      { text: t('可用'), value: true },
      { text: t('禁用'), value: false },
    ],
  },
  {
    title: t('创建时间'),
    width: 150,
    dataIndex: 'create_time',
    checked: true,
    content: (record: any) => {
      return getDateTime(record.create_time);
    },
  },
  {
    title: t('更新时间'),
    width: 150,
    dataIndex: 'update_time',
    checked: true,
    content: (record: any) => {
      return getDateTime(record.update_time);
    },
  },
  {
    title: t('操作'),
    width: 120,
    dataIndex: 'action',
    checked: true,
    cellComponent: 'action',
    fixed: 'right',
  },
];
const newSlotList: any = [];
Object.assign(newSlotList, slotList);
newSlotList.push({
  type: 'custom',
  // slotName: 'is_active',
  field_true: '可用',
  field_false: '禁用',
});

export default () => {
  return {
    searchConfig: search_config,
    searchConfigOptions: search_options,
    formModalConfig: edit_model_config,
    formModalConfigOptions: edit_model_config_options,
    updateFormModalConfig: update_model_config,
    updateFormModalConfigOptions: update_model_config_options,
    queryData: query_month_work_hour_list,
    addData: null,
    updateData: update_month_work_hour_data,
    removeData: null,
    recoverData: null,
    baseColumns: baseColumns,
    slotList: newSlotList,
    titleList: titleList,
    api_fields: api_fields,
    btn_list: btn_list,
    head_btn_list: head_btn_list,
    event_obj: event_obj,
  };
};
