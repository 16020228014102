<template>
  <div @click="handleOpen">
    <slot>
      <a-tooltip :title="t('导出Excel')">
        <download-outlined title="导出Excel"></download-outlined>
      </a-tooltip>
    </slot>
  </div>
  <a-modal
    title="导出Excel"
    width="800px"
    :visible="visible"
    :maskClosable="false"

    :okText="`导出${state.selectedFileType}`"
    centered
    @ok="handleExport"
    @cancel="handleCancel"
  >
    <a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
      <a-form-item label="文件名称:">
        <a-input v-model:value="state.filename" size="small" />
      </a-form-item>
      <a-form-item label="文件类型:">
        <a-radio-group v-model:value="state.selectedFileType">
          <a-radio v-for="(item, index) in state.fileTypeList" :key="index" :value="item">
            {{ item }}
          </a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item label="导出列:">
        <!--      todo:可选导出列-->
        <a-select size="default" v-model:value="state.selectedColumns" mode="multiple">
          <a-select-option v-for="item in columnList" :key="item.key" :value="item.key">
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item>
        <!--      todo:可选导出条目-->
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script lang="ts">
import { defineComponent, reactive, ref } from 'vue';
import { exportJsonToExcel } from '@/utils/excel';
import { BookType } from 'xlsx';
import { getDate, getDateMonth, getDateTime, getTime, toFixed2 } from '@/utils/function';
import { DownloadOutlined } from '@ant-design/icons-vue';

import { useI18n } from 'vue-i18n';

type StateType = {
  selectedFileType: string;
  fileTypeList: string[];
  selectedColumns: string[] | unknown[];
  filename: string;
};
export default defineComponent({
  name: 'exportExcelModal',
  props: {
    fileType: {
      type: String,
      default: () => 'xlsx',
    },
    columnList: {
      type: Array,
      default: () => [],
    },
    filename: {
      type: String,
      default: () => 'exportFile',
    },
    datasource: {
      type: Array,
      default: () => [],
    },
    multiHeader: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => [],
    },
    slotList: {
      type: Array,
      default: () => [],
    },
    pageSize: {
      type: Number,
      default: 0,
    },
    current: {
      type: Number,
      default: 0,
    },
  },
  components: {
    DownloadOutlined,
  },
  emits: ['ok', 'cancel'],
  setup(props, { emit }) {
    const visible = ref<boolean>(false);
    const { t } = useI18n();
    const state = reactive<StateType>({
      selectedFileType: 'xlsx',
      filename: props.filename,
      selectedColumns: props.columnList
        .filter((item: any) => item.checked)
        .map((item: any) => {
          return item.key;
        }),
      fileTypeList: ['xlsx', 'csv', 'txt'],
    });

    const handleOpen = () => {
      visible.value = true;
    };
    const handleCancel = () => {
      visible.value = false;
    };

    const column_dict: any = {};
    props.columns.forEach((item: any) => {
      column_dict[item.dataIndex ? item.dataIndex : item.key] = item;
    });

    const value_compute = (rowIndex: number, text: any, record: any, key: string) => {
      if (column_dict[key]?.slots?.customRender) {
        for (let i in props.slotList) {
          const item: any = props.slotList[i];
          if (item.slotName == column_dict[key].slots?.customRender) {
            if (item.slotName === 'index') {
              return rowIndex + 1 + props.pageSize * (props.current - 1);
            } else if (item.type === 'custom') {
              return item['field_' + text];
            } else if (item.type === 'custom1') {
              return item.content(record);
            } else if (item.type === 'datetime') {
              return getDateTime(text);
            } else if (item.type === 'date') {
              return getDate(text);
            } else if (item.type === 'date_month') {
              return getDateMonth(text);
            } else if (item.type === 'month') {
              return getDateMonth(text);
            } else if (item.type === 'time') {
              return getTime(text);
            } else if (item.type === 'percent') {
              return text + '%';
            } else if (item.type === 'toFixed2') {
              return text.toFixed(2);
            } else if (item.content) {
              return item.content(record);
            } else if (item.type === 'boolean') {
              return text ? item.name1 : item.name2;
            } else if (item.type === 'list') {
              const l: Array<string> = text.map((o: any) => o.name.toString());
              return l.join(' ');
            } else {
              return text;
            }
          }
        }
      } else {
        return text?.value ? text?.value : text;
      }
    };

    const handleExport = (e: Event) => {
      e.preventDefault();

      const selectedColumns = props.columnList
        .map((item: any) => {
          if (state.selectedColumns.indexOf(item.key) > -1) {
            return item;
          } else {
            return null;
          }
        })
        .filter(item => {
          if (item) {
            return true;
          }
        });

      const excel_data = props.datasource.map((item: any, rowIndex: number) =>
        selectedColumns.map((v: any) => value_compute(rowIndex, item[v.key], item, v.key)),
      );
      exportJsonToExcel({
        data: excel_data,
        multiHeader: props.multiHeader,
        header: selectedColumns.map((item: any) => item.label),
        filename: state.filename,
        bookType: state.selectedFileType as BookType,
        autoWidth: false,
      });
      visible.value = false;
    };

    return {
      props,
      t,
      state,
      visible,
      handleOpen,
      handleCancel,
      handleExport,
    };
  },
});
</script>
