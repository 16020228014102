import { reactive } from 'vue';
import {
  create_invoice_pdf,
  modify_invoice_mangefee,
  query_invoice_list,
  send_invoice_by_email,
} from '@/api/cerp-model';
import { message, Modal, notification } from 'ant-design-vue';
import modalBox from '@/components/form-modal/modal-tools';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';

export const btn_management_fee_invoice = {
  cb: (record: any) => {
    const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
    const editModal = reactive({
      visible: true,
      title: '修改请款单管理费',
      loading: false,
      form: {
        settings: {
          labelAlign: 'right',
          layout: 'vertical',
          col: [],
        },
        fields: [
          {
            type: 'input',
            name: 'invoice_no',
            label: '请款单号',
            label_i18n: '请款单号',
            placeholder_i18n: '请填写请款单号',
            disabled: true,
            allowClear: true,
            inputType: 'text',
            defaultValue: '',
            placeholder: '请填写请款单号',
          },
          {
            type: 'input',
            name: 'management_fee',
            label: '管理费',
            label_i18n: '管理费',
            placeholder_i18n: '请填写管理费',
            disabled: false,
            allowClear: true,
            inputType: 'text',
            defaultValue: '',
            placeholder: '请填写管理费',
          },
        ],
        rules: {
          management_fee: [{ required: true, message: '请填写管理费' }],
        },
        model: {
          org_id: current_org?.id,
          is_active: true,
          invoice_id: record.id,
          invoice_no: record.invoice_no,
          management_fee: record.management_fee ? record.management_fee : 0,
        },
      },
      options: {},
      ok: (payload: any) => {
        return new Promise(resolve => {
          editModal.loading = true;
          modify_invoice_mangefee(payload)
            .then(() => {
              return query_invoice_list({
                org_id: current_org?.id,
                ids: [record.id],
              });
            })
            .then((res: any) => {
              message.success('修改请款单管理费成功');
              Object.assign(record, res.data[0]);
              resolve(null);
            })
            .finally(() => {
              editModal.loading = false;
            });
        });
      },
    });
    modalBox(editModal);
  },
};
export const btn_update_invoice = {
  cb: (record: any) => {
    const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
    const modifyModal: any = reactive({
      visible: false,
      component: 'SetrecordModel',
      type: 'update',
      obj: {
        ...record,
      },
    });
    modifyModal.visible = true;
    modifyModal.ok = () => {
      query_invoice_list({
        org_id: current_org?.id,
        ids: [record.id],
      }).then((res: any) => {
        Object.assign(record, res.data[0]);
      });
    };
    modifyModal.cancel = () => {};
    modalBox(modifyModal);
  },
};
const times: any = [];

export const clear = () => {
  times.forEach((item: any) => {
    clearInterval(item);
  });
};
export const btn_invoice_send = {
  cb: (record: any, t: any) => {
    Modal.confirm({
      title: t('提示'),
      content: '是否发送 [' + record.invoice_no + ']',
      onOk() {
        return new Promise(resolve => {
          const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
          send_invoice_by_email({ org_id: current_org?.id, invoice_id: record.id }).then(() => {
            message.success('邮件发送成功');
            resolve(null);
            const time = setInterval(() => {
              query_invoice_list({
                org_id: current_org?.id,
                ids: [record.id],
              }).then((res: any) => {
                Object.assign(record, res.data[0]);
                if (res.data[0].status == 2) {
                  clearInterval(time);
                  times.remove(time);
                }
              });
            }, 1000);
            times.push(time);
          });
        });
      },
      onCancel() {},
    });
  },
};
export const btn_invoice_PDF = {
  cb: (record: any, t: any) => {
    if (!record.bank_account__account_name) {
      notification.error({
        message: t('提示'),
        description: record.invoice_no + ' ' + t('没有CC银行账户，请添加'),
      });
      return;
    }
    Modal.confirm({
      title: t('提示'),
      content: '是否生成请款单PDF [' + record.invoice_no + ']',
      onOk() {
        return new Promise(resolve => {
          const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
          create_invoice_pdf({ org_id: current_org?.id, invoice_id: record.id })
            .then(() => {
              return query_invoice_list({
                org_id: current_org?.id,
                ids: [record.id],
              });
            })
            .then((res: any) => {
              message.success('生成请款单PDF成功');
              resolve(null);
              Object.assign(record, res.data[0]);
            });
        });
      },
      onCancel() {},
    });
  },
};
export const btn_refresh_invoice = {
  cb: (record: any) => {
    const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
    record.is_refresh_loading = true;
    query_invoice_list({
      org_id: current_org?.id,
      ids: [record.id],
    }).then((res: any) => {
      message.success('刷新成功');
      record.is_refresh_loading = false;
      Object.assign(record, res.data[0]);
    });
  },
};
