//API_IMPORT_START ====
import {
  create_question,
  delete_question,
  query_question_list,
  recover_question,
  update_question,
} from '@/api/baseinfo-model';
//API_IMPORT_END
import { Record, SearchConfig } from '@/components/page-model/typing';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import { btn_list, event_obj, head_btn_list } from '@/components/page-model4/baseinfo/baseinfo-question-config';
import column_config from '@/components/page-model4/baseinfo/baseinfo-question-column-config';
import edit_config from '@/components/page-model4/baseinfo/baseinfo-question-edit-config';
import update_config from '@/components/page-model4/baseinfo/baseinfo-question-update-config';

import { reactive } from 'vue';
import { useI18n } from 'vue-i18n';

export default () => {
  const { t } = useI18n();
  const None = null;
  const True = true;
  const False = false;
  const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
  const { baseColumns, newSlotList } = column_config(current_org);
  const { edit_model_config, edit_model_config_options } = edit_config(current_org);
  const { update_model_config, update_model_config_options } = update_config(current_org);

  const search_config: SearchConfig = {
    settings: {
      labelAlign: 'right',
      layout: 'inline',
    },
    fields: [
      {
        type: 'input',
        name: 'name',
        label: '问题',
        label_i18n: '问题',
        disabled: false,
        allowClear: true,
        inputType: 'text',
        defaultValue: '',
        placeholder: '请填写问题',
        placeholder_i18n: '请填写问题',
      },
    ],
    rules: {},
    model: reactive({
      org_id: current_org?.id,
      is_active: true,
      name: null,
    }),
  };
  const search_options = {};

  const titleList = {
    title: t('客服问题管理'),
    addModalTitle: t('新建客服问题'),
    updateTitle: t('修改客服问题'),
    is_create: true, // 是否有【新建】按钮
    is_update: true, // 是否有【修改】按钮
    is_remove: true, // 是否有【删除】按钮
    is_recover: true, // 是否有【恢复】按钮
  };

  const api_fields: Record = {
    update: {
      question_id: 'id',
    },
    delete: {
      question_id: 'id',
    },
    recover: {
      question_id: 'id',
    },
    updateData: {
      org_id: 'org_id',
      question_id: 'id',
    },
  };

  return {
    searchConfig: search_config,
    searchConfigOptions: search_options,
    formModalConfig: edit_model_config,
    formModalConfigOptions: edit_model_config_options,
    updateFormModalConfig: update_model_config,
    updateFormModalConfigOptions: update_model_config_options,
    queryData: query_question_list,
    addData: create_question,
    updateData: update_question,
    removeData: delete_question,
    recoverData: recover_question,
    baseColumns: baseColumns,
    slotList: newSlotList,
    titleList: titleList,
    api_fields: api_fields,
    btn_list: btn_list,
    head_btn_list: head_btn_list,
    event_obj: event_obj,
  };
};
