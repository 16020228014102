import { UserState } from './typing';

export const state: UserState = {
  token: '',
  username: '',
  nickname: '',
  avatar: '',
  sessionid2: '',
  service: {}, // 客服信息
  no_read_number: 0,  // 客服未读数
  no_receive_num: 0,  // 客服未接入消息数
  extra: {},
  current_org: {},
  current_company: {},
  role: undefined,
  allowRouters: [],
  org_list: [],
  is_super_user: false,
  user_list: [],
  custome_data_user: {},
  custome_data_org: {},
};
