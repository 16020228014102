import request from '@/utils/request';
import { PageResult } from '@/api/typing';

export async function get_upload_files_url(params: Record<string, any>) {
  return request.post('/nsbcs/get_upload_files_url', params);
}
export async function upload_file(params: Record<string, any>, options: any) {
  return request.post('/nsbcs/upload_file', params, options);
}
export async function get_file(params: Record<string, any>) {
  return request.post('/nsbcs/get_file', params);
}
export async function download_zip_file(params: Record<string, any>) {
  return request.post('/nsbcs/download_zip_file', params);
}
export async function get_file_url(params: Record<string, any>) {
  return request.post('/nsbcs/get_file_url', params);
}

export async function query_git_project_list(params?: { [key: string]: any }): Promise<PageResult> {
  return request.post('/apidoc/query_git_project_list', params);
}
