import { TableColumn } from '@/typing';
import { slotList } from '@/components/page-model4/baseinfo/baseinfo-question-config';
import { getDateTime } from '@/utils/function';
import { useI18n } from 'vue-i18n';

export default (current_org: any) => {
  const { t } = useI18n();
  const None = null;
  const True = true;
  const False = false;
  const baseColumns: TableColumn[] = [
    {
      title: t('序号'),
      dataIndex: 'index',
      width: 50,
    },
    {
      title: 'ID',
      width: 150,
      dataIndex: 'id',
      checked: false,
      resizable: true,
    },
    {
      title: t('问题'),
      width: 200,
      dataIndex: 'name',
      checked: true,
      resizable: true,
    },
    {
      title: t('答案'),
      width: 200,
      dataIndex: 'answer',
      checked: true,
      resizable: true,
    },
    {
      title: t('隶属问题分组'),
      width: 150,
      dataIndex: 'group_id',
      checked: false,
      resizable: true,
    },
    {
      title: t('隶属问题分组'),
      width: 150,
      dataIndex: 'group__name',
      checked: true,
      resizable: true,
    },
    {
      title: t('创建时间'),
      width: 150,
      dataIndex: 'create_time',
      checked: true,
      resizable: true,
      content: (record: any) => {
        return getDateTime(record.create_time);
      },
    },
    {
      title: t('更新时间'),
      width: 150,
      dataIndex: 'update_time',
      checked: true,
      resizable: true,
      content: (record: any) => {
        return getDateTime(record.update_time);
      },
    },
    {
      title: t('是否可用'),
      width: 150,
      dataIndex: 'is_active',
      checked: true,
      resizable: true,
      cellComponent: 'boolean',
      name1: t('可用'),
      name2: t('禁用'),
      filterMultiple: false,
      filters: [
        { text: t('全部'), value: '' },
        { text: t('可用'), value: 'true' },
        { text: t('禁用'), value: 'false' },
      ],
    },

    {
      title: t('操作'),
      width: 200,
      dataIndex: 'action',
      checked: true,
      cellComponent: 'action',
      fixed: 'right',
    },
  ];

  const newSlotList: any = [];
  Object.assign(newSlotList, slotList);
  newSlotList.push({
    type: 'custom',
    slotName: 'is_active',
    field_true: '可用',
    field_false: '禁用',
  });

  return {
    baseColumns: baseColumns,
    newSlotList: newSlotList,
  };
};
