<template>
  <div style="margin-top: auto" v-if="(column.content ? column.content(record, text) : '') && record.id">
    <a-tooltip>
      <template #title>
        <span>{{ record.user }}</span>
      </template>
      <a-badge style="margin-top: 2px">
        <a-avatar
          shape="square"
          size="large"
          :style="{ backgroundColor: '#0089FF', verticalAlign: 'middle' }"
        >
          {{ column.cb ? column.cb(record) : '' }}
        </a-avatar>
      </a-badge>
    </a-tooltip>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
export default defineComponent({
  name: 'Cell_name',
  props: {
    column: {
      type: Object,
      required: true,
      default: () => {},
    },
    index: {
      type: Number,
      required: true,
    },
    text: {
      type: [String, Number],
      required: true,
    },
    record: {
      type: Object,
      required: true,
      default: () => {},
    },
    pageSize: {
      type: Number,
      required: true,
      default: () => 20,
    },
    current: {
      type: Number,
      required: true,
      default: () => 1,
    },
  },
});
</script>
