import mitt from 'mitt';
import defaultSlotList from '@/components/page-model/slotList';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import { reactive } from 'vue';
import { create_next_of_kin } from '@/api/cerp-model';
import { message } from 'ant-design-vue';
import modalBox from '@/components/form-modal/modal-tools';

export const event_obj = mitt();
export const btn_list = [];
export const slotList = defaultSlotList;
export const config = {
  creatCol: [],
  updateCol: [],
};

export const head_btn_list = [];
