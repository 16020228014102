import 'ant-design-vue/dist/antd.less';
import '@surely-vue/table/dist/index.css';
import {
  Affix,
  Alert,
  Avatar,
  BackTop,
  Badge,
  Button,
  Card,
  Carousel,
  Cascader,
  Checkbox,
  Col,
  Comment,
  ConfigProvider,
  DatePicker,
  Descriptions,
  Divider,
  Drawer,
  Dropdown,
  Empty,
  Form,
  Image,
  Input,
  InputNumber,
  Layout,
  List,
  Menu,
  Modal,
  PageHeader,
  Popconfirm,
  Popover,
  Progress,
  Radio,
  Rate,
  Result,
  Row,
  Select,
  Slider,
  Space,
  Spin,
  Statistic,
  Steps,
  Switch,
  Table,
  Tabs,
  Tag,
  TimePicker,
  Tooltip,
  Tree,
  TreeSelect,
  Upload,
  Typography,
} from 'ant-design-vue';
import SurelyTable, { setLicenseKey } from '@surely-vue/table';
import { createApp } from 'vue';
import router from './router';
import store from './store';
import locale from './locales';
import App from './App.vue';
import JsonViewer from 'vue3-json-viewer';

import FormDesign from '@/components/FormDesign';
import SetrecordModel from '@/components/form-modal/setrecordmodel/setrecordmodel.vue';
import PreviewModal from '@/components/form-modal/preview-modal/preview-modal.vue';
import PayslipModal from '@/components/form-modal/payslipmodal/payslipmodal.vue';
import ReceiptModel from '@/components/form-modal/receipt-modal/ReceiptModel.vue';
import UpdateReceiptModal from '@/components/form-modal/UpdateReceiptModal/index.vue';
import TableModal from '@/components/form-modal/table-modal/table-modal.vue';
import PageModel2 from '@/components/page-model2/index.vue';
import PageModel4 from '@/components/page-model4/index.vue';
import ShowInsurance from '@/components/show-insurance/index.vue';
import NoteModel from '@/components/form-modal/notemodel/notemodel.vue';
import CodeEditingModal from '@/components/form-modal/code-editing-modal/index.vue';
import SuperDetails from '@/components/super-details/index.vue';
import ImageDisplayModal from '@/components/info-modal/ImageDisplay-modal/index.vue';
import UploadImageModal from '@/components/form-modal/upload_image_modal/index.vue';
import SearchRender from '@/components/FormRender/SearchRender.vue';
import WorkPlaceEditModel from '@/components/form-modal/WorkPlaceEdit/WorkPlaceEdit.vue';
import RemoteSelect from '@/components/FormRender/RemoteSelect/RemoteSelect.vue';
import FieldRender from '@/components/FormRender/FieldRender.vue';
import TodoeventModal from '@/components/form-modal/todoevent-modal/index.vue';
import ExportExcelModal from '@/components/info-modal/export-excel-modal/index.vue';
import JsonViewModal from '@/components/form-modal/json-view-modal/index.vue';
import AttributeTable from '@/components/FormRender/AttributeTable/index.vue';
import AttributeTableModal from '@/components/info-modal/AttributeTableModal/index.vue';
import PersonModal from '@/components/person-model/person-model.vue';

import Cell_index from '@/components/cellComponent/cell_index.vue';
import Cell_remark from '@/components/cellComponent/cell_remark.vue';
import Cell_custom from '@/components/cellComponent/cell_custom.vue';
import Cell_custom1 from '@/components/cellComponent/cell_custom1.vue';
import Cell_datetime from '@/components/cellComponent/cell_datetime.vue';
import Cell_date from '@/components/cellComponent/cell_date.vue';
import Cell_date_month from '@/components/cellComponent/cell_date_month.vue';
import Cell_month from '@/components/cellComponent/cell_month.vue';
import Cell_time from '@/components/cellComponent/cell_time.vue';
import Cell_percent from '@/components/cellComponent/cell_percent.vue';
import Cell_toFixed2 from '@/components/cellComponent/cell_toFixed2.vue';
import Cell_money from '@/components/cellComponent/cell_money.vue';
import Cell_attributetable from '@/components/cellComponent/cell_attributetable.vue';
import Cell_click from '@/components/cellComponent/cell_click.vue';
import Cell_click1 from '@/components/cellComponent/cell_click1.vue';
import Cell_color from '@/components/cellComponent/cell_color.vue';
import Cell_boolean from '@/components/cellComponent/cell_boolean.vue';
import Cell_list from '@/components/cellComponent/cell_list.vue';
import Cell_list1 from '@/components/cellComponent/cell_list1.vue';
import Cell_tag from '@/components/cellComponent/cell_tag.vue';
import Cell_real_weight from '@/components/cellComponent/cell_real_weight.vue';
import Cell_weight_offset_percent from '@/components/cellComponent/cell_weight_offset_percent.vue';
import Cell_collect_status from '@/components/cellComponent/cell_collect_status.vue';
import Cell_send_robot from '@/components/cellComponent/cell_send_robot.vue';
import Cell_tag_tag from '@/components/cellComponent/cell_tag-tag.vue';
import Cell_progress from '@/components/cellComponent/cell_progress.vue';
import Cell_bind_no from '@/components/cellComponent/cell_bind_no.vue';
import Cell_action from '@/components/cellComponent/cell_action.vue';
import Cell_stop_tag from '@/components/cellComponent/cell_stop_tag.vue';
import Cell_report_tag from '@/components/cellComponent/cell_report_tag.vue';
import Cell_text from '@/components/cellComponent/cell_text.vue';
import Cell_obj from '@/components/cellComponent/cell_obj.vue';
import Cell_bolder from '@/components/cellComponent/cell_bolder.vue';
import Cell_share_tag from '@/components/cellComponent/cell_share_tag.vue';
import Cell_script_log from '@/components/cellComponent/cell_script_log.vue';
import Cell_click_copy from '@/components/cellComponent/cell_click_copy.vue';
import Cell_link from '@/components/cellComponent/cell_link.vue';
import Cell_clicklist from '@/components/cellComponent/cell_clicklist.vue';
import Cell_menulist from '@/components/cellComponent/cell_menulist.vue';
import Cell_hui_hua from '@/components/cellComponent/cell_hui_hua.vue';
import Cell_name from '@/components/cellComponent/cell_name.vue';
import Cell_talk from '@/components/cellComponent/cell_talk.vue';
import ListModal from '@/components/form-modal/list_modal.vue';
import UploadImageKefuModal from '@/components/form-modal/upload_image_kefu_modal/index.vue';
import PaylaterslipModal from '@/components/form-modal/PaylaterslipModal/index.vue';
import {
  PageContainer,
  PageContainer2,
  PageContainer3,
  ProProvider,
  TransformVnode,
} from '@/components';

import DragIcon from '@/components/table/drag-icon.vue';
import tagModal from '@/components/info-modal/tag-modal.vue';
import TimeCalendarModal from '@/components/form-modal/time_calendar-modal.vue';
import { Container as DragContainer, Draggable } from '@/components/draggable';

import { useIcons } from '@/icons';
import Authority from './utils/authority/authority.vue';
import './app.less';
import './router/router-guards';
import bus from './utils/bus';
import numerals from './utils/numeral';
// import * as Sentry from '@sentry/vue';
// import { Integrations } from '@sentry/tracing';
// if (process.env.NODE_ENV == 'production') {
//   Sentry.init({
//     dsn: 'http://978c03a14f2f4f5994f9086b5fca42df@sentry.cc-mgt.com/6',
//     integrations: [new Integrations.BrowserTracing()],
//
//     // Set tracesSampleRate to 1.0 to capture 100%
//     // of transactions for performance monitoring.
//     // We recommend adjusting this value in production
//     tracesSampleRate: 1.0,
//   });
// }
setLicenseKey(
  '7e739ffcce912bfb784bd89279d2272bT1JERVI6MTAwMDA5LEVYUElSWT0xNjc3MjgzMjAwMDAwLERPTUFJTj1zdXJlbHkuY29vbCxVTFRJTUFURT0xLEtFWVZFUlNJT049MQ==',
);

const app = createApp(App);
app.config.globalProperties.$bus = bus;
app.config.globalProperties.$numeral = numerals;

app
  .use(router)
  .use(locale as any)
  .use(store)
  .use(Affix)
  .use(Layout)
  .use(Menu)
  .use(Row)
  .use(Col)
  .use(Card)
  .use(Image)
  .use(Form)
  .use(Dropdown)
  .use(Select)
  .use(Button)
  .use(Checkbox)
  .use(Tabs)
  .use(Tag)
  .use(Input)
  .use(InputNumber)
  .use(DatePicker)
  .use(TimePicker)
  .use(Radio)
  .use(Tooltip)
  .use(Space)
  .use(Steps)
  .use(Divider)
  .use(Descriptions)
  .use(Alert)
  .use(Result)
  .use(Statistic)
  .use(Popconfirm)
  .use(Popover)
  .use(Table)
  .use(Avatar)
  .use(List)
  .use(Progress)
  .use(Switch)
  .use(Modal)
  .use(Rate)
  .use(ConfigProvider)
  .use(Empty)
  .use(Spin)
  .use(Drawer)
  .use(PageHeader)
  .use(TreeSelect)
  .use(ProProvider)
  .use(Slider)
  .use(FormDesign)
  .use(Badge)
  .use(Carousel)
  .use(BackTop)
  .use(Upload)
  .use(Typography)
  .use(Comment)
  .use(Tree)
  .use(Cascader)
  .use(SurelyTable as any)
  .use(JsonViewer)

  .component('DragContainer', DragContainer)
  .component('Draggable', Draggable)
  .component(DragIcon.name, DragIcon)
  .component(PageContainer.name, PageContainer)
  .component(PageContainer2.name, PageContainer2)
  .component(PageContainer3.name, PageContainer3)
  .component(TransformVnode.name, TransformVnode)
  .component(TodoeventModal.name, TodoeventModal)
  .component(Authority.name, Authority)
  .component(FieldRender.name, FieldRender)
  .component(SearchRender.name, SearchRender)
  .component(TableModal.name, TableModal)
  .component(SetrecordModel.name, SetrecordModel)
  .component(PageModel2.name, PageModel2)
  .component(PageModel4.name, PageModel4)
  .component(PreviewModal.name, PreviewModal)
  .component(PayslipModal.name, PayslipModal)
  .component(SuperDetails.name, SuperDetails)
  .component(ImageDisplayModal.name, ImageDisplayModal)
  .component(CodeEditingModal.name, CodeEditingModal)
  .component(JsonViewModal.name, JsonViewModal)
  .component(AttributeTable.name, AttributeTable)
  .component(AttributeTableModal.name, AttributeTableModal)
  .component(PersonModal.name, PersonModal)
  .component(UploadImageModal.name, UploadImageModal)
  .component(UploadImageKefuModal.name, UploadImageKefuModal)
  .component(ShowInsurance.name, ShowInsurance)
  .component(WorkPlaceEditModel.name, WorkPlaceEditModel)
  .component(RemoteSelect.name, RemoteSelect)
  .component(NoteModel.name, NoteModel)
  .component(ListModal.name, ListModal)
  .component(Cell_index.name, Cell_index)
  .component(Cell_clicklist.name, Cell_clicklist)
  .component(Cell_menulist.name, Cell_menulist)
  .component(Cell_custom.name, Cell_custom)
  .component(Cell_custom1.name, Cell_custom1)
  .component(Cell_datetime.name, Cell_datetime)
  .component(Cell_date.name, Cell_date)
  .component(Cell_date_month.name, Cell_date_month)
  .component(Cell_month.name, Cell_month)
  .component(Cell_time.name, Cell_time)
  .component(Cell_percent.name, Cell_percent)
  .component(Cell_toFixed2.name, Cell_toFixed2)
  .component(Cell_money.name, Cell_money)
  .component(Cell_attributetable.name, Cell_attributetable)
  .component(Cell_click.name, Cell_click)
  .component(Cell_click1.name, Cell_click1)
  .component(Cell_color.name, Cell_color)
  .component(Cell_boolean.name, Cell_boolean)
  .component(Cell_list.name, Cell_list)
  .component(Cell_list1.name, Cell_list1)
  .component(Cell_tag.name, Cell_tag)
  .component(Cell_real_weight.name, Cell_real_weight)
  .component(Cell_weight_offset_percent.name, Cell_weight_offset_percent)
  .component(Cell_collect_status.name, Cell_collect_status)
  .component(Cell_send_robot.name, Cell_send_robot)
  .component(Cell_tag_tag.name, Cell_tag_tag)
  .component(Cell_progress.name, Cell_progress)
  .component(Cell_bind_no.name, Cell_bind_no)
  .component(Cell_action.name, Cell_action)
  .component(Cell_stop_tag.name, Cell_stop_tag)
  .component(Cell_report_tag.name, Cell_report_tag)
  .component(Cell_text.name, Cell_text)
  .component(Cell_obj.name, Cell_obj)
  .component(Cell_bolder.name, Cell_bolder)
  .component(Cell_share_tag.name, Cell_share_tag)
  .component(Cell_script_log.name, Cell_script_log)
  .component(Cell_click_copy.name, Cell_click_copy)
  .component(Cell_link.name, Cell_link)
  .component(Cell_remark.name, Cell_remark)
  .component(Cell_hui_hua.name, Cell_hui_hua)
  .component(Cell_name.name, Cell_name)
  .component(Cell_talk.name, Cell_talk)
  .component(tagModal.name, tagModal)
  .component(TimeCalendarModal.name, TimeCalendarModal)
  .component(PaylaterslipModal.name, PaylaterslipModal)
  .component(ReceiptModel.name, ReceiptModel)
  .component(UpdateReceiptModal.name, UpdateReceiptModal)
  .component(ExportExcelModal.name, ExportExcelModal);

useIcons(app);

app.mount('#app');
