import mitt from 'mitt';
import defaultSlotList from '@/components/page-model/slotList';
import { reactive } from 'vue';
import { create_beneficiary } from '@/api/cerp-model';
import { message } from 'ant-design-vue';
import modalBox from '@/components/form-modal/modal-tools';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';

const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
export const event_obj = mitt();
export const btn_list = [
  {
    name: '备注',
    type: 'dropdown',
    clazz: 'primary',
    cb: (record: any) => {
      const modifyModal: any = reactive({
        visible: false,
        component: 'NoteModel',
        params: {
          object_id: record.id,
          note_type_flag: 'cerp.beneficiary',
        },
      });
      modifyModal.visible = true;
      modifyModal.ok = () => {
        event_obj.emit('refresh');
      };
      modifyModal.cancel = () => {
      };
      modalBox(modifyModal);
    },
  },
];
export const slotList = defaultSlotList;
export const config = {
  creatCol: [],
  updateCol: [],
};

export const head_btn_list = [
  {
    name: '新增受益人',
    clazz: 'primary',
    cb: (data: any, dataSource: any, t: any) => {
      const editModal = reactive({
        visible: true,
        title: t('新增受益人'),
        loading: false,
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'vertical',
            col: [],
          },
          fields: [
            {
              type: 'input',
              name: 'adviser__full_name',
              label: '顾问',
              label_i18n: '顾问',
              placeholder_i18n: '请选择隶属顾问',
              disabled: true,
              placeholder: '请选择隶属顾问',
              datasourceType: 'remote',
              allowClear: false,
            },
            {
              type: 'input',
              name: 'name',
              label: '姓名',
              label_i18n: '姓名',
              placeholder_i18n: '请填写姓名',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写姓名',
            },
            {
              type: 'radio',
              name: 'gender',
              label: '性别',
              label_i18n: '性别',
              placeholder_i18n: '性别',
              disabled: false,
              labelKey: 'name',
              valueKey: 'value',
              defaultValue: '',
            },
            {
              type: 'datepicker',
              name: 'date_of_birth',
              label: '出生日期',
              label_i18n: '出生日期',
              placeholder_i18n: '请填写出生日期',
              disabled: false,
              allowClear: true,
              inputType: 'number',
              defaultValue: '',
              showTime: false,
              valueFormat: 'YYYY-MM-DD',
              placeholder: '请填写出生日期',
            },
            {
              type: 'remoteselect',
              name: 'id_type_id',
              label: '证件类别',
              label_i18n: '证件类别',
              placeholder_i18n: '请选择证件类别',
              disabled: false,
              placeholder: '请选择证件类别',
              datasourceType: 'remote',
              mode: 'default',
              allowClear: true,
              modalType: 'baseinfo-idtype',
            },
            {
              type: 'input',
              name: 'id_number',
              label: '证件号',
              label_i18n: '证件号',
              placeholder_i18n: '请填写证件号',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写证件号',
            },
            {
              type: 'datepicker',
              name: 'id_expiry_date',
              label: '证件有效期',
              label_i18n: '证件有效期',
              placeholder_i18n: '请填写证件有效期',
              disabled: false,
              allowClear: true,
              inputType: 'number',
              defaultValue: '',
              showTime: false,
              valueFormat: 'YYYY-MM-DD',
              placeholder: '请填写证件有效期',
            },
            {
              type: 'select',
              name: 'relation',
              label: '与被保险人关系',
              label_i18n: '与被保险人关系',
              placeholder_i18n: '请选择与被保险人关系',
              mode: 'default',
              disabled: false,
              defaultValue: '',
              labelKey: 'name',
              valueKey: 'value',
              placeholder: '请选择与被保险人关系',
              datasource: 'relation',
            },
            {
              type: 'input',
              name: 'ratio',
              label: '受益比例',
              label_i18n: '受益比例',
              placeholder_i18n: '请填写受益比例',
              disabled: false,
              allowClear: true,
              inputType: 'number',
              defaultValue: '',
              placeholder: '请填写受益比例',
            },
          ],
          rules: {
            name: [{ required: true, message: t('请填输入姓名') }],
            gender: [{ required: true, message: t('请填输入性别') }],
            date_of_birth: [{ required: true, message: t('请填输入出生日期') }],
            id_number: [{ required: true, message: t('请填输入证件号') }],
            // id_expiry_date: [{ required: true, message: '请填输入证件有效期' }],
          },
          model: reactive({
            org_id: current_org?.id,
            is_active: true,
            adviser_id: Number(data.id),
            adviser__full_name: data.name,
            name: null,
            ratio: null,
            relation: null,
            gender: null,
            date_of_birth: null,
            id_type_id: null,
            id_number: null,
            id_expiry_date: null,
          }),
        },
        options: {
          gender: [
            { value: 0, name: t('男') },
            { value: 1, name: t('女') },
          ],
          relation: [
            { value: 0, name: t('本人') },
            { value: 1, name: t('配偶') },
            { value: 2, name: t('子女') },
            { value: 3, name: t('父母') },
            { value: 4, name: t('其他') },
          ],
        },
        ok: (payload: any) => {
          return new Promise(resolve => {
            editModal.loading = true;
            create_beneficiary(payload)
              .then(() => {
                message.success(t('添加成功'));
                event_obj.emit('refresh');
                resolve(null);
              })
              .finally(() => {
                editModal.loading = false;
              });
          });
        },
      });
      modalBox(editModal);
    },
  },
];
