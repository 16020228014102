<template>
  <a-button
    size="small"
    type="link"
    @click.stop="column.cb(text, record, column)"
  >
    <bar-chart-outlined style="font-weight: bolder;font-size: 18px;color: #0366d6" />
  </a-button>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
export default defineComponent({
  name: 'Cell_share_tag',
  props: {
    column: {
      type: Object,
      required: true,
      default: () => {},
    },
    index: {
      type: Number,
      required: true,
    },
    text: {
      type: [String, Number],
      required: true,
    },
    record: {
      type: Object,
      required: true,
      default: () => {},
    },
    pageSize: {
      type: Number,
      required: true,
      default: () => 20,
    },
    current: {
      type: Number,
      required: true,
      default: () => 1,
    },
  },
});
</script>
