
import { defineComponent, ref } from 'vue';
import CodeEditor from '@/components/code/CodeEditor.vue';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import { bulk_create_extra_field, bulk_create_todoevent } from '@/api/cerp-model';
import { query_cc_company_list } from '@/api/baseinfo-model';

export default defineComponent({
  name: 'TodoeventModal',
  props: {
    title: {
      type: String,
      required: true,
    },
    obj: {
      type: Object,
      required: true,
    },
    column: {
      type: Array,
      required: true,
    },
    flag: {
      type: String,
      required: true,
    },
  },
  components: {
    CodeEditor,
  },
  emits: ['ok'],
  setup(props, { emit }) {
    const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
    const err_list: any = ref([]);
    if (props.obj.err_list && props.obj.err_list.length > 0) {
      props.obj.err_list.forEach((item: any) => {
        err_list.value.push({
          ...item,
          is_cc_company: false,
        });
      });
    }
    query_cc_company_list({
      org_id: current_org.id,
      is_active: true,
    }).then((res: any) => {
      if (res && res.data && res.data.length > 0) {
        err_list.value.forEach((item1: any) => {
          let is = true;
          res.data.forEach((item: any) => {
            if (item.name == item1.cc_company) {
              is = false;
            }
          });
          if (is) {
            item1.is_cc_company = true;
          }
        });
      }
      err_list.value = [].concat(err_list.value);
    });
    const handleOk = () => {
      if (props.flag == 'todoevent') {
        bulk_create_todoevent({
          org_id: current_org.id,
          data_json_list: JSON.stringify(props.obj.ok_list),
        }).then((res: any) => {
          emit('ok');
        });
      } else if (props.flag == 'extra_field') {
        bulk_create_extra_field({
          org_id: current_org.id,
          data_json_list: JSON.stringify( props.obj.ok_list ),
        }).then((res: any) => {
          emit('ok');
        })
      }
    }
    return {
      err_list,
      handleOk,
    };
  },
});
