import { query_adviser_list } from '@/api/cerp-model';
import { useI18n } from 'vue-i18n';

interface SearchConfig {
  rules: any;
  fields: any;
  settings: any;
  model: any;
}

export default () => {
  const { t } = useI18n();

  const apiurl_config: SearchConfig = {
    settings: {
      name: '表单名称',
      labelAlign: 'right',
      layout: 'inline',
    },
    fields: [
      {
        type: 'input',
        name: 'employee_number',
        label: '顾问工号',
        label_i18n: '顾问工号',
        placeholder_i18n: '请填写顾问工号',
        disabled: false,
        allowClear: true,
        inputType: 'number',
        defaultValue: '',
        placeholder: '请填写顾问工号',
      },
      {
        type: 'select',
        name: 'title',
        label: '称谓',
        label_i18n: '称谓',
        placeholder_i18n: '请选择称谓',
        mode: 'default',
        disabled: false,
        defaultValue: '',
        labelKey: 'name',
        valueKey: 'value',
        placeholder: '请选择称谓',
        datasource: 'title',
      },
      {
        type: 'input',
        name: 'full_name',
        label: '姓名',
        label_i18n: '姓名',
        placeholder_i18n: '请填写姓名',
        disabled: false,
        allowClear: true,
        inputType: 'text',
        defaultValue: '',
        placeholder: '请填写姓名',
      },
      {
        type: 'input',
        name: 'first_name',
        label: '名',
        label_i18n: '名',
        placeholder_i18n: '请填写名',
        disabled: false,
        allowClear: true,
        inputType: 'text',
        defaultValue: '',
        placeholder: '请填写名字',
      },
      {
        type: 'input',
        name: 'last_name',
        label: '姓',
        label_i18n: '姓',
        placeholder_i18n: '请填写姓',
        disabled: false,
        allowClear: true,
        inputType: 'text',
        defaultValue: '',
        placeholder: '请填写姓',
      },
      {
        type: 'input',
        name: 'other_name',
        label: '其他名字',
        label_i18n: '其他名字',
        placeholder_i18n: '请填写其他名字',
        disabled: false,
        allowClear: true,
        inputType: 'text',
        defaultValue: '',
        placeholder: '请填写其他名字',
      },
      {
        type: 'remoteselect',
        name: 'nationality_id',
        label: '国籍',
        label_i18n: '国籍',
        placeholder_i18n: '请选择国籍',
        disabled: false,
        placeholder: '请选择国籍',
        datasourceType: 'remote',
        mode: 'default',
        allowClear: true,
        modalType: 'baseinfo-country',
      },
      {
        type: 'input',
        name: 'phone',
        label: '电话',
        label_i18n: '电话',
        placeholder_i18n: '请填写电话',
        disabled: false,
        allowClear: true,
        inputType: 'text',
        defaultValue: '',
        placeholder: '请填写电话',
      },
      {
        type: 'input',
        name: 'mobile',
        label: '手机',
        label_i18n: '手机',
        placeholder_i18n: '请填写手机',
        disabled: false,
        allowClear: true,
        inputType: 'text',
        defaultValue: '',
        placeholder: '请填写手机',
      },
      {
        type: 'input',
        name: 'personal_email',
        label: '个人邮箱',
        label_i18n: '个人邮箱',
        placeholder_i18n: '请填写个人邮箱',
        disabled: false,
        allowClear: true,
        inputType: 'text',
        defaultValue: '',
        placeholder: '请填写个人邮箱',
      },
      {
        type: 'input',
        name: 'work_email',
        label: '工作邮箱',
        label_i18n: '工作邮箱',
        placeholder_i18n: '请填写工作邮箱',
        disabled: false,
        allowClear: true,
        inputType: 'text',
        defaultValue: '',
        placeholder: '请填写工作邮箱',
      },
      {
        type: 'input',
        name: 'wechat',
        label: '微信账号',
        label_i18n: '微信账号',
        placeholder_i18n: '请填写微信账号',
        disabled: false,
        allowClear: true,
        inputType: 'text',
        defaultValue: '',
        placeholder: '请填写微信账号',
      },
      {
        type: 'input',
        name: 'whats_app',
        label: 'WhatsApp账号',
        label_i18n: 'WhatsApp账号',
        placeholder_i18n: '请填写WhatsApp账号',
        disabled: false,
        allowClear: true,
        inputType: 'text',
        defaultValue: '',
        placeholder: '请填写WhatsApp账号',
      },
      {
        type: 'input',
        name: 'qq',
        label: 'QQ账号',
        label_i18n: 'QQ账号',
        placeholder_i18n: '请填写QQ账号',
        disabled: false,
        allowClear: true,
        inputType: 'text',
        defaultValue: '',
        placeholder: '请填写QQ账号',
      },
      {
        type: 'input',
        name: 'skypelid',
        label: 'Skype ID',
        label_i18n: 'Skype ID',
        placeholder_i18n: '请填写Skype ID',
        disabled: false,
        allowClear: true,
        inputType: 'text',
        defaultValue: '',
        placeholder: '请填写Skype ID',
      },
      {
        type: 'remoteselect',
        name: 'person_type_id',
        label: '个人类别',
        label_i18n: '个人类别',
        placeholder_i18n: '请选择个人类别',
        disabled: false,
        placeholder: '请选择个人类别',
        datasourceType: 'remote',
        mode: 'default',
        allowClear: true,
        modalType: 'baseinfo-persontype',
      },
      {
        type: 'remoteselect',
        name: 'home_address_id',
        label: '家庭地址',
        label_i18n: '家庭地址',
        placeholder_i18n: '请选择家庭地址',
        disabled: false,
        placeholder: '请选择家庭地址',
        datasourceType: 'remote',
        init_model: { address_type: 0 },
        mode: 'default',
        allowClear: true,
        labelKey: 'display_name',
        valueKey: 'id',
        modalType: 'cerp-address',
      },
      {
        type: 'remoteselect',
        name: 'work_address_id',
        label: '工作地址',
        label_i18n: '工作地址',
        placeholder_i18n: '请选择工作地址',
        disabled: false,
        placeholder: '请选择工作地址',
        datasourceType: 'remote',
        init_model: { address_type: 1 },
        mode: 'default',
        allowClear: true,
        labelKey: 'display_name',
        valueKey: 'id',
        modalType: 'cerp-address',
      },
      {
        type: 'select',
        name: 'status',
        label: '个人状态',
        label_i18n: '个人状态',
        placeholder_i18n: '请选择个人状态',
        mode: 'default',
        disabled: false,
        allowClear: true,
        defaultValue: '',
        labelKey: 'name',
        valueKey: 'value',
        placeholder: '请选择个人状态',
        datasource: 'status',
      },
    ],
    rules: {
      name: [],
    },
    model: {
      employee_number: '',
      title: '',
      full_name: null,
      first_name: null,
      last_name: null,
      other_name: null,
      nationality_id: null,
      phone: null,
      mobile: null,
      personal_email: null,
      work_email: null,
      wechat: null,
      whats_app: null,
      qq: null,
      skypelid: null,
      tag_id: null,
      user_email_template_id: null,
      person_type_id: null,
      home_address_id: null,
      work_address_id: null,
      status: null,
    },
};
const apiurl_ConfigOptions = {
  title: [
    { value: '', name: '-None-' },
    { value: 'Mr.', name: 'Mr.' },
    { value: 'Mrs.', name: 'Mrs.' },
    { value: 'Ms.', name: 'Ms.' },
    { value: 'Dr.', name: 'Dr.' },
    { value: 'Prof.', name: 'Prof.' },
  ],
  status: [
    { value: 1, name: t('-无-') },
    { value: 2, name: t('工作中') },
    { value: 3, name: t('结束') },
    { value: 4, name: t('申请签证中') },
    { value: 5, name: t('尚未开始') },
    { value: 6, name: t('潜在雇员') },
    { value: 7, name: t('离职') },
    { value: 8, name: t('待定') },
  ],
};
const apiurl_columns = [
  {
    title: t('工号'),
    width: 80,
    dataIndex: 'employee_number',
    checked: true,
    fixed: 'left',
  },
  {
    title: t('姓名'),
    width: 150,
    dataIndex: 'full_name',
    checked: true,
    fixed: 'left',
  },
  {
    title: t('其他姓名'),
    width: 150,
    dataIndex: 'other_name',
    checked: true,
  },
  {
    title: t('称谓'),
    width: 100,
    dataIndex: 'title',
    checked: true,
  },
  {
    title: t('国籍'),
    width: 100,
    dataIndex: 'nationality__name',
    checked: true,
  },
  {
    title: t('电话'),
    width: 150,
    dataIndex: 'phone',
    checked: true,
  },
  {
    title: t('手机'),
    width: 150,
    dataIndex: 'mobile',
    checked: true,
  },
  {
    title: t('个人邮箱'),
    width: 200,
    dataIndex: 'personal_email',
    checked: true,
  },
  {
    title: t('工作邮箱'),
    width: 200,
    dataIndex: 'work_email',
    checked: true,
  },
  {
    title: t('微信账号'),
    width: 150,
    dataIndex: 'wechat',
    checked: true,
  },
  {
    title: t('WhatsApp账号'),
    width: 150,
    dataIndex: 'whats_app',
    checked: true,
  },
  {
    title: t('QQ账号'),
    width: 150,
    dataIndex: 'qq',
    checked: true,
  },
  {
    title: 'Skype ID',
    width: 150,
    dataIndex: 'skypelid',
    checked: true,
  },
  {
    title: t('个人类别'),
    width: 150,
    dataIndex: 'person_type__name',
    checked: true,
  },
  {
    title: t('个人状态'),
    width: 150,
    dataIndex: 'status',
    checked: true,
    slots: { customRender: 'worker_status' },
  },
  {
    title: t('家庭地址'),
    width: 150,
    dataIndex: 'home_address__display_name',
    checked: true,
  },
  {
    title: t('工作地址'),
    width: 150,
    dataIndex: 'work_address__country__name',
    checked: true,
  },
  {
    title: t('创建时间'),
    width: 150,
    dataIndex: 'create_time',
    checked: true,
    slots: { customRender: 'datetime' },
  },
  {
    title: t('更新时间'),
    width: 150,
    dataIndex: 'update_time',
    checked: true,
    slots: { customRender: 'datetime' },
  },
  {
    title: t('是否可用'),
    width: 150,
    dataIndex: 'is_active',
    checked: true,
    slots: { customRender: 'bool' },
  },
];
const slotList = [
  {
    slotName: 'index', // 槽名
  },
  {
    slotName: 'status',
    type: 'boolean', // 类型：'boolean' - text字段有无、(true/false)
    name1: '正常', // text字段有或true 展示
    name2: '禁用', // text字段无或false 展示
  },
  {
    slotName: 'bool',
    type: 'boolean', // 类型：'boolean' - text字段有无、(true/false)
    name1: '是', // text字段有或true 展示
    name2: '否', // text字段无或false 展示
  },
  {
    slotName: 'time',
    type: 'time', // 类型：'time' - text字段为时间
  },
  {
    slotName: 'datetime',
    type: 'datetime', // 类型：'time' - text字段为时间
  },
  {
    slotName: 'date',
    type: 'date', // 类型：'date' - text字段为日期
  },
  {
    slotName: 'list',
    type: 'list', // 类型：'list' - text字段为数组
  },
  {
    type: 'custom',
    slotName: 'worker_status',
    field_1: '-无-',
    field_2: '工作中',
    field_3: '结束',
    field_4: '申请签证中',
    field_5: '尚未开始',
    field_6: '潜在雇员',
    field_7: '离职',
    field_8: '待定',
  },
];

  return {
    searchConfig: apiurl_config,
    searchConfigOptions: apiurl_ConfigOptions,
    getData: query_adviser_list,
    columns: apiurl_columns,
    slotList: slotList,
  };
};
