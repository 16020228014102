import mitt from 'mitt';
import defaultSlotList from '@/components/page-model/slotList';
import { createVNode, reactive, ref } from 'vue';
import {
  create_beneficiary,
  create_insurance,
  delete_insurance,
  query_insurance_list,
  update_insurance,
  upload_insurance_appendix,
  upload_insurance_other_appendix,
} from '@/api/cerp-model';
import { message, Modal, notification } from 'ant-design-vue';
import modalBox from '@/components/form-modal/modal-tools';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import { query_insurance_type_list } from '@/api/baseinfo-model';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';

const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org

export const event_obj = mitt();
export const btn_list = [
  {
    name: '修改',
    type: 'dropdown',
    clazz: 'primary',
    cb: (data: any, dataSource: any, index: any, requestParams: any, t: any) => {
      const list: any = [];
      data.beneficiaries.forEach((item: any) => {
        list.push(item.id);
      });
      const editModal = reactive({
        visible: true,
        title: t('修改保险'),
        loading: false,
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'vertical',
            col: [],
          },
          fields: [
            {
              type: 'input',
              name: 'adviser__full_name',
              label: '顾问',
              label_i18n: '顾问',
              placeholder_i18n: '请选择隶属顾问',
              disabled: true,
              placeholder: '请选择隶属顾问',
              datasourceType: 'remote',
              allowClear: false,
            },
            {
              type: 'remoteselect',
              name: 'insurance_company_id',
              label: '保险公司',
              label_i18n: '保险公司',
              placeholder_i18n: '请选择保险公司',
              disabled: false,
              placeholder: '请选择保险公司',
              datasourceType: 'remote',
              mode: 'default',
              allowClear: true,
              labelKey: 'company_name',
              valueKey: 'id',
              modalType: 'baseinfo-insurancecompany',
            },
            {
              type: 'input',
              name: 'policy_no',
              label: '保险单号',
              label_i18n: '保险单号',
              placeholder_i18n: '请填写保险单号',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写保险单号',
            },
            {
              type: 'rangepicker',
              name: 'start_end_date',
              label: '起保-停保日期时间',
              label_i18n: '起保-停保日期时间',
              placeholder_i18n: '请填写起保日期时间',
              disabled: false,
              allowClear: true,
              inputType: 'number',
              defaultValue: '',
              showTime: false,
              valueFormat: 'YYYY-MM-DD',
              placeholder: '请填写起保日期时间',
            },
            {
              type: 'radio',
              name: 'is_current',
              label: '当前保险',
              label_i18n: '当前保险',
              placeholder_i18n: '请填写当前保险',
              disabled: false,
              labelKey: 'name',
              valueKey: 'value',
              defaultValue: '',
            },
            {
              type: 'remoteselect',
              name: 'insurance_type_id',
              label: '保险类型',
              label_i18n: '保险类型',
              placeholder_i18n: '请选择保险类型',
              disabled: false,
              placeholder: '请选择保险类型',
              datasourceType: 'remote',
              mode: 'default',
              allowClear: true,
              labelKey: 'name',
              valueKey: 'id',
              modalType: 'baseinfo-query_insurance_type_list',
            },
            {
              type: 'input',
              name: 'month_insurance_fee',
              label: '每月保费',
              label_i18n: '每月保费',
              placeholder_i18n: '请填写每月保费',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写每月保费',
            },
            {
              type: 'remoteselect',
              name: 'beneficiary_ids',
              label: '受益人',
              label_i18n: '受益人',
              placeholder_i18n: '请选择受益人',
              disabled: false,
              placeholder: '请选择受益人',
              datasourceType: 'remote',
              mode: 'multiple',
              allowClear: true,
              labelKey: 'name',
              valueKey: 'id',
              modalType: 'cerp-beneficiary',
              cb: () => {
                return new Promise(function (resolve) {
                  const editModal1 = reactive({
                    visible: true,
                    title: t('新建受益人'),
                    loading: false,
                    form: {
                      settings: {
                        labelAlign: 'right',
                        layout: 'vertical',
                        col: [],
                      },
                      fields: [
                        {
                          type: 'input',
                          name: 'adviser__full_name',
                          label: '顾问',
                          label_i18n: '顾问',
                          placeholder_i18n: '请选择隶属顾问',
                          disabled: true,
                          placeholder: '请选择隶属顾问',
                          datasourceType: 'remote',
                          allowClear: false,
                        },
                        {
                          type: 'input',
                          name: 'name',
                          label: '姓名',
                          label_i18n: '姓名',
                          placeholder_i18n: '请填写姓名',
                          disabled: false,
                          allowClear: true,
                          inputType: 'text',
                          defaultValue: '',
                          placeholder: '请填写姓名',
                        },
                        {
                          type: 'radio',
                          name: 'gender',
                          label: '性别',
                          label_i18n: '性别',
                          placeholder_i18n: '性别',
                          disabled: false,
                          labelKey: 'name',
                          valueKey: 'value',
                          defaultValue: '',
                        },
                        {
                          type: 'datepicker',
                          name: 'date_of_birth',
                          label: '出生日期',
                          label_i18n: '出生日期',
                          placeholder_i18n: '请填写出生日期',
                          disabled: false,
                          allowClear: true,
                          inputType: 'number',
                          defaultValue: '',
                          showTime: false,
                          valueFormat: 'YYYY-MM-DD',
                          placeholder: '请填写出生日期',
                        },
                        {
                          type: 'remoteselect',
                          name: 'id_type_id',
                          label: '证件类别',
                          label_i18n: '证件类别',
                          placeholder_i18n: '请选择证件类别',
                          disabled: false,
                          placeholder: '请选择证件类别',
                          datasourceType: 'remote',
                          mode: 'default',
                          allowClear: true,
                          modalType: 'baseinfo-idtype',
                        },
                        {
                          type: 'input',
                          name: 'id_number',
                          label: '证件号',
                          label_i18n: '证件号',
                          placeholder_i18n: '请填写证件号',
                          disabled: false,
                          allowClear: true,
                          inputType: 'text',
                          defaultValue: '',
                          placeholder: '请填写证件号',
                        },
                        {
                          type: 'datepicker',
                          name: 'id_expiry_date',
                          label: '证件有效期',
                          label_i18n: '证件有效期',
                          placeholder_i18n: '请填写证件有效期',
                          disabled: false,
                          allowClear: true,
                          inputType: 'number',
                          defaultValue: '',
                          showTime: false,
                          valueFormat: 'YYYY-MM-DD',
                          placeholder: '请填写证件有效期',
                        },
                      ],
                      rules: {
                        name: [{ required: true, message: t('请填输入姓名') }],
                        gender: [{ required: true, message: t('请填输入性别') }],
                        date_of_birth: [{ required: true, message: t('请填输入出生日期') }],
                        id_number: [{ required: true, message: t('请填输入证件号') }],
                        // id_expiry_date: [{ required: true, message: '请填输入证件有效期' }],
                      },
                      model: reactive({
                        org_id: current_org?.id,
                        is_active: true,
                        adviser_id: Number(data.adviser_id),
                        adviser__full_name: data.adviser__full_name,
                        insurance_id: null,
                        name: null,
                        gender: null,
                        date_of_birth: null,
                        id_type_id: null,
                        id_number: null,
                        id_expiry_date: null,
                      }),
                    },
                    options: {
                      gender: [
                        { value: 0, name: t('男') },
                        { value: 1, name: t('女') },
                      ],
                    },
                    ok: (payload: any) => {
                      return new Promise(resolve1 => {
                        editModal1.loading = true;
                        create_beneficiary(payload)
                          .then((res: any) => {
                            message.success(t('创建成功'));
                            resolve(res.id as number);
                            resolve1(null);
                          })
                          .finally(() => {
                            editModal1.loading = false;
                          });
                      });
                    },
                  });
                  modalBox(editModal1);
                });
              },
            },
            {
              type: 'textarea',
              name: 'remark',
              label: '备注',
              label_i18n: '备注',
              placeholder_i18n: '请填写备注',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              maxlength: 200,
              defaultValue: '',
              placeholder: '请填写备注',
            },
          ],
          rules: {},
          model: reactive({
            org_id: current_org?.id,
            is_active: true,
            insurance_id: Number(data.id),
            adviser_id: Number(data.adviser_id),
            adviser__full_name: data.adviser__full_name,
            start_end_date: [data?.effective_date_time, data?.expiry_date_time],
            insurance_company_id: data?.insurance_company_id,
            policy_no: data?.policy_no,
            remark: data?.remark,
            effective_date_time: data?.effective_date_time,
            expiry_date_time: data?.expiry_date_time,
            is_current: data?.is_current,
            insurance_fee: data?.insurance_fee,
            beneficiary_ids: list,
            month_insurance_fee: data?.month_insurance_fee,
            nsfile_id: null,
            insurance_type_id: data?.insurance_type_id,
          }),
          watch: {
            insurance_type_id: (data1: any) => {
              if (data1.insurance_type_id) {
                query_insurance_type_list({
                  org_id: current_org?.id,
                  ids: [data1.insurance_type_id],
                }).then((res: any) => {
                  if (res && res.data && res.data.length > 0) {
                    data1.month_insurance_fee = res.data[0].month_insurance_fee;
                  }
                });
              }
            },
          },
        },
        options: {
          is_current: [
            { value: true, name: t('是') },
            { value: false, name: t('否') },
          ],
        },
        ok: (payload: any) => {
          return new Promise(resolve => {
            editModal.loading = true;
            update_insurance({
              ...payload,
              effective_date_time: payload.start_end_date[0],
              expiry_date_time: payload.start_end_date[1],
            })
              .then(() => {
                message.success(t('修改成功'));
                event_obj.emit('refresh');
                resolve(null);
              })
              .finally(() => {
                editModal.loading = false;
              });
          });
        },
      });
      modalBox(editModal);
    },
  },
  {
    name: '保单上传',
    type: 'dropdown',
    clazz: 'primary',
    cb: (data: any, dataSource: any, index: any, requestParams: any, t: any) => {
      const loading = ref(false);
      const editModal = reactive({
        visible: true,
        title: t('保单上传'),
        group: '保单',
        type: 'picture',
        component: 'upload-image-modal',
        loading: loading,
        ok: (payload: any) => {
          loading.value = true;
          if (data.appendix_ids) {
            payload.push(...data.appendix_ids.split(','));
          }
          return new Promise(resolve => {
            upload_insurance_appendix({
              insurance_id: data.id,
              appendix_ids: payload,
              org_id: current_org?.id,
            })
              .then(() => {
                message.success(t('上传成功'));
                event_obj.emit('refresh');
                resolve(null);
              })
              .finally(() => {
                loading.value = false;
              });
          });
        },
      });
      modalBox(editModal);
    },
  },
  {
    name: '其他附件上传',
    type: 'dropdown',
    clazz: 'primary',
    cb: (data: any, dataSource: any, index: any, requestParams: any, t: any) => {
      const loading = ref(false);
      const editModal = reactive({
        visible: true,
        title: t('其他附件上传'),
        group: '其他附件',
        type: 'picture',
        component: 'upload-image-modal',
        loading: loading,
        ok: (payload: any) => {
          loading.value = true;
          if (data.other_appendix_ids) {
            payload.push(...data.other_appendix_ids.split(','));
          }
          return new Promise(resolve => {
            upload_insurance_other_appendix({
              insurance_id: data.id,
              other_appendix_ids: payload,
              org_id: current_org?.id,
            })
              .then(() => {
                message.success(t('上传成功'));
                event_obj.emit('refresh');
                resolve(null);
              })
              .finally(() => {
                loading.value = false;
              });
          });
        },
      });
      modalBox(editModal);
    },
  },
  {
    name: '备注',
    type: 'dropdown',
    clazz: 'primary',
    cb: (record: any) => {
      const modifyModal: any = reactive({
        visible: false,
        component: 'NoteModel',
        params: {
          object_id: record.id,
          note_type_flag: 'cerp.insurance',
        },
      });
      modifyModal.visible = true;
      modifyModal.ok = () => {
        event_obj.emit('refresh');
      };
      modifyModal.cancel = () => {};
      modalBox(modifyModal);
    },
  },
  {
    name: '删除',
    type: 'dropdown',
    clazz: 'primary',
    is_show: (record: any) => {
      return record.is_active;
    },
    cb: (record: any, dataSource: any, index: any, requestParams: any, t: any) => {
      if (record.is_current) {
        notification.error({
          message: t('温馨提示'),
          description: t('当前保险,不可删除'),
        });
        return;
      } else {
        Modal.confirm({
          title: t('确认删除保险') + '?',
          icon: createVNode(ExclamationCircleOutlined),
          onOk() {
            return new Promise(resolve => {
              delete_insurance({
                org_id: current_org?.id,
                insurance_id: record.id,
              }).then(() => {
                message.success(t('操作成功'));
                record.is_active = false;
                resolve(null);
              });
            });
          },
          onCancel() {},
        });
      }
    },
  },
];
export const slotList = defaultSlotList;
export const config = {
  creatCol: [],
  updateCol: [],
};

export const head_btn_list = [
  {
    name: '新增保险',
    clazz: 'primary',
    cb: (data: any, dataSource: any, t: any) => {
      Modal.confirm({
        title: t('提示'),
        content: t('是否复制当前的保险') + '?',
        okText: t('复制'),
        cancelText: t('新建'),
        onOk() {
          return new Promise(resolve => {
            query_insurance_list({
              org_id: current_org?.id,
              adviser_id: data.id,
              is_current: true,
              is_active: true,
            })
              .then((res: any) => {
                if (res && res.data && res.data.length > 0) {
                  const editModal = reactive({
                    visible: true,
                    title: t('复制新增保险'),
                    loading: false,
                    form: {
                      settings: {
                        labelAlign: 'right',
                        layout: 'vertical',
                        col: [],
                      },
                      fields: [
                        {
                          type: 'input',
                          name: 'adviser__full_name',
                          label: '顾问',
                          label_i18n: '顾问',
                          placeholder_i18n: '请选择隶属顾问',
                          disabled: true,
                          placeholder: '请选择隶属顾问',
                          datasourceType: 'remote',
                          allowClear: false,
                        },
                        {
                          type: 'remoteselect',
                          name: 'insurance_company_id',
                          label: '保险公司',
                          label_i18n: '保险公司',
                          placeholder_i18n: '请选择保险公司',
                          disabled: false,
                          placeholder: '请选择保险公司',
                          datasourceType: 'remote',
                          mode: 'default',
                          allowClear: true,
                          labelKey: 'company_name',
                          valueKey: 'id',
                          modalType: 'baseinfo-insurancecompany',
                        },
                        {
                          type: 'input',
                          name: 'policy_no',
                          label: '保险单号',
                          label_i18n: '保险单号',
                          placeholder_i18n: '请填写保险单号',
                          disabled: false,
                          allowClear: true,
                          inputType: 'text',
                          defaultValue: '',
                          placeholder: '请填写保险单号',
                        },
                        {
                          type: 'rangepicker',
                          name: 'start_end_date',
                          label: '起保-停保日期时间',
                          label_i18n: '起保-停保日期时间',
                          placeholder_i18n: '请填写起保日期时间',
                          disabled: false,
                          allowClear: true,
                          inputType: 'number',
                          defaultValue: '',
                          showTime: false,
                          valueFormat: 'YYYY-MM-DD',
                          placeholder: '请填写起保日期时间',
                        },
                        {
                          type: 'radio',
                          name: 'is_current',
                          label: '当前保险',
                          label_i18n: '当前保险',
                          placeholder_i18n: '请填写当前保险',
                          disabled: false,
                          labelKey: 'name',
                          valueKey: 'value',
                          defaultValue: '',
                        },
                        {
                          type: 'remoteselect',
                          name: 'insurance_type_id',
                          label: '保险类型',
                          label_i18n: '保险类型',
                          placeholder_i18n: '请选择保险类型',
                          disabled: false,
                          placeholder: '请选择保险类型',
                          datasourceType: 'remote',
                          mode: 'default',
                          allowClear: true,
                          labelKey: 'name',
                          valueKey: 'id',
                          modalType: 'baseinfo-query_insurance_type_list',
                        },
                        {
                          type: 'input',
                          name: 'month_insurance_fee',
                          label: '每月保费',
                          label_i18n: '每月保费',
                          placeholder_i18n: '请填写每月保费',
                          disabled: false,
                          allowClear: true,
                          inputType: 'text',
                          defaultValue: '',
                          placeholder: '请填写每月保费',
                        },
                        {
                          type: 'remoteselect',
                          name: 'beneficiary_ids',
                          label: '受益人',
                          label_i18n: '受益人',
                          placeholder_i18n: '请选择受益人',
                          disabled: false,
                          placeholder: '请选择受益人',
                          datasourceType: 'remote',
                          mode: 'multiple',
                          allowClear: true,
                          labelKey: 'name',
                          valueKey: 'id',
                          modalType: 'cerp-beneficiary',
                          cb: () => {
                            return new Promise(function (resolve) {
                              const editModal1 = reactive({
                                visible: true,
                                title: t('新建受益人'),
                                loading: false,
                                form: {
                                  settings: {
                                    labelAlign: 'right',
                                    layout: 'vertical',
                                    col: [],
                                  },
                                  fields: [
                                    {
                                      type: 'input',
                                      name: 'adviser__full_name',
                                      label: '顾问',
                                      label_i18n: '顾问',
                                      placeholder_i18n: '请选择隶属顾问',
                                      disabled: true,
                                      placeholder: '请选择隶属顾问',
                                      datasourceType: 'remote',
                                      allowClear: false,
                                    },
                                    {
                                      type: 'input',
                                      name: 'name',
                                      label: '姓名',
                                      label_i18n: '姓名',
                                      placeholder_i18n: '请填写姓名',
                                      disabled: false,
                                      allowClear: true,
                                      inputType: 'text',
                                      defaultValue: '',
                                      placeholder: '请填写姓名',
                                    },
                                    {
                                      type: 'radio',
                                      name: 'gender',
                                      label: '性别',
                                      label_i18n: '性别',
                                      placeholder_i18n: '性别',
                                      disabled: false,
                                      labelKey: 'name',
                                      valueKey: 'value',
                                      defaultValue: '',
                                    },
                                    {
                                      type: 'datepicker',
                                      name: 'date_of_birth',
                                      label: '出生日期',
                                      label_i18n: '出生日期',
                                      placeholder_i18n: '请填写出生日期',
                                      disabled: false,
                                      allowClear: true,
                                      inputType: 'number',
                                      defaultValue: '',
                                      showTime: false,
                                      valueFormat: 'YYYY-MM-DD',
                                      placeholder: '请填写出生日期',
                                    },
                                    {
                                      type: 'remoteselect',
                                      name: 'id_type_id',
                                      label: '证件类别',
                                      label_i18n: '证件类别',
                                      placeholder_i18n: '请选择证件类别',
                                      disabled: false,
                                      placeholder: '请选择证件类别',
                                      datasourceType: 'remote',
                                      mode: 'default',
                                      allowClear: true,
                                      modalType: 'baseinfo-idtype',
                                    },
                                    {
                                      type: 'input',
                                      name: 'id_number',
                                      label: '证件号',
                                      label_i18n: '证件号',
                                      placeholder_i18n: '请填写证件号',
                                      disabled: false,
                                      allowClear: true,
                                      inputType: 'text',
                                      defaultValue: '',
                                      placeholder: '请填写证件号',
                                    },
                                    {
                                      type: 'datepicker',
                                      name: 'id_expiry_date',
                                      label: '证件有效期',
                                      label_i18n: '证件有效期',
                                      placeholder_i18n: '请填写证件有效期',
                                      disabled: false,
                                      allowClear: true,
                                      inputType: 'number',
                                      defaultValue: '',
                                      showTime: false,
                                      valueFormat: 'YYYY-MM-DD',
                                      placeholder: '请填写证件有效期',
                                    },
                                  ],
                                  rules: {
                                    name: [{ required: true, message: t('请填输入姓名') }],
                                    gender: [{ required: true, message: t('请填输入性别') }],
                                    date_of_birth: [
                                      { required: true, message: t('请填输入出生日期') },
                                    ],
                                    id_number: [{ required: true, message: t('请填输入证件号') }],
                                    // id_expiry_date: [
                                    //   { required: true, message: '请填输入证件有效期' },
                                    // ],
                                  },
                                  model: reactive({
                                    org_id: current_org?.id,
                                    is_active: true,
                                    adviser_id: Number(data.id),
                                    adviser__full_name: data.name,
                                    insurance_id: null,
                                    name: null,
                                    gender: null,
                                    date_of_birth: null,
                                    id_type_id: null,
                                    id_number: null,
                                    id_expiry_date: null,
                                  }),
                                },
                                options: {
                                  gender: [
                                    { value: 0, name: t('男') },
                                    { value: 1, name: t('女') },
                                  ],
                                },
                                ok: (payload: any) => {
                                  editModal1.loading = true;
                                  return new Promise(resolve1 => {
                                    create_beneficiary(payload)
                                      .then((res: any) => {
                                        message.success(t('创建成功'));
                                        resolve1(null);
                                        resolve(res.id as number);
                                      })
                                      .finally(() => {
                                        editModal1.loading = false;
                                      });
                                  });
                                },
                              });
                              modalBox(editModal1);
                            });
                          },
                        },
                        {
                          type: 'textarea',
                          name: 'remark',
                          label: '备注',
                          label_i18n: '备注',
                          placeholder_i18n: '请填写备注',
                          disabled: false,
                          allowClear: true,
                          inputType: 'text',
                          maxlength: 200,
                          defaultValue: '',
                          placeholder: '请填写备注',
                        },
                      ],
                      rules: {
                        start_end_date: [{ required: true, message: t('请填输入起停保日期时间') }],
                        is_current: [{ required: true, message: t('请填输入当前保险') }],
                        month_insurance_fee: [{ required: true, message: t('请填输入每月保费') }],
                      },
                      model: reactive({
                        org_id: current_org?.id,
                        is_active: true,
                        adviser_id: Number(data.id),
                        adviser__full_name: data.name,
                        start_end_date: [
                          res.data[0]?.effective_date_time,
                          res.data[0]?.expiry_date_time,
                        ],
                        insurance_company_id: res.data[0]?.insurance_company_id,
                        policy_no: res.data[0]?.policy_no,
                        remark: res.data[0]?.remark,
                        effective_date_time: res.data[0]?.effective_date_time,
                        expiry_date_time: res.data[0]?.expiry_date_time,
                        is_current: true,
                        insurance_fee: res.data[0]?.insurance_fee,
                        beneficiary_ids: res.data[0]?.beneficiary_ids,
                        month_insurance_fee: res.data[0]?.month_insurance_fee,
                        insurance_type_id: res.data[0]?.insurance_type_id,
                        nsfile_id: null,
                      }),
                      watch: {
                        insurance_type_id: (data1: any) => {
                          if (data1.insurance_type_id) {
                            query_insurance_type_list({
                              org_id: current_org?.id,
                              ids: [data1.insurance_type_id],
                            }).then((res: any) => {
                              if (res && res.data && res.data.length > 0) {
                                data1.month_insurance_fee = res.data[0].month_insurance_fee;
                              }
                            });
                          }
                        },
                      },
                    },
                    options: {
                      is_current: [
                        { value: true, name: t('是') },
                        { value: false, name: t('否') },
                      ],
                    },
                    ok: (payload: any) => {
                      return new Promise(resolve => {
                        editModal.loading = true;
                        create_insurance({
                          ...payload,
                          effective_date_time: payload.start_end_date[0],
                          expiry_date_time: payload.start_end_date[1],
                        })
                          .then(() => {
                            message.success(t('添加成功'));
                            event_obj.emit('refresh');
                            resolve(null);
                          })
                          .finally(() => {
                            editModal.loading = false;
                          });
                      });
                    },
                  });
                  modalBox(editModal);
                } else {
                  message.error(t('无当前保险，请新增'));
                  const editModal = reactive({
                    visible: true,
                    title: t('新增保险'),
                    loading: false,
                    form: {
                      settings: {
                        labelAlign: 'right',
                        layout: 'vertical',
                        col: [],
                      },
                      fields: [
                        {
                          type: 'input',
                          name: 'adviser__full_name',
                          label: '顾问',
                          label_i18n: '顾问',
                          placeholder_i18n: '请选择隶属顾问',
                          disabled: true,
                          placeholder: '请选择隶属顾问',
                          datasourceType: 'remote',
                          allowClear: false,
                        },
                        {
                          type: 'remoteselect',
                          name: 'insurance_company_id',
                          label: '保险公司',
                          label_i18n: '保险公司',
                          placeholder_i18n: '请选择保险公司',
                          disabled: false,
                          placeholder: '请选择保险公司',
                          datasourceType: 'remote',
                          mode: 'default',
                          allowClear: true,
                          labelKey: 'company_name',
                          valueKey: 'id',
                          modalType: 'baseinfo-insurancecompany',
                        },
                        {
                          type: 'input',
                          name: 'policy_no',
                          label: '保险单号',
                          label_i18n: '保险单号',
                          placeholder_i18n: '请填写保险单号',
                          disabled: false,
                          allowClear: true,
                          inputType: 'text',
                          defaultValue: '',
                          placeholder: '请填写保险单号',
                        },
                        {
                          type: 'rangepicker',
                          name: 'start_end_date',
                          label: '起保-停保日期时间',
                          label_i18n: '起保-停保日期时间',
                          placeholder_i18n: '请填写起保日期时间',
                          disabled: false,
                          allowClear: true,
                          inputType: 'number',
                          defaultValue: '',
                          showTime: false,
                          valueFormat: 'YYYY-MM-DD',
                          placeholder: '请填写起保日期时间',
                        },
                        {
                          type: 'radio',
                          name: 'is_current',
                          label: '当前保险',
                          label_i18n: '当前保险',
                          placeholder_i18n: '请填写当前保险',
                          disabled: false,
                          labelKey: 'name',
                          valueKey: 'value',
                          defaultValue: '',
                        },
                        {
                          type: 'remoteselect',
                          name: 'insurance_type_id',
                          label: '保险类型',
                          label_i18n: '保险类型',
                          placeholder_i18n: '请选择保险类型',
                          disabled: false,
                          placeholder: '请选择保险类型',
                          datasourceType: 'remote',
                          mode: 'default',
                          allowClear: true,
                          labelKey: 'name',
                          valueKey: 'id',
                          modalType: 'baseinfo-query_insurance_type_list',
                        },
                        {
                          type: 'input',
                          name: 'month_insurance_fee',
                          label: '每月保费',
                          label_i18n: '每月保费',
                          placeholder_i18n: '请填写每月保费',
                          disabled: false,
                          allowClear: true,
                          inputType: 'text',
                          defaultValue: '',
                          placeholder: '请填写每月保费',
                        },
                        {
                          type: 'remoteselect',
                          name: 'beneficiary_ids',
                          label: '受益人',
                          label_i18n: '受益人',
                          placeholder_i18n: '请选择受益人',
                          disabled: false,
                          placeholder: '请选择受益人',
                          datasourceType: 'remote',
                          mode: 'multiple',
                          allowClear: true,
                          labelKey: 'name',
                          valueKey: 'id',
                          modalType: 'cerp-beneficiary',
                          cb: () => {
                            return new Promise(function (resolve, reject) {
                              const editModal1 = reactive({
                                visible: true,
                                title: t('新建受益人'),
                                loading: false,
                                form: {
                                  settings: {
                                    labelAlign: 'right',
                                    layout: 'vertical',
                                    col: [],
                                  },
                                  fields: [
                                    {
                                      type: 'input',
                                      name: 'adviser__full_name',
                                      label: '顾问',
                                      label_i18n: '顾问',
                                      placeholder_i18n: '请选择隶属顾问',
                                      disabled: true,
                                      placeholder: '请选择隶属顾问',
                                      datasourceType: 'remote',
                                      allowClear: false,
                                    },
                                    {
                                      type: 'input',
                                      name: 'name',
                                      label: '姓名',
                                      label_i18n: '姓名',
                                      placeholder_i18n: '请填写姓名',
                                      disabled: false,
                                      allowClear: true,
                                      inputType: 'text',
                                      defaultValue: '',
                                      placeholder: '请填写姓名',
                                    },
                                    {
                                      type: 'radio',
                                      name: 'gender',
                                      label: '性别',
                                      label_i18n: '性别',
                                      placeholder_i18n: '性别',
                                      disabled: false,
                                      labelKey: 'name',
                                      valueKey: 'value',
                                      defaultValue: '',
                                    },
                                    {
                                      type: 'datepicker',
                                      name: 'date_of_birth',
                                      label: '出生日期',
                                      label_i18n: '出生日期',
                                      placeholder_i18n: '请填写出生日期',
                                      disabled: false,
                                      allowClear: true,
                                      inputType: 'number',
                                      defaultValue: '',
                                      showTime: false,
                                      valueFormat: 'YYYY-MM-DD',
                                      placeholder: '请填写出生日期',
                                    },
                                    {
                                      type: 'remoteselect',
                                      name: 'id_type_id',
                                      label: '证件类别',
                                      label_i18n: '证件类别',
                                      placeholder_i18n: '请选择证件类别',
                                      disabled: false,
                                      placeholder: '请选择证件类别',
                                      datasourceType: 'remote',
                                      mode: 'default',
                                      allowClear: true,
                                      modalType: 'baseinfo-idtype',
                                    },
                                    {
                                      type: 'input',
                                      name: 'id_number',
                                      label: '证件号',
                                      label_i18n: '证件号',
                                      placeholder_i18n: '请填写证件号',
                                      disabled: false,
                                      allowClear: true,
                                      inputType: 'text',
                                      defaultValue: '',
                                      placeholder: '请填写证件号',
                                    },
                                    {
                                      type: 'datepicker',
                                      name: 'id_expiry_date',
                                      label: '证件有效期',
                                      label_i18n: '证件有效期',
                                      placeholder_i18n: '请填写证件有效期',
                                      disabled: false,
                                      allowClear: true,
                                      inputType: 'number',
                                      defaultValue: '',
                                      showTime: false,
                                      valueFormat: 'YYYY-MM-DD',
                                      placeholder: '请填写证件有效期',
                                    },
                                  ],
                                  rules: {
                                    name: [{ required: true, message: t('请填输入姓名') }],
                                    gender: [{ required: true, message: t('请填输入性别') }],
                                    date_of_birth: [
                                      { required: true, message: t('请填输入出生日期') },
                                    ],
                                    id_number: [{ required: true, message: t('请填输入证件号') }],
                                    // id_expiry_date: [
                                    //   { required: true, message: '请填输入证件有效期' },
                                    // ],
                                  },
                                  model: reactive({
                                    org_id: current_org?.id,
                                    is_active: true,
                                    adviser_id: Number(data.id),
                                    adviser__full_name: data.full_name,
                                    insurance_id: null,
                                    name: null,
                                    gender: null,
                                    date_of_birth: null,
                                    id_type_id: null,
                                    id_number: null,
                                    id_expiry_date: null,
                                  }),
                                },
                                options: {
                                  gender: [
                                    { value: 0, name: t('男') },
                                    { value: 1, name: t('女') },
                                  ],
                                },
                                ok: (payload: any) => {
                                  return new Promise(resolve1 => {
                                    editModal1.loading = true;
                                    create_beneficiary(payload)
                                      .then((res: any) => {
                                        message.success(t('创建成功'));
                                        resolve1(null);
                                        resolve(res.id as number);
                                      })
                                      .finally(() => {
                                        editModal1.loading = false;
                                      });
                                  });
                                },
                              });
                              modalBox(editModal1);
                            });
                          },
                        },
                        {
                          type: 'textarea',
                          name: 'remark',
                          label: '备注',
                          label_i18n: '备注',
                          placeholder_i18n: '请填写备注',
                          disabled: false,
                          allowClear: true,
                          inputType: 'text',
                          maxlength: 200,
                          defaultValue: '',
                          placeholder: '请填写备注',
                        },
                      ],
                      rules: {
                        start_end_date: [{ required: true, message: t('请填输入起停保日期时间') }],
                        is_current: [{ required: true, message: t('请填输入当前保险') }],
                        month_insurance_fee: [{ required: true, message: t('请填输入每月保费') }],
                      },
                      model: reactive({
                        org_id: current_org?.id,
                        is_active: true,
                        adviser_id: Number(data.id),
                        adviser__full_name: data.name,
                        insurance_company_id: null,
                        policy_no: null,
                        remark: null,
                        start_end_date: null,
                        effective_date_time: null,
                        expiry_date_time: null,
                        is_current: true,
                        insurance_fee: null,
                        beneficiary_ids: null,
                        nsfile_id: null,
                        month_insurance_fee: null,
                        insurance_type_id: null,
                      }),
                      watch: {
                        insurance_type_id: (data1: any) => {
                          if (data1.insurance_type_id) {
                            query_insurance_type_list({
                              org_id: current_org?.id,
                              ids: [data1.insurance_type_id],
                            }).then((res: any) => {
                              if (res && res.data && res.data.length > 0) {
                                data1.month_insurance_fee = res.data[0].month_insurance_fee;
                              }
                            });
                          }
                        },
                      },
                    },
                    options: {
                      is_current: [
                        { value: true, name: t('是') },
                        { value: false, name: t('否') },
                      ],
                    },
                    ok: (payload: any) => {
                      return new Promise(resolve => {
                        editModal.loading = true;
                        create_insurance({
                          ...payload,
                          effective_date_time: payload.start_end_date[0],
                          expiry_date_time: payload.start_end_date[1],
                        })
                          .then(() => {
                            message.success(t('添加成功'));
                            event_obj.emit('refresh');
                            resolve(null);
                          })
                          .finally(() => {
                            editModal.loading = false;
                          });
                      });
                    },
                  });
                  modalBox(editModal);
                }
              })
              .finally(() => {
                resolve(null);
              });
          });
        },
        onCancel() {
          const editModal = reactive({
            visible: true,
            title: t('新增保险'),
            loading: false,
            form: {
              settings: {
                labelAlign: 'right',
                layout: 'vertical',
                col: [],
              },
              fields: [
                {
                  type: 'input',
                  name: 'adviser__full_name',
                  label: '顾问',
                  label_i18n: '顾问',
                  placeholder_i18n: '请选择隶属顾问',
                  disabled: true,
                  placeholder: '请选择隶属顾问',
                  datasourceType: 'remote',
                  allowClear: false,
                },
                {
                  type: 'remoteselect',
                  name: 'insurance_company_id',
                  label: '保险公司',
                  label_i18n: '保险公司',
                  placeholder_i18n: '请选择保险公司',
                  disabled: false,
                  placeholder: '请选择保险公司',
                  datasourceType: 'remote',
                  mode: 'default',
                  allowClear: true,
                  labelKey: 'company_name',
                  valueKey: 'id',
                  modalType: 'baseinfo-insurancecompany',
                },
                {
                  type: 'input',
                  name: 'policy_no',
                  label: '保险单号',
                  label_i18n: '保险单号',
                  placeholder_i18n: '请填写保险单号',
                  disabled: false,
                  allowClear: true,
                  inputType: 'text',
                  defaultValue: '',
                  placeholder: '请填写保险单号',
                },
                {
                  type: 'rangepicker',
                  name: 'start_end_date',
                  label: '起保-停保日期时间',
                  label_i18n: '起保-停保日期时间',
                  placeholder_i18n: '请填写起保日期时间',
                  disabled: false,
                  allowClear: true,
                  inputType: 'number',
                  defaultValue: '',
                  showTime: false,
                  valueFormat: 'YYYY-MM-DD',
                  placeholder: '请填写起保日期时间',
                },
                {
                  type: 'radio',
                  name: 'is_current',
                  label: '当前保险',
                  label_i18n: '当前保险',
                  placeholder_i18n: '请填写当前保险',
                  disabled: false,
                  labelKey: 'name',
                  valueKey: 'value',
                  defaultValue: '',
                },
                {
                  type: 'remoteselect',
                  name: 'insurance_type_id',
                  label: '保险类型',
                  label_i18n: '保险类型',
                  placeholder_i18n: '请选择保险类型',
                  disabled: false,
                  placeholder: '请选择保险类型',
                  datasourceType: 'remote',
                  mode: 'default',
                  allowClear: true,
                  labelKey: 'name',
                  valueKey: 'id',
                  modalType: 'baseinfo-query_insurance_type_list',
                },
                {
                  type: 'input',
                  name: 'month_insurance_fee',
                  label: '每月保费',
                  label_i18n: '每月保费',
                  placeholder_i18n: '请填写每月保费',
                  disabled: false,
                  allowClear: true,
                  inputType: 'text',
                  defaultValue: '',
                  placeholder: '请填写每月保费',
                },
                {
                  type: 'remoteselect',
                  name: 'beneficiary_ids',
                  label: '受益人',
                  label_i18n: '受益人',
                  placeholder_i18n: '请选择受益人',
                  disabled: false,
                  placeholder: '请选择受益人',
                  datasourceType: 'remote',
                  mode: 'multiple',
                  allowClear: true,
                  labelKey: 'name',
                  valueKey: 'id',
                  modalType: 'cerp-beneficiary',
                  cb: () => {
                    return new Promise(function (resolve) {
                      const editModal1 = reactive({
                        visible: true,
                        title: t('新建受益人'),
                        loading: false,
                        form: {
                          settings: {
                            labelAlign: 'right',
                            layout: 'vertical',
                            col: [],
                          },
                          fields: [
                            {
                              type: 'input',
                              name: 'adviser__full_name',
                              label: '顾问',
                              label_i18n: '顾问',
                              placeholder_i18n: '请选择隶属顾问',
                              disabled: true,
                              placeholder: '请选择隶属顾问',
                              datasourceType: 'remote',
                              allowClear: false,
                            },
                            {
                              type: 'input',
                              name: 'name',
                              label: '姓名',
                              label_i18n: '姓名',
                              placeholder_i18n: '请填写姓名',
                              disabled: false,
                              allowClear: true,
                              inputType: 'text',
                              defaultValue: '',
                              placeholder: '请填写姓名',
                            },
                            {
                              type: 'radio',
                              name: 'gender',
                              label: '性别',
                              label_i18n: '性别',
                              placeholder_i18n: '性别',
                              disabled: false,
                              labelKey: 'name',
                              valueKey: 'value',
                              defaultValue: '',
                            },
                            {
                              type: 'datepicker',
                              name: 'date_of_birth',
                              label: '出生日期',
                              label_i18n: '出生日期',
                              placeholder_i18n: '请填写出生日期',
                              disabled: false,
                              allowClear: true,
                              inputType: 'number',
                              defaultValue: '',
                              showTime: false,
                              valueFormat: 'YYYY-MM-DD',
                              placeholder: '请填写出生日期',
                            },
                            {
                              type: 'remoteselect',
                              name: 'id_type_id',
                              label: '证件类别',
                              label_i18n: '证件类别',
                              placeholder_i18n: '请选择证件类别',
                              disabled: false,
                              placeholder: '请选择证件类别',
                              datasourceType: 'remote',
                              mode: 'default',
                              allowClear: true,
                              modalType: 'baseinfo-idtype',
                            },
                            {
                              type: 'input',
                              name: 'id_number',
                              label: '证件号',
                              label_i18n: '证件号',
                              placeholder_i18n: '请填写证件号',
                              disabled: false,
                              allowClear: true,
                              inputType: 'text',
                              defaultValue: '',
                              placeholder: '请填写证件号',
                            },
                            {
                              type: 'datepicker',
                              name: 'id_expiry_date',
                              label: '证件有效期',
                              label_i18n: '证件有效期',
                              placeholder_i18n: '请填写证件有效期',
                              disabled: false,
                              allowClear: true,
                              inputType: 'number',
                              defaultValue: '',
                              showTime: false,
                              valueFormat: 'YYYY-MM-DD',
                              placeholder: '请填写证件有效期',
                            },
                          ],
                          rules: {
                            name: [{ required: true, message: t('请填输入姓名') }],
                            gender: [{ required: true, message: t('请填输入性别') }],
                            date_of_birth: [{ required: true, message: t('请填输入出生日期') }],
                            id_number: [{ required: true, message: t('请填输入证件号') }],
                            // id_expiry_date: [{ required: true, message: '请填输入证件有效期' }],
                          },
                          model: reactive({
                            org_id: current_org?.id,
                            is_active: true,
                            adviser_id: Number(data.id),
                            adviser__full_name: data.name,
                            insurance_id: null,
                            name: null,
                            gender: null,
                            date_of_birth: null,
                            id_type_id: null,
                            id_number: null,
                            id_expiry_date: null,
                          }),
                        },
                        options: {
                          gender: [
                            { value: 0, name: t('男') },
                            { value: 1, name: t('女') },
                          ],
                        },
                        ok: (payload: any) => {
                          return new Promise(resolve1 => {
                            editModal1.loading = true;
                            create_beneficiary(payload)
                              .then((res: any) => {
                                message.success(t('创建成功'));
                                resolve(res.id as number);
                                resolve1(null);
                              })
                              .finally(() => {
                                editModal1.loading = false;
                              });
                          });
                        },
                      });
                      modalBox(editModal1);
                    });
                  },
                },
                {
                  type: 'textarea',
                  name: 'remark',
                  label: '备注',
                  label_i18n: '备注',
                  placeholder_i18n: '请填写备注',
                  disabled: false,
                  allowClear: true,
                  inputType: 'text',
                  maxlength: 200,
                  defaultValue: '',
                  placeholder: '请填写备注',
                },
              ],
              rules: {
                start_end_date: [{ required: true, message: t('请填输入起停保日期时间') }],
                is_current: [{ required: true, message: t('请填输入当前保险') }],
                month_insurance_fee: [{ required: true, message: t('请填输入每月保费') }],
              },
              model: reactive({
                org_id: current_org?.id,
                is_active: true,
                adviser_id: Number(data.id),
                adviser__full_name: data.name,
                insurance_company_id: null,
                policy_no: null,
                effective_date_time: null,
                remark: null,
                expiry_date_time: null,
                start_end_date: null,
                is_current: true,
                insurance_fee: null,
                beneficiary_ids: null,
                nsfile_id: null,
                month_insurance_fee: null,
                insurance_type_id: null,
              }),
              watch: {
                insurance_type_id: (data1: any) => {
                  if (data1.insurance_type_id) {
                    query_insurance_type_list({
                      org_id: current_org?.id,
                      ids: [data1.insurance_type_id],
                    }).then((res: any) => {
                      if (res && res.data && res.data.length > 0) {
                        data1.month_insurance_fee = res.data[0].month_insurance_fee;
                      }
                    });
                  }
                },
              },
            },
            options: {
              is_current: [
                { value: true, name: t('是') },
                { value: false, name: t('否') },
              ],
            },
            ok: (payload: any) => {
              return new Promise(resolve => {
                editModal.loading = true;
                create_insurance({
                  ...payload,
                  effective_date_time: payload.start_end_date[0],
                  expiry_date_time: payload.start_end_date[1],
                })
                  .then(() => {
                    message.success(t('添加成功'));
                    event_obj.emit('refresh');
                    resolve(null);
                  })
                  .finally(() => {
                    editModal.loading = false;
                  });
              });
            },
          });
          modalBox(editModal);
        },
      });
    },
  },
];
