import { Component, defineAsyncComponent, h } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import { MenuDataItem } from './typing';
import Layout from '@/layouts/index.vue';
// import UserLayout from '@/layouts/user-layout.vue';
import UserLayout2 from '@/layouts/user-layout2.vue';
import RouteView from '@/layouts/route-view.vue';

const AsyncWorkplace = defineAsyncComponent(() => import('@/views/dashboard/workplace3/index.vue'));
const kefu = defineAsyncComponent(() => import('@/views/kefu/index.vue'));
// const AdviserInformation = defineAsyncComponent(() => import('@/views/profile/basic-adviser/index.vue'));
// const AsyncWorkplace2 = defineAsyncComponent(
//   () => import('@/views/dashboard/workplace3/index2.vue'),
// );
export const routes: MenuDataItem[] = [
  {
    name: 'index',
    path: '/',
    redirect: '/workplace',
    component: Layout,
    children: [
      // dashboard
      {
        path: '/workplace',
        name: 'Workplace',
        meta: { icon: 'HistoryOutlined', title: 'pages.dashboard.workplace.title' },
        component: h(RouteView, {}, () => h(AsyncWorkplace)),
      },
      // {
      //   path: '/adviser_information',
      //   name: 'adviser_information',
      //   meta: { icon: 'HistoryOutlined', title: '顾问详情' },
      //   component: h(RouteView, {}, () => h(AdviserInformation)),
      // },

      {
        path: '/static-info-manage',
        name: 'static-info-manage',
        meta: { icon: 'HeartOutlined', title: '静态信息管理', authority: 'static-info-manage' },
        component: RouteView,
        redirect: '/static-info-manage/baseinfo-ccbankaccountscc',
        children: [
          {
            path: '/static-info-manage/sandbox-custompanel',
            name: 'sandbox-custompanel',
            meta: {
              icon: 'HeartOutlined',
              title: '首页自定义查询看板',
              authority: 'static-info-manage/sandbox-custompanel',
            },
            component: (): Component => import('@/components/page-model/index.vue'),
          },
          {
            path: '/static-info-manage/baseinfo-ccbankaccountscc',
            name: 'baseinfo-ccbankaccountscc',
            meta: {
              icon: 'HeartOutlined',
              title: 'CC银行账户',
              authority: 'static-info-manage/baseinfo-ccbankaccountscc',
            },
            component: (): Component => import('@/views/profile/basic-ccbank/index.vue'),
          },
          {
            path: '/static-info-manage/baseinfo-cccompany',
            name: 'baseinfo-cccompany',
            meta: {
              icon: 'HeartOutlined',
              title: 'CC公司管理',
              authority: 'static-info-manage/baseinfo-cccompany',
            },
            component: (): Component => import('@/components/page-model/index.vue'),
          },
          {
            path: '/static-info-manage/baseinfo-cccompany_contract',
            name: 'baseinfo-cccompany_contract',
            meta: {
              icon: 'HeartOutlined',
              title: 'CC公司合同模板',
              authority: 'static-info-manage/baseinfo-cccompany_contract',
            },
            component: (): Component => import('@/components/page-model/index.vue'),
          },
          {
            path: '/static-info-manage/baseinfo-country',
            name: 'baseinfo-country',
            meta: {
              icon: 'HeartOutlined',
              title: '国家管理',
              authority: 'static-info-manage/baseinfo-country',
            },
            component: (): Component => import('@/components/page-model/index.vue'),
          },
          {
            path: '/static-info-manage/baseinfo-idtype',
            name: 'baseinfo-idtype',
            meta: {
              icon: 'HeartOutlined',
              title: 'ID类别',
              authority: 'static-info-manage/baseinfo-idtype',
            },
            component: (): Component => import('@/components/page-model/index.vue'),
          },
          // {
          //   path: '/static-info-manage/baseinfo-invoicetype',
          //   name: 'baseinfo-invoicetype',
          //   meta: { icon: 'HeartOutlined', title: '请款类型' },
          //   component: (): Component => import('@/components/page-model/index.vue'),
          // },
          {
            path: '/static-info-manage/baseinfo-insurancecompany',
            name: 'baseinfo-insurancecompany',
            meta: {
              icon: 'HeartOutlined',
              title: '保险公司',
              authority: 'static-info-manage/baseinfo-insurancecompany',
            },
            component: (): Component => import('@/components/page-model/index.vue'),
          },
          {
            path: '/static-info-manage/baseinfo-notetype',
            name: 'baseinfo-notetype',
            meta: {
              icon: 'HeartOutlined',
              title: '记录类型',
              authority: 'static-info-manage/baseinfo-notetype',
            },
            component: (): Component => import('@/components/page-model/index.vue'),
          },
          {
            path: '/static-info-manage/baseinfo-persontype',
            name: 'baseinfo-persontype',
            meta: {
              icon: 'HeartOutlined',
              title: '个人类别',
              authority: 'static-info-manage/baseinfo-persontype',
            },
            component: (): Component => import('@/components/page-model/index.vue'),
          },
          {
            path: '/static-info-manage/baseinfo-recuitmentcompany',
            name: 'baseinfo-recuitmentcompany',
            meta: {
              icon: 'HeartOutlined',
              title: '顾问公司',
              authority: 'static-info-manage/baseinfo-recuitmentcompany',
            },
            component: (): Component => import('@/components/page-model/index.vue'),
          },
          {
            path: '/static-info-manage/staticinfo-recuitment_company_user',
            name: 'staticinfo-recuitment_company_user',
            meta: {
              icon: 'HeartOutlined',
              title: '顾问公司账号',
              authority: 'static-info-manage/staticinfo-recuitment_company_user',
            },
            component: (): Component => import('@/components/page-model/index.vue'),
          },
          {
            path: '/static-info-manage/baseinfo-recuitmentcompanyperson',
            name: 'baseinfo-recuitmentcompanyperson',
            meta: {
              icon: 'HeartOutlined',
              title: '顾问公司联系人',
              authority: 'static-info-manage/baseinfo-recuitmentcompanyperson',
            },
            component: (): Component => import('@/components/page-model/index.vue'),
          },
          {
            path: '/static-info-manage/baseinfo-referencenumbertype',
            name: 'baseinfo-referencenumbertype',
            meta: {
              icon: 'HeartOutlined',
              title: '参考号类别',
              authority: 'static-info-manage/baseinfo-referencenumbertype',
            },
            component: (): Component => import('@/components/page-model/index.vue'),
          },
          // {
          //   path: '/static-info-manage/baseinfo-status',
          //   name: 'baseinfo-status',
          //   meta: { icon: 'HeartOutlined', title: '个人状态' },
          //   component: (): Component => import('@/components/page-model/index.vue'),
          // },
          {
            path: '/static-info-manage/baseinfo-managementfeeformula',
            name: 'baseinfo-managementfeeformula',
            meta: {
              icon: 'HeartOutlined',
              title: '管理费计算方式',
              authority: 'static-info-manage/baseinfo-managementfeeformula',
            },
            component: (): Component => import('@/components/page-model/index.vue'),
          },
          {
            path: '/static-info-manage/baseinfo-ccemail',
            name: 'baseinfo-ccemail',
            meta: {
              icon: 'HeartOutlined',
              title: 'CC邮箱管理',
              authority: 'static-info-manage/baseinfo-ccemail',
            },
            component: (): Component => import('@/components/page-model/index.vue'),
          },
          {
            path: '/static-info-manage/baseinfo-emailtemplate',
            name: 'baseinfo-emailtemplate',
            meta: {
              icon: 'HeartOutlined',
              title: '邮箱模板',
              authority: 'static-info-manage/baseinfo-emailtemplate',
            },
            component: (): Component => import('@/components/page-model/index.vue'),
          },
          {
            path: '/static-info-manage/baseinfo-emailaddress',
            name: 'baseinfo-emailaddress',
            meta: {
              icon: 'HeartOutlined',
              title: '收件箱地址管理',
              authority: 'static-info-manage/baseinfo-emailaddress',
            },
            component: (): Component => import('@/components/page-model/index.vue'),
          },
          {
            path: '/static-info-manage/cerp-changehistory',
            name: 'cerp-changehistory',
            meta: {
              icon: 'HeartOutlined',
              title: '数据变动',
              authority: 'static-info-manage/cerp-changehistory',
            },
            component: (): Component => import('@/components/page-model/index.vue'),
          },
          {
            path: '/static-info-manage/baseinfo-contracttemplate',
            name: 'baseinfo-contracttemplate',
            meta: {
              icon: 'HeartOutlined',
              title: '合同模板',
              authority: 'static-info-manage/baseinfo-contracttemplate',
            },
            component: (): Component => import('@/components/page-model/index.vue'),
          },
          {
            path: '/static-info-manage/baseinfo-bankmanage',
            name: 'baseinfo-bankmanage',
            meta: {
              icon: 'HeartOutlined',
              title: '银行管理',
              authority: 'static-info-manage/baseinfo-bankmanage',
            },
            component: (): Component => import('@/components/page-model/index.vue'),
          },
          {
            path: '/static-info-manage/baseinfo-endclient',
            name: 'baseinfo-endclient',
            meta: {
              icon: 'HeartOutlined',
              title: '终端用户',
              authority: 'mastatic-info-manage/baseinfo-endclient',
            },
            component: (): Component => import('@/components/page-model/index.vue'),
          },
          {
            path: '/static-info-manage/baseinfo-to_do_event_priority',
            name: 'baseinfo-to_do_event_priority',
            meta: {
              icon: 'HeartOutlined',
              title: '待办优先等级',
              authority: 'mastatic-info-manage/baseinfo-to_do_event_priority',
            },
            component: (): Component => import('@/components/page-model/index.vue'),
          },
          {
            path: '/static-info-manage/baseinfo-question',
            name: 'baseinfo-question',
            meta: {
              icon: 'HeartOutlined',
              title: '客服问题管理',
              authority: 'static-info-manage/baseinfo-question',
            },
            component: (): Component => import('@/views/profile/basic-question/index.vue'),
          },
          {
            path: '/static-info-manage/baseinfo-exchangerate',
            name: 'baseinfo-exchangerate',
            meta: {
              icon: 'HeartOutlined',
              title: '汇率',
              authority: 'static-info-manage/baseinfo-exchangerate',
            },
            component: (): Component => import('@/components/page-model/index.vue'),
          },
          {
            path: '/static-info-manage/baseinfo-wechatmainpage',
            name: 'baseinfo-wechatmainpage',
            meta: {
              icon: 'HeartOutlined',
              title: '微信小程序主页',
              authority: 'static-info-manage/baseinfo-wechatmainpage',
            },
            component: (): Component => import('@/components/page-model/index.vue'),
          },
          {
            path: '/static-info-manage/baseinfo-tag',
            name: 'baseinfo-tag',
            meta: {
              icon: 'HeartOutlined',
              title: '标签',
              authority: 'static-info-manage/baseinfo-tag',
            },
            component: (): Component => import('@/components/page-model/index.vue'),
          },
          {
            path: '/static-info-manage/baseinfo-markdown',
            name: 'baseinfo-markdown',
            meta: {
              icon: 'HeartOutlined',
              title: '公告与重要信息',
              authority: 'static-info-manage/baseinfo-markdown',
            },
            component: (): Component => import('@/components/page-model/index.vue'),
          },
          {
            path: '/static-info-manage/baseinfo-tip',
            name: 'baseinfo-tip',
            meta: {
              icon: 'HeartOutlined',
              title: '重要提示',
              authority: 'static-info-manage/baseinfo-tip',
            },
            component: (): Component => import('@/components/page-model/index.vue'),
          },
          {
            path: '/static-info-manage/baseinfo-processtip',
            name: 'baseinfo-processtip',
            meta: {
              icon: 'HeartOutlined',
              title: '入职流程提示',
              authority: 'static-info-manage/baseinfo-processtip',
            },
            component: (): Component => import('@/components/page-model/index.vue'),
          },
          {
            path: '/static-info-manage/staticinfo-insurance_type',
            name: 'staticinfo-insurance_type',
            meta: {
              icon: 'HeartOutlined',
              title: '保险类型管理',
              authority: 'static-info-manage/staticinfo-insurance_type',
            },
            component: (): Component => import('@/components/page-model/index.vue'),
          },
          // {
          //   path: '/static-info-manage/time_calendar1',
          //   name: 'staticinfo-time_calendar1',
          //   meta: {
          //     icon: 'HeartOutlined',
          //     title: '工时日历',
          //     authority: 'static-info-manage/staticinfo-time_calendar',
          //   },
          //   component: (): Component => import('@/views/time_calendar/index.vue'),
          // },
          {
            path: '/static-info-manage/baseinfo-time_calendar',
            name: 'staticinfo-time_calendar',
            meta: {
              icon: 'HeartOutlined',
              title: '工时日历模板',
              authority: 'static-info-manage/staticinfo-time_calendar',
            },
            component: (): Component => import('@/components/page-model/index.vue'),
          },
          {
            path: '/static-info-manage/baseinfo-notice/:id/',
            name: 'baseinfo-notice',
            meta: {
              icon: 'HeartOutlined',
              title: '公告详情',
              hideInMenu: true,
            },
            component: (): Component => import('@/views/markdown/index.vue'),
          },
        ],
      },
      {
        path: '/adviser-manage',
        name: 'adviser-manage',
        meta: {
          icon: 'HeartOutlined',
          title: 'router.title.adviser-manage',
          authority: 'adviser-manage',
        },
        component: RouteView,
        redirect: '/adviser-manage/cerp-adviser',
        children: [
          {
            path: '/adviser-manage/cerp-adviser',
            name: 'cerp-adviser',
            meta: {
              icon: 'HeartOutlined',
              title: 'router.title.cerp-adviser',
              authority: 'adviser-manage/cerp-adviser',
            },
            component: (): Component => import('@/components/page-model/index.vue'),
          },
          {
            path: '/adviser-manage/basic-balance_statement',
            name: 'basic-balance_statement',
            meta: {
              icon: 'HeartOutlined',
              title: 'router.title.basic-balance_statement',
              authority: 'adviser-manage/basic-balance_statement',
            },
            component: (): Component =>
              import('@/views/profile/basic-balance_statement/basic-balance_statement.vue'),
          },
          {
            path: '/adviser-manage/cerp-payslip_report',
            name: 'cerp-payslip_report',
            meta: {
              icon: 'HeartOutlined',
              title: 'router.title.cerp-payslip_report',
              authority: 'adviser-manage/cerp-payslip_report',
            },
            component: (): Component => import('@/components/page-model/index.vue'),
          },
          {
            path: '/adviser-manage/cerp-bankaccount',
            name: 'cerp-bankaccount',
            meta: {
              icon: 'HeartOutlined',
              title: 'router.title.cerp-bankaccount',
              authority: 'adviser-manage/cerp-bankaccount',
            },
            component: (): Component => import('@/components/page-model/index.vue'),
          },
          {
            path: '/adviser-manage/cerp-bankaccount1',
            name: 'cerp-bankaccount1',
            meta: {
              icon: 'HeartOutlined',
              title: 'router.title.cerp-bankaccount1',
              authority: 'adviser-manage/cerp-bankaccount1',
            },
            component: (): Component => import('@/components/page-model/index.vue'),
          },
          {
            path: '/adviser-manage/cerp-banktransaction',
            name: 'cerp-banktransaction',
            meta: {
              icon: 'HeartOutlined',
              title: 'router.title.cerp-banktransaction',
              authority: 'adviser-manage/cerp-banktransaction',
            },
            component: (): Component => import('@/components/page-model/index.vue'),
          },
          {
            path: '/adviser-manage/cerp-contract',
            name: 'cerp-contract',
            meta: {
              icon: 'HeartOutlined',
              title: 'router.title.cerp-contract',
              authority: 'adviser-manage/cerp-contract',
            },
            component: (): Component => import('@/components/page-model/index.vue'),
          },
          {
            path: '/adviser-manage/cerp-insurance',
            name: 'cerp-insurance',
            meta: {
              icon: 'HeartOutlined',
              title: 'router.title.cerp-insurance',
              authority: 'adviser-manage/cerp-insurance',
            },
            component: (): Component => import('@/components/page-model/index.vue'),
          },
          {
            path: '/adviser-manage/cerp-beneficiary',
            name: 'cerp-beneficiary',
            meta: {
              icon: 'HeartOutlined',
              title: 'router.title.cerp-beneficiary',
              authority: 'adviser-manage/cerp-beneficiary',
            },
            component: (): Component => import('@/components/page-model/index.vue'),
          },
          {
            path: '/adviser-manage/cerp-remittanceset',
            name: 'cerp-remittanceset',
            meta: {
              icon: 'HeartOutlined',
              title: 'router.title.cerp-remittanceset',
              authority: 'adviser-manage/cerp-remittanceset',
            },
            component: (): Component => import('@/components/page-model/index.vue'),
          },
          {
            path: '/adviser-manage/cerp-invoice',
            name: 'cerp-invoice',
            meta: {
              icon: 'HeartOutlined',
              title: 'router.title.cerp-invoice',
              authority: 'adviser-manage/cerp-invoice',
            },
            component: (): Component => import('@/views/profile/basic-invoice1/index.vue'),
          },
          {
            path: '/adviser-manage/cerp-payslip',
            name: 'cerp-payslip',
            meta: {
              icon: 'HeartOutlined',
              title: 'router.title.cerp-payslip',
              authority: 'adviser-manage/cerp-payslip',
            },
            component: (): Component => import('@/views/profile/basic-payslip/index.vue'),
          },
          // {
          //   path: '/adviser-manage/cerp-customfield',
          //   name: 'cerp-customfield',
          //   meta: { icon: 'HeartOutlined', title: '自定义项' },
          //   component: (): Component => import('@/components/page-model/index.vue'),
          // },
          {
            path: '/adviser-manage/cerp-address',
            name: 'cerp-address',
            meta: {
              icon: 'HeartOutlined',
              title: 'router.title.cerp-address',
              authority: 'adviser-manage/cerp-address',
            },
            component: (): Component => import('@/components/page-model/index.vue'),
          },
          {
            path: '/adviser-manage/cerp-nextofkin',
            name: 'cerp-nextofkin',
            meta: {
              icon: 'HeartOutlined',
              title: 'router.title.cerp-nextofkin',
              authority: 'adviser-manage/cerp-nextofkin',
            },
            component: (): Component => import('@/components/page-model/index.vue'),
          },
          {
            path: '/adviser-manage/cerp-note',
            name: 'cerp-note',
            meta: {
              icon: 'HeartOutlined',
              title: 'router.title.cerp-note',
              authority: 'adviser-manage/cerp-note',
            },
            component: (): Component => import('@/components/page-model/index.vue'),
          },
          {
            path: '/adviser-manage/cerp-personreferencenumber',
            name: 'cerp-personreferencenumber',
            meta: {
              icon: 'HeartOutlined',
              title: 'router.title.cerp-personreferencenumber',
              authority: 'adviser-manage/cerp-personreferencenumber',
            },
            component: (): Component => import('@/components/page-model/index.vue'),
          },
          {
            path: '/adviser-manage/cerp-social_insurance',
            name: 'cerp-social_insurance',
            meta: {
              icon: 'HeartOutlined',
              title: 'router.title.cerp-social_insurance',
              authority: 'adviser-manage/cerp-social_insurance',
            },
            component: (): Component => import('@/components/page-model/index.vue'),
          },
          {
            path: '/static-info-manage/cerp-tiprecord',
            name: 'cerp-tiprecord',
            meta: {
              icon: 'HeartOutlined',
              title: 'router.title.cerp-tiprecord',
              authority: 'static-info-manage/cerp-tiprecord',
            },
            component: (): Component => import('@/components/page-model/index.vue'),
          },
          {
            path: '/adviser-manage/basic-remittance/:id/:name',
            name: 'basic-remittance',
            meta: {
              icon: 'HeartOutlined',
              title: '汇款凭证项',
              hideInMenu: true,
              authority: 'adviser-manage/basic-remittance',
            },
            component: (): Component => import('@/views/profile/basic-remittance/index.vue'),
          },
          {
            path: '/profile/basic/:id/:full_name/:employee_number/:contract_id',
            name: 'basic',
            meta: {
              icon: 'HeartOutlined',
              title: '顾问详情页',
              hideInMenu: true,
              authority: 'profile/basic',
            },
            component: (): Component => import('@/views/profile/basic/index.vue'),
          },
          {
            path: '/profile/basic-invoice/:id',
            name: 'basic-invoice',
            meta: {
              icon: 'HeartOutlined',
              title: '请款单详情页',
              hideInMenu: true,
              authority: 'profile/basic-invoice',
            },
            component: (): Component => import('@/views/profile/basic-invoice/index.vue'),
          },
          {
            path: '/profile/basic-insurance/:id',
            name: 'basic-insurance',
            meta: {
              icon: 'HeartOutlined',
              title: '新增保险',
              hideInMenu: true,
              authority: 'profile/basic-insurance',
            },
            component: (): Component => import('@/views/profile/basic-insurance/index.vue'),
          },
          {
            path: '/profile/basic-remittanceset/:id/:name/:date/:total_amount/:invoice_id',
            name: 'basic-remittanceset',
            meta: {
              icon: 'HeartOutlined',
              title: '汇款凭证',
              hideInMenu: true,
              authority: 'profile/basic-remittanceset',
            },
            component: (): Component => import('@/views/profile/basic-remittanceset/index.vue'),
          },
          {
            path: '/adviser-manage/cerp-todoevent',
            name: 'cerp-todoevent',
            meta: {
              icon: 'HeartOutlined',
              title: 'router.title.cerp-todoevent',
              authority: 'adviser-manage/cerp-todoevent',
            },
            component: (): Component => import('@/components/page-model/index.vue'),
          },
          {
            path: '/adviser-manage/cerp-wechatupdaterecord',
            name: 'cerp-wechatupdaterecord',
            meta: {
              icon: 'HeartOutlined',
              title: 'router.title.cerp-wechatupdaterecord',
              authority: 'adviser-manage/cerp-wechatupdaterecord',
            },
            component: (): Component => import('@/components/page-model/index.vue'),
          },
          {
            path: '/adviser-manage/cerp-extra_field',
            name: 'cerp-extra_field',
            meta: {
              icon: 'HeartOutlined',
              title: 'router.title.cerp-extra_field',
              authority: 'adviser-manage/cerp-wechatupdaterecord',
            },
            component: (): Component => import('@/components/page-model/index.vue'),
          },
          {
            path: '/adviser-manage/cerp-additional_item_abnormal_data',
            name: 'cerp-additional_item_abnormal_data',
            meta: {
              icon: 'HeartOutlined',
              title: 'router.title.cerp-additional_item_abnormal_data',
              authority: 'adviser-manage/cerp-wechatupdaterecord',
            },
            component: (): Component => import('@/components/page-model/index.vue'),
          },
        ],
      },
      {
        path: '/receipt',
        name: 'receipt',
        meta: { icon: 'HeartOutlined', title: '票据管理', authority: 'main.receipt' },
        component: RouteView,
        redirect: '/receipt/receipt-type',
        children: [
          {
            path: '/receipt/receipt-type',
            name: 'receipt-type',
            meta: {
              icon: 'HeartOutlined',
              title: '票据种类',
              authority: 'receipt-type',
            },
            component: (): Component => import('@/components/page-model/index.vue'),
          },
          {
            path: '/receipt/receipt-contract_template',
            name: 'receipt-contract_template',
            meta: {
              icon: 'HeartOutlined',
              title: '票据合同模板',
              authority: 'receipt-contract_template',
            },
            component: (): Component => import('@/components/page-model/index.vue'),
          },
          {
            path: '/receipt/receipt-receipt_template',
            name: 'receipt-receipt_template',
            meta: {
              icon: 'HeartOutlined',
              title: '票据模板',
              authority: 'receipt-receipt_template',
            },
            component: (): Component => import('@/components/page-model/index.vue'),
          },
          {
            path: '/receipt/receipt-customer',
            name: 'receipt-customer',
            meta: {
              icon: 'HeartOutlined',
              title: '客户管理',
              authority: 'receipt-customer',
            },
            component: (): Component => import('@/components/page-model/index.vue'),
          },
          {
            path: '/receipt/receipt-contract',
            name: 'receipt-contract',
            meta: {
              icon: 'HeartOutlined',
              title: '票据合同',
              authority: 'receipt-contract',
            },
            component: (): Component => import('@/components/page-model/index.vue'),
          },
          {
            path: '/receipt/receipt-receipt',
            name: 'receipt-receipt',
            meta: {
              icon: 'HeartOutlined',
              title: '发票清单',
              authority: 'receipt-receipt',
            },
            component: (): Component => import('@/components/page-model/index.vue'),
          },
          {
            path: '/receipt/receipt-payment',
            name: 'receipt-payment',
            meta: {
              icon: 'HeartOutlined',
              title: '付款记录',
              authority: 'receipt-payment',
            },
            component: (): Component => import('@/components/page-model/index.vue'),
          },
        ],
      },
      {
        name: '权限管理',
        path: '/permission',
        meta: { icon: 'HeartOutlined', title: '权限管理', authority: 'permission' },
        component: RouteView,
        redirect: '/permission/role-manage',
        children: [
          {
            path: '/permission/menu-manage',
            name: 'menu-manage',
            meta: { icon: 'HeartOutlined', title: '菜单管理', authority: 'permission/menu-manage' },
            component: (): Component => import('@/views/permission/menu-manage/index.vue'),
          },
          {
            path: '/permission/apiurl-manage',
            name: 'apiurl-manage',
            meta: {
              icon: 'HeartOutlined',
              title: '接口管理',
              authority: 'permission/apiurl-manage',
            },
            component: (): Component => import('@/views/permission/apiurl-manage/index.vue'),
            redirect: '/permission/apiurl-manage/index-1',
            children: [
              {
                path: '/permission/apiurl-manage/permission-apiurl_manage1',
                name: 'apiurl-manage-1',
                meta: { title: '接口管理', authority: 'permission/apiurl-manage/index-1' },
                component: (): Component => import('@/components/page-model/index.vue'),
              },
              {
                path: '/permission/apiurl-manage/permission-apiurl_manage2',
                name: 'apiurl-manage-2',
                meta: { title: '未绑定菜单的接口', authority: 'permission/apiurl-manage/index-2' },
                component: (): Component => import('@/components/page-model/index.vue'),
              },
            ],
          },
          {
            path: '/permission/permission-role-manage',
            name: 'role-manage',
            meta: { icon: 'HeartOutlined', title: '角色管理', authority: 'permission/role-manage' },
            component: (): Component => import('@/components/page-model/index.vue'),
          },
          {
            path: '/permission/auth-manage',
            name: 'auth-manage',
            meta: { icon: 'HeartOutlined', title: '权限管理', authority: 'permission/auth-manage' },
            component: (): Component => import('@/views/permission/auth-manage/index.vue'),
          },
          {
            path: '/permission/cerp-person_to_company',
            name: 'person_to_company',
            meta: { icon: 'HeartOutlined', title: 'CC公司权限', authority: 'permission/person_to_company' },
            component: (): Component => import('@/components/page-model/index.vue'),
          },
        ],
      },
      {
        name: '人员组织',
        path: '/personnel-organization',
        meta: { icon: 'HeartOutlined', title: '人员组织', authority: 'personnel-organization' },
        component: RouteView,
        redirect: '/permission/permission-user-manage',
        children: [
          {
            path: '/permission/permission-user-manage',
            name: 'user-manage',
            meta: { icon: 'HeartOutlined', title: '用户管理', authority: 'permission/user-manage' },
            component: (): Component => import('@/components/page-model/index.vue'),
          },
          {
            path: '/permission/group-manage',
            name: 'group-manage',
            meta: {
              icon: 'HeartOutlined',
              title: '部门管理',
              authority: 'permission/group-manage',
            },
            component: (): Component => import('@/views/permission/group-manage/index.vue'),
          },

          // {
          //   path: '/permission/form-design-test',
          //   name: 'form-design-test',
          //   meta: {
          //     icon: 'HeartOutlined',
          //     title: '表单设计器',
          //     authority: 'permission/form-design-test',
          //   },
          //   component: (): Component => import('@/components/FormDesign/FormDesign.vue'),
          // },
          // {
          //   path: '/permission/user-test',
          //   name: 'page-model-test',
          //   meta: { icon: 'HeartOutlined', title: '页面组件', authority: 'permission/user-test' },
          //   component: (): Component => import('@/components/page-model/index.vue'),
          // },
          // {
          //   path: '/permission/codemirror',
          //   name: 'codemirror',
          //   meta: {
          //     icon: 'HeartOutlined',
          //     title: '代码编辑器',
          //     authority: 'permission/codemirror',
          //   },
          //   component: (): Component => import('@/views/permission/codemirrorpage/index.vue'),
          // },
          // {
          //   path: '/workplace2',
          //   name: 'Workplace2',
          //   meta: { icon: 'HistoryOutlined', title: 'Grid布局', authority: 'workplace2' },
          //   component: (): Component => import('@/views/dashboard/workplace3/index2.vue'),
          // },
        ],
      },
      {
        name: '客服',
        path: '/kefu',
        meta: { icon: 'HeartOutlined', title: '客服'},
        component: RouteView,
        redirect: '/kefu/apidoc-project',
        children: [
          {
            path: '/kefu/service-service',
            name: 'service-service',
            meta: {
              icon: 'HeartOutlined',
              title: '客服人员',
            },
            component: (): Component => import('@/components/page-model/index.vue'),
          },
          {
            path: '/kefu/kefu-session',
            name: 'kefu-session',
            meta: {
              icon: 'HeartOutlined',
              title: '客服',
            },
            component: h(RouteView, {}, () => h(kefu)),
          },
        ],
      },
      {
        name: '接口文档',
        path: '/apidoc',
        meta: { icon: 'HeartOutlined', title: '接口文档', authority: 'apidoc' },
        component: RouteView,
        redirect: '/apidoc/apidoc-project',
        children: [
          {
            path: '/apidoc/apidoc-project',
            name: '项目管理',
            meta: { icon: 'HeartOutlined', title: '项目', authority: 'apidoc/apidoc-project' },
            component: (): Component => import('@/components/page-model/index.vue'),
          },
          {
            path: '/apidoc/apidoc-app',
            name: '应用管理',
            meta: { icon: 'HeartOutlined', title: '应用', authority: 'apidoc/apidoc-app' },
            component: (): Component => import('@/components/page-model/index.vue'),
          },
          {
            path: '/apidoc/apidoc-gitproject',
            name: 'Git项目',
            meta: {
              icon: 'HeartOutlined',
              title: 'Git项目',
              authority: 'apidoc/apidoc-gitproject',
            },
            component: (): Component => import('@/components/page-model/index.vue'),
          },
          {
            path: '/apidoc/apidoc-gitbranch',
            name: 'Git项目分支',
            meta: {
              icon: 'HeartOutlined',
              title: 'Git项目分支',
              authority: 'apidoc/apidoc-gitbranch',
            },
            component: (): Component => import('@/components/page-model/index.vue'),
          },
          {
            path: '/apidoc/apidoc-apiinfo',
            name: 'API接口',
            meta: { icon: 'HeartOutlined', title: 'API接口', authority: 'apidoc/apidoc-apiinfo' },
            component: (): Component => import('@/components/page-model/index.vue'),
          },
          {
            path: '/apidoc/apidoc-parameter',
            name: '接口参数',
            meta: {
              icon: 'HeartOutlined',
              title: '接口参数',
              authority: 'apidoc/apidoc-parameter',
            },
            component: (): Component => import('@/components/page-model/index.vue'),
          },
          {
            path: '/apidoc/apidoc-listresultfield',
            name: '接口返回值',
            meta: {
              icon: 'HeartOutlined',
              title: '接口返回值',
              authority: 'apidoc/apidoc-listresultfield',
            },
            component: (): Component => import('@/components/page-model/index.vue'),
          },
          {
            path: '/apidoc/apidoc-pageinfo',
            name: '页面组件',
            meta: { icon: 'HeartOutlined', title: '页面组件', authority: 'apidoc/apidoc-pageinfo' },
            component: (): Component => import('@/components/page-model/index.vue'),
          },
        ],
      },

      // dashboard
      // form
      // list
      // profile
      // exception 异常页
    ],
  },
];

export const staticRoutes: MenuDataItem[] = [
  {
    path: '/user',
    name: 'user',
    meta: { hideInMenu: true, title: 'pages.layouts.userLayout.title' },
    component: UserLayout2,
    children: [
      {
        path: '/user/login',
        name: 'login',
        meta: { title: 'pages.login.accountLogin.tab' },
        component: () => import('@/views/user/login.vue'),
      },
      {
        path: '/user/register',
        name: 'register',
        meta: { title: 'pages.login.registerAccount' },
        component: () => import('@/views/user/register.vue'),
      },
      {
        path: '/user/register-result',
        name: 'register-result',
        meta: { title: 'pages.login.registerAccount' },
        component: () => import('@/views/user/register-result.vue'),
      },
    ],
  },
  {
    path: '/:pathMatch(.*)',
    component: () => import('@/views/exception/404.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_PUBLIC_PATH),
  routes: staticRoutes,
});

export default router;
