import {
  create_contract,
  delete_contract,
  query_contract_list,
  recover_contract,
  update_contract,
} from '@/api/cerp-model';
import { Record, SearchConfig } from '@/components/page-model/typing';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import { btn_list, event_obj, head_btn_list } from '@/components/page-model2/cerp/cerp-contract-config';
import { baseColumns } from '@/components/page-model/cerp/cerp-contract-colums';
import { reactive } from 'vue';
import slotList from '@/components/page-model/slotList';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();


const newSlotList: any = [];
Object.assign(newSlotList, slotList);
const None = null;
const True = true;
const False = false;

const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org

const search_config: SearchConfig = {
  settings: {},
  fields: [],
  rules: {},
  model: reactive({}),
};
const search_options = {};

const edit_model_config: SearchConfig = {
  settings: {},
  fields: [],
  rules: {},
  model: reactive({}),
};
const edit_model_config_options = {};

const update_model_config: SearchConfig = {
  settings: {},
  fields: [],
  rules: {},
  model: reactive({}),
};
const update_model_config_options = {};

const titleList = {
  title: t('合同管理'),
  addModalTitle: t('新合同'),
  updateTitle: t('修改合同'),
  is_create: false, // 是否有【新建】按钮
  is_update: false, // 是否有【修改】按钮
  is_remove: true, // 是否有【删除】按钮
  is_recover: true, // 是否有【恢复】按钮
};

const api_fields: Record = {
  update: {
    contract_id: 'id',
  },
  delete: {
    contract_id: 'id',
  },
  recover: {
    contract_id: 'id',
  },
  updateData: {
    org_id: 'org_id',
    contract_id: 'id',
  },
};
newSlotList.push();
export default () => {
  return {
    searchConfig: search_config,
    searchConfigOptions: search_options,
    formModalConfig: edit_model_config,
    formModalConfigOptions: edit_model_config_options,
    updateFormModalConfig: update_model_config,
    updateFormModalConfigOptions: update_model_config_options,
    queryData: query_contract_list,
    addData: create_contract,
    updateData: update_contract,
    removeData: delete_contract,
    recoverData: recover_contract,
    baseColumns: baseColumns,
    slotList: newSlotList,
    titleList: titleList,
    api_fields: api_fields,
    btn_list: btn_list,
    head_btn_list: head_btn_list,
    event_obj: event_obj,
  };
};
