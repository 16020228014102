import {
  create_c_c_bank_number_curreny,
  delete_c_c_bank_number_curreny,
  query_c_c_bank_number_curreny_list,
  recover_c_c_bank_number_curreny,
  update_c_c_bank_number_curreny,
} from '@/api/baseinfo-model';
import { TableColumn } from '@/typing';
import { Record, SearchConfig } from '@/components/page-model/typing';
import { useI18n } from 'vue-i18n';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import {
  btn_list,
  event_obj,
  head_btn_list,
  slotList,
} from '@/components/page-model4/baseinfo/baseinfo-ccbanknumbercurreny-config';

import { reactive } from 'vue';
import { getDateTime } from '@/utils/function';

const None = null;
const True = true;
const False = false;

const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org

const { t } = useI18n();
const search_config: SearchConfig = {
  settings: {},
  fields: [],
  rules: {},
  model: reactive({}),
};
const search_options = {};

const edit_model_config: SearchConfig = {
  settings: {},
  fields: [],
  rules: {},
  model: reactive({}),
};
const edit_model_config_options = {};

const update_model_config: SearchConfig = {
  settings: {},
  fields: [],
  rules: {},
  model: reactive({}),
};
const update_model_config_options = {};

const titleList = {
  title: 'CC银行账户管理',
  addModalTitle: '新建CC银行账户',
  updateTitle: '修改CC银行账户',
  is_create: false, // 是否有【新建】按钮
  is_update: false, // 是否有【修改】按钮
  is_remove: true, // 是否有【删除】按钮
  is_recover: true, // 是否有【恢复】按钮
};

const api_fields: Record = {
  update: {
    cc_bank_number_curreny_id: 'id',
  },
  delete: {
    cc_bank_number_curreny_id: 'id',
  },
  recover: {
    cc_bank_number_curreny_id: 'id',
  },
  updateData: {
    org_id: 'org_id',
    cc_bank_number_curreny_id: 'id',
  },
};

const baseColumns: TableColumn[] = [
  {
    title: t('序号'),
    dataIndex: 'index',
    width: 40,
  },
  {
    title: 'ID',
    dataIndex: 'id',
    width: 50,
    checked: false,
  },
  {
    title: t('账号'),
    dataIndex: 'account_number',
    minWidth: 150,
    checked: true,
  },
  {
    title: t('币种'),
    dataIndex: 'currenies',
    minWidth: 150,
    checked: true,
    cellComponent: 'list',
    listKey: 'currency',
  },
  {
    title: t('更新时间'),
    dataIndex: 'update_time',
    width: 110,
    checked: true,
    content: (record: any) => {
      return getDateTime(record.update_time);
    },
  },
  {
    title: t('是否可用'),
    dataIndex: 'is_active',
    checked: true,
    width: 80,
    cellComponent: 'boolean',
    name1: t('可用'),
    name2: t('禁用'),
    filterMultiple: false,
    filters: [
      { text: t('全部'), value: '' },
      { text: t('可用'), value: true },
      { text: t('禁用'), value: false },
    ],
  },

  {
    title: t('操作'),
    width: 120,
    dataIndex: 'action',
    checked: false,
    cellComponent: 'action',
    fixed: 'right',
  },
];
const newSlotList: any = [];
Object.assign(newSlotList, slotList);
newSlotList.push({
  type: 'custom',
  // slotName: 'is_active',
  field_true: '可用',
  field_false: '禁用',
});

export default () => {
  return {
    searchConfig: search_config,
    searchConfigOptions: search_options,
    formModalConfig: edit_model_config,
    formModalConfigOptions: edit_model_config_options,
    updateFormModalConfig: update_model_config,
    updateFormModalConfigOptions: update_model_config_options,
    queryData: query_c_c_bank_number_curreny_list,
    addData: create_c_c_bank_number_curreny,
    updateData: update_c_c_bank_number_curreny,
    removeData: delete_c_c_bank_number_curreny,
    recoverData: recover_c_c_bank_number_curreny,
    baseColumns: baseColumns,
    slotList: newSlotList,
    titleList: titleList,
    api_fields: api_fields,
    btn_list: btn_list,
    head_btn_list: head_btn_list,
    event_obj: event_obj,
  };
};
