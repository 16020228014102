<template>
  <a-input-group compact>
    <a-select
      :mode="field.mode"
      size="small"
      v-model:value="modalRef.selectedRows"
      :show-search="!!field.show_search"
      style="width: 70%; min-width: 100px"
      :disabled="field.disabled"
      :placeholder="
        field.placeholder_i18n == undefined ? field.placeholder : t(field.placeholder_i18n)
      "
      :dropdown-match-select-width="500"
      :default-value="field.defaultValue"
      :allow-clear="field.allowClear"
      :maxTagCount="field.maxTagCount"
      @change="handleChange"
    >
      <a-select-option v-for="option in datasource" :key="option.value" :value="option.value">
        {{ option.label }}
      </a-select-option>
    </a-select>
    <a-button
      :disabled="field.disabled"
      size="small"
      style="width: 30%"
      type="primary"
      @click="openModal"
    >
      {{ t('选择') }}
    </a-button>
  </a-input-group>
  <a-modal
    :title="field.title"
    width="900px"
    :maskClosable="false"
    :visible="modalRef.visible"
    :confirmLoading="loading"
    centered
    @ok="handleSubmit"
    @cancel="
      () => {
        modalRef.visible = false;
      }
    "
  >
    <search-render
      :form="searchConfig"
      :model="search_modelRef"
      :options="searchConfigOptions"
      :validateInfos="validateInfos"
      :resetFields="resetFields"
      @search="search"
    />
    <a-table
      bordered="true"
      size="small"
      :loading="state.loading"
      :scroll="{ y: 400, x: columnState.tableWidth }"
      :row-selection="rowSelection"
      :columns="dynamicColumns"
      row-key="id"
      :data-source="state.dataSource"
      :pagination="{
        current: state.current,
        pageSize: state.pageSize,
        total: state.total,
      }"
      @change="handleTableChange"
    >
      <template
        v-for="item in slotList"
        v-slot:[item.slotName]="{ index, text }"
        :key="item.slotName"
      >
        <span v-if="item.slotName === 'index'">
          {{ index + 1 + state.pageSize * (state.current - 1) }}
        </span>
        <span v-if="item.type === 'custom'">
          {{ item['field_' + text] }}
        </span>
        <span v-if="item.type === 'datetime'">
          {{ getDateTime(text) }}
        </span>
        <span v-if="item.type === 'date'">
          {{ getDate(text) }}
        </span>
        <span v-if="item.type === 'full_date'">
          {{ getFullDate(text) }}
        </span>
        <span v-if="item.type === 'time'">
          {{ getTime(text) }}
        </span>
        <a-tag color="green" v-if="text && item.type === 'boolean'">{{ item.name1 }}</a-tag>
        <a-tag color="red" v-if="!text && item.type === 'boolean'">{{ item.name2 }}</a-tag>
        <template v-if="item.type === 'list'">
          <a-tag color="#87d068" v-for="obj in text" :key="obj.id" style="margin-bottom: 1px">
            {{ obj.name }}
          </a-tag>
        </template>
      </template>
    </a-table>
  </a-modal>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, reactive, ref, watch } from 'vue';
import { ColumnProps } from 'ant-design-vue/es/table/interface';
import { RequestData, useFetchData } from '@/utils/hooks/useFetchData';
import { useForm } from 'ant-design-vue/es/form';
import { Pagination, TableColumn, TableColumnSortOrder, TableFilters } from '@/typing';
import { useStore } from 'vuex';
import getFormConfig from '@/components/FormRender/RemoteModal/index';
import { useTableDynamicColumns } from '@/utils/hooks/useTableColumn';
import { useI18n } from 'vue-i18n';
import SearchRender from '@/components/FormRender/SearchRender.vue';

type Key = ColumnProps['key'];

interface DataType {
  id: number;
  key: Key;
  name: string;
}

export default defineComponent({
  name: 'RemoteModal',
  props: {
    field: {
      type: Object,
      required: true,
    },
    modalType: {
      type: String,
      required: true,
      default: () => '',
    },
    value: {
      type: Array,
      required: true,
      default: () => [],
    },
    init_model: {
      type: Object,
      default: () => {},
    },
    field_status: {
      type: Object,
      default: () => {},
    },
    watch: {
      type: Array,
      default: () => [],
    },
    formModel: {
      type: Object,
      required: true,
    },
  },
  emits: ['update:value'],
  setup(props, { emit }) {
    const { t } = useI18n();
    const loading = ref(false);
    const store = useStore();
    const current_org = store.getters['user/current_org'];
    const { searchConfig, searchConfigOptions, getData, columns, slotList } = getFormConfig(
      props.modalType,
    );
    const search_modelRef = reactive({
      ...searchConfig.model,
      org_id: current_org.id,
      is_active: true,
      ...props.init_model,
    });
    const rulesRef: any = []
    for (let field of searchConfig.fields) {
      if (props.field_status && props.field_status.hasOwnProperty(field.name)) {
        if (props.field_status[field.name] == 0) {
          field.disabled = true;
          field.hidden = false;
        } else if (props.field_status[field.name] == 1) {
          field.disabled = false;
          field.hidden = true;
        } else {
          field.disabled = false;
          field.hidden = false;
        }
      }
    }

    const old_obj: { [key: string]: any } = {};
    onMounted(() => {
      if (props.watch) {
        for (let k of props.watch) {
          if (props.formModel) {
            old_obj[k as string] = props.formModel[k as string];
          }
        }
      }
      if (props.watch && props.formModel) {
        for (let k of props.watch) {
          search_modelRef[k as string] = props.formModel[k as string];
          watch(
            () => props.formModel[k as string],
            () => {
              if (props.watch && props.formModel) {
                for (let k of props.watch) {
                  if (old_obj[k as string] != props.formModel[k as string]) {
                    search_modelRef[k as string] = props.formModel[k as string];
                  }
                }
              }
            },
            { immediate: true },
          );
        }
      }
    });

    const { state: columnState, dynamicColumns } = useTableDynamicColumns(
      columns as TableColumn[],
      {
        checkAll: false,
        needRowIndex: false,
      },
    );
    const datasource = computed(() => {
      return modalRef.datasource.map(item => {
        return {
          value: item[props.field.valueKey ? (props.field.valueKey as string) : 'id'],
          label: item[props.field.labelKey ? (props.field.labelKey as string) : 'name'],
        };
      });
    });
    const modalRef = reactive({
      visible: false,
      selectedRows: new Array<number>(),
      selectedRowsKey: new Array<number>(),
      selectedRowsTotal: new Array<any>(),
      datasource: new Array<any>(),
    });

    const rowSelection = {
      type:
        props.field.mode == 'default'
          ? 'radio'
          : props.field.mode == 'multiple'
          ? 'checkbox'
          : 'checkbox',
      columnWidth: '30px',
      selectedRowKeys: modalRef.selectedRowsKey,
      onChange: (selectedRowKeys: Key[], selectedRows: DataType[]) => {
        // modalRef.selectedRowsKey.length = 0;
        let remove_key: Array<number> = modalRef.selectedRowsKey.filter(
          item => selectedRowKeys.indexOf(Number(item)) == -1,
        );
        modalRef.selectedRowsTotal = modalRef.selectedRowsTotal.filter(
          item => remove_key.indexOf(item.id) == -1,
        );
        selectedRows.forEach((item: DataType) => {
          if (modalRef.selectedRowsKey.indexOf(item.id) == -1) {
            modalRef.selectedRowsTotal.push(item);
          }
        });

        modalRef.selectedRowsKey.length = 0;
        selectedRows.forEach((item: DataType) => {
          modalRef.selectedRowsKey.push(item.id);
          modalRef.datasource.push(item);
        });
      },
    };

    const { reload, setPageInfo, context: state } = useFetchData(getData, {
      current: 1,
      pageSize: 20,
      tableSize: 'middle', // 'default' | 'middle' | 'small'
      stripe: true,
      requestParams: {
        ...search_modelRef,
      },
    });
    watch(
      () => state.dataSource,
      () => {
        let total_ids: Array<number> = modalRef.selectedRowsTotal.map(item => item.id);
        state.dataSource.map((item: any) => {
          if (total_ids.indexOf(item.id) > -1) {
            modalRef.selectedRowsKey.push(item.id);
          }
        });
      },
    );
    const init = () => {
      let list: any[] = [];
      if (props.value instanceof Array) {
        if (props.value.length > 0) {
          list = props.value as Array<number>;
        }
      } else {
        if (props.value) {
          list.push(props.value);
        }
      }
      if (list.length > 0) {
        let tmp_ids: any[] = [];
        let tmp_item_ids: any[] = [];
        modalRef.datasource.map(item => tmp_item_ids.push(item.id));
        list.map(item => {
          if (modalRef.selectedRows.indexOf(Number(item)) == -1) {
            modalRef.selectedRows.push(Number(item));
            modalRef.selectedRowsKey.push(Number(item));
          }
          if (tmp_item_ids.indexOf(item) == -1) {
            tmp_ids.push(item);
          }
        });
        if (tmp_ids.length > 0) {
          getData({ current: 1, ...search_modelRef, pageSize: 2000, ids: tmp_ids.join(',') }).then(
            function (res: RequestData<any>) {
              res.data.forEach(item => {
                if (list.indexOf(item.id) >= 0) {
                  modalRef.datasource.push(item);
                  modalRef.selectedRowsTotal.push(item);
                }
              });
            },
          );
        }
      } else {
        modalRef.selectedRows.length = 0;
        modalRef.selectedRowsKey.length = 0;
        modalRef.selectedRowsTotal.length = 0;
      }
    };
    init();

    watch(
      () => props.value,
      () => {
        init();
      },
    );

    const handleTableChange = (
      { current, pageSize }: Pagination,
      filters: TableFilters,
      sorter: TableColumnSortOrder,
    ) => {
      console.log(sorter);
      setPageInfo({
        current: current ? current : state.current,
        pageSize: pageSize ? pageSize : state.pageSize,
        ...filters,
        ...search_modelRef,
      });

      reload();
    };

    const { resetFields, validateInfos } = useForm(search_modelRef, []);

    const search = () => {
      setPageInfo({
        current: 1,
        ...search_modelRef,
      });
      reload();
    };

    const openModal = () => {
      modalRef.visible = true;
      let remove_ids: Array<number> = modalRef.datasource
        .filter(item => modalRef.selectedRows.indexOf(item.id) == -1)
        .map(item => item.id);
      remove_ids.forEach(item => {
        if (modalRef.selectedRowsKey.indexOf(item) != -1) {
          modalRef.selectedRowsKey.splice(modalRef.selectedRowsKey.indexOf(item), 1);
        }
      });

      let remove_total: Array<any> = modalRef.selectedRowsTotal.filter(
        item => modalRef.selectedRows.indexOf(item.id) == -1,
      );
      remove_total.forEach(item => {
        if (modalRef.selectedRowsTotal.indexOf(item) != -1) {
          modalRef.selectedRowsTotal.splice(modalRef.selectedRowsTotal.indexOf(item), 1);
        }
      });
    };

    const handleSubmit = (e: Event) => {
      e.preventDefault();
      modalRef.visible = false;
      if (modalRef.selectedRows == undefined) {
        modalRef.selectedRows = new Array<number>();
      }
      modalRef.selectedRows.length = 0;
      modalRef.selectedRowsTotal.map(item => modalRef.selectedRows.push(item.id));
      modalRef.datasource.length = 0;
      modalRef.selectedRowsTotal.map(item => modalRef.datasource.push(item));
      if (props.field.mode == 'default') {
        if (modalRef.selectedRows.length == 1) {
          emit('update:value', modalRef.selectedRows[0]);
        } else {
          emit('update:value', null);
        }
      } else {
        emit('update:value', modalRef.selectedRows);
      }
    };
    const handleChange = () => {
      if (modalRef.selectedRows == undefined) {
        modalRef.selectedRows = new Array<number>();
      }
      if (props.field.mode == 'default') {
        if (modalRef.selectedRows.length == 1) {
          emit('update:value', modalRef.selectedRows[0]);
        } else {
          emit('update:value', null);
        }
      } else {
        emit('update:value', modalRef.selectedRows);
      }
    };

    // 接口返回时间，格式优化
    const getDateTime = (time: any) => {
      if (time && new Date().getFullYear().toString() == time.substring(0, 4)) {
        time = time.substring(5, 16);
      } else if (time) {
        time = time.substring(0, 16);
      }
      return time;
    };
    const getDate = (time: any) => {
      if (time && new Date().getFullYear().toString() == time.substring(0, 4)) {
        time = time.substring(0, 10);
      } else if (time) {
        time = time.substring(0, 10);
      }
      return time;
    };
    const getFullDate = (time: any) => {
      if (time) {
        time = time.substring(0, 10);
      }
      return time;
    };
    const getTime = (time: any) => {
      if (time) {
        time = time.substring(0, 5);
      }
      return time;
    };

    return {
      t,
      props,
      modalRef,
      datasource,
      state,
      loading,
      getDateTime,
      getDate,
      getFullDate,
      getTime,
      search,
      openModal,
      handleSubmit,
      handleChange,
      handleTableChange,
      rowSelection,
      resetFields,
      validateInfos,
      search_modelRef,
      searchConfig,
      searchConfigOptions,
      getData,
      columnState,
      dynamicColumns,
      slotList,
    };
  },
  components: {
    SearchRender,
  },
  data() {
    return {
      model: this.value,
    };
  },
});
</script>
