import { SearchConfig } from '@/components/page-model/typing';
import { reactive } from 'vue';
import { config } from '@/components/page-model4/baseinfo/baseinfo-question-config';
import { useI18n } from 'vue-i18n';

export default (current_org: any) => {
  const { t } = useI18n();
  const None = null;
  const True = true;
  const False = false;

  const edit_model_config: SearchConfig = {
    settings: {
      labelAlign: 'right',
      layout: 'vertical',
      col: config.creatCol,
    },
    fields: [
      {
        type: 'remoteselect',
        name: 'group_id',
        label: '隶属问题分组',
        label_i18n: '隶属问题分组',
        disabled: false,
        placeholder: '请选择隶属问题分组',
        placeholder_i18n: '请选择隶属问题分组',
        datasourceType: 'remote',
        mode: 'default',
        allowClear: true,
        modalType: 'baseinfo-questiongroup',
      },
      {
        type: 'input',
        name: 'name',
        label: '问题',
        label_i18n: '问题',
        disabled: false,
        allowClear: true,
        inputType: 'text',
        defaultValue: '',
        placeholder: '请填写问题',
        placeholder_i18n: '请填写问题',
      },
      {
        type: 'textarea',
        name: 'answer',
        label: '答案',
        label_i18n: '答案',
        disabled: false,
        allowClear: true,
        inputType: 'text',
        defaultValue: '',
        placeholder: '请填写答案',
        placeholder_i18n: '请填写答案',
      },
    ],
    rules: { name: [{ required: true, message: t('请填写问题') }] },
    model: reactive({
      org_id: current_org?.id,
      is_active: true,
      name: null,
      answer: null,
      group_id: null,
    }),
  };
  const edit_model_config_options = {};

  return {
    edit_model_config: edit_model_config,
    edit_model_config_options: edit_model_config_options,
  };
};
