import { query_invoice_list } from '@/api/cerp-model';
import { useI18n } from 'vue-i18n';

interface SearchConfig {
  rules: any;
  fields: any;
  settings: any;
  model: any;
}

export default () => {
  const { t } = useI18n();
  const None = null;
  const True = true;
  const False = false;
  const apiurl_config: SearchConfig = {
    settings: {
      name: '表单名称',
      labelAlign: 'right',
      layout: 'inline',
    },
    fields: [
      {
        type: 'remotemodal',
        name: 'adviser_id',
        label: '隶属顾问',
        label_i18n: '隶属顾问',
        placeholder_i18n: '隶属顾问',
        disabled: false,
        defaultValue: '',
        labelKey: 'full_name',
        valueKey: 'id',
        placeholder: '隶属顾问',
        show_search: false,
        maxTagCount: 5,
        mode: 'default',
        title: t('选择隶属顾问'),
        modalType: 'cerp-adviser',
      },
      {
        type: 'monthpicker',
        name: 'service_month',
        label: '服务月份',
        label_i18n: '服务月份',
        placeholder_i18n: '请填写服务月份',
        disabled: false,
        allowClear: true,
        inputType: 'number',
        defaultValue: '',
        showTime: false,
        valueFormat: 'YYYY-MM-DD',
        format: 'YYYY-MM',
        placeholder: '请填写开始时间',
      },
      {
        type: 'select',
        name: 'invoice_type',
        label: '请款类型',
        label_i18n: '请款类型',
        placeholder_i18n: '请选择请款类型',
        mode: 'default',
        disabled: false,
        defaultValue: '',
        labelKey: 'name',
        valueKey: 'value',
        placeholder: '请选择请款类型',
        datasource: 'invoice_type',
      },
      {
        type: 'select',
        name: 'status',
        label: '状态',
        label_i18n: '状态',
        mode: 'default',
        disabled: false,
        defaultValue: '',
        labelKey: 'name',
        valueKey: 'value',
        placeholder: '请选择状态',
        datasource: 'status',
      },
      {
        type: 'remoteselect',
        name: 'bank_account_id',
        label: '银行账户',
        label_i18n: '银行账户',
        placeholder_i18n: '请选择银行账户',
        disabled: false,
        placeholder: '请选择银行账户',
        datasourceType: 'remote',
        mode: 'default',
        allowClear: true,
        labelKey: 'account_flag',
        valueKey: 'id',
        modalType: 'baseinfo-ccbankaccountscc',
      },
      {
        type: 'select',
        name: 'is_active',
        label: '是否可用',
        label_i18n: '是否可用',
        placeholder_i18n: '请选择是否可用',
        mode: 'default',
        disabled: false,
        defaultValue: '',
        labelKey: 'name',
        valueKey: 'value',
        placeholder: '请选择是否可用',
        datasource: 'is_active',
      },
      {
        type: 'select',
        name: 'is_remittance',
        label: '未创建汇款凭证项',
        label_i18n: '未创建汇款凭证项',
        placeholder_i18n: '请选择未创建汇款凭证项',
        mode: 'default',
        disabled: false,
        defaultValue: '',
        labelKey: 'name',
        valueKey: 'value',
        placeholder: '请选择未创建汇款凭证项',
        datasource: 'is_remittance',
      },
    ],
    rules: {},
    model: {
      is_active: true,
      is_remittance: false,
      status: null,
      invoice_type: null,
      contract_id: null,
      service_month: null,
      adviser_id: null,
      invoice_type_id: null,
      bank_account_id: null,
    },
  };
  const apiurl_ConfigOptions = {
    is_remittance: [
      { value: True, name: t('未创建汇款凭证项') },
      { value: False, name: t('全部数据') },
    ],
    is_active: [
      { value: None, name: t('全部') },
      { value: True, name: t('可用') },
      { value: False, name: t('禁用') },
    ],
    status: [
      { value: None, name: t('全部') },
      { value: 1, name: t('新创建') },
      { value: 2, name: t('已发送') },
      { value: 3, name: t('已收到请款') },
      { value: 4, name: t('已作废') },
      { value: 5, name: t('生成工资单') },
      { value: 6, name: t('生成PDF') },
      { value: 7, name: t('垫付') },
    ],
    invoice_type: [
      { value: None, name: t('全部') },
      { value: 1, name: t('工资') },
      { value: 2, name: t('报销') },
      { value: 3, name: 'credit note' },
      { value: 4, name: t('加班') },
      { value: 5, name: t('CC报销') },
    ],
  };
  const apiurl_columns = [
    {
      title: t('顾问'),
      width: 150,
      dataIndex: 'adviser__full_name',
      checked: true,
      fixed: 'left',
    },
    {
      title: t('请款单号'),
      width: 160,
      dataIndex: 'invoice_no',
      checked: true,
      fixed: 'left',
    },
    {
      title: t('状态'),
      width: 100,
      dataIndex: 'status',
      checked: true,
      slots: { customRender: 'status' },
      filterMultiple: false,
      filters: [
        { text: t('全部'), value: '' },
        { text: t('新创建'), value: 1 },
        { text: t('已发送'), value: 2 },
        { text: t('已收到请款'), value: 3 },
        { text: t('已作废'), value: 4 },
        { text: t('生成工资单'), value: 5 },
        { text: t('生成PDF'), value: 6 },
        { text: t('垫付'), value: 7 },
      ],
    },
    {
      title: t('合同起始日期'),
      width: 150,
      dataIndex: 'contract__start_date',
      checked: true,
      slots: { customRender: 'date' },
    },
    {
      title: t('合同结束日期'),
      width: 150,
      dataIndex: 'contract__end_date',
      checked: true,
      slots: { customRender: 'date' },
    },
    {
      title: t('汇款凭证项'),
      width: 150,
      dataIndex: 'remittance__remittance_set__remittance_name',
      checked: true,
    },
    {
      title: t('汇款金额'),
      width: 150,
      dataIndex: 'remittance__amount',
      checked: true,
      slots: { customRender: 'remittance__amount' },
    },
    {
      title: t('汇率'),
      width: 150,
      dataIndex: 'exchange_rate',
      checked: true,
    },
    {
      title: t('服务月份'),
      width: 150,
      dataIndex: 'service_month',
      checked: true,
      slots: { customRender: 'month' },
    },
    {
      title: t('请款起始日期'),
      width: 150,
      dataIndex: 'invoice_start_date',
      checked: true,

      slots: { customRender: 'date' },
    },
    {
      title: t('请款截止日期'),
      width: 150,
      dataIndex: 'invoice_end_date',
      checked: true,

      slots: { customRender: 'date' },
    },
    {
      title: t('提交日期'),
      width: 150,
      dataIndex: 'date_submitted',
      checked: true,

      slots: { customRender: 'date' },
    },
    {
      title: t('普通工时单位'),
      width: 150,
      dataIndex: 'work_unit',
      checked: true,
    },
    {
      title: t('基本工资总额'),
      width: 150,
      dataIndex: 'basic_amount',
      checked: true,
    },
    {
      title: t('宿款单位'),
      width: 150,
      dataIndex: 'accommodation_unit',
      checked: true,
    },
    {
      title: t('住宿总额'),
      width: 150,
      dataIndex: 'accommodation_amount',
      checked: true,
    },
    {
      title: t('加班单位'),
      width: 150,
      dataIndex: 'overtime',
      checked: true,
    },
    {
      title: t('加班总额'),
      width: 150,
      dataIndex: 'overtime_amount',
      checked: true,
    },
    {
      title: t('管理费'),
      width: 150,
      dataIndex: 'management_fee',
      checked: true,
    },
    {
      title: t('总金额'),
      width: 150,
      dataIndex: 'total_amount',
      checked: true,
    },
    {
      title: t('备注'),
      width: 150,
      dataIndex: 'remark',
      checked: true,
    },
    {
      title: t('付款周期（天）'),
      width: 150,
      dataIndex: 'payment_term',
      checked: true,
    },
    {
      title: t('账户名'),
      width: 150,
      dataIndex: 'bank_account__account_name',
      checked: true,
    },
    {
      title: t('账号'),
      width: 150,
      dataIndex: 'bank_account__account_number',
      checked: true,
    },
    {
      title: t('银行账户'),
      width: 150,
      dataIndex: 'bank_account__account_name',
      checked: true,
    },
    {
      title: t('创建时间'),
      width: 150,
      dataIndex: 'create_time',
      checked: true,
      slots: { customRender: 'datetime' },
    },
    {
      title: t('更新时间'),
      width: 150,
      dataIndex: 'update_time',
      checked: true,
      slots: { customRender: 'datetime' },
    },
    {
      title: t('是否可用'),
      width: 150,
      dataIndex: 'is_active',
      checked: true,
      slots: { customRender: 'is_active' },
      filterMultiple: false,
      filters: [
        { text: t('全部'), value: '' },
        { text: t('可用'), value: true },
        { text: t('禁用'), value: false },
      ],
    },
  ];
  const slotList = [
    {
      slotName: 'index', // 槽名
    },
    {
      type: 'custom',
      slotName: 'status',
      field_1: '新创建',
      field_2: '已发送',
      field_3: '已收到请款',
      field_4: '已作废',
      field_5: '生成工资单',
      field_6: '生成PDF',
      field_7: '垫付',
    },
    {
      slotName: 'is_active',
      type: 'boolean', // 类型：'boolean' - text字段有无、(true/false)
      name1: '可用', // text字段有或true 展示
      name2: '禁用', // text字段无或false 展示
    },
    {
      slotName: 'time',
      type: 'time', // 类型：'time' - text字段为时间
    },
    {
      slotName: 'datetime',
      type: 'datetime', // 类型：'time' - text字段为时间
    },
    {
      slotName: 'date',
      type: 'date', // 类型：'date' - text字段为日期
    },
    {
      slotName: 'list',
      type: 'list', // 类型：'list' - text字段为数组
    },
  ];

  return {
    searchConfig: apiurl_config,
    searchConfigOptions: apiurl_ConfigOptions,
    getData: query_invoice_list,
    columns: apiurl_columns,
    slotList: slotList,
  };
};
