import request from '@/utils/request';
const base_url = '/cerp/';

export async function query_contract_list(params?: {
  [key: string]: any;
}): Promise<ResultResponse> {
  return request.post(base_url + 'query_contract_list', params);
}

export async function delete_contract(params: Record<string, any>) {
  return request.post(base_url + 'delete_contract', params);
}

export async function recover_contract(params: Record<string, any>) {
  return request.post(base_url + 'recover_contract', params);
}

export async function create_contract(params: Record<string, any>) {
  return request.post(base_url + 'create_contract', params);
}

export async function update_contract(params: Record<string, any>) {
  return request.post(base_url + 'update_contract', params);
}

interface RusultItem {
  createdAt: string;
}

interface ResultResponse {
  current: number;
  data: RusultItem[];
  pageSize: string;
  success: boolean;
  total: number;
}
