<template>
  <a-modal
    :title="title"
    width="1000px"
    :maskClosable="false"
    :visible="visible"
    :confirmLoading="loading"
    centered
    @ok="handleSubmit"
    @cancel="cancel_address"
  >
    <!--<search-render-->
    <!--  v-if="searchConfig"-->
    <!--  :form="searchConfig"-->
    <!--  :model="search_modelRef"-->
    <!--  :options="searchConfigOptions"-->
    <!--  :validateInfos="searchValidateInfos"-->
    <!--  @search="search"-->
    <!--/>-->
    <a-card :body-style="{ padding: 0 }" ref="elRef">
      <div class="ant-pro-table-list-toolbar">
        <div class="ant-pro-table-list-toolbar-container">
          <div class="ant-pro-table-list-toolbar-left">
            <div class="ant-pro-table-list-toolbar-title">{{ titleList.title }}</div>
          </div>
          <div class="ant-pro-table-list-toolbar-right">
            <a-space align="center">
              <a-button v-if="titleList.is_create" type="primary" size="small" @click="handleAdd">
                <plus-outlined />
                {{ titleList.addModalTitle }}
              </a-button>
              <a-button size="small" type="primary" @click="create_address">{{ t('新建地址') }}</a-button>
            </a-space>
            <div class="ant-pro-table-list-toolbar-divider">
              <a-divider type="vertical" />
            </div>
            <div class="ant-pro-table-list-toolbar-setting-item">
              <a-tooltip :title="t('刷新')">
                <reload-outlined @click="handleTableChange" />
              </a-tooltip>
            </div>
          </div>
        </div>
      </div>
      <a-table
        bordered="true"
        size="small"
        :loading="state.loading"
        :scroll="{ y: 500, x: columnState.tableWidth }"
        :row-selection="rowSelection"
        :columns="dynamicColumns"
        row-key="id"
        :data-source="state.dataSource"
        :pagination="{
          current: state.current,
          pageSize: state.pageSize,
          total: state.total,
        }"
        @change="handleTableChange"
      >
        <template
          v-for="item in slotList"
          v-slot:[item.slotName]="{ index, text }"
          :key="item.slotName"
        >
          <span v-if="item.slotName === 'index'">
            {{ index + 1 + state.pageSize * (state.current - 1) }}
          </span>
          <span v-if="item.type === 'custom'">
            {{ item['field_' + text] }}
          </span>
          <span v-if="item.type === 'datetime'">
            {{ getDateTime(text) }}
          </span>
          <a-tag color="green" v-if="text && item.type === 'boolean'">{{ item.name1 }}</a-tag>
          <a-tag color="red" v-if="!text && item.type === 'boolean'">{{ item.name2 }}</a-tag>
          <template v-if="item.type === 'list'">
            <a-tag color="#87d068" v-for="obj in text" :key="obj.id" style="margin-bottom: 1px">
              {{ obj.name }}
            </a-tag>
          </template>
        </template>
        <template #action="{ record }">
          <a-button
            type="primary"
            size="small"
            @click="() => update_address(record)"
            v-if="record.is_active"
          >
            {{ t('修改') }}
          </a-button>
          <a-button
            size="small"
            type="danger"
            @click="() => handleDelete(record)"
            v-if="record.is_active"
          >
            {{ t('删除') }}
          </a-button>

          <a-button
            size="small"
            type="dashed"
            @click="() => handleRecover(record)"
            v-if="!record.is_active"
          >
            {{ t('恢复') }}
          </a-button>
        </template>
      </a-table>
    </a-card>
  </a-modal>
</template>
<script lang="ts">
import { createVNode, defineComponent, reactive, ref } from 'vue';
import getFormConfig from '@/components/form-modal/table-modal/index';
import { useFetchData } from '@/utils/hooks/useFetchData';
import { useStore } from 'vuex';
import { useTableDynamicColumns } from '@/utils/hooks/useTableColumn';
import { Pagination, TableColumn, TableColumnSortOrder, TableFilters } from '@/typing';
import { message, Modal, notification } from 'ant-design-vue';
import { ExclamationCircleOutlined, ReloadOutlined } from '@ant-design/icons-vue';
import { Record } from '@/components/page-model/typing';
import modalBox from '@/components/form-modal/modal-tools';
import { useI18n } from 'vue-i18n';


export default defineComponent({
  name: 'TableModal',
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
    },
    obj: {
      type: Object,
      required: true,
    },
    modalType: {
      type: String,
      required: true,
    },
  },
  emits: ['ok', 'cancel'],
  setup(props, { emit }) {
    const store = useStore();
    const { t } = useI18n();
    const current_org = store.getters['user/current_org'];
    const confirmLoading = ref<boolean>(false);
    const {
      searchConfig,
      queryData,
      addData,
      updateData,
      removeData,
      recoverData,
      baseColumns,
      slotList,
      titleList,
      api_fields,
    } = getFormConfig(props.modalType);
    const search_modelRef = reactive({
      ...searchConfig.model,
      org_id: current_org.id,
      is_active: true,
    });
    const { reload, setPageInfo, context: state } = useFetchData(queryData, {
      current: 1,
      pageSize: 20,
      tableSize: 'small', // 'default' | 'middle' | 'small'
      stripe: true,
      requestParams: {
        ...search_modelRef,
        ...props.obj,
      },
    });
    const { state: columnState, dynamicColumns } = useTableDynamicColumns(
      baseColumns as TableColumn[],
      {
        checkAll: false,
        needRowIndex: false,
      },
    );

    const modalRef = reactive({
      selectedRows: new Array<number>(),
    });
    const rowSelection = {
      type: 'radio',
      columnWidth: '30px',
      onChange: (selectedRowKeys: any, selectedRows: any) => {
        modalRef.selectedRows = selectedRowKeys;
      },
    };
    const handleTableChange = (
      { current, pageSize }: Pagination,
      filters: TableFilters,
      sorter: TableColumnSortOrder,
    ) => {
      console.log(sorter);
      let filter_map: any = {};
      for (let key in filters) {
        filter_map[key] = filters[key].map((x: any) => x.toString()).join(',');
      }
      setPageInfo({
        current: current ? current : state.current,
        pageSize: pageSize ? pageSize : state.pageSize,
        ...searchConfig.model,
        ...props.obj,
        ...filter_map,
      });
      reload();
    };
    const cancel_address = () => {
      emit('cancel', modalRef.selectedRows[0]);
    };
    const handleSubmit = () => {
      if (modalRef.selectedRows.length > 0) {
        emit('ok', modalRef.selectedRows[0]);
      } else {
        notification.error({
          message: t('提示'),
          description: t('请选择地址'),
        });
      }
    };
    const handleDelete = (record: any) => {
      Modal.confirm({
        title: t('是否删除此项记录?'),
        icon: createVNode(ExclamationCircleOutlined),
        onOk() {
          return new Promise(resolve => {
            removeData({
              org_id: current_org.id,
              ...getObjList(api_fields.delete, record),
            }).then((res: any) => {
              message.success(t('删除成功'));
              Object.assign(record, res);
              resolve(null);
            });
          }).catch(() => console.log('Oops errors!'));
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onCancel() {},
      });
    };
    const handleRecover = (record: any) => {
      recoverData({
        org_id: current_org.id,
        ...getObjList(api_fields.recover, record),
      }).then((res: any) => {
        message.success(t('恢复成功'));
        Object.assign(record, res);
      });
    };
    const getObjList = (api_fields: Record, record: Record) => {
      const obj: any = {};
      for (const key in api_fields) {
        if (record[api_fields[key] as string]) {
          obj[key] = record[api_fields[key] as string];
        }
      }
      return obj;
    };
    const getDateTime = (time: any) => {
      if (time && new Date().getFullYear().toString() == time.substring(0, 4)) {
        time = time.substring(0, 16);
      } else if (time) {
        time = time.substring(0, 16);
      }
      return time;
    };
    const create_address = () => {
      const editModal = reactive({
        visible: true,
        title: props.obj.address_type == 1 ? t('新建工作地址') : t('新建家庭地址'),
        loading: false,
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'vertical',
            col: [],
          },
          fields: [
            {
              type: 'input',
              name: 'adviser_name',
              label: '隶属顾问',
              label_i18n: '隶属顾问',
              disabled: true,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写隶属顾问',
              placeholder_i18n: '请填写隶属顾问',
            },
            {
              type: 'input',
              name: 'street',
              label: '街道',
              label_i18n: '街道',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写街道',
              placeholder_i18n: '请填写街道',
            },
            {
              type: 'input',
              name: 'city',
              label: '城市',
              label_i18n: '城市',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写城市',
              placeholder_i18n: '请填写城市',
            },
            {
              type: 'input',
              name: 'state',
              label: '州/省',
              label_i18n: '州/省',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写州/省',
              placeholder_i18n: '请填写州/省',
            },
            {
              type: 'remoteselect',
              name: 'country_id',
              label: '国家',
              label_i18n: '国家',
              disabled: false,
              placeholder: '请选择国家',
              placeholder_i18n: '请选择国家',
              datasourceType: 'remote',
              mode: 'default',
              allowClear: true,
              modalType: 'baseinfo-country',
            },
            {
              type: 'input',
              name: 'postcode',
              label: '邮编',
              label_i18n: '邮编',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写邮编',
              placeholder_i18n: '请填写邮编',
            },
            {
              type: 'radio',
              name: 'address_type',
              label: '地址类型',
              label_i18n: '地址类型',
              disabled: true,
              labelKey: 'name',
              valueKey: 'value',
              defaultValue: '',
            },
          ],
          rules: {},
          model: reactive({
            org_id: current_org?.id,
            is_active: true,
            adviser_id: props.obj.adviser_id,
            adviser_name: props.obj.full_name,
            street: null,
            city: null,
            state: null,
            country_id: null,
            postcode: null,
            address_type: props.obj.address_type,
          }),
        },
        options: {
          address_type: [
            { value: 0, name: t('家庭地址') },
            { value: 1, name: t('工作地址') },
          ],
        },
        ok: (payload: any) => {
          return new Promise(resolve => {
            editModal.loading = true;
            addData(payload)
              .then(() => {
                message.success(t('新建成功'));
                reload();
                resolve(null);
              })
              .finally(() => {
                editModal.loading = false;
              });
          });
        },
      });
      modalBox(editModal);
    };
    const update_address = (record: any) => {
      const editModal = reactive({
        visible: true,
        title: props.obj.address_type == 1 ? t('修改工作地址') : t('修改家庭地址'),
        loading: false,
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'vertical',
            col: [],
          },
          fields: [
            {
              type: 'input',
              name: 'adviser_name',
              label: '隶属顾问',
              label_i18n: '隶属顾问',
              disabled: true,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写隶属顾问',
              placeholder_i18n: '请填写隶属顾问',
            },
            {
              type: 'input',
              name: 'street',
              label: '街道',
              label_i18n: '街道',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写街道',
              placeholder_i18n: '请填写街道',
            },
            {
              type: 'input',
              name: 'city',
              label: '城市',
              label_i18n: '城市',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写城市',
              placeholder_i18n: '请填写城市',
            },
            {
              type: 'input',
              name: 'state',
              label: '州/省',
              label_i18n: '州/省',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写州/省',
              placeholder_i18n: '请填写州/省',
            },
            {
              type: 'remoteselect',
              name: 'country_id',
              label: '国家',
              label_i18n: '国家',
              disabled: false,
              placeholder: '请选择国家',
              placeholder_i18n: '请选择国家',
              datasourceType: 'remote',
              mode: 'default',
              allowClear: true,
              modalType: 'baseinfo-country',
            },
            {
              type: 'input',
              name: 'postcode',
              label: '邮编',
              label_i18n: '邮编',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写邮编',
              placeholder_i18n: '请填写邮编',
            },
            {
              type: 'radio',
              name: 'address_type',
              label: '地址类型',
              label_i18n: '地址类型',
              disabled: true,
              labelKey: 'name',
              valueKey: 'value',
              defaultValue: '',
            },
          ],
          rules: {},
          model: reactive({
            org_id: current_org?.id,
            is_active: true,
            address_id: record.id,
            adviser_id: props.obj.adviser_id,
            adviser_name: props.obj.full_name,
            street: record.street,
            city: record.city,
            state: record.state,
            country_id: record.country_id,
            postcode: record.postcode,
            address_type: props.obj.address_type,
          }),
        },
        options: {
          address_type: [
            { value: 0, name: t('家庭地址') },
            { value: 1, name: t('工作地址') },
          ],
        },
        ok: (payload: any) => {
          return new Promise(resolve => {
            editModal.loading = true;
            updateData(payload)
              .then(() => {
                message.success(t('修改成功'));
                reload();
                resolve(null);
              })
              .finally(() => {
                editModal.loading = false;
              });
          });
        },
      });
      modalBox(editModal);
    };
    return {
      props,
      t,
      slotList,
      state,
      columnState,
      dynamicColumns,
      modalRef,
      rowSelection,
      titleList,
      confirmLoading,
      handleTableChange,
      handleDelete,
      handleRecover,
      getDateTime,
      create_address,
      handleSubmit,
      cancel_address,
      update_address,
    };
  },
  components: {
    ReloadOutlined,
  },
});
</script>
