<template>
  <a-card style="margin-top: 24px" :bordered="false">
    <template #title>
      <exclamation-circle-outlined style="color: #ff0000" v-if="!insuranceCreate" />
      <check-circle-outlined style="color: #0f0" v-if="insuranceCreate" />
      {{ get_form_obj.adviser__full_name }}
    </template>
    <template #extra>
      <a-button
        style="margin-left: 15px"
        size="small"
        :disabled="insuranceCreate"
        :loading="loading"
        type="primary"
        @click="craete_insurance_fun"
      >
        保存
      </a-button>
    </template>
    <a-row :gutter="24" type="flex">
      <a-col
        v-bind="{ xs: 24, sm: 12, md: 12, lg: 12, xl: 12 }"
        style="margin-bottom: 24px; height: 500px"
      >
        <a-card style="background-color: #bfe; height: 260px" :bordered="false">
          <a-descriptions size="small" :column="2">
            <a-descriptions-item label="顾问">
              {{ get_form_obj.adviser__full_name }}
            </a-descriptions-item>
            <a-descriptions-item label="保险公司">
              {{ get_form_obj.insurance_company_name }}
            </a-descriptions-item>
            <a-descriptions-item label="保险单号">{{ get_form_obj.policy_no }}</a-descriptions-item>
            <a-descriptions-item label="保险起止日期">
              {{ get_form_obj.start_end_date }}
            </a-descriptions-item>
            <a-descriptions-item label="保费">{{ get_form_obj.insurance_fee }}</a-descriptions-item>
            <a-descriptions-item label="每月保费">
              {{ get_form_obj.month_insurance_fee }}
            </a-descriptions-item>
          </a-descriptions>
          <a-descriptions size="small" :column="2" v-for="item in get_form_obj.beneficiary_list">
            <a-descriptions-item label="受益人">
              {{ item.name }}
              <a-button
                style="margin-left: 15px"
                size="small"
                :disabled="item.is_create"
                type="primary"
                :loading="item.loading"
                @click="craete_beneficiary(item)"
              >
                新建
              </a-button>
            </a-descriptions-item>
            <a-descriptions-item label="证件号">{{ item.id_number }}</a-descriptions-item>
          </a-descriptions>
        </a-card>
        <a-card style="margin-top: 4px; background-color: #bfe; height: 260px" :bordered="false">
          <a-image
            style="text-align: center"
            :width="212"
            src="http://erp-cc.oss-cn-hongkong.aliyuncs.com/public/2021-04-23/7e1b8edc-a3f6-11eb-a34c-0242ac110013.png"
          />
          <a-image
            style="text-align: center; margin-left: 4px"
            :width="212"
            src="http://erp-cc.oss-cn-hongkong.aliyuncs.com/public/2021-04-23/7e1b8edc-a3f6-11eb-a34c-0242ac110013.png"
          />
        </a-card>
      </a-col>
      <a-col v-bind="{ xs: 24, sm: 12, md: 12, lg: 12, xl: 12 }" style="margin-bottom: 24px">
        <a-card style="background-color: #bfe; height: 524px" :bordered="false">
          <div style="height: 500px" v-if="is_from">
            <from-render
              ref="render"
              :form="form"
              :model="modelRef"
              :options="options"
              :validateInfos="validateInfos"
            />
          </div>
        </a-card>
      </a-col>
    </a-row>
    <!--    <a-card type="inner">-->
    <!--      <template #title>-->
    <!--     标题 按钮-->
    <!--      </template>-->
    <!--    </a-card>-->
  </a-card>
</template>

<script lang="ts">
import { CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { defineComponent, reactive, ref } from 'vue';
import { useStore } from 'vuex';
import { useForm } from 'ant-design-vue/es/form';
import { createBeneficiary, get_form, options } from '@/components/show-insurance/show-insurance.ts';
import { create_insurance } from '@/api/cerp-model';
import { message, notification } from 'ant-design-vue';
import bus from '@/utils/bus';

import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'ShowInsurance',
  components: { ExclamationCircleOutlined, CheckCircleOutlined },
  props: {
    title: {
      type: String,
      required: true,
    },
    insuranceObj: {
      type: Object,
      required: true,
    },
    insuranceCreate: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['ok', 'cancel'],
  setup(props, { emit }) {
    const store = useStore();
    const { t } = useI18n();
    const event_obj = bus;
    const loading = ref(false);
    const is_from = ref(true);
    const get_form_obj = reactive({
      ...props.insuranceObj,
    });
    get_form_obj.beneficiary_list.forEach((item: any) => {
      item.is_create = false;
      item.loading = false;
    });
    let form = reactive({ ...get_form(get_form_obj) });
    const modelRef = reactive({
      ...form.model,
      policy_no: props.insuranceObj.policy_no,
      insurance_fee: props.insuranceObj.insurance_fee,
      month_insurance_fee: props.insuranceObj.month_insurance_fee,
      start_end_date: props.insuranceObj.start_end_date,
      beneficiary_ids: [],
    });
    const rulesRef = reactive({ ...form.rules });
    const { validateInfos: validateInfos } = useForm(modelRef, rulesRef);

    const craete_insurance_fun = () => {
      loading.value = true;
      create_insurance({
        ...modelRef,
        effective_date_time: modelRef.start_end_date[0],
        expiry_date_time: modelRef.start_end_date[1],
      })
        .then(() => {
          loading.value = false;
          message.success(t('添加成功'));
          emit('ok', true);
        })
        .catch(() => {
          loading.value = false;
        });
    };
    const craete_beneficiary = (item: any) => {
      if (modelRef.adviser_id) {
        item.loading = true;
        createBeneficiary({
          adviser_id: modelRef.adviser_id,
          ...item,
        })
          .then((id: any) => {
            item.loading = false;
            item.is_create = true;
            (modelRef.beneficiary_ids as Array<any>).push(id);
            event_obj.emit('remoteselect');
          })
          .catch(() => {
            item.loading = false;
          })
          .finally(() => {
            item.loading = false;
          });
      } else {
        notification.error({
          message: '提示',
          description: '请先选择确认顾问！',
        });
      }
    };
    const getDateTime = (time: any) => {
      if (time) {
        time = time.substring(0, 16);
      }
      return time;
    };
    const getMonth = (time: any) => {
      if (time) {
        time = time.substring(0, 7);
      }
      return time;
    };
    const getDate = (time: any) => {
      if (time) {
        time = time.substring(0, 10);
      }
      return time;
    };

    return {
      craete_insurance_fun,
      craete_beneficiary,
      getDateTime,
      getMonth,
      getDate,
      createBeneficiary,
      get_form_obj,
      form,
      is_from,
      loading,
      options,
      modelRef,
      validateInfos,
    };
  },
});
</script>
