
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  UploadOutlined,
} from '@ant-design/icons-vue';
import { computed, createVNode, defineComponent, onBeforeUnmount, reactive, ref, watch } from 'vue';
import {
  create_invoice,
  delete_invoice,
  get_cc_bank_by_contract_id,
  query_contract_list,
  query_invoice_list,
} from '@/api/cerp-model';
import { useStore } from 'vuex';
import { message, Modal, notification } from 'ant-design-vue';
import getFormConfig from '@/components/FormRender/RemoteModal/index';
import { useTableDynamicColumns } from '@/utils/hooks/useTableColumn';
import { Pagination, TableColumn, TableColumnSortOrder, TableFilters } from '@/typing';
import { useFetchData } from '@/utils/hooks/useFetchData';
import { useForm } from 'ant-design-vue/es/form';
import { ColumnProps } from 'ant-design-vue/es/table/interface';
import {
  btn_invoice_PDF,
  btn_invoice_send,
  btn_management_fee_invoice,
  btn_refresh_invoice,
  btn_update_invoice,
} from '@/components/super-details/index.ts';
import { clear } from '@/components/show-remittanceset/index1';
import modalBox from '@/components/form-modal/modal-tools';
import { event_obj } from '@/views/profile/basic-invoice/index1';
import { useI18n } from 'vue-i18n';
import moment from 'moment';

type Key = ColumnProps['key'];

interface DataType {
  id: number;
  key: Key;
  name: string;
}

export default defineComponent({
  name: 'SuperDetails',
  components: { UploadOutlined, ExclamationCircleOutlined, CheckCircleOutlined },
  props: {
    title: {
      type: String,
      required: true,
    },
    cc_company: {
      type: Number || String,
      required: false,
    },
    cc_company_name: {
      type: String,
      required: false,
    },
    invoiceObj: {
      type: Object,
      required: true,
    },
    invoiceCreate: {
      type: Boolean,
      required: true,
    },
    pdfNum: {
      type: Number,
      required: true,
    },
  },
  emits: ['ok', 'cancel', 'checked'],
  setup(props, { emit }) {
    const store = useStore();
    const { t } = useI18n();
    const current_org = store.getters['user/current_org'];
    const current_company = computed(() => store.getters['user/current_company']);
    const loading = ref(false);
    onBeforeUnmount(() => {
      clear();
    });
    const invoice_obj = reactive({
      ...props.invoiceObj,
    });
    const modalRef = reactive<{
      selectedRowsKey: Array<any>; // 选中行key数组
      selectedData: Array<any>; // 选中行数据
      visible: boolean;
    }>({
      selectedRowsKey: [],
      selectedData: [],
      visible: false,
    });
    const { searchConfig, searchConfigOptions, getData, columns, slotList } = getFormConfig(
      'cerp-adviser',
    );
    const { state: columnState, dynamicColumns } = useTableDynamicColumns(
      columns as TableColumn[],
      {
        checkAll: false,
        needRowIndex: false,
      },
    );
    const search_modelRef = reactive({
      ...searchConfig.model,
      org_id: current_org.id,
      is_active: true,
    });
    const { validateInfos: searchValidateInfos } = useForm(search_modelRef, {});
    const { reload, setPageInfo, context: state } = useFetchData(getData, {
      current: 1,
      pageSize: 20,
      tableSize: 'middle', // 'default' | 'middle' | 'small'
      stripe: true,
      requestParams: {
        ...search_modelRef,
      },
    });
    const search = () => {
      setPageInfo({
        current: 1,
        ...search_modelRef,
      });
      reload();
    };
    const handleTableChange = (
      { current, pageSize }: Pagination,
      filters: TableFilters,
      sorter: TableColumnSortOrder,
    ) => {
      setPageInfo({
        current: current ? current : state.current,
        pageSize: pageSize ? pageSize : state.pageSize,
        ...filters,
        ...search_modelRef,
      });
      reload();
    };

    // 选择功能
    const rowSelection = {
      type: 'radio', // 单选
      columnWidth: '30px',
      selectedRowKeys: modalRef.selectedRowsKey,
      onChange: (selectedRowKeys: Key[], selectedRows: DataType[]) => {
        loading.value = false;
        modalRef.selectedRowsKey.length = 0;
        selectedRowKeys.forEach((item: any) => {
          if (item >= 1) {
            modalRef.selectedRowsKey.push(item);
          }
        });
        modalRef.selectedData = selectedRows;
      },
    };
    const refresh_contract = (cc_company_id: any) => {
      if (invoice_obj.adviser_id_list.length >= 1) {
        invoice_obj.adviser_id_list.forEach((item: any) => {
          query_contract_list({
            org_id: current_org.id,
            adviser_id: item.id,
            is_current: true,
            cc_company_id: cc_company_id,
          }).then((res: any) => {
            if (res && res.data && res.data.length > 0) {
              item.contract_date = res.data[0].start_date + '~' + res.data[0].end_date;
              item.remark = res.data[0].remark;
              item.currency__currency = res.data[0].currency__currency;
              item.rate_type = res.data[0].rate_type;
              item.basic_rate = res.data[0].basic_rate;
              item.management_fee_payment_flag = res.data[0].management_fee_payment_flag;
              item.insurance_payment_flag = res.data[0].insurance_payment_flag;
              item.work_location = res.data[0].work_location__name;
              item.recuitment_company = res.data[0].recuitment_company__company_name;
              item.company_bank_charge = res.data[0].company_bank_charge;
              item.insurance_fee = res.data[0].c_insurance_fee;
              item.is_bank_charge_payment = res.data[0].is_bank_charge_payment;
              item.contract_id = res.data[0].id;
              item.cc_company__name = res.data[0].cc_company__name;
              item.cc_company_id = res.data[0].cc_company_id;
              item.overtime_rate = res.data[0].overtime_rate;
            } else {
              query_contract_list({
                org_id: current_org.id,
                adviser_id: item.id,
                cc_company_id: cc_company_id,
              }).then((res1: any) => {
                if (res1 && res1.data && res1.data.length > 0) {
                  item.contract_date = res1.data[0].start_date + '~' + res1.data[0].end_date;
                  item.remark = res1.data[0].remark;
                  item.currency__currency = res1.data[0].currency__currency;
                  item.rate_type = res1.data[0].rate_type;
                  item.basic_rate = res1.data[0].basic_rate;
                  item.management_fee_payment_flag = res1.data[0].management_fee_payment_flag;
                  item.insurance_payment_flag = res1.data[0].insurance_payment_flag;
                  item.work_location = res1.data[0].work_location__name;
                  item.recuitment_company = res1.data[0].recuitment_company__company_name;
                  item.company_bank_charge = res1.data[0].company_bank_charge;
                  item.insurance_fee = res1.data[0].c_insurance_fee;
                  item.is_bank_charge_payment = res1.data[0].is_bank_charge_payment;
                  item.contract_id = res1.data[0].id;
                  item.cc_company__name = res1.data[0].cc_company__name;
                  item.overtime_rate = res.data[0].overtime_rate;
                } else {
                  item.contract_date = null;
                  item.remark = null;
                  item.currency__currency = null;
                  item.rate_type = null;
                  item.basic_rate = null;
                  item.management_fee_payment_flag = null;
                  item.insurance_payment_flag = null;
                  item.work_location = null;
                  item.recuitment_company = null;
                  item.company_bank_charge = null;
                  item.insurance_fee = null;
                  item.is_bank_charge_payment = null;
                  item.contract_id = null;
                  item.cc_company__name = null;
                  item.overtime_rate = null;
                }
              });
            }
          });
          item.is_create = false;
          item.is_loading = false;
          item.invoice = {};
        });
      }
    };
    refresh_contract(current_company.value?.id);
    watch(
      () => props.cc_company,
      () => {
        console.log('props.cc_company', props.cc_company);
        refresh_contract(props.cc_company);
      },
    );
    const old_adviser_id_list = JSON.parse(JSON.stringify(invoice_obj.adviser_id_list));
    const update_contracts = (obj: any, item: any) => {
      const editModal = reactive({
        visible: true,
        title: t('修改合同'),
        loading: false,
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'vertical',
            col: [],
          },
          fields: [
            {
              type: 'input',
              name: 'cc_company_name',
              label: 'CC公司',
              label_i18n: 'CC公司',
              placeholder_i18n: '请选择CC公司',
              disabled: true,
              inputType: 'text',
              maxlength: 100,
              defaultValue: '',
            },
            {
              type: 'remoteselect',
              name: 'contract_id',
              label: '合同',
              label_i18n: '合同',
              placeholder_i18n: '请选择合同',
              disabled: false,
              placeholder: '请选择合同',
              datasourceType: 'remote',
              mode: 'default',
              allowClear: true,
              init_model: {
                adviser_id: item?.id,
                cc_company_id: props.cc_company,
              },
              labelKeyList: ['start_date', 'end_date'],
              valueKey: 'id',
              modalType: 'cerp-contract',
            },
          ],
          rules: {
            contract_id: [{ required: true, message: t('请选择合同') }],
          },
          model: reactive({
            org_id: current_org?.id,
            contract_id: null,
            cc_company_id: props.cc_company,
            cc_company_name: props.cc_company_name,
          }),
        },
        options: {},
        ok: (payload: any) => {
          item.contract_id = payload.contract_id;
          item.is_contract_id = true;
          query_contract_list({
            org_id: current_org.id,
            ids: payload.contract_id,
          }).then((res: any) => {
            if (res && res.data && res.data.length > 0) {
              item.contract_date = res.data[0].start_date + '~' + res.data[0].end_date;
              item.remark = res.data[0].remark;
              item.currency__currency = res.data[0].currency__currency;
              item.rate_type = res.data[0].rate_type;
              item.basic_rate = res.data[0].basic_rate;
              item.management_fee_payment_flag = res.data[0].management_fee_payment_flag;
              item.insurance_payment_flag = res.data[0].insurance_payment_flag;
              item.work_location = res.data[0].work_location__name;
              item.recuitment_company = res.data[0].recuitment_company__company_name;
              item.company_bank_charge = res.data[0].company_bank_charge;
              item.insurance_fee = res.data[0].c_insurance_fee;
              item.is_bank_charge_payment = res.data[0].is_bank_charge_payment;
              item.cc_company__name = res.data[0].cc_company__name;
              item.overtime_rate = res.data[0].overtime_rate;
              item.cc_company_id = res.data[0].cc_company_id;
              item.is_current = res.data[0].is_current;
              item.recuitment_company_id = res.data[0].recuitment_company_id;
              item.cc_company__bank_charge = res.data[0].cc_company__bank_charge;
            }
          });
        },
      });
      modalBox(editModal);
    };
    const del_invoice = (obj: any, item: any) => {
      invoice_obj.adviser_id_list.map((res: any, index: any) => {
        if (res.id == item.id) {
          invoice_obj.adviser_id_list.splice(index, 1);
        }
      });
    };
    const create_invoice_pro = (obj: any, item: any) => {
      if (!item.contract_id) {
        notification.error({
          message: t('提示'),
          description: t('该顾问无当前合同，请单独创建请款单'),
        });
      } else if (!obj.service_month) {
        notification.error({
          message: t('提示'),
          description: t('服务月份不能为空，请修改上传导入文件'),
        });
      } else if (!obj.invoice_start_date) {
        notification.error({
          message: t('提示'),
          description: t('请款起始日期不能为空，请修改上传导入文件'),
        });
      } else {
        item.is_loading = true;
        if (obj?.invoice_type == 'Salary') {
          query_invoice_list({
            org_id: current_org.id,
            service_month: obj?.service_month,
            invoice_type: 1,
            is_active: true,
            cc_company_id: props.cc_company,
            adviser_id: item?.id,
          }).then((res1: any) => {
            if (res1 && res1.data && res1.data.length > 0 && res1.data.length < 2) {
              item.is_loading = false;
              Modal.confirm({
                title: t('提示'),
                content: t('当前已有一个工资类型请款单,是否创建另一个?'),
                okText: t('是'),
                cancelText: t('否'),
                icon: createVNode(ExclamationCircleOutlined),
                onOk() {
                  return new Promise(resolve => {
                    const invoice_objs: any = {
                      customfield_json: JSON.stringify(obj.customfield_list),
                      adviser_id: item?.id,
                      contract_id: item?.contract_id,
                      service_month: obj?.service_month,
                      overtime_rate: obj?.overtime_rate ? obj?.overtime_rate : item?.overtime_rate,
                      overtime_amount: obj?.overtime_amount,
                      invoice_type:
                        obj?.invoice_type == 'Expense'
                          ? 2
                          : obj?.invoice_type == 'Salary'
                          ? 1
                          : obj?.invoice_type == 'Overtime'
                          ? 4
                          : obj?.invoice_type == 'Credit Note'
                          ? 3
                          : null,
                      invoice_start_date: obj?.invoice_start_date,
                      basic_amount: obj?.basic_amount,
                      work_unit: obj?.work_unit,
                      accommodation_unit: obj?.accommodation_unit,
                      accommodation_amount: obj?.accommodation_amount,
                      overtime: obj?.overtime,
                      remark: obj?.remark,
                      payment_term: obj?.payment_term,
                      bank_charge_cost:
                        obj?.invoice_type == 'Salary'
                          ? // ? item?.is_current
                            item?.company_bank_charge
                          : // : item?.cc_company__bank_charge
                            0,
                      insurance_fee: obj?.invoice_type == 'Salary' ? item?.c_insurance_fee : 0,
                    };
                    get_cc_bank_by_contract_id({
                      org_id: current_org.id,
                      contract_id: item?.contract_id,
                    })
                      .then((res: any) => {
                        invoice_objs.bank_account_id = res.cc_bank_account_id;
                        create_invoice({
                          org_id: current_org?.id,
                          ...invoice_objs,
                        }).then((res: any) => {
                          emit('ok', true);
                          message.success(t('创建成功'));
                          item.is_create = true;
                          query_invoice_list({
                            org_id: current_org?.id,
                            ids: [res.id],
                          }).then((res1: any) => {
                            item.invoice = res1.data[0];
                            invoice_obj.adviser_id_list.length = 0;
                            invoice_obj.adviser_id_list.push(item);
                          });
                        });
                      })
                      .finally(() => {
                        resolve(null);
                      });
                  });
                },
                onCancel() {},
              });
            } else if (res1 && res1.data && res1.data.length >= 2) {
              item.is_loading = false;
              notification.error({
                message: t('温馨提示'),
                description: t('工资类型请款单已有2个，不可继续创建'),
              });
            } else {
              const invoice_objs: any = {
                customfield_json: JSON.stringify(obj.customfield_list),
                adviser_id: item?.id,
                contract_id: item?.contract_id,
                service_month: obj?.service_month,
                overtime_rate: obj?.overtime_rate ? obj?.overtime_rate : item?.overtime_rate,
                overtime_amount: obj?.overtime_amount,
                invoice_type:
                  obj?.invoice_type == 'Expense'
                    ? 2
                    : obj?.invoice_type == 'Salary'
                    ? 1
                    : obj?.invoice_type == 'Overtime'
                    ? 4
                    : obj?.invoice_type == 'Credit Note'
                    ? 3
                    : null,
                invoice_start_date: obj?.invoice_start_date,
                basic_amount: obj?.basic_amount,
                work_unit: obj?.work_unit,
                accommodation_unit: obj?.accommodation_unit,
                accommodation_amount: obj?.accommodation_amount,
                overtime: obj?.overtime,
                remark: obj?.remark,
                payment_term: obj?.payment_term,
                bank_charge_cost:
                  obj?.invoice_type == 'Salary'
                    ? // ? item?.is_current
                      item?.company_bank_charge
                    : // : item?.cc_company__bank_charge
                      0,
                insurance_fee: obj?.invoice_type == 'Salary' ? item?.c_insurance_fee : 0,
              };
              get_cc_bank_by_contract_id({
                org_id: current_org.id,
                contract_id: item?.contract_id,
              })
                .then((res: any) => {
                  invoice_objs.bank_account_id = res.cc_bank_account_id;
                  create_invoice({
                    org_id: current_org?.id,
                    ...invoice_objs,
                  }).then((res: any) => {
                    emit('ok', true);
                    message.success(t('创建成功'));
                    item.is_create = true;
                    query_invoice_list({
                      org_id: current_org?.id,
                      ids: [res.id],
                    }).then((res1: any) => {
                      item.invoice = res1.data[0];
                      invoice_obj.adviser_id_list.length = 0;
                      invoice_obj.adviser_id_list.push(item);
                    });
                  });
                })
                .finally(() => {
                  item.is_loading = false;
                });
            }
          });
        } else {
          const invoice_objs: any = {
            customfield_json: JSON.stringify(obj.customfield_list),
            adviser_id: item?.id,
            contract_id: item?.contract_id,
            service_month: obj?.service_month,
            overtime_rate: obj?.overtime_rate ? obj?.overtime_rate : item?.overtime_rate,
            overtime_amount: obj?.overtime_amount,
            invoice_type:
              obj?.invoice_type == 'Expense'
                ? 2
                : obj?.invoice_type == 'Salary'
                ? 1
                : obj?.invoice_type == 'Overtime'
                ? 4
                : obj?.invoice_type == 'Credit Note'
                ? 3
                : null,
            invoice_start_date: obj?.invoice_start_date,
            basic_amount: obj?.basic_amount,
            work_unit: obj?.work_unit,
            accommodation_unit: obj?.accommodation_unit,
            accommodation_amount: obj?.accommodation_amount,
            overtime: obj?.overtime,
            remark: obj?.remark,
            payment_term: obj?.payment_term,
            bank_charge_cost:
              obj?.invoice_type == 'Salary'
                ? // ? item?.is_current
                  item?.company_bank_charge
                : // : item?.cc_company__bank_charge
                  0,
            insurance_fee: obj?.invoice_type == 'Salary' ? item?.c_insurance_fee : 0,
          };
          get_cc_bank_by_contract_id({
            org_id: current_org.id,
            contract_id: item?.contract_id,
          })
            .then((res: any) => {
              invoice_objs.bank_account_id = res.cc_bank_account_id;
              create_invoice({
                org_id: current_org?.id,
                ...invoice_objs,
              }).then((res: any) => {
                emit('ok', true);
                message.success(t('创建成功'));
                item.is_create = true;
                query_invoice_list({
                  org_id: current_org?.id,
                  ids: [res.id],
                }).then((res1: any) => {
                  item.invoice = res1.data[0];
                  invoice_obj.adviser_id_list.length = 0;
                  invoice_obj.adviser_id_list.push(item);
                });
              });
            })
            .finally(() => {
              item.is_loading = false;
            });
        }
      }
    };
    const delete_invoice_pro = (item: any) => {
      delete_invoice({
        org_id: current_org?.id,
        invoice_id: item.id,
      }).then((res: any) => {
        emit('cancel');
        message.success(t('取消成功'));
        item.is_create = false;
        invoice_obj.adviser_id_list.length = 0;
        invoice_obj.adviser_id_list = JSON.parse(JSON.stringify(old_adviser_id_list));
      });
    };
    const getDateTime = (time: any) => {
      if (time) {
        time = time.substring(0, 16);
      }
      return time;
    };
    const getMonth = (time: any) => {
      if (time) {
        time = time.substring(0, 7);
      }
      return time;
    };
    const getDate = (time: any) => {
      if (time) {
        time = time.substring(0, 10);
      }
      return time;
    };

    const handleSubmit = () => {
      invoice_obj.adviser_id_list.reverse();
      const obj = { ...modalRef.selectedData[0] };
      obj.is_create = false;
      obj.is_loading = false;
      obj.invoice = {};
      invoice_obj.adviser_id_list.push(obj);
      invoice_obj.adviser_id_list.reverse();
      modalRef.visible = false;
      modalRef.selectedRowsKey.length = 0;
    };

    const open_pdf = (file_url: any) => {
      if (file_url) {
        window.open('https://erp-cc.oss-cn-hongkong.aliyuncs.com/' + file_url, '_blank');
        // axios.get('/' + file_url,{
        //   responseType: 'blob' }).then((res: any) => {
        //   var blob = res.data;
        //   // FileReader主要用于将文件内容读入内存
        //   var reader = new FileReader();
        //   reader.readAsDataURL(blob);
        //   // onload当读取操作成功完成时调用
        //   reader.onload = (e: any) => {
        //     var a = document.createElement('a');
        //     // 获取文件名fileName
        //     var fileName = res.config["url"].split("/");
        //     fileName = fileName[fileName.length - 1];
        //     // fileName = fileName.replace(/"/g, "");
        //     a.download = fileName;
        //     a.href = e.target.result;
        //     document.body.appendChild(a);
        //     a.click();
        //     document.body.removeChild(a);
        //   }
        // })
      } else {
        notification.error({
          message: t('提示'),
          description: t('无对应附件'),
        });
      }
    };

    watch(
      () => props.pdfNum,
      () => {
        if (
          invoice_obj.adviser_id_list.length == 1 &&
          invoice_obj.adviser_id_list[0].invoice.status == 1
        ) {
          refresh();
        }
        if (
          invoice_obj.adviser_id_list.length == 1 &&
          invoice_obj.adviser_id_list[0].invoice.status == 6
        ) {
          refresh();
        }
      },
    );
    watch(
      () => invoice_obj.checked,
      () => {
        emit('checked', invoice_obj.checked);
      },
    );
    watch(
      () => props.invoiceObj.checked,
      () => {
        invoice_obj.checked = props.invoiceObj.checked;
      },
    );
    const refresh = () => {
      query_invoice_list({
        org_id: current_org?.id,
        ids: [invoice_obj.adviser_id_list[0].invoice.id],
      }).then((res: any) => {
        Object.assign(invoice_obj.adviser_id_list[0].invoice, res.data[0]);
        if (res.data[0].email_record__status == 2) {
          message.error(res.data[0].invoice_no + t('发送失败'));
          event_obj.emit('is_email');
        }
        if (res.data[0].pdf_status == 2) {
          message.error(res.data[0].invoice_no + t('PDF生成失败'));
          event_obj.emit('is_pdf');
        }
        if (res.data[0].status == 1 && res.data[0].pdf_status == 1) {
          setTimeout(() => {
            refresh();
          }, 1000);
        } else {
          event_obj.emit('is_pdf');
        }
        if (res.data[0].status == 6 && res.data[0].email_record__status == 0) {
          setTimeout(() => {
            refresh();
          }, 1000);
        } else {
          event_obj.emit('is_email');
        }
      });
    };
    return {
      t,
      searchConfig,
      searchConfigOptions,
      slotList,
      dynamicColumns,
      columnState,
      rowSelection,
      state,
      search_modelRef,
      modalRef,
      loading,
      invoice_obj,
      searchValidateInfos,
      btn_update_invoice,
      btn_management_fee_invoice,
      btn_invoice_send,
      btn_invoice_PDF,
      btn_refresh_invoice,
      getDateTime,
      open_pdf,
      search,
      handleTableChange,
      create_invoice_pro,
      delete_invoice_pro,
      handleSubmit,
      getMonth,
      getDate,
      del_invoice,
      update_contracts,
    };
  },
});
