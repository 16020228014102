<template>
  <a-modal
    :title="title"
    width="1000px"
    :maskClosable="false"
    :visible="visible"
    :confirmLoading="loading"
    centered
    :footer="null"
    @cancel="
      () => {
        $emit('cancel');
      }
    "
  >
    <a-card :body-style="{ padding: 0 }" ref="elRef">
      <a-table
        bordered="true"
        size="small"
        :scroll="{ y: 500, x: columnState.tableWidth }"
        :columns="dynamicColumns"
        row-key="adviser_id"
        :data-source="dataSource"
        :pagination="false"
      >
        <template
          v-for="item in slotList"
          v-slot:[item.slotName]="{ index, text }"
          :key="item.slotName"
        >
          <span v-if="item.slotName === 'index'">
            {{ index + 1 + state.pageSize * (state.current - 1) }}
          </span>
        </template>
      </a-table>
    </a-card>
  </a-modal>
</template>
<script lang="ts">
import { computed, createVNode, defineComponent, reactive, ref } from 'vue';
import getFormConfig from '@/components/form-modal/table-modal/index';
import { useFetchData } from '@/utils/hooks/useFetchData';
import { useStore } from 'vuex';
import { useTableDynamicColumns } from '@/utils/hooks/useTableColumn';
import { Pagination, TableColumn, TableColumnSortOrder, TableFilters } from '@/typing';
import { message, Modal, notification } from 'ant-design-vue';
import { ExclamationCircleOutlined, ReloadOutlined } from '@ant-design/icons-vue';
import { Record } from '@/components/page-model/typing';
import modalBox from '@/components/form-modal/modal-tools';
export default defineComponent({
  name: 'ListModal',
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
    },
    dataSource: {
      type: Array,
      required: true,
    },
  },
  emits: ['ok', 'cancel'],
  setup(props, { emit }) {
    const store = useStore();
    const current_org = store.getters['user/current_org'];
    const confirmLoading = ref<boolean>(false);

    const baseColumns = [
      {
        title: '顾问',
        width: 200,
        dataIndex: 'adviser_name',
        checked: true,
      },
      {
        title: '工号',
        width: 60,
        dataIndex: 'employee_number',
        checked: true,
      },
      {
        title: '失败反馈',
        minWidth: 120,
        dataIndex: 'msg',
        checked: true,
      },
    ]
    const { state: columnState, dynamicColumns } = useTableDynamicColumns(
      baseColumns as TableColumn[],
      {
        checkAll: false,
        needRowIndex: false,
      },
    );

    return {
      props,
      columnState,
      dynamicColumns,
      confirmLoading,
    };
  },
  components: {
    ReloadOutlined,
  },
});
</script>
