import mqtt from 'mqtt';
import bus from '../bus';

let $bus: {
  emit: (arg0: string, arg1: any) => void;
} | null;
let mqtt_state: number; //0:未连接 1:已经 -1:错误
let sub_topic: any;
let client: mqtt.MqttClient | null;
const need_subscribe_list:Array<any> = [];
export default () => {
  console.log(client);
  console.log($bus);
  const username: any = '';

  const http: any = window.location.href.split('/')[0];
  let mqtt_host: any = window.location.href.split('/')[2];
  let mqtt_port: any = '';
  let wss: any = '';
  if (http == 'http:') {
    wss = 'ws';
    if (mqtt_host.split(':').length == 2) {
      mqtt_port = mqtt_host.split(':')[1];
      mqtt_host = mqtt_host.split(':')[0];
    } else {
      mqtt_port = '80';
    }
  } else {
    mqtt_port = '443';
    wss = 'wss';
  }
  const init = () => {
    $bus = bus;
    mqtt_state = 0;
    sub_topic = {};
    const base_configs = {
      connection: {
        host: mqtt_host,
        port: mqtt_port,
        endpoint: '/mqtt',
        clean: true, // 保留会话
        keepalive: 10, // 心跳时间
        connectTimeout: 3000, // 超时时间
        reconnectPeriod: 4000, // 重连时间间隔
        clientId: 'ant_web_' + new Date().getTime(),
        username: username,
        password: '',
      },
    };
    const config = {
      host: '',
      client_type: '',
      username: '',
      password: '',
      host_timeout: false,
      is_login: false,
      clientId: '',
      ...base_configs,
    };

    const { host, port, endpoint, ...options } = config.connection;
    const connectUrl = `${wss}://${host}:${port}${endpoint}`;
    client = mqtt.connect(connectUrl, options);
    client.on('connect', () => {
      console.log('mqtt connect');
      console.log('client connected:' + client?.connected);

      mqtt_state = 1;
      for (const topic in sub_topic) {
        client?.subscribe(topic, sub_topic[topic]);
      }
      $bus?.emit('mqtt_state', mqtt_state);
      if(need_subscribe_list.length>0){
        need_subscribe_list.map((item:any)=>{
          channel_subscribe_event(item.channel, item.event_type);
        });
        need_subscribe_list.length = 0;
      }

      // client?.subscribe('foo', { qos: 0 }, (error: any) => {
      //   if (error) {
      //     console.log('Subscribe to topics error', error);
      //   }
      // });
      //#test publish:
      // client?.publish(
      //   'foo',
      //   '{ "fun": "$broadcast","event_type":"foo","data":"1" }',
      //   { qos: 0 },
      //   error => {
      //     if (error) {
      //       console.log('Publish error', error);
      //     }
      //   },
      // );
    });

    client.on('message', (topic: string, message: any) => {
      if (topic.indexOf('channel/') === 0) {
        const topic_arr = topic.split('/');
        $bus?.emit(topic_arr[2], JSON.parse(message));
      } else if (topic.indexOf('monitor') >= 0) {
        const topic_arr = topic.split('/');
        $bus?.emit(topic_arr[1], JSON.parse(message));
      } else {
        $bus?.emit(topic, JSON.parse(message));
      }
    });
    client.on('error', () => {
      console.log('client connected:' + client?.connected);
      mqtt_state = -1;
      $bus?.emit('mqtt_state', mqtt_state);
      setTimeout(() => {
        if (!client?.connected && !client?.reconnecting) {
          client?.reconnect();
        }
      }, 2000);
      // if (topic.indexOf('channel/') === 0) {
      //   const topic_arr = topic.split('/');
      // handle_event(topic_arr[3], JSON.parse(message));
      // } else if (topic.indexOf('group/') === 0) {
      // handle_event(topic, JSON.parse(message));
      // }
    });
  };
  // const handle_event = (event_type: any, object: any) => {
  // postMessage({ event_type: event_type, data: object, fun: '$broadcast' });
  // };
  /**
   * 初始化登录后,应该订阅的信息频道
   */
    // const init_subscribe = () => {};

  const channel_subscribe_event = (channel: string | number, event_type: string) => {
      let web_flag: any = '';
      const str: any = window.location.host;
      if (str == 'erp.cmes.work') {
        web_flag = 'cc_erp';
      } else {
        web_flag = 'cc_erp_test';
      }
      let topic: string;
      if (channel) {
        topic = 'channel/' + web_flag + '/' + channel + '/' + event_type;
      } else {
        topic = 'channel/' + web_flag + '/' + event_type;
      }
      if (client && client.connected) {

        sub_topic[topic] = { qos: 1 };
        client.subscribe(topic, { qos: 1 });
        console.log(`subscribe event: ${channel}/${event_type}!`);
      }else{
        need_subscribe_list.push({ channel: channel, event_type: event_type });
      }
    };

  const channel_unsubscribe_event = (channel: string | number, event_type: string) => {
    let web_flag: any = '';
    const str: any = window.location.host;
    if (str == 'erp.cmes.work') {
      web_flag = 'cc_erp';
    } else {
      web_flag = 'cc_erp_test';
    }
    if (client && client.connected) {
      let topic: string;
      if (channel) {
        topic = 'channel/' + web_flag + '/' + channel + '/' + event_type;
      } else {
        topic = 'channel/' + web_flag + '/' + event_type;
      }
      delete sub_topic[topic];
      client.unsubscribe(topic);
      console.log(`unsubscribe event: ${channel}/${event_type}!`);
    }
  };

  const liyudas_subscribe_event = (event_type: string) => {
    if (client && client.connected) {
      const topic = 'liyudas/datascript/' + event_type;
      sub_topic[topic] = { qos: 1 };
      client.subscribe(topic, { qos: 1 });
      console.log(`subscribe event: ${topic}!`);
    }
  };

  function liyudas_unsubscribe_event(event_type: string) {
    if (client && client.connected) {
      const topic = 'liyudas/datascript/' + event_type;
      delete sub_topic[topic];
      client.unsubscribe(topic);
      console.log(`unsubscribe event: ${topic}!`);
    }
  }
  // const is_mqttserver = () => {
  //   let mqtt_state;
  //   if (localStorage.get('mqtt_state')) {
  //     mqtt_state = parseInt(localStorage.get('mqtt_state'));
  //   }
  //   if (mqtt_state == undefined) {
  //     return 1;
  //   }
  //   return mqtt_state;
  // };
  const logout = () => {
    client?.end(true, {}, () => {
      mqtt_state = -1;
      $bus?.emit('mqtt_state', mqtt_state);
    });
  };
  return {
    init: init,
    // login: login,
    logout: logout,
    // init_subscribe: init_subscribe,
    // is_mqttserver: is_mqttserver,
    channel_subscribe_event: channel_subscribe_event,
    channel_unsubscribe_event: channel_unsubscribe_event,
    liyudas_subscribe_event: liyudas_subscribe_event,
    liyudas_unsubscribe_event: liyudas_unsubscribe_event,
  };
};
